import {NgxCookiebotConfig} from '@halloverden/ngx-cookiebot';

export class CookiebotConfig implements NgxCookiebotConfig {
  blockingMode: 'auto' | 'manual' | string = 'auto';
  cbId: string = 'cdaf73ae-0ad2-4547-b480-573b2aeb565d';
  cdn: 'com' | 'eu' | string = 'eu';
  loadScript: boolean = true;
  culture?: string;
  framework?: string;
  level?: string;
  type?: string;
  widgetEnabled?: boolean = false;
  widgetPosition?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | string;
  widgetDistanceVertical?: number;
  widgetDistanceHorizontal?: number;
}
