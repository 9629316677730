import {AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import clamp from 'lodash-es/clamp';
import {CurrentCourseService} from '../../../../services/current-course-service';

@Component({
  selector: 'app-super-radial-meter',
  templateUrl: './super-radial-meter.component.html',
  styleUrls: ['./super-radial-meter.component.scss']
})
export class SuperRadialMeterComponent implements OnChanges, AfterViewInit {

  @Input()
  goal: number;

  @Input()
  current: number;

  @Input()
  visibleGoal = false;

  @Input()
  visibleCurrent = false;

  @ViewChild('greyLine', {static: false})
  greyLine: ElementRef;

  public visibilityOfArrow: { [key: number]: string } = {};
  public visibilityOfTick: { [key: number]: string } = {};
  public gradientNameOfMainLine = '';

  protected totalLineLength: number;

  protected max = 60;


  goalDashArray = '0 10000';
  goalDashOffset = '10000';
  currentDashArray = '0 10000';
  currentDashOffset = '10000';
  shimmerDashArray = '0 10000';
  shimmerDashOffset = '10000';

  constructor(
    protected currentCourseService: CurrentCourseService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.goal || changes.current || changes.visibleGoal || changes.visibleCurrent) {
      if (this.goal || this.current) {
        this.recalculateLengths();
      }
    }
  }

  ngAfterViewInit(): void {
    this.reloadMeter();
  }

  reloadMeter() {
    const line: SVGPathElement = this.greyLine.nativeElement;
    this.totalLineLength = line.getTotalLength();
    setTimeout(
      () => {
        this.recalculateLengths();
      },
      0
    );
  }

  recalculateLengths() {

    if (!this.totalLineLength) {

      this.goalDashArray = '0 10000';
      this.currentDashArray = '0 10000';
      this.goalDashOffset = '1000';
      this.currentDashOffset = '1000';

      return;
    }

    const goalPercentage = clamp(this.goal / this.max, 0, 1);
    const currentPercentage = clamp(this.current / this.max, 0, 1);

    let goalLength = this.totalLineLength * goalPercentage;
    let currentLength = this.totalLineLength * currentPercentage;

    if (!this.visibleGoal) {
      goalLength = 0;
    }

    if (!this.visibleCurrent) {
      currentLength = 0;
    }


    this.goalDashArray = this.totalLineLength + ' 10000';
    this.currentDashArray = this.totalLineLength + ' 10000';
    this.shimmerDashArray = this.totalLineLength + ' 10000';

    this.goalDashOffset = '' + (this.totalLineLength - goalLength);
    this.currentDashOffset = '' + (this.totalLineLength - currentLength);
    this.shimmerDashOffset = '' + ((this.totalLineLength - currentLength * 0.96));

    this.visibilityOfArrow = {};
    for (let i = 5; i <= 55; i += 5) {
      this.visibilityOfArrow[i] = this.calculateVisibilityOfArrow(i);
    }

    this.visibilityOfTick = {};
    for (let i = 0; i <= 55; i += 5) {
      this.visibilityOfTick[i] = this.calculateVisibilityOfTick(i);
    }

    this.gradientNameOfMainLine = this.getGradientNameOfMainLine();

  }


  protected calculateVisibilityOfTick(nmbr: number): string {
    if (nmbr > this.goal - 3) {
      return 'hidden';
    }
    return 'visible';
  }

  protected calculateVisibilityOfArrow(nmbr: number): string {
    // return 'visible';
    if (Math.abs(nmbr - this.goal) <= 2.5) {
      return 'visible';
    }
    return 'hidden';
  }

  protected getGradientNameOfMainLine() {
    if (this.current >= this.goal) {
      return '#linearGradient-green';
    } else {
      return '#linearGradient-1';
    }
  }
}
