import { Injectable } from '@angular/core';
import {LocationStrategy} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppNavigateService {

  storedRedirectUrl = '';

  STORED_REDIRECT_URL_KEY = 'app-redirect-url';

  constructor(
    public locationStrategy: LocationStrategy
  ) { }

  disableBackNavigation() {
    history.pushState(null, null, window.location.href);
    history.pushState(null, null, window.location.href);
    history.pushState(null, null, window.location.href);
    history.pushState(null, null, window.location.href);

    this.locationStrategy
      .onPopState((c: PopStateEvent) => {
        if ((c.currentTarget as Window).location.href.indexOf('/login') !== -1) {
          history.pushState(null, null, window.location.href);
          return false;
        }
      })
  }

  public setRedirectUrl(url: string) {
    if (url) {
      this.storedRedirectUrl = url;
      localStorage.setItem(this.STORED_REDIRECT_URL_KEY, this.storedRedirectUrl);
    }
  }

  public getRedirectUrl(): string | undefined {
    // look to local variable
    if (this.storedRedirectUrl) {
      return this.storedRedirectUrl;
    }

    // if there isnt anything, try to look to local storage
    const itemFromLocalStorage = localStorage.getItem(this.STORED_REDIRECT_URL_KEY) as string;
    // if there is anything, restore it
    if (itemFromLocalStorage) {
      this.storedRedirectUrl = itemFromLocalStorage;
      return this.storedRedirectUrl;
    }

    return undefined;
  }

  public removeRedirectUrl() {
    this.storedRedirectUrl = null;
    localStorage.removeItem(this.STORED_REDIRECT_URL_KEY);
  }
}
