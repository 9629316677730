import { Injectable } from '@angular/core';
import {TTS_MODE} from './tts-mode';
import {CacheEntry} from './cache-entry';
import { isArray } from 'lodash-es';
import {CACHE_LENGTH} from './cache-length';
import * as saveAs from 'file-saver';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../config/app-config';
import {CurrentUserService} from '../auth/current-user.service';
import {CurrentCourseService} from '../services/current-course-service';
import {SoundService} from '../common/sound/sound.service';
import {SoundClip} from '../common/sound/sound-clip.interface';
import {VocabularyWord} from '../../../oj-app-common/vocabulary/models/vocabulary-word';
import {VocabularyTrainingWord} from '../../../oj-app-common/vocabulary/models/vocabulary-training-word';
@Injectable({
  providedIn: 'root'
})
export class TtsService {

  protected cache: CacheEntry[] = [];

  protected problematicCharacters = {
    from: [],
    to: [],
    regexp: [],
  };

  constructor(
    private appConfig: AppConfig,
    private currentUserService: CurrentUserService,
    private currentCourseService: CurrentCourseService,
    private soundService: SoundService,
    private http: HttpClient
  ) {
    this.problematicCharacters.from = `“”‘’‛‟′″´˝\``.split('');
    this.problematicCharacters.to = `""'''"'"'"'`.split('');
    this.problematicCharacters.regexp = this.problematicCharacters.from.map(
      (ch) => new RegExp(ch, 'g')
    );

    this.soundService.didDestroyAllSounds.subscribe(
      () => {
        this.flushCache();
      }
    );
  }

  protected flushCache() {
    this.cache = [];
  }

  static getWordForTts(word: VocabularyWord | VocabularyTrainingWord, type: 'source' | 'translation') {
    let w = '';

    if (word.prefix && word.prefix.length > 0) {
      w += word.prefix + ' ';
    }

    w += word[type];

    return w;
  }

  public say(word: string, mode: TTS_MODE = TTS_MODE.SPEAK_ALL, lessonId: number = null): Promise<void> {

    const normalizedWord = this.normalizeText(word, mode);

    let audio: SoundClip;

    const fromCache = this.findInCache(normalizedWord);

    if (fromCache) {
      audio = fromCache;
    } else {
      audio = this.createSoundClip(normalizedWord, lessonId);
      this.saveToCache(normalizedWord, audio);
    }

    return new Promise<void>(
      (resolve, reject) => {
        audio.errorHappened.toPromise().then(
          (e) => {
            reject(e);
          }
        );
        let startedPlaying = false;
        let changeSubscription = audio.didChangePlayingStatus.subscribe(
          (s) => {
            if (s) {
              startedPlaying = true;
            } else {
              if (startedPlaying) {
                changeSubscription.unsubscribe();
                changeSubscription = null;
                resolve();
              }
            }
          }
        );
        audio.play();
      }
    );

  }

  downloadMp3(words: string[], fileName = 'vocabulary', lessonId: number | null = null) {
    const normalizedWords = words.map((w) => this.normalizeText(w));
    const url = this.getMultipleWordsUrlAddress(normalizedWords, lessonId)

    this.http
      .get(url, {responseType: 'blob'})
      .subscribe(v => {
        console.log(v);
        saveAs(v, `${fileName}.mp3`)
      })
  }

  downloadMp3FromString(words: string, fileName = 'audio', directlyAudio = false, lessonId: number | null = null) {
    let url = '';

    if (!directlyAudio) {
      const normalizedWords = this.normalizeText(words);
      url = this.getUrlAddress(normalizedWords, lessonId)
    } else {
      url = words
    }

    this.http
      .get(url, {responseType: 'blob'})
      .subscribe(v => {
        console.log(v);
        saveAs(v, `${fileName}.mp3`)
      })
  }

  public getPlayer(multipleWords: string[]): SoundClip;
  public getPlayer(multipleWords: string[], mode: TTS_MODE): SoundClip;
  public getPlayer(multipleWords: string[], mode: TTS_MODE, lessonId: number | null): SoundClip;
  public getPlayer(word: string): SoundClip;
  public getPlayer(word: string, mode: TTS_MODE): SoundClip;
  public getPlayer(word: string, mode: TTS_MODE, lessonId: number | null): SoundClip;
  public getPlayer(wordOrWords: string|string[], mode = TTS_MODE.SPEAK_ALL, lessonId: number | null = null): SoundClip {

    if (typeof wordOrWords === 'string') {
      const normalizedWord = this.normalizeText(wordOrWords, mode);

      const fromCache = this.findInCache(normalizedWord);

      if (fromCache) {
        return fromCache;
      } else {
        const audio = this.createSoundClip(normalizedWord, lessonId);
        this.saveToCache(normalizedWord, audio);
        return audio;
      }
    } else if (isArray(wordOrWords)) {

      const normalizedWords = wordOrWords.map((w) => this.normalizeText(w, mode));
      const normalizedWordsAsKey = normalizedWords.join('... ');

      const fromCache = this.findInCache(normalizedWordsAsKey);

      if (fromCache) {
        return fromCache;
      } else {
        const audio = this.createMultipleWordsSoundClip(normalizedWords, lessonId);
        this.saveToCache(normalizedWordsAsKey, audio);
        return audio;
      }

    } else {
      throw new Error('Invalid text to speech - give either a string or an array of strings');
    }
  }

  protected createSoundClip(text, lessonId: number | null = null): SoundClip {
    const url = this.getUrlAddress(text, lessonId);
    return this.soundService.createSound(url);
  }

  protected createMultipleWordsSoundClip(words: string[], lessonId: number | null = null): SoundClip {
    const url = this.getMultipleWordsUrlAddress(words, lessonId);
    return this.soundService.createSound(url);
  }

  protected findInCache(text: string): SoundClip {
    for (const entry of this.cache) {
      if (entry.text === text) {
        return entry.audio;
      }
    }
    return null;
  }

  protected saveToCache(text: string, audio: SoundClip) {
    this.cache.push(new CacheEntry(audio, text));
    if (this.cache.length > CACHE_LENGTH) {
      this.cache.splice(0, this.cache.length - CACHE_LENGTH);
    }
  }

  protected normalizeText(word: string, mode = TTS_MODE.SPEAK_ALL) {
    word = word.toLowerCase().trim();
    this.problematicCharacters.regexp.map(
      (regexp, index) => {
        word = word.replace(regexp, this.problematicCharacters.to[index]);
      }
    );
    if (mode === TTS_MODE.VOCABULARY) {
      word = word.replace(/\s?\([^)]*\)/ig, '');
    }
    return word;
  }

  public getUrlAddress(word: string, lessonId: number | null = null) {
    let url = this.appConfig.studovnaApiUrl + '/text-to-speech'
      + '/user/' + this.currentUserService.studovnaId
      + '/token/' + this.currentUserService.token
      + '/course/' + this.currentCourseService.courseId
      + '/owner/' + this.appConfig.ttsOwner;
    if (lessonId !== null) {
      url += '/lesson/' + lessonId;
    }
    url += '/text/' + encodeURIComponent(word)
    return url
  }

  public getMultipleWordsUrlAddress(words: string[], lessonId: number | null = null) {
    let url = this.appConfig.studovnaApiUrl + '/text-to-speech'
      + '/user/' + this.currentUserService.studovnaId
      + '/token/' + this.currentUserService.token
      + '/course/' + this.currentCourseService.courseId
      + '/owner/' + this.appConfig.ttsOwner;
    if (lessonId !== null) {
      url += '/lesson/' + lessonId;
    }
    url += '/words/' + encodeURIComponent(JSON.stringify(words));
    return url;
  }

  public getSayUrl(word: string, mode: TTS_MODE = TTS_MODE.SPEAK_ALL, lessonId: number|null = null): string {
    const normalizedWord = this.normalizeText(word, mode);
    return this.getUrlAddress(normalizedWord, lessonId);
  }
}
