import {NgModule} from '@angular/core';
import {ReportBugDialogComponent} from './report-bug-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule, MatIconModule, MatInputModule} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ModalModule} from "ngx-bootstrap/modal";

@NgModule({
  declarations: [
    ReportBugDialogComponent
  ],
    imports: [
        ModalModule,
        TranslateModule,
        FormsModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        DragDropModule
    ],
  exports: [
    ReportBugDialogComponent
  ]
})

export class ReportBugDialogModule {}
