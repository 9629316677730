import {NgModule} from '@angular/core';
import {PhotoCaptureComponent} from './photo-capture.component';
import {WebcamModule} from 'ngx-webcam';
import {MatButtonModule} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {ModalModule} from "ngx-bootstrap/modal";

@NgModule({
  declarations: [
    PhotoCaptureComponent
  ],
  imports: [
    WebcamModule,
    ModalModule,
    MatButtonModule,
    TranslateModule,
    CommonModule,
    MatIconModule
  ],
  exports: [
    PhotoCaptureComponent
  ]
})

export class PhotoCaptureModule {}
