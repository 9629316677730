import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {LoginService} from '../../services/login.service';
import {Router, RouterOutlet} from '@angular/router';
import {SidebarComponent, sidebarPositions} from '../../components/sidebar/sidebar.component';
import {TranslateService} from '@ngx-translate/core';
import {StudovnaApi} from '../../../../oj-app-common/studovna-api/studovna-api';
import {APP_THEMES} from '../../theme/theme';
import {ThemeService} from '../../theme/theme.service';
import {BaseLayoutService} from './base-layout.service';
import {ActivitySidebarComponent} from './components/activity-sidebar/activity-sidebar.component';
import {slideInAnimation} from '../../animations/slide-in-animation';

@Component({
  selector: 'app-layout-page',
  templateUrl: './base-layout-page.component.html',
  styleUrls: ['./base-layout-page.component.scss'],
  animations: [slideInAnimation]
})
export class BaseLayoutPageComponent implements OnInit, AfterViewInit {
  sidebarOptions = {
    theme: APP_THEMES.dark,
    position: sidebarPositions.side
  };
  protected sidebarContent: ComponentRef<any>;
  protected sidebarFactory: ComponentFactory<any>;

  @ViewChild(SidebarComponent, {static: true}) sidebar: SidebarComponent;
  @ViewChild('container', {static: false, read: ViewContainerRef})
  container: ViewContainerRef;

  constructor(
    private _loginService: LoginService,
    private router: Router,
    private translateService: TranslateService,
    protected studovnaApi: StudovnaApi,
    private themeService: ThemeService,
    private baseLayoutService: BaseLayoutService,
    protected componentFactoryResolver: ComponentFactoryResolver,
  ) {
  }

  ngOnInit() {
    this.baseLayoutService
      .sidebarContent
      .subscribe(c => {
        const newSidebarFactory = this.componentFactoryResolver.resolveComponentFactory(c);
        if (this.sidebarFactory && newSidebarFactory.componentType !== this.sidebarFactory.componentType) {
          this.sidebarFactory = this.componentFactoryResolver.resolveComponentFactory(c);
          this.reloadSidebarContent();
        }
        if (!this.sidebarFactory) {
          this.sidebarFactory = this.componentFactoryResolver.resolveComponentFactory(c);
          this.reloadSidebarContent();
        }
      });

    this.sidebarOptions.theme = this.themeService
      .getActiveTheme();

    this.sidebarOptions.theme = APP_THEMES.light;

    // this.themeService
    //   .themeChanged
    //   .subscribe(thm => this.sidebarOptions.theme = thm);
  }

  ngAfterViewInit() {
    if (this.sidebarFactory) {
      this.sidebarContent = this.container.createComponent(this.sidebarFactory);
    } else {
      this.sidebarFactory = this.componentFactoryResolver.resolveComponentFactory(ActivitySidebarComponent);
      this.sidebarContent = this.container.createComponent(this.sidebarFactory);
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  reloadSidebarContent() {
    if (this.container) {
      this.container.remove();
      setTimeout(() => {
        this.sidebarContent = this.container.createComponent(this.sidebarFactory);
      }, 200);
    }
  }
}
