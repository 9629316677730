import { CourseModel } from './course.model';

export class CourseCombinedModel extends CourseModel {
  userHasAsTrial: boolean;
  userHasAsFullCourse: boolean;
  expireDate?: Date;
  availableTrial: boolean;
  availableForOneTimePurchase: boolean;
  availableForSubscription: boolean;
  priority: number;
  productIds: number[];
  imageUrl: string;
  favorite: boolean;
  starred: boolean;
}
