import { SentenceToken } from './sentence-token';
import { SafeHtml } from '@angular/platform-browser';

export class StaticToken extends SentenceToken {

	html: string;
	unsafeHtml: SafeHtml;

	type = StaticToken.typeName;

	static typeName = 'text';

}
