import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'characterCount'
})
export class CharacterCountPipe implements PipeTransform {

  transform(value: string): number {
    if (!value) {
      return 0;
    }
    const tmp = document.createElement('DIV');
    tmp.innerHTML = value;
    const trimmedText =  tmp.textContent || tmp.innerText || '';
    if (trimmedText) {
      return trimmedText.length;
    }
    return value.length;
  }

}
