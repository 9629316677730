import {NgModule} from '@angular/core';
import {LangSwitcherComponent} from './lang-switcher.component';
import {MatIconModule} from '@angular/material';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [
    MatIconModule,
    CommonModule
  ],
  exports: [
    LangSwitcherComponent
  ],
  declarations: [
    LangSwitcherComponent
  ]
})

export class LangSwitcherModule {}
