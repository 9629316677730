export class Achievements {

	dayOff = false;
	vocabularyTask = false;
	exerciseTask = false;
	contentTask = false;
	dailyGoal = false;
	certificate = false;
	points = 0;
	endTrial: 0 | 1 | 2 = 0;

	static createFromObject(source: any): Achievements {

		let a = new Achievements();

		if (source) {
			a.dayOff = !!source['dayOff'];
			a.vocabularyTask = !!source['vocabularyTask'];
			a.exerciseTask = !!source['exerciseTask'];
			a.contentTask = !!source['contentTask'];
			a.dailyGoal = !!source['dailyGoal'];
			a.certificate = !!source['certificate'];
			a.points = +source['points'];
			a.endTrial = source['endTrial'];
		}

		return a;
	}

	public mergeWith(achievements: Achievements) {
		if (achievements.dayOff) {
			this.dayOff = true;
		}
		if (achievements.vocabularyTask) {
			this.vocabularyTask = true;
		}
		if (achievements.exerciseTask) {
			this.exerciseTask = true;
		}
		if (achievements.contentTask) {
			this.contentTask = true;
		}
		if (achievements.dailyGoal) {
			this.dailyGoal = true;
		}
		if (achievements.certificate) {
			this.certificate = true;
		}
		if (achievements.points) {
			this.points += achievements.points;
		}
		if (!achievements.endTrial) {
			this.endTrial = 0;
		}
	}

}
