import {Lesson} from '../../../oj-app-common/course-structure/models/lesson';
import {Chapter} from '../../../oj-app-common/course-structure/models/chapter';
import {Week} from '../../../oj-app-common/course-structure/models/week';

export class LessonSeries {

  protected lessons: Lesson[] = [];

  protected currentIndex: number;

  public chapter?: Chapter;

  public week?: Week;

  protected _alwaysStartExercises = false;

  private _markLessonAccess = true;

  private _peekMode = false;

  private _afterReachingEndAction: () => Promise<any> = null;

  public markAsExercise = false;

  constructor(
    lessons: Lesson[],
    alwaysStartExercises = false,
    currentLesson: Lesson = null
  ) {
    this.lessons = lessons;
    this.currentIndex = 0;
    this._alwaysStartExercises = alwaysStartExercises;

    if (currentLesson) {
      this.currentIndex = this.lessons.findIndex(l => l.id === currentLesson.id);
      if (this.currentIndex === -1) {
        this.currentIndex = 0;
      }
    }
  }

  setPositionInCourse(chapter: Chapter, week: Week) {
    this.chapter = chapter;
    this.week = week;
  }

  get alwaysStartExercises(): boolean {
    return this._alwaysStartExercises;
  }

  get peekMode(): boolean {
    return this._peekMode;
  }

  set peekMode(peekMode: boolean) {
    this._peekMode = peekMode;
  }

  getLength(): number {
    return this.lessons.length;
  }

  getCurrentIndex(): number {
    return this.currentIndex;
  }

  getAll(): Lesson[] {
    return this.lessons;
  }

  getCurrent(): Lesson {
    return (this.lessons[this.currentIndex] || null);
  }

  getNext(): Lesson {
    return (this.lessons[this.currentIndex + 1] || null);
  }

  hasNext(): boolean {
    return this.lessons.length > this.currentIndex + 1;
  }

  getPrevious(): Lesson {
    return (this.lessons[this.currentIndex - 1] || null);
  }

  hasPrevious(): boolean {
    return this.currentIndex > 0;
  }


  get markLessonAccess(): boolean {
    return this._markLessonAccess;
  }

  set markLessonAccess(value: boolean) {
    this._markLessonAccess = value;
  }

  get afterReachingEndAction(): ((() => Promise<any>) | null) {
    return this._afterReachingEndAction;
  }

  set afterReachingEndAction(value: () => Promise<any>) {
    this._afterReachingEndAction = value;
  }

  goToNextLesson(): boolean {
    if (this.hasNext()) {
      this.currentIndex++;
      return true;
    }
    return false;
  }

  goToPreviousLesson(): boolean {
    if (this.hasPrevious()) {
      this.currentIndex--;
      return true;
    }
    return false;
  }

  goTo(lesson: Lesson) {
    let found = this.lessons.find(
      (l) => {
        return (l.id === lesson.id);
      }
    );
    if (!found) {
      throw new Error('This is not a lesson from series.');
    }
    let index = this.lessons.indexOf(found);
    this.currentIndex = index;
  }


  reachedEnd(): Promise<any> {
    if (this._afterReachingEndAction) {
      return this._afterReachingEndAction();
    }
    return Promise.reject('No action after reaching end has been specified.');
  }

}
