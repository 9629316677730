import {ErrorHandler, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {CommonModule, registerLocaleData} from '@angular/common';
import {AppRoutingModule} from './app.routing.module';
import {ServicesAccessor} from '../../oj-app-common/tools/services-accessor';
import {EshopApi} from '../../oj-app-common/eshop-api/eshop-api';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuard} from './guards/auth.guard';
import {MainNavigationLayoutModule} from './layouts/main-navigation-layout/main-navigation-layout.module';
import {MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateParser} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {StudovnaApi} from '../../oj-app-common/studovna-api/studovna-api';
import {AppConfig} from './config/app-config';
import {CurrentUserService} from './auth/current-user.service';
import {CurrentCourseService} from './services/current-course-service';
import {CurrentLessonSeriesService} from './services/current-lesson-series-service';
import {OverlayService} from './components/overlay-dialog/overlay-service';
import {ModalContentComponent} from './components/modal-content/modal-content.component';
import {LoadingComponent} from './components/loading/loading.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {PercentageSignTranslateParser} from '../../oj-app-common/i18n/percentage-sign-translate-parser';
import {MatButtonModule, MatCheckboxModule, MatIconModule} from '@angular/material';
import {MainLayoutModule} from './layouts/main-layout/main-layout.module';
import {SidebarModule} from './components/sidebar/sidebar.module';
import {BaseLayoutPageModule} from './pages/base-layout-page/base-layout-page.module';
import {PluralPipeModule} from './pipes/plural-pipe.module';
import {BaseContentComponent} from './pages/study-page/base-content.component';
import {
  VocabularyFilterModalContent
} from './pages/base-layout-page/pages/vocabulary-page/components/vocabulary-filter-modal/vocabulary-filter-modal-content';
import {FormsModule} from '@angular/forms';
import {OverlayDialogModule} from './components/overlay-dialog/overlay-dialog.module';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {ReportBugDialogModule} from './components/report-bug-dialog/report-bug-dialog.module';
import {TutorialModule} from './components/tutorial/tutorial.module';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppMissingTranslationService} from '../../oj-app-common/i18n/app-missing-translation-handler';
import {UserPreferencesService} from '../../oj-app-common/services/user-preferences.service';
import {SpecialLettersService} from '../../oj-app-common/services/special-letters.service';
import {PaymentDialogsModule} from './components/payment-dialogs/payment-dialogs.module';
import {PaymentDialogService} from './components/payment-dialogs/payment-dialog.service';
import {default as localeCs} from '@angular/common/locales/cs';
import {default as localeEn} from '@angular/common/locales/en';
import {default as localeEs} from '@angular/common/locales/es';
import {default as localeSk} from '@angular/common/locales/sk';
import {default as localePl} from '@angular/common/locales/pl';
import {VideoLayerModule} from './components/video-layer/video-layer.module';
import {devElConfig} from './config/dev-el.config';
import {AppAdsService} from './services/app-ads.service';
import {ThemeService} from 'ng2-charts';
import {
  UnsupportedDeviceDialogComponent
} from './components/unsupported-device-dialog/unsupported-device-dialog.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ShowInLeaderboardService} from './services/show-in-leaderboard.service';
import {NgxCookiebotModule} from '@halloverden/ngx-cookiebot';
import {CookiebotConfig} from './config/cookiebot-config';

@NgModule({
  declarations: [
    AppComponent,
    ModalContentComponent,
    VocabularyFilterModalContent,
    LoadingComponent,
    BaseContentComponent,
    UnsupportedDeviceDialogComponent
  ],
  imports: [NgCircleProgressModule.forRoot(),
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MainNavigationLayoutModule,
    MainLayoutModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
        parser: {
          provide: TranslateParser,
          useClass: PercentageSignTranslateParser,
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useExisting: AppMissingTranslationService,
        },
      },
    ),
    HttpClientModule,
    MatButtonModule,
    SidebarModule,
    BaseLayoutPageModule,
    PluralPipeModule,
    MatCheckboxModule,
    FormsModule,
    OverlayDialogModule,
    ToastModule,
    ReportBugDialogModule,
    TutorialModule, PaymentDialogsModule,
    VideoLayerModule, ModalModule, MatIconModule,
    NgxCookiebotModule.forRoot(CookiebotConfig)
  ],
  providers: [
    MessageService,
    {
      provide: AppConfig, useFactory: () => {
        return devElConfig;
      }
    },
    {
      provide: ServicesAccessor, useFactory: (serviceAccessorFn)
    },
    ErrorHandler,
    // {provide: ErrorHandler, useClass: AppErrorHandler},
    {
      provide: EshopApi, useFactory: (
        appConfig: AppConfig,
        servicesAccessor: ServicesAccessor,
        errorHandler: ErrorHandler) => {
        return new EshopApi(devElConfig, serviceAccessorFn(), errorHandler);
      },
      deps: [
        ServicesAccessor,
        ErrorHandler
      ]
    },
    {
      provide: StudovnaApi, useFactory: (studovnaApiFn),
      deps: [
        ServicesAccessor,
        ErrorHandler,
      ]
    },
    AuthGuard,
    CurrentUserService,
    {
      provide: CurrentCourseService, useFactory: (
        currentUser: CurrentUserService,
        studovnaApi: StudovnaApi,
        servicesAccessor: ServicesAccessor,
        appConfig: AppConfig,
        eshopApi: EshopApi
      ) => {
        return new CurrentCourseService(currentUser, studovnaApi, servicesAccessor, appConfig, eshopApi);
      },
      deps: [
        CurrentUserService,
        StudovnaApi,
        ServicesAccessor,
        AppConfig,
        EshopApi
      ]
    },
    CurrentLessonSeriesService,
    OverlayService,
    AppMissingTranslationService,
    UserPreferencesService,
    SpecialLettersService,
    PaymentDialogService,
    AppAdsService,
    ThemeService,
    ShowInLeaderboardService
  ],
  exports: [
    LoadingComponent
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalContentComponent,
    VocabularyFilterModalContent
  ]
})
export class AppModule {

  constructor() {
    registerLocaleData(localeCs);
    registerLocaleData(localeEn);
    registerLocaleData(localeEs);
    registerLocaleData(localeSk);
    registerLocaleData(localePl);
  }

}

export function serviceAccessorFn() {
  return new ServicesAccessor(true);
}

export function studovnaApiFn(
  appConfig: AppConfig,
  servicesAccessor: ServicesAccessor,
  errorHandler: ErrorHandler
) {
  return new StudovnaApi(devElConfig, serviceAccessorFn(), errorHandler);
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    'assets/i18n/',
    '.json',
  );
}
