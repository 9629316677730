import {NgModule} from '@angular/core';
import {BaseModalComponent} from './base-modal.component';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {PluralPipeModule} from '../../pipes/plural-pipe.module';
import {MatButtonModule} from '@angular/material';
import {ModalModule} from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    BaseModalComponent
  ],
  imports: [
    ModalModule,
    TranslateModule,
    CommonModule,
    PluralPipeModule,
    MatButtonModule
  ],
  exports: [
    BaseModalComponent
  ]
})

export class BaseModalModule {}
