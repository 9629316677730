import { MethodDescription } from '../method-description';

export const EditProfileMethodDescription = new MethodDescription();

EditProfileMethodDescription.name = 'editProfile';
EditProfileMethodDescription.fields = [
	'id',
	'name',
	'phone',
	'street',
	'city',
	'zip',
	'company',
	'ico',
	'dic',
	'company_reporting',
	'emailing_company',
	'profile_picture',
];
EditProfileMethodDescription.usesToken = true;
