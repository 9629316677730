import {ActivityTimer} from '../../common/activity-timer';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {BaseComponent} from './base.component';
import {LessonPageComponent} from './lesson/lesson-page.component';
import {BaseExercise} from '../../../../oj-app-common/exercises/base-exercise';
import {BaseExerciseComponent} from './base-exercise.component';
import {EXERCISE_STATUS} from '../../../../oj-app-common/exercises/misc/exercise-status';
import {OverlayService} from '../../components/overlay-dialog/overlay-service';
import {
  ExerciseResultDialogComponent,
} from '../../components/overlay-dialog/exercise-result-dialog/exercise-result-dialog.component';
import {Achievements} from '../../../../oj-app-common/studovna-api/models/achievements';
import {COMPONENT_TYPE} from './component-type.enum';
import {DailyTaskType} from '../../models/daily-task-type';
import {ListeningService, PLAY_STATUS} from './components/listening/listening.service';
import {ToleranceSettings} from '../../../../oj-app-common/studovna-api/models/tolerance-settings';

export class LessonPageController {
  protected _isReady = false;

  public timer: ActivityTimer;

  protected _audioInterruptor = new Subject<void>();

  protected contentComponent: BaseComponent;

  public nextButtonClicked: Subject<boolean> = new Subject<boolean>();

  public currentLessonComponentType: COMPONENT_TYPE;

  public currentDailyTaskType: DailyTaskType;

  public isPreview = false;

  public playedTimeTotal = 0;

  public playedTimeInterval;

  public hasAutomaticBookmark = false;

  protected shouldAutostartExercise = false;

  protected _relevantToleranceTypeChanged = new BehaviorSubject<ToleranceSettings>({
    diacritics: true,
    punctuation: true,
    capitalization: true,
  });

  constructor(
    protected lessonPage: LessonPageComponent,
    protected overlayService: OverlayService,
    protected listeningService: ListeningService
  ) {

  }

  startPlayedTimeInterval() {
    this.playedTimeInterval = setInterval(() => {
      this.playedTimeTotal++;
    }, 1000);
  }

  removePlayedTimeInterval() {
    if (this.playedTimeInterval) {
      clearInterval(this.playedTimeInterval);
    }
  }

  resetPlayedTime() {
    this.removePlayedTimeInterval();
    setTimeout(() => {
      this.playedTimeTotal = 0;
    }, 1000);
  }

  getPlayedAudioOrVideoTime(): number {
    if (this.playedTimeTotal && this.playedTimeTotal > 0) {
      return this.playedTimeTotal;
    }
    return 0;
  }

  setCurrentDailyTaskType(type: DailyTaskType) {
    this.currentDailyTaskType = type;
  }

  getCurrentDailyTaskType(): DailyTaskType {
    return this.currentDailyTaskType;
  }

  setCurrentLessonComponentType(type: COMPONENT_TYPE) {
    this.currentLessonComponentType = type;
  }

  setIsPreview(preview: boolean) {
    this.isPreview = preview;
  }

  getIsPreview(): boolean {
    return this.isPreview;
  }

  getCurrentLessonComponentType(): COMPONENT_TYPE {
    return this.currentLessonComponentType;
  }

  public isReady(): boolean {
    return this._isReady;
  }

  get audioInterruptor(): Subject<void> {
    return this._audioInterruptor;
  }

  public isLessonTouched(): boolean {
    const componentAsExercise = this.contentComponent as BaseExerciseComponent<BaseExercise>;
    if (!componentAsExercise) {
      return false;
    }
    if (componentAsExercise && !componentAsExercise.model) {
      return false;
    }
    return componentAsExercise.model.status === EXERCISE_STATUS.STUDYING && componentAsExercise.model.isTouched();
  }

  public setContentComponent(component: BaseComponent): void {
    this.contentComponent = component;
    // @ts-ignore
    window.lessonContent = component;
    setTimeout(
      () => {
        this._isReady = true;
      },
      0,
    );
  }

  public beforeDestroy(): void {
    this.lessonPage = null;
    this.contentComponent = null;
    this._isReady = false;
    this.overlayService = null;
  }

  public resetExercise(): void {
    const componentAsExercise = this.contentComponent as BaseExerciseComponent<BaseExercise>;
    if (componentAsExercise && componentAsExercise.model) {
      componentAsExercise.model.reset();
      componentAsExercise.model.setStatusToStudying();
      if (componentAsExercise.exerciseWasReset) {
        componentAsExercise.exerciseWasReset();
      }
    } else {
      console.error('Can not reset exercise. Component is not exercise type or has no model.');
    }
  }

  public getExerciseModel(): null | BaseExercise {
    const componentAsExercise = this.contentComponent as BaseExerciseComponent<BaseExercise>;
    if (!componentAsExercise) {
      return null;
    }
    if (componentAsExercise && !componentAsExercise.model) {
      return null;
    }
    return componentAsExercise.model;
  }

  public evaluateAndSubmitExercise(): void {
    const componentAsExercise = this.contentComponent as BaseExerciseComponent<BaseExercise>;
    this.audioInterruptor.next();
    if (componentAsExercise && componentAsExercise.model) {
      componentAsExercise.model.evaluate();
      componentAsExercise.model.setStatusToSubmitted();
      if (componentAsExercise.finishedExercise) {
        componentAsExercise.finishedExercise();
      }
      if (componentAsExercise.model.result) {
        const evalResponse = componentAsExercise.sendAnswersToApi();
        this.showResultDialog();
        if (evalResponse) {
          evalResponse.then(
            (response) => {
              const dial = this.overlayService.getCurrentDialog();
              if (dial && dial instanceof ExerciseResultDialogComponent) {
                dial.setReceivedPoints(response.points);
                setTimeout(() => {
                  dial.setVideoReceivedFromBackend(response.video320);
                }, 50);
              }
            },
          );
        }
      }
    } else {
      console.error('Can not evaluate exercise. Component is not exercise type or has no model.');
    }
  }

  logSomeActivity(): Promise<boolean> {

    return new Promise((resolve, reject) => {

      const componentAsExercise = this.contentComponent as BaseExerciseComponent<BaseExercise>;

      componentAsExercise.logTimeActivity(true);

      return resolve(true);

    })
  }

  public showResultDialog() {
    const componentAsExercise = this.contentComponent as BaseExerciseComponent<BaseExercise>;
    if (componentAsExercise && componentAsExercise.model && componentAsExercise.model.result) {
      const videoService = this.lessonPage.getVideoService();
      // const videoFile = exerciseEvaluationResponse.video;
      const videoImage = videoService.getImageForResult(componentAsExercise.model.result);

      this.overlayService.show(ExerciseResultDialogComponent)
        .then(
          (result) => {

            result.componentRef.instance.initialize(
              componentAsExercise.model,
              () => {
                setTimeout(
                  () => {
                    this.goToNextLesson();
                  },
                  100,
                );
              },
              () => {
                this.resetExercise();
              },
              null,
              null,
              videoImage,
              result.componentRef.instance.close,
            );

            const component = result.componentRef.instance;
            component.didHide.subscribe(
              () => {
                if (this.timer) {
                  this.timer = null;
                  // this.timer.reset();
                  // this.timer.start();
                }
              },
            );

          },
        )
      ;
    } else {
      console.error('Can not showResultDialog(). Component is not exercise type or has no model or is not evaluated yet.');
    }
  }

  public goToNextLesson(): void {
    console.log('GO TO NEXT LESSON');
    this.lessonPage.goToNextLesson();
  }

  public addBookmark(): void {
    this.lessonPage.updateBookmarkStatus(true);
  }

  public removeBookmark(): void {
    this.lessonPage.updateBookmarkStatus(false);
  }

  public setContentContainsFixedElements(contains = false) {
    this.lessonPage.contentContainsFixedElements = contains;
  }

  public async isBookmarked(): Promise<boolean> {
    return this.lessonPage.isBookmarked();
  }

  public setActiveTimer(timer: ActivityTimer) {
    if (this.timer && timer !== this.timer) {
      this.timer.destroy();
    }
    this.timer = timer;
  }

  public markSomeUserActivity() {
    if (this.timer) {
      this.timer.markActivity();
    }
  }

  public canReset(): boolean {
    const componentAsExercise = this.contentComponent as BaseExerciseComponent<BaseExercise>;
    if (!this.contentComponent) {
      return false;
    }
    return !!(componentAsExercise && componentAsExercise.model);
  }

  public canBeEvaluated(): boolean {
    const componentAsExercise = this.contentComponent as BaseExerciseComponent<BaseExercise>;
    if (!this.contentComponent) {
      return false;
    }

    return !!(componentAsExercise
      && componentAsExercise.model
      && componentAsExercise.model.canBeEvaluated()
      && componentAsExercise.checkAdditionalConfirmation()
    );
  }

  isExerciseCompletedEnough(): boolean {
    const componentAsExercise = this.contentComponent as BaseExerciseComponent<BaseExercise>;
    if (!this.contentComponent) {
      return false;
    }

    return !!(componentAsExercise
      && componentAsExercise.model
      && componentAsExercise.model.canBeEvaluated()
    );
  }

  public isExercise(): boolean {
    const componentAsExercise = this.contentComponent as BaseExerciseComponent<BaseExercise>;
    if (!this.contentComponent) {
      return false;
    }
    return !!(componentAsExercise && componentAsExercise.model);
  }

  public receivedAchievements(achievements: Achievements) {
    if (this.lessonPage) {
      this.lessonPage.receivedAchievements(achievements);
    }
  }

  public getExerciseStatus(): (null | EXERCISE_STATUS) {
    const componentAsExercise = this.contentComponent as BaseExerciseComponent<BaseExercise>;
    if (!componentAsExercise || !componentAsExercise.model) {
      return null;
    }
    return componentAsExercise.model.status;
  }

  public destroyTimer() {
    if (this.timer) {
      this.timer.destroy();
    }

    setTimeout(() => {
      this.timer = null;
    }, 500);
  }


  public setIfCurrentLessonIsAutomaticBookmark(isAutomaticBookmark: boolean) {
    if (this.lessonPage) {
      this.lessonPage.setIfCurrentLessonIsAutomaticBookmark(isAutomaticBookmark);
      this.hasAutomaticBookmark = false;
    } else {
      this.hasAutomaticBookmark = isAutomaticBookmark;
    }
  }

  public async removeAutomaticBookmark() {
    if (this.lessonPage) {
      await this.lessonPage.removeAutomaticBookmark();
    }
  }

  public autostartExerciseWhenPossible() {
    this.shouldAutostartExercise = true;
  }

  public needsAutostartExercise(): boolean {
    return this.shouldAutostartExercise;
  }

  public clearAutostartExercise() {
    this.shouldAutostartExercise = false;
  }

  get relevantToleranceTypeChanged(): Observable<ToleranceSettings> {
    return this._relevantToleranceTypeChanged.asObservable();
  }

  public updateRelevantToleranceTypes(types: ToleranceSettings) {
    this._relevantToleranceTypeChanged.next(types);
  }

}
