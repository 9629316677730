import {NgModule} from '@angular/core';

/* Custom components && modules import */
import {CircleProgressbarComponent} from './circle-progressbar.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {CommonModule} from '@angular/common';
import {PluralPipeModule} from '../../pipes/plural-pipe.module';

@NgModule({
  declarations: [
    CircleProgressbarComponent
  ],
    imports: [
        NgCircleProgressModule,
        CommonModule,
        PluralPipeModule
    ],
  providers: [
  ],
  exports: [
    CircleProgressbarComponent
  ]
})
export class CircleProgressbarModule {
}
