import { SentenceToken } from './models/sentence-token';
import { FILLABLE_TOKEN_SIZE, SentenceTokenWithSize } from './models/sentence-token-with-size';

export function autoCalculateTokenSizes(tokens: SentenceTokenWithSize[]) {

	let lengths = tokens.map((t) => t.getLength());
	let defaultLengthsClass = lengths.map(SentenceTokenWithSize.calculateDefaultSize);
	let usedLengths = new Set<FILLABLE_TOKEN_SIZE>();
	defaultLengthsClass.forEach((m) => usedLengths.add(m));

	if (usedLengths.size <= 1) {
		// All can have the same size
		tokens.forEach((t, index) => t.sizeMode = defaultLengthsClass[index]);
	}

	if (usedLengths.size === 2) {
		// two similar sizes - set all to bigger one
		// Case simple - course 398, lesson 94013
		// Case with multiple sentences: course 398, lesson 94015

		// Extreme case - course 572, lesson 160963
		// Short fields and extreme long fields. We use extreme long fields everywhere.

		let sizesAsNumbers: number[] = Array.from(usedLengths);
		sizesAsNumbers.sort((a, b) => (a - b));
		if (sizesAsNumbers[0] === sizesAsNumbers[1] - 1 || (sizesAsNumbers[0] === 0 && sizesAsNumbers[1] >= 3)) {
			// classes are close to each other or it's the extreme case
			let useThisEverywhere = sizesAsNumbers[1]; // bigger one
			tokens.forEach((t) => t.sizeMode = useThisEverywhere);
		}

	}


}
