import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';
import {sidebarAnimation, sidebarStates} from '../../animations/sidebar.animation';
import {layoutAnimation, layoutStates} from '../../animations/layout.animation';
import {ThemeService} from '../../theme/theme.service';
import {APP_THEMES, Theme} from '../../theme/theme';
import {SidebarService} from "./sidebar.service";

/* defined sidebar positions */
export enum sidebarPositions {
  side = 'side',
  over = 'over'
}

/* defined border width, because of margin of the layout (if sidebarBorderWidth is zero there is no space between sidebar and layout) */
export const sidebarBorderWidth = 40;

/*
* SIDEBAR Component
*  ** has two positions, side or over
*     (position can be defined by parent component but also it force its state itself when screen size is on limit)
*  ** has two themes, dark or light
*     (theme can be defined by parent component)
*  ** sidebar can be in three states - closed, opened and partiallyClosed (it all is defined in sidebarAnimation)
*     (partiallyClosed means, that sidebar is closed and only border is visible on the right side)
*  ** when state of sidebar has changed, automatically is changed state of layout too
*     (layout is automatically recalculated to fill the whole content area)
* */

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    sidebarAnimation,
    layoutAnimation
  ]
})
export class SidebarComponent implements OnInit {
  sidebarWidth = 0;
  sidebarOpenedWidth = 0;
  layoutCloseContainerWidth = 0;
  isOpened = true;
  showCloseButton = false;
  states = {
    sidebarState: sidebarStates.opened,
    layoutState: layoutStates.pushed
  };
  options = {
    theme: APP_THEMES.light,
    position: sidebarPositions.side
  };

  @Input() theme: Theme;
  @Input() position = sidebarPositions.side;
  @Input() forceCloseBtnVisible = false;
  @Input() forcePartiallyClosed = false;
  @Output() sidebarStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    protected themeService: ThemeService,
    protected sidebarService: SidebarService
  ) {
  }

  ngOnInit() {
    this.options.position = this.position;

    // if (this.theme.name === 'light') {
    //   this.options.theme = APP_THEMES.dark;
    // } else {
    //   this.options.theme = APP_THEMES.light;
    // }

    this.themeService
      .themeChanged
      .subscribe(thm => {
        if (thm.name === 'light') {
          this.options.theme = APP_THEMES.dark;
        } else {
          this.options.theme = APP_THEMES.light;
        }
      });
    this.showCloseButton = this.forceCloseBtnVisible;
    this.sidebarStatus.emit(this.isOpened);
    this.sidebarService.sidebarType.next(this.options.position)
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerWidth < 1350) {
      this.options.position = sidebarPositions.over;
      this.showCloseButton = true;
      this.isOpened = false;
    } else {
      this.options.position = sidebarPositions.side;
      this.showCloseButton = this.forceCloseBtnVisible;
      this.isOpened = true;
      this.changeSidebarWidth(395)
    }
    this.sidebarService.isOpenedSidebar.next(this.isOpened);
    this.sidebarService.sidebarType.next(this.options.position);
    this.animate();
  }

  /* Toggle sidebar function - toggle opened/closed or opened/partiallyClosed */
  toggleSidebar() {
    this.isOpened = !this.isOpened;
    this.sidebarStatus.emit(this.isOpened);
    this.animate();
  }

  /* Function called when detected change of sidebar's width */
  changeSidebarWidth(width: number) {
    if (this.isOpened) {
      this.sidebarOpenedWidth = width;
    }
    this.sidebarWidth = width + sidebarBorderWidth;
    this.animate();
  }

  /* Close the sidebar */
  closeSidebar(e: Event) {
    e.preventDefault();
    this.isOpened = false;
    this.animate();
  }

  sidebarAnimationDone(val) {
    setTimeout(() => {
      this.sidebarService.isOpenedSidebar.next(this.isOpened);
    }, 150);
  }

  animate() {
    switch (this.options.position) {
      case sidebarPositions.side:
        if (this.isOpened) {
          this.closedToOpenedSideAnimation();
        } else {
          this.openedToClosedSideAnimation();
        }
        break;
      case sidebarPositions.over:
        if (this.isOpened) {
          this.closedToOpenedOverAnimation();
        } else {
          this.openedToClosedOverAnimation();
        }
        break;
    }
  }

  closedToOpenedSideAnimation() {
    if (this.forcePartiallyClosed) {
      this.states.sidebarState = sidebarStates.opened;
      this.states.layoutState = layoutStates.pushed;
    } else {
      this.states.sidebarState = sidebarStates.opened;
      this.states.layoutState = layoutStates.pushed;
    }
  }

  openedToClosedSideAnimation() {
    if (this.forcePartiallyClosed) {
      this.states.sidebarState = sidebarStates.partiallyClosed;
      this.states.layoutState = layoutStates.full;
    } else {
      this.states.sidebarState = sidebarStates.closed;
      this.states.layoutState = layoutStates.full;
    }
  }

  closedToOpenedOverAnimation() {
    this.states.sidebarState = sidebarStates.opened;
    this.states.layoutState = layoutStates.pushedOver;
  }

  openedToClosedOverAnimation() {
    this.states.sidebarState = sidebarStates.partiallyClosed;
    this.states.layoutState = layoutStates.full;
  }

}
