import { AbstractAssignTextToImageExercise } from './abstract-assign-text-to-image-exercise';
import { FillableImage } from './models/fillable-image';
import { InvalidExerciseData } from '../errors/invalid-exercise-data';
import { ANSWER_STATUS_BOOLEAN } from '../misc/answer-status-boolean';
import { isInCorrectStringWithTolerance } from '../../tools/text-comparator';

export class FillinAssignTextToImageExercise extends AbstractAssignTextToImageExercise {

	static typeString = 'fillin';
	public images: FillableImage[] = [];

	public init(lessonData: object): void {
		if (!lessonData['images']) {
			throw new InvalidExerciseData('Data must have a [images] field.');
		}

		this.images = [];

		let num = 1;

		for (let imageIndex of Object.keys(lessonData['images'])) {

			let imageData = lessonData['images'][imageIndex];
			let image = new FillableImage();
			image.id = +imageData['id'];
			image.imageUrl = imageData['image'];
			image.originalImage = imageData['originalImage'];
			image.correctTexts = imageData['text'];
			image.filledText = '';
			image.number = num;
			num++;
			this.images.push(image);

		}

		super.init(lessonData);
	}

	public reset(): void {

		this.images.map(
			(i) => {
				i.result = ANSWER_STATUS_BOOLEAN.UNKNOWN_YET;
				i.filledText = '';
			}
		);

	}

	public evaluate(): void {

		let total = this.images.length;
		let correct = 0;

		this.images.map(
			(i) => {
				let val = i.filledText.trim();
				let ok = isInCorrectStringWithTolerance(val, i.correctTexts, this.tolerance);
				if (ok) {
					correct++;
					i.result = ANSWER_STATUS_BOOLEAN.CORRECT;
				} else {
					i.result = ANSWER_STATUS_BOOLEAN.INCORRECT;
				}

			}
		);

		this.setResultUsingNumbers(correct, total);

	}

	public getAnswersForApi(): object {
		let answers = {};
		this.images.map(
			(i) => {
				answers[i.id] = i.filledText;
			}
		)
		return {
			answers: answers
		};
	}

	public canBeEvaluated(): boolean {
		return !this.images.some((i) => !i.filledText);
	}

	public setFromPreviousAnswers(data: object): void {

		if (!data['answers']) {
			throw new InvalidExerciseData('Data must contain [answers]field.');
		}

		for (let imageIdStr of Object.keys(data['answers'])) {
			let imageData = data['answers'][imageIdStr];
			let imageId = +imageIdStr;
			let foundImage = this.images.find(
				(i) => {
					return (i.id === imageId);
				}
			);
			if (foundImage) {
				foundImage.filledText = imageData;
			}
		}

	}


	isTouched(): boolean {
		return !!this.images.find(
			(image) => {
				return (image.filledText !== '');
			}
		);
	}
}
