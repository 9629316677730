import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isShowed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  loadingText: BehaviorSubject<string> = new BehaviorSubject<string>('');

  forceLoading = false;

  constructor() {
  }

  show(text: string = null, forceLoading = false) {
    this.forceLoading = forceLoading;
    if (text) {
      this.loadingText.next(text);
    }
    this.isShowed.next(true);
  }

  hide(forceLoading = false) {
    if (this.forceLoading && !forceLoading) {
      return;
    }
    this.loadingText.next('');
    this.isShowed.next(false);
  }
}
