import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CourseItem} from './course-item.model';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit, OnChanges {
  tooltipText = '';

  @Input() item: CourseItem;

  @Input() actionText = '';

  @Output() buttonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() containerClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() lockClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() buttonTheme: 'green' | 'yellow' | 'blue' = 'green';

  @Input() buttonSize: 'sm' | 'lg' = 'sm';

  @Input() courseSize: 'sm' | 'lg';

  @Input() buttonOnHover = false;

  @Input() animatedTile = false;

  @Input() staticTextPosition = false;

  @Output() removeFromFavorites: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() toggledStarredCourse: EventEmitter<CourseItem> = new EventEmitter<CourseItem>();

  @Output() deactivateDemoChange: EventEmitter<CourseItem> = new EventEmitter<CourseItem>();

  @Input() defaultCourseStyle = false;

  @Input() isClickable = true;

  constructor() {
  }

  ngOnInit() {
    this.generateTooltip();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['item']) {
      if (this.item.button) {
        this.generateButton();
      }

      if (this.item.expiredStatus && this.item.expiredStatus.isExpired) {
        this.animatedTile = false;
        this.buttonOnHover = false;
      }
    }
  }

  generateTooltip() {
    if (this.item.expiredStatus) {

      if (this.item.expiredStatus.expireWarning) {
        this.tooltipText = this.item.expiredStatus.expireWarning;
        return;
      }

      if (this.item.expiredStatus.willExpireText) {
        this.tooltipText = this.item.expiredStatus.willExpireText;
        return;
      }
    }
  }

  generateButton() {
    switch (this.item.button) {

      case 'activate':
        this.buttonTheme = 'green';
        this.actionText = 'shop-button-study';
        break;

      case 'study':
        this.actionText = 'shop-button-study';
        if (!this.item.isDemo) {
          this.buttonTheme = 'green';
        } else {
          this.buttonTheme = 'blue';
        }
        break;

      case 'get':
        this.buttonTheme = 'yellow';

        if (this.item.expiredStatus.isExpired) {
          this.actionText = 'shop-button-get-expired';
        } else {
          this.actionText = 'shop-button-get';
        }
        break;

      case 'start':
        this.buttonTheme = 'yellow';
        this.actionText = 'shop-button-start';
        break;

    }
  }

  removeFromFavoritesAction() {
    this.removeFromFavorites.emit(true);
  }

  action(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.buttonClicked.emit(true);
  }

  clickedContainer(e: Event) {
    e.preventDefault();
    this.containerClick.emit(true);
  }

  lockClicked(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.lockClick.emit(true);
  }

  toggleStarredCourse(e: Event, courseItem: CourseItem) {
    e.preventDefault();
    e.stopPropagation();
    this.toggledStarredCourse.emit(courseItem);
  }

  deactivateDemo(e: Event, courseItem: CourseItem) {
    e.preventDefault();
    e.stopPropagation();
    this.deactivateDemoChange.emit(courseItem);
  }

}
