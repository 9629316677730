import { MethodDescription } from '../method-description';

export const GetAvailableDemosMethodDescription = new MethodDescription();

GetAvailableDemosMethodDescription.name = 'getAvailableDemos';
GetAvailableDemosMethodDescription.fields = [
	'studentId',
	'includeHidden',
];
GetAvailableDemosMethodDescription.usesToken = true;
