import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Video } from './models/video';
import { Token } from '../misc/token';
import { CourseCategoryModel } from '../shop/models/shop/course-category.model';
import { ProductModel } from '../shop/models/shop/product.model';
import { CurrencyModel } from '../shop/models/shop/currency.model';
import { NotificationPlatform } from './models/notification-platform';
import { NotificationPreferences } from './models/notification-preferences';
import { createSubscriptionFromObject, Subscription, subscriptionSortFunction } from './models/subscription';
import {AiStudyConfirmationResponse, AiStudyHelperResponse, AiStudyHelpRequest} from './models/ai';

export const RUBY_BASE_URL = 'https://ruby-api.onlinejazyky.cz/api/';

interface Options {
	noJson?: boolean;
}

@Injectable()
export class RubyService {

	constructor(
		protected httpClient: HttpClient,
	) {
	}


	public videosAvailable(token: Token): Promise<{next_onboarding?: Video, next?: Video}> {
		return this.call('videos/available', token);
	}

	public async getSubscriptions(token: Token, sorted = true): Promise<{student: any, subscriptions: Subscription[]}> {
		let result = await this.call('subscriptions', token);
		if (result.subscriptions) {
			result.subscriptions = result.subscriptions.map(createSubscriptionFromObject);
			if (sorted) {
				result.subscriptions.sort(subscriptionSortFunction);
			}
		}
		return result;
	}

	public videosSeen(token: Token, videoId: number): Promise<void> {
		return this.call('videos/seen', token, {id: +videoId}, 'post', {
			noJson: true
		});
	}

	public async courseCategories(): Promise<CourseCategoryModel[]> {
		return this.call('course_categories');
	}

	public async products(): Promise<ProductModel[]> {
		let raw = await this.call('products');
		let normalised = raw.map(
			(source) => {
				if (source.prices) {
					for (let currency of Object.keys(source.prices)) {
						let priceSource = source.prices[currency];
						source.prices[currency] = {
							oneTime: parseFloat(priceSource.basic) || null,
							monthly: parseFloat(priceSource.monthly) || null,
							quarterYearly: parseFloat(priceSource.three_months) || null,
							halfYearly: parseFloat(priceSource.six_months) || null,
							yearly: parseFloat(priceSource.yearly) || null,
						};
					}
				}
				return source;
			}
		) ;
		return normalised;
	}

	public async currencies(): Promise<CurrencyModel[]> {
		let returned = await this.call('currencies.json');
		return returned.currencies;
	}

	public async registerNotificationToken(studentId: number, platform: NotificationPlatform, deviceToken: string, token: Token, preferences: NotificationPreferences = null): Promise<void> {
		return this.call(
			'students/' + studentId + '/notification_devices',
			token,
			{
				kind: platform,
				token: deviceToken,
				preferences: preferences || null,
			},
			'post',
		).catch(
			(e) => {
				if (e.status === 422 && e.error && e.error.token && e.error.token[0] === 'již databáze obsahuje') {
					return null;
				}
				throw e;
			}
		);
	}

	public async removeNotificationToken(studentId: number, deviceToken: string, token: Token): Promise<void> {
		return this.call(
			'students/' + studentId + '/notification_devices/' + deviceToken,
			token,
			null,
			'delete',
		).catch(
			(e) => {
				if (e.status === 404 && e.error && e.error.code === '404') {
					return null;
				}
				throw e;
			}
		);
	}

	public async setNotificationPreferences(studentId: number, token: Token, preferences: NotificationPreferences): Promise<void> {
		return this.call(
			'students/' + studentId + '/notification_preferences',
			token,
			preferences,
			'put',
		);
	}

	public async getNotificationPreferences(studentId: number, token: Token): Promise<NotificationPreferences> {
		let resp = await this.call(
			'students/' + studentId + '/notification_preferences',
			token,
		);
		return resp;
	}

  public async getAIStudyHelper(aiRequest: AiStudyHelpRequest, token: Token): Promise<AiStudyHelperResponse> {
    const resp = await this.call(
      'ai/study-helper',
      '4882edc3ebed398b65b3a9cffb84b712',
      aiRequest,
      'post',
      {
        noJson: false
      }
    );
    return resp;
  }

  public async getAIStudyConfirmation(aiRequest: AiStudyHelpRequest, token: Token): Promise<AiStudyConfirmationResponse> {
    const resp = await this.call(
      'ai/confirmation',
      '4882edc3ebed398b65b3a9cffb84b712',
      aiRequest,
      'post',
      {
        noJson: false
      }
    );
    return resp;
  }

	protected async call(endpointPath: string, token = '', body: any = null, method = 'get', options: Options = null): Promise<any> {
		if (!options) {
			options = {};
		}
		let r = new HttpRequest(
			method,
			RUBY_BASE_URL + endpointPath,
			body,
			{
				responseType: options.noJson ? 'text' : 'json',
			},
		);
		if (token) {
			r = r.clone({
				headers: r.headers.append('Authorization', 'Bearer ' + token),
			});
		}
		let resp: HttpResponse<any> = await this.httpClient.request(r).toPromise() as HttpResponse<any>;
		if (resp) {
			return resp.body;
		}
	}

}
