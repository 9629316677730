import {NgModule} from '@angular/core';
import {LoadingIndicatorComponent} from './loading-indicator.component';

@NgModule({
  declarations: [
    LoadingIndicatorComponent
  ],
  exports: [
    LoadingIndicatorComponent
  ]
})

export class LoadingIndicatorModule {}
