import { MethodDescription } from '../method-description';

export const DeactivateDemoMethodDescription = new MethodDescription();

DeactivateDemoMethodDescription.name = 'deactivateDemo';
DeactivateDemoMethodDescription.fields = [
	'productId',
	'studentId',
	'sendToPodio',
];
DeactivateDemoMethodDescription.usesToken = true;
