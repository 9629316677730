import { LEVEL, levelFromString } from './level';

export class VocabularyWord {
	id: number;
	source: string;
	translation: string;
	excluded?: boolean;
	level: LEVEL;
	favorite: boolean;

	selected? = false;
  translationAlternatives?: string[];
	sourceAlternatives?: string[];

	prefix = '';
	postfix = '';

  translationDescription?: string;
  extraInfo?: string;
  extraInfoType?: number;
  description?: string;
  avatarText?: string;

	options?: VocabularyWord[] = [];

	static createFromObject(object: any): VocabularyWord {
		let w = new VocabularyWord();
		w.id = +object.id;
		w.source = object.source;
		w.favorite = !!object.favorite;
		w.translation = object.translation;
		w.excluded = false;
		w.translationAlternatives = object.translationAlternatives || [];
		if (typeof w.translationAlternatives === 'string') {
			w.translationAlternatives = (w.translationAlternatives as string).split(';');
		}
		w.sourceAlternatives = object.sourceAlternatives || [];
		if (typeof w.sourceAlternatives === 'string') {
			w.sourceAlternatives = (w.sourceAlternatives as string).split(';');
		}
		if (object.disabled) {
			w.excluded = true;
		}
		if (object.excluded) {
			w.excluded = true;
		}
		w.prefix = object.prefix || '';
		w.postfix = object.postfix || '';

		if (object.options && object.options.length) {
			w.options = object.options.map((opt) => this.createFromObject(opt));
		} else {
			w.options = [];
		}

		w.level = levelFromString(object.level);

    w.translationDescription = object.translationDescription || '';
    w.extraInfo = object.extraInfo || '';
    w.extraInfoType = object.extraInfoType;
    w.description = object.description || '';
    w.avatarText = object.avatarText || '';

		return w;
	}

	fillAdvancedProperties(source: any) {
		this.level = source['level'];
		this.excluded = !!source['disabled'];
		this.favorite = !!source['favorite'];

		this.prefix = (source['prefix'] || '').trim();
		this.postfix = (source['postfix'] || '').trim();

		this.options = source['options'] || [];
	}
}
