import {Component, OnInit} from '@angular/core';
import {OverlayService} from '../../../../../../components/overlay-dialog/overlay-service';
import {VocabularyService} from '../../../../../../services/vocabulary.service';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-vocabulary-filter-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{title | translate}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" *ngIf="chapters && chapters.length > 0">
      <div class="chapter" *ngFor="let chapter of chapters">
        <mat-checkbox color="primary" class="rounded-checkbox" [checked]="chapter.isChecked" (change)="onChapterClick(chapter)" [name]="chapter.chapter.name"></mat-checkbox>
        <span>{{chapter.chapter.name}}</span>
        <div class="week" *ngFor="let week of chapter.chapter.weeks">
          <mat-checkbox color="primary" class="rounded-checkbox" [checked]="week.isChecked" (change)="onWeekClick(chapter, week)" [name]="week.name"></mat-checkbox>
          <span>{{week.name}}</span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button"
              *ngFor="let button of buttons"
              class="btn btn-default"
              (click)="onClick(button)">{{button.text}}</button>
    </div>
  `,
  styles: [`
    .modal-body {
      max-height: 500px;
      overflow-y: auto;
    }

    .chapter > span {
      padding-left: 15px;
      font-weight: 600;
    }

    .week {
      padding-left: 70px;
    }

    .week > span {
      padding-left: 15px;
    }
  `]
})

export class VocabularyFilterModalContent implements OnInit {
  title: string;
  chapters: any[] = [];
  buttons: any[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    public overlayService: OverlayService,
    public vocabularyFiter: VocabularyService
  ) {
  }

  ngOnInit() {
    if (this.chapters) {
      const checkedChapters = this.chapters.filter(ch => ch.isChecked === true);
      if (checkedChapters && checkedChapters.length > 0) {
        checkedChapters.forEach(ch => ch.chapter.weeks.forEach(w => w.isChecked = true));
      }
    }
  }

  onChapterClick(chapter) {
    chapter.isChecked = !chapter.isChecked;
    chapter.chapter.weeks.forEach(week => week.isChecked = chapter.isChecked);
  }

  onWeekClick(chapter, week) {
    week.isChecked = !week.isChecked;

    const uncheckedWeek = chapter.chapter.weeks.filter(w => w.isChecked === false);
    chapter.isChecked = !(uncheckedWeek && uncheckedWeek.length > 0);
  }

  onClick(button: any) {
    if (button && button.handler) {
      const checkedChapters = this.chapters.filter(ch => ch.isChecked === true);
      const checkedWeeks = {};
      this.chapters.forEach(chapter => {
        chapter.chapter.weeks.forEach(week => {
          if (week.isChecked && !chapter.isChecked) {
            if (!checkedWeeks[chapter.chapter.id]) {
              checkedWeeks[chapter.chapter.id] = [];
              checkedWeeks[chapter.chapter.id].push(week.id);
            } else {
              checkedWeeks[chapter.chapter.id].push(week.id);
            }
          }
        })
      });
      this.overlayService.data.next({
        checkedChapters,
        checkedWeeks
      });
      this.bsModalRef.hide();
      return button.handler.apply(this.chapters);
    }

    return;
  }
}
