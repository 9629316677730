import {NgModule} from '@angular/core';
import {BaseOverlayDialogComponent} from './base-overlay-dialog/base-overlay-dialog';
import {ChapterFinishedDialogComponent} from './chapter-finished-dialog/chapter-finished-dialog';
import {DailyGoalDialog} from './daily-goal-dialog/daily-goal-dialog';
import {JokerDialog} from './joker-dialog/joker-dialog';
import {TaskDoneDialogComponent} from './task-done-dialog/task-done-dialog';
import {ExerciseResultDialogComponent} from './exercise-result-dialog/exercise-result-dialog.component';
import {OverlayDialogRootComponent} from './overlay-dialog-root';
import {TranslateModule} from '@ngx-translate/core';
import {PluralPipeModule} from '../../pipes/plural-pipe.module';
import {CommonModule} from '@angular/common';
import {MatButtonModule, MatCheckboxModule, MatIconModule} from '@angular/material';
import {DailyGoalPickerComponent} from './daily-goal-picker/daily-goal-picker.component';
import {NouisliderModule} from 'ng2-nouislider';
import {FormsModule} from '@angular/forms';
import {MediaFilesService} from '../../services/media-files.service';
import {ReportProblemDialogComponent} from './report-problem-dialog/report-problem-dialog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {LectorDialogComponent} from './lector-dialog/lector-dialog.component';
import {BaseModalModule} from '../base-modal/base-modal.module';
import {CertificateDialog} from './certificate-dialog/certificate-dialog';
import {ModalModule} from "ngx-bootstrap/modal";
import {TrialEndDialog} from './trial-end-dialog/trial-end-dialog';
import {DeleteAccountDialogComponent} from "./delete-account-dialog/delete-account-dialog.component";
import {VideoDialogMenuModule} from '../video-dialog-menu/video-dialog-menu.module';

@NgModule({
  declarations: [
    BaseOverlayDialogComponent,
    ChapterFinishedDialogComponent,
    DailyGoalDialog,
    JokerDialog,
    TaskDoneDialogComponent,
    ExerciseResultDialogComponent,
    OverlayDialogRootComponent,
    DailyGoalPickerComponent,
    ReportProblemDialogComponent,
    CertificateDialog,
    LectorDialogComponent,
    TrialEndDialog,
    DeleteAccountDialogComponent
  ],
	imports: [
		TranslateModule,
		PluralPipeModule,
		CommonModule,
		MatButtonModule,
		NouisliderModule,
		FormsModule,
		MatIconModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		BaseModalModule,
		ModalModule,
		VideoDialogMenuModule,
	],
  exports: [
    ExerciseResultDialogComponent,
    OverlayDialogRootComponent,
    BaseOverlayDialogComponent,
    TaskDoneDialogComponent,
    DailyGoalPickerComponent,
    ReportProblemDialogComponent,
    LectorDialogComponent,
    DeleteAccountDialogComponent
  ],
  entryComponents: [
    ExerciseResultDialogComponent,
    ChapterFinishedDialogComponent,
    TaskDoneDialogComponent,
    DailyGoalDialog,
    JokerDialog,
    BaseOverlayDialogComponent,
    DailyGoalPickerComponent,
    ReportProblemDialogComponent,
    LectorDialogComponent,
    CertificateDialog,
    TrialEndDialog,
    DeleteAccountDialogComponent
  ],
  providers: [
    MediaFilesService
  ]
})

export class OverlayDialogModule {
}
