import {Component, OnInit, ViewChild} from '@angular/core';
import {CourseCombinedModel} from '../../../../../../oj-app-common/shop/models/shop/course-combined.model';
import {CourseShopOptionsModel} from '../../../../../../oj-app-common/shop/models/course-shop-options.model';
import {ProductCombinedModel} from '../../../../../../oj-app-common/shop/models/shop/product-combined.model';
import {AnyCurrency} from '../../../../../../oj-app-common/shop/types/any-currency';
import {ModalDirective} from "ngx-bootstrap/modal";
import {PaymentDialogService} from "../../payment-dialog.service";

export const OpenModalParams = {
  license: 'license',
  payment: 'payment',
  period: 'period'
};

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit {
  currentModal = null;

  OPEN_MODAL_PARAMS = OpenModalParams;

  course: CourseCombinedModel;

  product: ProductCombinedModel | null;

  directlyProduct = false;

  shopOptions: CourseShopOptionsModel;

  currentSelectedSubscription: { product: ProductCombinedModel, currency: AnyCurrency };

  @ViewChild('staticModal', {static: false}) modal: ModalDirective;

  constructor(
    protected paymentDialogService: PaymentDialogService
  ) {

  }

  ngOnInit() {
  }

  openModal(
    type: string,
    course: CourseCombinedModel,
    product: ProductCombinedModel = null,
    options: CourseShopOptionsModel,
    currentSubscription: { product: ProductCombinedModel, currency: AnyCurrency } = null,
    directlyProduct = false
  ) {
    if (currentSubscription) {
      this.currentSelectedSubscription = currentSubscription;
    } else {
      this.currentSelectedSubscription = null;
    }
    this.currentModal = type;
    this.course = course;
    this.product = product;
    this.shopOptions = options;
    this.directlyProduct = directlyProduct;
    this.modal.show();
  }

  close() {
    this.currentModal = null;
    this.modal.hide();
    this.paymentDialogService.clickedDoAfterEnd();
  }

  onSubscribeCourseClicked(val: { product: ProductCombinedModel, currency: AnyCurrency }) {
    if (val) {
      this.modal.hide();
      setTimeout(() => {
        this.openModal(this.OPEN_MODAL_PARAMS.payment, this.course, this.product, this.shopOptions, val, this.directlyProduct);
      }, 200);
    }
  }

}
