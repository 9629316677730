import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {CurrentCourseService} from '../../services/current-course-service';
import {CurrentLessonSeriesService} from '../../services/current-lesson-series-service';
import {StudovnaApi} from '../../../../oj-app-common/studovna-api/studovna-api';
import {CurrentUserService} from '../../auth/current-user.service';
import {ModalContentComponent} from '../modal-content/modal-content.component';
import {CurrentStatusResponse} from '../../../../oj-app-common/studovna-api/responses/current-status';
import { UrlSet } from 'oj-app-common/shop/tools/woocommerce-bridge';
import {PaymentDialogService} from '../payment-dialogs/payment-dialog.service';
import {UserPreferencesService} from '../../../../oj-app-common/services/user-preferences.service';
import {DailyTaskType} from '../../models/daily-task-type';
import {VideoLayerManagerService} from '../video-layer/video-layer-manager.service';
import {BsModalRef, BsModalService, ModalDirective, ModalOptions} from 'ngx-bootstrap/modal';
import {CertificateDialog} from "../overlay-dialog/certificate-dialog/certificate-dialog";
import {OverlayService} from "../overlay-dialog/overlay-service";

@Component({
  selector: 'app-secret-modal-settings',
  templateUrl: './secret-modal-settings.component.html',
  styleUrls: ['./secret-modal-settings.component.scss']
})
export class SecretModalSettingsComponent implements OnInit {
  VIEW_TYPES = {
    default: 'default',
    lesson: 'lesson',
    course: 'course',
    student: 'student',
    findLesson: 'findLesson',
    wooUrl: 'wooUrl'
  };
  currentView = this.VIEW_TYPES.default;
  modalRef: BsModalRef;

  public wooUrlSet: UrlSet;
  public UrlSet = UrlSet;

  isPreview = false;

  @ViewChild('secretModalSettings', {static: false}) secretModalSettings: ModalDirective;
  constructor(
    private router: Router,
    private messageService: MessageService,
    private currentCourseService: CurrentCourseService,
    private currentLessonSeriesService: CurrentLessonSeriesService,
    private studovnaApi: StudovnaApi,
    private currentUserService: CurrentUserService,
    private modalService: BsModalService,
    protected paymentDialogService: PaymentDialogService,
    protected userPrefs: UserPreferencesService,
    protected videoLayerManager: VideoLayerManagerService,
    protected overlayService: OverlayService
  ) { }

  ngOnInit() {
    this.wooUrlSet = this.paymentDialogService.getPreferredWooUrlSet();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
      switch (event.key) {
        case 'Enter':
          if (this.currentView === this.VIEW_TYPES.lesson) {
            const input = document.getElementById('lessonId') as HTMLInputElement;
            if (input && input.value && input.value.length > 0) {
              this.goDirectlyToHandler(input.value);
            }
          } else if (this.currentView === this.VIEW_TYPES.course) {
            const input = document.getElementById('courseId') as HTMLInputElement;
            if (input && input.value && input.value.length > 0) {
              this.goDirectlyToCourseHandler(input.value);
            }
          }
          break;
        case 'Esc':
          this.close();
          break;
      }
  }

  show(preview = false) {
    this.isPreview = preview;
    this.secretModalSettings.show();
    if (this.isPreview) {
      setTimeout(() => {
        this.clickedLesson();
      }, 50);
    }
  }

  close() {
    this.secretModalSettings.hide();
  }

  resetView() {
    this.currentView = this.VIEW_TYPES.default;
  }

  clickedLesson() {
    this.currentView = this.VIEW_TYPES.lesson;
    setTimeout(() => {
      const input = document.getElementById('lessonId');
      if (input) {
        input.focus();
      }
    }, 200);
  }

  clickedCourse() {
    this.currentView = this.VIEW_TYPES.course;
    setTimeout(() => {
      const input = document.getElementById('courseId');
      if (input) {
        input.focus();
      }
    }, 200);
  }

  clickedSwitchUser() {
    this.currentView = this.VIEW_TYPES.student;
  }

  clickedWooShop() {
    this.currentView = this.VIEW_TYPES.wooUrl;
  }

  clickedTempVideoModal() {
    this.videoLayerManager.showTemp();
    this.close();
  }

  clickedShowCertificateDialog() {
    this.overlayService.show(
      CertificateDialog
    ).then(
      (response) => {
        const dialog = response.componentRef.instance;
        dialog.initialize(
          this.currentCourseService.course.name,
          this.currentCourseService.productData.image,
        );
        dialog.didHide.subscribe(
          () => {
            // resolver();
          }
        );
      }
    );
    this.close();
  }

  showTrialEndVideo() {
    this.videoLayerManager.showTrialEndPopup();
    this.close();
  }

  clickedFindLessonByType() {
    this.currentView = this.VIEW_TYPES.findLesson;
  }

  protected goDirectlyToHandler(lessonId) {
    if (this.isPreview) {
      this.router.navigate([`/lesson/public-detail/id/${lessonId}/hash/xxx`], );
      this.resetView();
      this.close();
      return;
    }
    const lesson = this.currentCourseService.getLessonById(lessonId);
    if (!lesson) {
      this.messageService.add({severity: 'error', detail: 'V tomto kurzu lekce se zadaným ID není.'});
      return;
    }
    this.currentLessonSeriesService.startStudyOnExactPoint(lesson);
    this.resetView();
    this.close();
    this.router.navigate(['/lesson'], {
      queryParams: {taskType: DailyTaskType.STUDY, from: '/dashboard'},
      skipLocationChange: false
    });
  }

  async goDirectlyToCourseHandler(courseId) {
    try {
      const activationResponse = await this.studovnaApi.courseActivation(
        this.currentUserService.studovnaId,
        courseId,
        this.currentUserService.token
      );
      await this.currentCourseService.activateCourse(courseId);
      let response: CurrentStatusResponse;
      response = await this.studovnaApi.currentStatus(this.currentUserService.studovnaId, this.currentUserService.token);
      this.currentUserService.updateActiveCourse(courseId);
      this.currentCourseService.setCurrentLesson(response.lastLessonBlock);
      this.messageService.add({severity: 'success', data: 'Úspěšně přepnuto.'});
      this.resetView();
    } catch (e) {
      this.messageService.add({severity: 'success', data: 'Nepovedlo se přepnout kurz.'});
      this.resetView();
    }
  }

  handleSwitchStudent(eshopId, studovnaId, email, token) {
    if (eshopId && studovnaId && email && token) {
      this.currentUserService.setLoggedUserData(eshopId, studovnaId, email, token)
    }
  }

  handleFindLesson(type, subtype) {
    const results = this.currentCourseService.findLessonsByType(type, subtype, 20);

    let message = '';
    for (const result of results) {
      message += '<br />ID ' + result.id + ' - ' + result.name;
    }

    if (!message) {
      message = 'Bohužel, nic nebylo nalezeno.';
    }

    const initState = {
      title: 'Nalezene lekce',
      body: message,
      buttons: [
        {
          text: 'OK, diky',
          class: ['rounded-button-primary rounded-button'],
          role: 'cancel',
        },
        {
          text: 'Otevřít tyto lekce',
          handler: () => {
            if (results && results.length > 0) {
              this.currentLessonSeriesService.startStudyWithGivenLessons(results);
              this.router.navigate(['/lesson']);
              this.resetView();
              this.close();
            }
          }
        }
      ]
    };
    const modalOptions: ModalOptions = new ModalOptions();
    modalOptions.initialState = initState;
    modalOptions.class = 'modal-dialog-centered custom-modal';
    this.modalRef = this.modalService
      .show(ModalContentComponent, modalOptions);
  }

  changeWooUrl() {
    this.setPreferredWooUrlSet(this.wooUrlSet);
    this.messageService.add({severity: 'success', data: 'Úspěšně zmeneno.'});
    this.resetView();
    this.close();
  }

  protected setPreferredWooUrlSet(urlSet: UrlSet) {
    this.userPrefs.set(PaymentDialogService.URL_SET_PREF_KEY, urlSet);
    this.wooUrlSet = this.paymentDialogService.getPreferredWooUrlSet();
  }

}
