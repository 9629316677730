import {NgModule} from '@angular/core';
import {VideoLayerComponent} from './video-layer.component';
import {CommonModule} from '@angular/common';
import {MatButtonModule, MatIconModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {VideoLayerManagerService} from './video-layer-manager.service';
import {ProgressBarModule} from 'angular-progress-bar';
import {RubyService} from '../../../../oj-app-common/ruby/ruby.service';
import {VideoDialogMenuModule} from '../video-dialog-menu/video-dialog-menu.module';

@NgModule({
	imports: [
		CommonModule,
		MatButtonModule,
		TranslateModule,
		MatIconModule,
		ProgressBarModule,
		VideoDialogMenuModule,
	],
  declarations: [
    VideoLayerComponent
  ],
  providers: [
    VideoLayerManagerService,
    RubyService
  ],
  entryComponents: [
    VideoLayerComponent
  ]
})

export class VideoLayerModule {}
