import { parseTime } from '../../tools/parse-time';

export interface LeaderboardStudent {
	id: number;
	userName: string;
	lastLogin?: Date;
	firstName: string;
	lastName: string;
	image: string;
	eshopId: number;
	points: number;
	isFriend?: boolean;
	position: number;
	languageList: {
		language: string;
		level: number;
		points: number;
	}[];
	displayedName?: string;
}

export function createLeaderboardStudentFromObject(input): LeaderboardStudent {

	let student = Object.assign({}, input);

	student.id = +student.id || 0;
	student.points = +student.points || 0;
	student.eshopId = +student.eshopId || 0;
	student.lastLogin = student.lastLogin ? parseTime(student.lastLogin, true) : null;
	student.points = +student.points || 0;
	student.position = +student.position || 0;

	student.languageList = student.languageList || [];
	student.languageList.map(
		(l) => {
			l.level = +l.level || 0;
			l.points = +l.points || 0;
		}
	);

	return student;


}
