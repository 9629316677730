import {Injectable} from '@angular/core';
import {CurrentUserService} from '../../../../../auth/current-user.service';
import {UserPreferencesService} from '../../../../../../../oj-app-common/services/user-preferences.service';
import {AppTranslationService} from '../../../../../services/app-translation.service';
import {Subject} from 'rxjs';
import {AnyCurrency} from '../../../../../../../oj-app-common/shop/types/any-currency';
import {CourseLanguage} from '../../../../../../../oj-app-common/shop/types/course-language';
import {EshopPreferencesModel} from '../models/eshop-preferences.model';

@Injectable({
  providedIn: 'root'
})
export class EshopUserPreferencesService {

  protected studentLang: CourseLanguage;

  protected taughtLang: CourseLanguage;

  protected eshopCurrency: AnyCurrency;

  protected preferences: EshopPreferencesModel = new EshopPreferencesModel();

  protected USER_PREF_KEY = 'EshopPreferences';

  preferencesChanged: Subject<EshopPreferencesModel> = new Subject<EshopPreferencesModel>();

  constructor(
    protected currentUserService: CurrentUserService,
    protected appTranslationService: AppTranslationService,
    protected userPrefs: UserPreferencesService,
  ) {

    this.currentUserService.onLogout.subscribe(
      () => {
        this.reset();
      }
    );
    this.load();

  }

  public get studentLanguage(): CourseLanguage {
    if (this.studentLang) {
      return this.studentLang;
    }
    return this.appTranslationService.currentLanguage;
  }

  public setStudentLanguage(lang: CourseLanguage) {
    this.studentLang = lang;
    this.save();
  }

  public get taughtLanguage(): CourseLanguage {
    if (this.taughtLang) {
      return this.taughtLang;
    }
    return 'en';
  }

  public setTaughtLanguage(lang: CourseLanguage) {
    this.taughtLang = lang;
    this.save();
  }

  public get currency(): AnyCurrency {
    if (this.eshopCurrency) {
      return this.eshopCurrency;
    }
  }

  public setCurrency(curr: AnyCurrency) {
    this.eshopCurrency = curr;
    this.save();
  }

  public get isSelectedCurrency(): boolean {
    return !!this.currency;
  }

  public get isSelectedLanguageStudentSpeak(): boolean {
    return !!this.studentLanguage;
  }

  public get isSelectedLanguageStudentStudies(): boolean {
    return !!this.taughtLanguage;
  }

  get eshopPreferences(): EshopPreferencesModel {
    return this.preferences;
  }

  public reset() {
    this.preferences = new EshopPreferencesModel();
    localStorage.removeItem(this.USER_PREF_KEY);
  }

  protected save() {
    this.preferences.taughtLanguage = this.taughtLang;
    this.preferences.studentLanguage = this.studentLang;
    this.preferences.currency = this.eshopCurrency;
    this.preferencesChanged.next(this.preferences);
    this.userPrefs.set(this.USER_PREF_KEY, this.preferences);
  }

  public load() {
    this.preferences = this.userPrefs.get(this.USER_PREF_KEY, null);

    if (!this.preferences) {
      this.setDefaultValues();
    } else {
      this.studentLang = this.preferences.studentLanguage;
      this.taughtLang = this.preferences.taughtLanguage;
      this.eshopCurrency = this.preferences.currency;
    }
  }

  setDefaultValues() {
    this.preferences = new EshopPreferencesModel();
    // firstly set student language
    this.studentLang = this.appTranslationService.currentLanguage;

    // secondly set taught language
    // TODO temporary
    this.taughtLang = 'en';

    // finally set currency
    if (this.studentLang === 'cs') {
      this.eshopCurrency = 'CZK';
    } else {
      this.eshopCurrency = 'EUR';
    }

    this.save();
  }
}
