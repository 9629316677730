import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {VideoService} from '../../contents/services/video.service';
import {VideoLayerManagerService} from '../video-layer/video-layer-manager.service';
import {VideoDialogMenuOutput} from './video-dialog-menu-output';

@Component({
  selector: 'app-video-dialog-menu',
  templateUrl: './video-dialog-menu.component.html',
  styleUrls: ['./video-dialog-menu.component.scss'],
})
export class VideoDialogMenuComponent implements OnInit {

  public videoEnabled = false;

  public tutorialVideoEnabled = true;

  @Output() onClose: EventEmitter<VideoDialogMenuOutput> = new EventEmitter<VideoDialogMenuOutput>();

  constructor(
    public videoService: VideoService,
    protected videoLayerService: VideoLayerManagerService,
  ) {
  }

  ngOnInit() {
    this.videoEnabled = this.videoService.isVideoEnabled();

    this.tutorialVideoEnabled = this.videoLayerService.tutorialVideosEnabled;
  }

  clickedSave() {
    this.videoService.enableVideo(this.videoEnabled);
    this.videoLayerService.enableTutorialVideos(this.tutorialVideoEnabled, true);

    this.onClose.emit('saved');
  }

  clickedBack() {
    this.onClose.emit('closed');
  }

}
