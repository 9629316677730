import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {CurrencyModel} from '../../../../../../oj-app-common/shop/models/shop/currency.model';
import {AnyCurrency} from '../../../../../../oj-app-common/shop/types/any-currency';
import {EshopCurrenciesService} from "../../../../pages/base-layout-page/pages/eshop-page/services/eshop-currencies.service";
import {BsDropdownDirective, BsDropdownToggleDirective} from "ngx-bootstrap/dropdown";
import {EshopUserPreferencesService} from "../../../../pages/base-layout-page/pages/eshop-page/services/eshop-user-preferences.service";

@Component({
  selector: 'app-currency-dropdown',
  templateUrl: './currency-dropdown.component.html',
  styleUrls: ['./currency-dropdown.component.scss']
})
export class CurrencyDropdownComponent implements OnChanges {

  isOpenedDropdown = false;

  currenciesSwitcherData: CurrencyModel[] = [];

  selectedCurrency: AnyCurrency;

  selectedCurrencySymbol = '';

  @Output() changedCurrency: EventEmitter<AnyCurrency> = new EventEmitter<AnyCurrency>();

  @Input() allCurrencies: AnyCurrency[];

  @Input() preselectedValue: AnyCurrency = null;

  @Input() scrollable = false;

  @ViewChild('currencyDropdown', {static: false}) currencyDropdownEl: BsDropdownDirective;

  @ViewChild('currencyDropdownBtn', {static: false}) currencyDropdownBtn: ElementRef;

  constructor(
    protected currenciesService: EshopCurrenciesService,
    protected eshopPreferences: EshopUserPreferencesService
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['allCurrencies']) {
      this.allCurrencies = changes['allCurrencies'].currentValue;
      this.prepareCurrenciesSwitcherData();
    }

    if (changes && changes['preselectedValue']) {
      this.selectedCurrency = changes['preselectedValue'].currentValue;
    }
  }

  preselectCurrency() {
    if (this.allCurrencies && this.allCurrencies.length < 1) {
      return;
    }

    const storedCurrency = this.eshopPreferences.currency;
    const isInCurrencies = this.allCurrencies.find(c => c === storedCurrency);

    if (this.preselectedValue) {
      this.selectedCurrency = this.preselectedValue;
    } else if (storedCurrency && isInCurrencies) {
      this.selectedCurrency = storedCurrency;
    } else if (this.allCurrencies && this.allCurrencies.length > 0) {
      this.selectedCurrency = this.allCurrencies[0];
    }
    this.changeCurrency(this.selectedCurrency);
  }

  prepareCurrenciesSwitcherData() {
    this.currenciesSwitcherData = [];

    this.allCurrencies
      .forEach(c => {
        const code = this.currenciesService.getCurrencyByCode(c);
        this.currenciesSwitcherData.push(code);
      })
    this.preselectCurrency();
  }

  changeCurrency(currency: AnyCurrency) {
    this.selectedCurrency = currency;
    const code = this.currenciesService.getCurrencyByCode(currency);
    if (code) {
      this.selectedCurrencySymbol = code.symbol;
    }
    this.eshopPreferences.setCurrency(currency);
    this.isOpenedDropdown = false;
    this.changedCurrency.emit(this.selectedCurrency);
  }

  openDropdown() {
      this.isOpenedDropdown = !this.isOpenedDropdown;
  }

}
