import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-circle-progressbar',
  templateUrl: './circle-progressbar.component.html',
  styleUrls: ['./circle-progressbar.component.scss']
})
export class CircleProgressbarComponent implements OnInit {

  @Input() size: number;
  @Input() percentage: number;
  @Input() imageUrl: string;
  @Input() imageSizePercentage: string;
  @Input() borderWidth: number;
  @Input() borderColor: string;
  @Input() innerStrokeColor: string;
  @Input() circleBackground = false;
  @Input() circleBackgroundColor = '';
  @Input() roundedImage = false;
  constructor() { }

  ngOnInit() {
  }

}
