import { Pipe, PipeTransform } from '@angular/core';
import {MediaFilesService} from '../services/media-files.service';

@Pipe({
  name: 'mediaFile',
  pure: true
})
export class MediaFilePipe implements PipeTransform {

  constructor(
    protected mediaService: MediaFilesService
  ) {

  }

  transform(value: string): string {
    return this.mediaService.getServerImageUrl(value);
  }

}
