import {Injectable} from '@angular/core';
import {ToleranceSettings} from '../../../oj-app-common/studovna-api/models/tolerance-settings';
import {StudovnaApi} from '../../../oj-app-common/studovna-api/studovna-api';
import {CurrentCourseService} from './current-course-service';
import {CurrentUserService} from '../auth/current-user.service';
import {Observable, Subject} from 'rxjs';
import {compareStringsWithTolerance} from '../../../oj-app-common/tools/text-comparator';

@Injectable({
  providedIn: 'root'
})
export class ExerciseToleranceService {
  protected _changed = new Subject<void>();

  protected _tolerance: ToleranceSettings = {
    capitalization: true,
    diacritics: true,
    punctuation: true,
  };

  constructor(
    protected studovnaApi: StudovnaApi,
    protected currentCourseService: CurrentCourseService,
    protected currentUserService: CurrentUserService,
  ) {
    this.loadFromApi();

    this.currentCourseService.onSwitchCourse.subscribe(
      () => {
        this.loadFromApi();
      }
    );

    window['compareTexts'] = compareStringsWithTolerance;

  }

  get toleranceChanged(): Observable<void> {
    return this._changed.asObservable();
  }

  get tolerance(): ToleranceSettings {
    return this._tolerance;
  }

  public update(settings: ToleranceSettings) {
    this._tolerance = settings;
    this.studovnaApi.setCourseTolerance(
      this.currentUserService.studovnaId,
      this.currentUserService.token,
      settings,
      this.currentCourseService.courseId,
    );
    this._changed.next();
  }

  public async init(): Promise<ToleranceSettings> {
    return this.loadFromApi();
  }

  protected async loadFromApi(): Promise<ToleranceSettings> {
    const loaded = await this.studovnaApi.courseTolerance(
      this.currentUserService.studovnaId,
      this.currentUserService.token,
      this.currentCourseService.courseId
    );
    this._tolerance = loaded;
    this._changed.next();
    return loaded;
  }

}
