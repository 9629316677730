import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

/* Angular material components && modules import */

/* Custom components && modules import */
import {ProfilePageComponent} from './profile-page.component';
import {
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatToolbarModule
} from '@angular/material';
import {ProgressBarModule} from 'angular-progress-bar';
import {TranslateModule} from '@ngx-translate/core';
import {ToStringPipe} from '../../../../pipes/to-string.pipe';
import {PluralPipeModule} from '../../../../pipes/plural-pipe.module';
import {BasicTopBarModule} from '../../components/basic-top-bar/basic-top-bar.module';
import {CourseModule} from '../../../../components/course/course.module';
import { PhotoCaptureModule } from './photo-capture/photo-capture.module';
import { CertificatesComponent } from './certificates/certificates.component';
import {BaseModalModule} from '../../../../components/base-modal/base-modal.module';
import { CertifateDataModalContentComponent } from './certificates/certifate-data-modal-content/certifate-data-modal-content.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ShopYellowBannerModule} from '../../../../components/shop-yellow-banner/shop-yellow-banner.module';
import {TooltipModule} from 'ng2-tooltip-directive';
import { EditProfileModalComponent } from './edit-profile-modal/edit-profile-modal.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SubscriptionItemComponent } from './subscriptions/subscription-item/subscription-item.component';

const routes: Routes = [
  {
    path: '', component: ProfilePageComponent, data: {pageTitle: 'Profil', animation: 'ProfilePage'}
  },
  {
    path: 'certificates', component: CertificatesComponent, data: {pageTitle: 'Certifikáty', animation: 'CertificatesPage'}
  },
  {
    path: 'subscriptions', component: SubscriptionsComponent, data: {pageTitle: 'Subscriptions', animation: 'SubscriptionsPage'}
  }
];

@NgModule({
  declarations: [
    ProfilePageComponent,
    ToStringPipe,
    CertificatesComponent,
    CertifateDataModalContentComponent,
    EditProfileModalComponent,
    SubscriptionsComponent,
    SubscriptionItemComponent,
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        ProgressBarModule,
        TranslateModule,
        PluralPipeModule,
        ModalModule.forRoot(),
        BasicTopBarModule,
        CourseModule,
        PhotoCaptureModule,
        BaseModalModule,
        NgbModule,
        ShopYellowBannerModule,
        TooltipModule,
        MatCheckboxModule
    ],
  exports: [],
  providers: [],
  entryComponents: [
    CertifateDataModalContentComponent
  ]
})
export class ProfilePageModule {
}
