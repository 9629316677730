import {Injectable} from '@angular/core';
import {AppConfig} from '../config/app-config';
import {EshopApi} from '../../../oj-app-common/eshop-api/eshop-api';
import {StudovnaApi} from '../../../oj-app-common/studovna-api/studovna-api';
import {TranslateService} from '@ngx-translate/core';
import {CurrentUserService} from '../auth/current-user.service';
import {Subject} from 'rxjs';
import {initTranslatePlaceholderFunction} from "../../../oj-app-common/i18n/translate-placeholder";

@Injectable({
  providedIn: 'root'
})
export class AppTranslationService {

  private _currentLanguage: string;

  private _changedLanguageSubject = new Subject<string>();

  constructor(
    protected appConfig: AppConfig,
    protected eshopApi: EshopApi,
    protected studovnaApi: StudovnaApi,
    protected translateService: TranslateService,
    protected currentUser: CurrentUserService,
  ) {


  }

  public static languageCode2Locale(code: string) {
    switch (code) {
      case 'cs':
        return 'cs';

      case 'en':
        return 'en';

      case 'es':
        return 'es';

      case 'sk':
        return 'sk';

      case 'pl':
        return 'pl';
    }
  }

  get changedLanguage(): Subject<string> {
    return this._changedLanguageSubject;
  }

  get currentLanguage(): string {
    return this._currentLanguage;
  }

  get hasSavedLanguage(): boolean {
    const language = window.localStorage.getItem('language');
    return !!language;
  }

  protected simplifyLanguageCode(code: string): string {
    return code.substr(0, 2);
  }

  initApp(): Promise<void> {

    let currentLanguage = '';
    if (window.localStorage.getItem('language')) {
      currentLanguage = window.localStorage.getItem('language');
    }
    if (!currentLanguage) {
      if (navigator.languages) {
        for (let testedLang of navigator.languages) {
          testedLang = this.simplifyLanguageCode(testedLang);
          if (this.appConfig.availableLanguages.indexOf(testedLang) !== -1) {
            currentLanguage = testedLang;
            break;
          }
        }
      } else if (navigator.language && this.appConfig.availableLanguages.indexOf(this.simplifyLanguageCode(navigator.language)) !== -1) {
        currentLanguage = navigator.language;
      }
    }
    if (!currentLanguage) {
      currentLanguage = this.appConfig.defaultLanguage;
    }

    this.translateService.setDefaultLang(
      this.appConfig.defaultLanguage
    );
    this._currentLanguage = currentLanguage;
    const returnedPromise = this.translateService.use(
      currentLanguage
    ).toPromise();

    initTranslatePlaceholderFunction(this.translateService);

    this._changedLanguageSubject.next(this._currentLanguage);

    return returnedPromise;
  }

  switchLanguage(language: string, saveToBackend = true): Promise<void> {

    if (language === this._currentLanguage) {
      return Promise.resolve();
    }

    if (this.appConfig.availableLanguages.indexOf(language) === -1) {
      return Promise.reject('Invalid language code: ' + language);
    }

    this._currentLanguage = language;

    window.localStorage.setItem('language', language);

    if (saveToBackend && this.currentUser.isLoggedIn) {

      this.studovnaApi.setSettings(
        this.currentUser.studovnaId,
        this.currentUser.token,
        {
          language: this._currentLanguage
        }
      );

    }

    const asPromise = this.translateService.use(
      language
    ).toPromise();

    asPromise.then(
      () => {
        this._changedLanguageSubject.next(this._currentLanguage);
      }
    );

    return asPromise;

  }
}
