import {MyProfileResponse} from '../../../../oj-app-common/studovna-api/responses/my-profile';
import moment from 'moment';
import {DashboardInfoResponse} from '../../../../oj-app-common/studovna-api/responses/dashboard-info';
import {ThreeFiguresConfig} from '../../pages/base-layout-page/components/three-figures/three-figures.component';
import {_} from '../../../../oj-app-common/i18n/translate-placeholder';
import {DailyTasksResponse} from '../../../../oj-app-common/studovna-api/responses/daily-tasks';
import {days, daysShort, prepareRenderedData} from './prepare-rendered-data.function';
import { vocabChartOptions, vocabChartColors } from './vocab-chart.function';
import {LoginService} from '../../services/login.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {StudovnaApi} from '../../../../oj-app-common/studovna-api/studovna-api';
import {CurrentUserService} from '../../auth/current-user.service';
import {ThemeService} from '../../theme/theme.service';
import {VocabularyService} from '../../services/vocabulary.service';
import {CurrentLessonSeriesService} from '../../services/current-lesson-series-service';
import {CurrentCourseService} from '../../services/current-course-service';
import {OverlayService} from '../../components/overlay-dialog/overlay-service';
import {BaseLayoutService} from '../../pages/base-layout-page/base-layout.service';
import {MessageService} from 'primeng/api';
import {LoadingService} from '../../services/loading.service';
import {TutorialService} from '../../services/tutorial.service';
import {DailyTaskType} from '../../models/daily-task-type';
import {CurrentStatusResponse} from '../../../../oj-app-common/studovna-api/responses/current-status';
import {ChapterFinishedDialogComponent} from '../../components/overlay-dialog/chapter-finished-dialog/chapter-finished-dialog';
import {CHAPTER_FINISHED_DIALOG_RESULT} from '../../components/overlay-dialog/chapter-finished-dialog/chapter-finished-dialog-result';
import {AvailableCourse} from '../../models/available-course';
import {DailyGoalPickerComponent} from '../../components/overlay-dialog/daily-goal-picker/daily-goal-picker.component';
import {clamp} from 'lodash-es';
import {range} from 'lodash-es';
import {RenderedDataModel} from '../../models/rendered-data.model';
import {Subject} from 'rxjs';
import {BsModalService} from 'ngx-bootstrap/modal';

export class ActivityComponent {
  public profileDetails: MyProfileResponse;
  public currentWeek = {
    start: moment().startOf('week').toDate(),
    end: moment().endOf('week').toDate()
  };
  public displayedDailyGoalNumber = '';
  public visibleDailyCurrent = false;
  public visibleDailyGoal = false;
  public data: DashboardInfoResponse = null;
  public days = days;
  public daysShort = daysShort;

  public dayToday: string;
  public vocabChartOptions = vocabChartOptions;
  public vocabChartColors = vocabChartColors;
  public threeFiguresConfig: ThreeFiguresConfig = {
    name1: _('Body'),
    name2: _('Čas'),
    name3: _('Úspěšnost'),
    icon1: 'shield',
    icon2: 'time',
    icon3: 'success',
  };
  public vocabChartData: number[] = [];
  public vocabProficiency = 0;
  public minutesDailyGoalClamped = 0;
  public minutesDailyGoal = 0;
  public minutesDailyCurrentClamped = 0;
  public minutesTodayStudy = 0;
  public isTodayGoalReached = false;
  public dailyTasks: DailyTasksResponse = null;
  public dailyTasksCompleted = 0;
  public dailyTasksTotal = 0;
  public weekOverviewMaxLevel = 0;
  public weekBarHeight = 75;
  public renderedData: RenderedDataModel = new RenderedDataModel();
  public doNotShowFirstDailyTask = false;

  public currentCourse: AvailableCourse;

  loading: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected _loginService: LoginService,
    protected router: Router,
    protected translateService: TranslateService,
    protected studovnaApi: StudovnaApi,
    protected currentUserService: CurrentUserService,
    protected themeService: ThemeService,
    protected vocabularyService: VocabularyService,
    protected currentLessonService: CurrentLessonSeriesService,
    protected currentCourseService: CurrentCourseService,
    protected overlayDialogService: OverlayService,
    protected baseLayoutService: BaseLayoutService,
    protected messageService: MessageService,
    protected loadingService: LoadingService,
    protected modalService: BsModalService,
    protected tutorial: TutorialService
  ) {}

  async reloadData() {
    this.loading.next(true);
    return this.initUserProfile()
      .then((data) => {
        return this.loadData();
      }, () => {
        this.loading.next(false);

        if (!this.profileDetails.courseList || (this.profileDetails.courseList && this.profileDetails.courseList.length === 0)) {
          this.router.navigate(['/trialPicker']);
        } else {
          this.router.navigate(['/eshop']);
        }
        return Promise.reject();
      })
      .then(() => this.loadDailyTasks(), err => {
        console.log(err)
        return Promise.reject();
      })
      .then(() => {
        setTimeout(() => {
          this.renderedData = prepareRenderedData(this.data, this.dailyTasks, this.weekOverviewMaxLevel, this.dayToday);
        }, 20);
        this.loading.next(true);
      })
      .finally(() => {
        this.loading.next(true);
      });
  }

  protected async initUserProfile() {
    this.profileDetails = await this
      .currentUserService
      .getMyProfileFromLocalStorage();
    return this.profileDetails;
  }

  protected async loadData() {
    let response: DashboardInfoResponse;
    try {
      response = await this.studovnaApi.dashboardInfo(
        this.currentUserService.studovnaId,
        this.currentUserService.token,
      );
    } catch (error) {
      this.router.navigate(['/trialPicker/']);
      return Promise.reject();
    }

    this.data = response;

    this.currentCourseService.activateCourse(response.courseId);

    const todayTotalTime = this.data.todayTotal ? this.data.todayTotal.time : this.data.today.time;

    this.minutesDailyGoalClamped = Math.round(clamp(this.data.dailyGoal / 60, 0, 60));
    this.minutesDailyGoal = Math.round(this.data.dailyGoal / 60);
    this.minutesDailyCurrentClamped = Math.round(clamp(todayTotalTime / 60, 0, 60));
    this.minutesTodayStudy = Math.round(todayTotalTime / 60);
    this.isTodayGoalReached = (todayTotalTime >= this.data.dailyGoal);

    this.weekOverviewMaxLevel = 0;

    Object.keys(this.data.weekTotal.overview).map(
      (dayCode) => {
        const dayTime = this.data.weekTotal.overview[dayCode].time;
        if (dayTime > this.weekOverviewMaxLevel) {
          this.weekOverviewMaxLevel = dayTime;
        }
      }
    );

    if (this.weekOverviewMaxLevel < this.data.dailyGoal) {
      this.weekOverviewMaxLevel = this.data.dailyGoal;
    }

    let dayTodayNumber = (new Date()).getDay() - 1;
    if (dayTodayNumber === -1) {
      dayTodayNumber = 6;
    }
    this.dayToday = days[dayTodayNumber];
    this.startDailyGoalAnimation();
    this.prepareVocabChartData();
  }

  protected async loadDailyTasks() {

    let response;

    try {
      response = await this.studovnaApi.dailyTasks(
        this.currentUserService.studovnaId,
        this.currentUserService.token
      );
    } catch (error) {
      console.error(error);
      this.messageService.add({severity: 'error', detail: _('Nelze načíst denní úlohy.')});
      this.dailyTasks = null;
      this.dailyTasksTotal = 0;
      this.data = null;
      return Promise.reject();
    }

    this.dailyTasks = response;

    this.dailyTasksCompleted = 0;
    this.dailyTasksTotal = 1;
    if (this.dailyTasks.vocabulary.active) {
      this.dailyTasksTotal++;
    }
    if (this.dailyTasks.exercise.active) {
      this.dailyTasksTotal++;
    }
    if (this.dailyTasks.study.done) {
      this.dailyTasksCompleted++;
    }
    if (this.dailyTasks.vocabulary.done && this.dailyTasks.vocabulary.active) {
      this.dailyTasksCompleted++;
    }
    if (this.dailyTasks.exercise.done && this.dailyTasks.exercise.active) {
      this.dailyTasksCompleted++;
    }

    if (this.currentCourseService.course && this.currentCourseService.course.isNonLingual && !this.dailyTasks.vocabulary.active) {
      this.doNotShowFirstDailyTask = true;
    } else {
      this.doNotShowFirstDailyTask = false;
    }

    return true;

  }

  protected startDailyGoalAnimation() {
    this.displayedDailyGoalNumber = '';

    const todayTotalTime = this.data.todayTotal ? this.data.todayTotal.time : this.data.today.time;

    const targetNumber = Math.round(clamp(todayTotalTime / this.data.dailyGoal, 0, 1) * 100);

    for (const step of range(0, 11)) {
      setTimeout(
        () => {
          this.displayedDailyGoalNumber = '' + Math.round(targetNumber * step / 10);
        },
        1500 + step * 50
      );
    }

    setTimeout(
      () => {
        this.visibleDailyGoal = true;
      },
      1500
    );

    setTimeout(
      () => {
        this.visibleDailyCurrent = true;
      },
      1700
    );
  }

  isDayInStudyPlan(statusClasses: string[]) {
    if (!this.days) {
      return;
    }
    const includes = statusClasses.includes('status--future-empty');
    if (includes) {
      return false;
    }
    return true;
  }

  public prepareVocabChartData() {

    this.vocabChartData = [
      this.data.overall.vocabulary.overview.L0,
      this.data.overall.vocabulary.overview.L1,
      this.data.overall.vocabulary.overview.L2,
      this.data.overall.vocabulary.overview.L3,
      this.data.overall.vocabulary.overview.L4,
      this.data.overall.vocabulary.overview.L5,
    ];

    this.vocabProficiency = this.data.overall.vocabulary.success;

  }

  clickedVocabulary() {
    if (!this.dailyTasks.vocabulary.active) {
      this.messageService.add({
        severity: 'info',
        summary: _('Žádná slovíčka k procvičení...'),
        detail: _('Zatím nemáte žádná slovíčka k procvičování. Projděte pár lekcemi v kurzu a další slovíčka se objeví.')
      });
    }

    const expired = this.checkExpiredCourse();

    if (expired) {
      return;
    }

    this.vocabularyService
      .loadDailyTrainingDeck().then(
      (deck) => {
        if (deck.words.length) {
          this.router.navigate(['/review'], {
            queryParams: {deck: JSON.stringify(deck), from: '/dashboard'},
            skipLocationChange: true
          });
        } else {
          this.messageService.add({
            severity: 'info',
            summary: _('Žádná slovíčka'),
            detail: _('Zatím nemáte žádná slovíčka k procvičování.')
          });
        }
      }
    );
  }

  clickedExercise() {
    let startedSomething = false;

    const expired = this.checkExpiredCourse();

    if (expired) {
      return;
    }

    if (this.dailyTasks.exercise.lessonBlocks && this.dailyTasks.exercise.lessonBlocks.length) {
      startedSomething = this.currentLessonService.startStudyWithGivenLessons(
        this.dailyTasks.exercise.lessonBlocks,
        null,
        true,
        false,
        null,
        true
      );

      if (startedSomething) {
        setTimeout(() => {
          this.router.navigate(['/lesson'], {
            queryParams: {taskType: DailyTaskType.EXERCISE, from: '/dashboard'},
            skipLocationChange: false
          });
          return;
        }, 200);
      }
    }

    if (!startedSomething) {
      this.messageService.add({
        severity: 'info',
        summary: _('Není co opakovat.'),
        detail: _('Momentálně v kurzu nemáte žádnou látku, kterou by bylo potřeba procvičovat.')
      });
    }
  }

  public async clickedStudy() {

    const expired = this.checkExpiredCourse();

    if (expired) {
      return;
    }

    let response: CurrentStatusResponse;

    try {

      response = await this.studovnaApi.currentStatus(this.currentUserService.studovnaId, this.currentUserService.token);

      if (!response || !response.activeCourse) {
        console.log('Nieje vybrany kurz');
        this.messageService.add({
          severity: 'info',
          summary: _('Nemáte vybraný kurz.'),
          detail: _('Nejprve si vyberte nějaký kurz.')
        });
        return;
      }

      this.currentUserService.updateActiveCourse(response.activeCourse);

      this.currentCourseService.setCurrentLesson(response.lastLessonBlock);

      this.goToLessonIfPossible(response.activeCourse, response.lastLessonBlock, false)
        .then(
          (success) => {
            if (!success) {
              this.router.navigate(['/eshop/course']);
              // this.router.navigate(['/content']);
              // this.goToCurrentCourseStart();
            }
          }
        )
      ;

    } catch (error) {
      console.error(error);
      this.messageService.add({severity: 'error', summary: _('Nelze pokračovat.')});
      throw error;
    }

  }

  checkExpiredCourse(): boolean {
    if (this.currentCourse && this.currentCourse.expireDate &&
      this.currentCourse.expireDate.getTime &&
      this.currentCourse.expireDate.getTime() <
      (new Date()).getTime() - 86400 * 1000) {
      this.messageService.add({severity: 'error', summary: _('Kurz expiroval'), detail: _('Předplatné kurzu již vypršelo.')});
      return true;
    }
    return false;
  }

  async goToLessonIfPossible(courseId: number, lessonBlockId: number, goToNext = false): Promise<boolean> {
    if (!this.currentCourseService.courseId) {
      return false;
    }

    let lesson;

    if (!lessonBlockId) {
      const course = this.currentCourseService.course;
      const firstChapter = course.chapters[0];
      if (firstChapter) {
        const firstWeek = firstChapter.weeks[0];
        if (firstWeek) {
          const firstBlock = firstWeek.blocks[0];
          if (firstBlock) {
            lesson = firstBlock.lessons[0];
          }
        }
      }
    } else {
      lesson = this.currentCourseService.getLessonById(lessonBlockId);
    }

    if (!lesson) {
      return false;
    } else {
      this.currentLessonService
        .startStudyOnExactPoint(
          lesson,
          () => {
            let resolver: (result) => any;
            console.log('finished exercise !!!');
            const returnedPromise = new Promise<any>(
              (resolve, reject) => {
                resolver = resolve;
              }
            );

            this.overlayDialogService.show(
              ChapterFinishedDialogComponent
            ).then(dialogResult => {
              const dialog = dialogResult.componentRef.instance;
              const lessonPosition = this.currentCourseService.locateLessonBlockById(lesson.id);
              if (lessonPosition) {
                dialog.setChapterAndWeek(lessonPosition.chapter, lessonPosition.week);
              }
              dialog.didHide.subscribe(
                (result: CHAPTER_FINISHED_DIALOG_RESULT) => {
                  if (result === CHAPTER_FINISHED_DIALOG_RESULT.RETURN_TO_DASHBOARD) {
                    this.router.navigate(['/dashboard']);
                    resolver(false);
                  } else {
                    const success = this.currentLessonService.startStudyingNextChapter(lesson);
                    if (!success) {
                      this.router.navigate(['/dashboard']);
                      resolver(false);
                    } else {
                      resolver(true);
                    }
                  }
                }
              );
            });

            return returnedPromise;
          }
        );
      this.router.navigate(['/lesson'], {queryParams: {taskType: DailyTaskType.STUDY, from: '/dashboard'}, skipLocationChange: false});
      return true;
    }
  }

  slideToDailyTasks() {
    if (!this.dailyTasks.vocabulary.done && this.dailyTasks.vocabulary.active) {
      this.clickedVocabulary();
      return;
    }
    if (!this.dailyTasks.exercise.done && this.dailyTasks.exercise.active) {
      this.clickedExercise();
      return;
    }
    if (!this.dailyTasks.study.done) {
      this.clickedStudy();
      return;
    }
    this.clickedStudy();
    return;
  }

  goToDailyGoalPicker() {
    this.overlayDialogService
      .show(DailyGoalPickerComponent);
  }

}
