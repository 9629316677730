import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ReportBugService, ReportProblemType} from '../../services/report-bug.service';
import {OverlayService} from "../overlay-dialog/overlay-service";
import {ModalDirective} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-report-bug-dialog',
  templateUrl: './report-bug-dialog.component.html',
  styleUrls: ['./report-bug-dialog.component.scss']
})
export class ReportBugDialogComponent implements OnInit {
  public message = '';

  REPORT_PROBLEM_TYPES = ReportProblemType;

  reportProblemType = ReportProblemType.ERROR;

  @ViewChild('staticModal', {static: false}) modal: ModalDirective;

  @Output() onReportBugSubmit: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    protected reportBugService: ReportBugService,
    protected overlayService: OverlayService
  ) { }

  ngOnInit() {
  }

  openModal(type: ReportProblemType) {
    this.reportProblemType = type;
    this.modal.show();
    this.overlayService.forceOperating(true);
    this.reportBugService.isShown.next(true);
  }

  close() {
    this.modal.hide();
    this.reportBugService.onClose.next(true);
    this.overlayService.forceOperating(false);
    this.reportBugService.isShown.next(false);
  }

  clickedButton() {
    if (this.message && this.message.length > 0) {
      this.onReportBugSubmit.emit(this.message);
      this.message = '';
      this.close();
    }
    return;
  }
}
