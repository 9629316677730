import {SoundClip} from '../common/sound/sound-clip.interface';

export class CacheEntry {
  audio: SoundClip;
  text: string;

  constructor(audio: SoundClip, text: string) {
    this.audio = audio;
    this.text = text;
  }
}
