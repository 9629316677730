import { MethodDescription } from '../method-description';

export const BlockStudentAccountDescription = new MethodDescription();

BlockStudentAccountDescription.name = 'blockStudentAccount';
BlockStudentAccountDescription.fields = [
	'studentId',
	'block',
];
BlockStudentAccountDescription.usesToken = true;
