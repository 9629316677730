import { MethodDescription } from '../method-description';

export const ActivateDemoMethodDescription = new MethodDescription();

ActivateDemoMethodDescription.name = 'activateDemo';
ActivateDemoMethodDescription.fields = [
	'productId',
	'studentId',
	'sourcecode',
	'expireRenewalUrl',
	'sendToPodio',
];
ActivateDemoMethodDescription.usesToken = true;
