import { BaseExercise } from '../base-exercise';
import { InvalidExerciseData } from '../errors/invalid-exercise-data';
import { Sentence } from './models/sentence';
import { SentenceToken } from './models/sentence-token';
import { StaticToken } from './models/static-token';
import { autoCalculateTokenSizes } from './token-auto-size-calculator';
import {
	filterOutSentenceTokensWithSize,
	SentenceTokenWithSize,
} from './models/sentence-token-with-size';

export abstract class AbstractFillUpExercise extends BaseExercise {

	public static fillUpType: string;

	public sentences: Sentence[] = [];

	public abstract createToken(tokenData: any, sentence: Sentence): SentenceToken;

	public init(lessonData: object): void {
		if (lessonData['fillups']) {

			let sentenceCounter = 1;
			let countOfSentences = Object.keys(lessonData['fillups']).length;

			for (let key of Object.keys(lessonData['fillups'])) {

				let sentenceData = lessonData['fillups'][key];

				if (!sentenceData['tokens'] || !sentenceData['tokens'].length) {
					continue;
				}

				let sentence = new Sentence();
				sentence.id = +sentenceData['id'];
				sentence.tokens = [];
				sentence.displayedNumber = sentenceCounter;
				sentence.number = +key;
				let firstTokenOfSentence = true;
				for (let tokenData of sentenceData['tokens']) {
					let token = this.createToken(tokenData, sentence);
					if (token) {
						sentence.tokens.push(token);
						firstTokenOfSentence = false;
					}
				}

				let tokensToAutosize = filterOutSentenceTokensWithSize(sentence.tokens);
				if (tokensToAutosize.length) {
					autoCalculateTokenSizes(tokensToAutosize);
				}
				sentenceCounter++;
				this.sentences.push(sentence);
			}

			this.filterNumbersFromBeginning();

			// In case there are many sentences with one token each, we need to autosize them in-between sentences too
			// test case: course 398, lesson 94015
			if (this.sentences.every((s) => filterOutSentenceTokensWithSize(s.tokens).length <= 1)) {
				let allTokensToAutosize: SentenceTokenWithSize[] = [];
				this.sentences.forEach((s) => {
					allTokensToAutosize = [...allTokensToAutosize, ...filterOutSentenceTokensWithSize(s.tokens)];
				});
				autoCalculateTokenSizes(allTokensToAutosize);
			}

		} else {
			throw new InvalidExerciseData('Lesson data must have [fillups] field.');
		}




	}

	public filterNumbersFromBeginning() {

		let isEligibleForFiltering = true;

		if (this.sentences.length === 1) {
			return;
		}

		for (let sentence of this.sentences) {
			if (sentence.tokens.length) {
				let firstToken: StaticToken = sentence.tokens[0] as StaticToken;
				if (firstToken.type !== StaticToken.typeName) {
					isEligibleForFiltering = false;
					break;
				}

				let numberOnBeginningMatch = firstToken.html.match(/^(\s*(\d+)\.\s?)/);
				if (numberOnBeginningMatch && numberOnBeginningMatch[2] !== sentence.number + '') {
					isEligibleForFiltering = false;
					break;
				}
			}
		}

		if (isEligibleForFiltering) {
			this.sentences.map(
				(s: Sentence) => {
					let token = s.tokens[0] as StaticToken;
					let matchingFirstNumber = token.html.match(/^(\s*(\d+)\.\s?)/);
					if (matchingFirstNumber) {
						token.html = token.html.substring(matchingFirstNumber[1].length);
					}
				}
			);
		}

	}

}
