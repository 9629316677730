export class CourseDetailResponse {

	id: number;
	name: string;
	subTitle: string;
	description: string;
	level: string;
	type: string;
	target: string;
	isDemo: boolean;
	isGradual: boolean;
	isTrial = false;
	points: number;
	isWeeklyGradual: boolean;
	allWords: number;
	learnedWords: number;
	vocabularyLanguageList: string[];
	time: number;
	complete: number;
	success: number;
	bookmarksCount: number;
	vocabularyTraining: boolean;

}
