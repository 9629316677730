import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from '../../../../../../../../oj-app-common/ruby/models/subscription';
import {AppTranslationService} from '../../../../../../services/app-translation.service';

@Component({
  selector: 'app-subscription-item',
  templateUrl: './subscription-item.component.html',
  styleUrls: ['./subscription-item.component.scss'],
})
export class SubscriptionItemComponent implements OnInit {

  @Input() subscription: Subscription;

  public localeCode: string;

  constructor(
    private appTranslationService: AppTranslationService
  ) {
  }

  ngOnInit() {
    this.localeCode = AppTranslationService.languageCode2Locale(this.appTranslationService.currentLanguage);
  }

}
