export interface ImageSource {
	w: number;
	h: number;
	url: string;
}

export function createImageSource(input: any): ImageSource {
	return {
		w: +input.w || 0,
		h: +input.h || 0,
		url: (input.url || '') + '',
	};
}
