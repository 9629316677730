import {Component, HostListener, OnInit} from '@angular/core';
import {BaseOverlayDialogComponent} from '../base-overlay-dialog/base-overlay-dialog';

@Component({
  selector: 'app-lector-dialog',
  templateUrl: './lector-dialog.component.html',
  styleUrls: ['./lector-dialog.component.scss']
})
export class LectorDialogComponent extends BaseOverlayDialogComponent implements OnInit {
  public loading = true;
  public dialogHidden = true;

  public message = '';

  buttonAction: () => void;

  ngOnInit() {
  }

  public async initData() {

    this.loading = true;

    this.loading = false;

  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.clickedButton();
    }
  }

  setupButton(action: () => void) {
    this.buttonAction = action;
  }

  clickedButton() {
    if (this.buttonAction) {
      this.buttonAction();
    }
    this.close();
  }

  getHideDuration(): number {
    return 0;
  }

  getShowDuration(): number {
    return 0;
  }

  hide() {
    setTimeout(
      () => {
        this.dialogHidden = true;
      },
      50
    );
  }

  show() {
    this.initData()
      .then(() => {
        this.dialogHidden = false;
      })
  }

}
