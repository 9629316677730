import {Subscription} from 'rxjs';

export class ActivityTimer {

  protected _startTime: Date;
  protected _endTime: Date;

  protected pausedTime: Date;

  protected activityWatchInterval = 0;

  protected platformBindings: Subscription[] = [];

  constructor(activityWatchInterval = 0) {
    this.activityWatchInterval = activityWatchInterval;
  }

  get running(): boolean {
    return (this._startTime && !this.pausedTime && !this._endTime);
  }

  get startTime(): Date {
    return this._startTime;
  }

  get endTime(): Date {
    return this._endTime;
  }

  get _pausedTime(): Date {
    return this.pausedTime;
  }

  public reset() {
    this._startTime = null;
    this._endTime = null;
    this.pausedTime = null;
  }

  public start() {
    this._startTime = new Date();
  }

  public stop() {
    this._endTime = new Date();
    this.unpause()
  }

  public pause() {
    if (this.pausedTime) {
      return;
    }
    this.pausedTime = new Date();
  }

  public unpause() {
    if (this.pausedTime) {
      this.pausedTime = null;
    }
  }

  public getSeconds() {
    if (!this._startTime) {
      return 0;
    }
    let start: number;
    let end: number;
    if (this.endTime) {
      start = this.startTime.getTime();
      end = this.endTime.getTime();
      return Math.round((end - start) / 1000);
    }
    if (this.pausedTime) {
      start = this._startTime.getTime();
      end = this.pausedTime.getTime();
      return Math.round((end - start) / 1000);
    }
    start = this._startTime.getTime();
    end = Date.now();
    return Math.round((end - start) / 1000);
  }

  public markActivity() {
    this.unpause();
  }

  public destroy() {
    if (this.platformBindings) {
      this.unbindPlatform();
    }
  }

  public unbindPlatform() {
    this.platformBindings.map(
      (b) => {
        b.unsubscribe();
      }
    );
    this.platformBindings = [];
  }

}
