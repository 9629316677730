import { Block } from './block';

export class Week {

	id: number;
	name: string;
	order: number;
	blocks: Block[];
	allowed: boolean;
	isNew?: boolean;

	static createFromData(data: any): Week {

		let week = new Week();
		week.id = +data.id;
		week.name = data.name + '';
		week.order = +data.order || 0;
		week.allowed = !!data.allowed;
		week.isNew = false;

		week.blocks = [];

		if (data.blocks) {
			for (let blockId of Object.keys(data.blocks)) {
				let block = data.blocks[blockId];
				let b = Block.createFromData(block);
				week.blocks.push(b);
				if (b.isNew) {
					week.isNew = true;
				}
			}
		}

		return week;

	}

}
