import { MethodDescription } from '../method-description';

export const TestMethodDescription = new MethodDescription();

TestMethodDescription.name = 'test';
TestMethodDescription.fields = [
	'test1',
	'test2',
	'test3'
];
