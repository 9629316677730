import {animate, animateChild, group, query, style, transition, trigger} from '@angular/animations';

export const slideLeft = [
  style({position: 'relative'}),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%'
    })
  ]),
  query(':enter', [
    style({right: '-100%'})
  ]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate('200ms ease-out', style({right: '100%'}))
    ]),
    query(':enter', [
      animate('300ms ease-out', style({right: '0%'}))
    ])
  ]),
  query(':enter', animateChild()),
];

export const slideRight = [
  style({position: 'relative'}),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%'
    })
  ]),
  query(':enter', [
    style({left: '-100%'})
  ]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate('200ms ease-out', style({left: '100%'}))
    ]),
    query(':enter', [
      animate('300ms ease-out', style({left: '0%'}))
    ])
  ]),
  query(':enter', animateChild()),
];


export const slideInAnimation =
  trigger('routeAnimations', [
    transition('EshopMain => EshopLanguageDetail', slideLeft),
    transition('EshopLanguageDetail => EshopMain', slideRight),
    transition('EshopCoursesSection => EshopMain', slideRight),
    transition('EshopMain => EshopCoursesSection', slideLeft),
    transition('EshopMain => EshopCourseDetail', slideLeft),
    transition('EshopCourseDetail => EshopMain', slideRight),
    transition('EshopCourseDetail => EshopLanguageDetail', slideRight),
    transition('EshopLanguageDetail => EshopCourseDetail', slideLeft),

    // transition('Profile => Friends', slideLeft),
    // transition('Friends => Profile', slideRight),
    // transition('Friends => UserDetail', slideLeft),
    // transition('UserDetail => Friends', slideRight),
  ]);

