import {NgModule} from '@angular/core';
import {CourseComponent} from './course.component';
import {ProgressBarModule} from 'angular-progress-bar';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from "@angular/material/icon";
import {TooltipModule} from 'ng2-tooltip-directive';
import {MatButtonModule} from '@angular/material';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";

@NgModule({
  imports: [
    ProgressBarModule,
    CommonModule,
    TranslateModule,
    MatIconModule,
    TooltipModule,
    BsDropdownModule,
    MatButtonModule
  ],
  exports: [
    CourseComponent
  ],
  declarations: [
    CourseComponent
  ]
})

export class CourseModule {}
