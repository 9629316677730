import { MethodDescription } from '../method-description';

export const GetExpiryDateMethodDescription = new MethodDescription();

GetExpiryDateMethodDescription.name = 'getExpiryDate';
GetExpiryDateMethodDescription.fields = [
	'studentId',
	'productId',
];
GetExpiryDateMethodDescription.usesToken = true;
