export const apps = {
  onlinejazyky: 'onlinejazyky',
  easylingo: 'easylingo'
};

export var currentApp = apps.onlinejazyky;

export var currentAppLogoPathText = `/assets/img/apps/${currentApp}/logo-text.png`;
export var currentAppLogoPathNoText = `/assets/img/apps/${currentApp}/logo.svg`;

export function getCurrentSupportEmail(): string {
  if (currentApp === apps.onlinejazyky) {
    return 'info@onlinejazyky.cz'
  } else {
    return 'info@easylingo.com';
  }
}

export const appCodes = {
  easylingo: 'webappel',
  onlinejazyky: 'webapp'
}

export const appSecrets = {
  easylingo: 'bca8abdb7e62df97e5c9c1bf66e3b4bb',
  onlinejazyky: '0702c074eaa5913b146383f8b4cd7e17'
}
