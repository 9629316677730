import { BOOKMARK_STATUS } from './bookmark-status';
import { BOOKMARK_CATEGORY } from './bookmark-category.enum';
import { LESSON_TYPE } from '../../course-structure/models/lesson-type.enum';
import { EXERCISE_TYPE } from '../../course-structure/models/exercise-type.enum';

export class Bookmark {
	id: number;
	state: BOOKMARK_STATUS;
	category: BOOKMARK_CATEGORY;
	lessonId: number;
	lessonType: LESSON_TYPE;
	exerciseType: EXERCISE_TYPE;
	weekName: string;
	chapterName: string;
	lessonName: string;

	public static fromData(data): Bookmark {
		let b = new Bookmark();
		Object.assign(b, data);
		b.id = +b.id || null;
		b.lessonId = +b.lessonId || null;
		if (!b.lessonId && data['lessonBlockId']) {
			b.lessonId = +data['lessonBlockId'];
		}
		return b;
	}
}
