import {getDailyTaskProgress, getFormattedPercentage, getFormattedTime, getFormattedTimeWithoutSeconds} from '../../misc/format-functions';
import {DailyOverview, DashboardInfoResponse} from '../../../../oj-app-common/studovna-api/responses/dashboard-info';
import {DailyTasksResponse} from '../../../../oj-app-common/studovna-api/responses/daily-tasks';
import clamp from 'lodash-es/clamp';
import {_} from '../../../../oj-app-common/i18n/translate-placeholder';
import {RenderedDataModel} from '../../models/rendered-data.model';

export const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const daysShort = {
  monday: _('Po'),
  tuesday: _('Út'),
  wednesday: _('St'),
  thursday: _('Čt'),
  friday: _('Pá'),
  saturday: _('So'),
  sunday: _('Ne')
}

export const weekBarHeight = 75;

export function prepareRenderedData(
  data: DashboardInfoResponse,
  dailyTasks: DailyTasksResponse,
  weekOverviewMaxLevel: number,
  dayToday: string
) {
  const renderedData: RenderedDataModel = RenderedDataModel.createEmpty();

  renderedData.weekDailyGoalPos = calculateWeekDailyGoalPos(data, weekOverviewMaxLevel);

  for (const dayCode of days) {
    renderedData.barHeight[dayCode] = calculateBarHeight(data.weekTotal.overview[dayCode].time, weekOverviewMaxLevel);
    renderedData.dayStatusClass[dayCode] = getDayStatusClass(dayCode, data, dayToday);
  }

  renderedData.formattedTimeToday = getFormattedTime(data.todayTotal.time);
  renderedData.formattedPercentageToday = getFormattedPercentage(data.today.success);
  renderedData.formattedTimeWeek = getFormattedTime(data.weekTotal.time);
  renderedData.formattedPercentageWeek = getFormattedPercentage(data.week.success);
  renderedData.formattedTimeOverall = getFormattedTime(data.overall.time);
  renderedData.formattedPercentageOverall = getFormattedPercentage(data.overall.success);

  if (dailyTasks) {
    renderedData.dailyTaskProgressVocabulary = getDailyTaskProgress(dailyTasks.vocabulary);
    renderedData.dailyTaskProgressExercise = getDailyTaskProgress(dailyTasks.exercise);
    renderedData.dailyTaskProgressStudy = getDailyTaskProgress(dailyTasks.study);

    renderedData.formattedTimeDailyTaskExerciseStudyTime = getFormattedTimeWithoutSeconds(dailyTasks.exercise.studyTime, true);
    renderedData.formattedTimeDailyTaskExerciseGoalTime = getFormattedTimeWithoutSeconds(dailyTasks.exercise.goalTime, true);

    renderedData.formattedTimeDailyTaskVocabularyStudyTime = getFormattedTimeWithoutSeconds(dailyTasks.vocabulary.studyTime, true);
    renderedData.formattedTimeDailyTaskVocabularyGoalTime = getFormattedTimeWithoutSeconds(dailyTasks.vocabulary.goalTime, true);

    renderedData.formattedTimeDailyTaskStudyStudyTime = getFormattedTimeWithoutSeconds(dailyTasks.study.studyTime, true);
    renderedData.formattedTimeDailyTaskStudyGoalTime = getFormattedTimeWithoutSeconds(dailyTasks.study.goalTime, true);
  }

  return renderedData;
}

export function calculateWeekDailyGoalPos(data: DashboardInfoResponse, weekOverviewMaxLevel: number) {
  return Math.round(weekBarHeight - clamp(data.dailyGoal / weekOverviewMaxLevel, 0, 1) * weekBarHeight);
}

export function calculateBarHeight(nmbr: number, weekOverviewMaxLevel: number) {
  if (!weekOverviewMaxLevel) {
    return 0;
  }
  return Math.round(weekBarHeight * (nmbr / weekOverviewMaxLevel));
}

export function getDayStatusClass(dayCode: string, data: DashboardInfoResponse, dayToday: string): string[] {
  const day: DailyOverview = data.weekTotal.overview[dayCode];

  if (dayCode === dayToday) {
    if (day.time >= day.dailyGoal) {
      return ['status', 'status--success'];
    }
    return ['status', 'status--today'];
  }

  switch (day.status) {

    case 0:
      return ['status', 'status--future'];

    case 1:
      return ['status', 'status--future', 'status--future-empty'];

    case 2:
      if (day.time >= day.dailyGoal) {
        return ['status', 'status--success'];
      }
      return ['status', 'status--today'];

    case 3:
    case 6:
      return ['status', 'status--success'];

    case 4:
      return ['status', 'status--joker'];

    case 5:
      return ['status', 'status--not-success'];

    case 7:
      return ['status', 'status--not-study-plan'];

  }
}
