import {TRAINING_DIRECTION} from '../../../oj-app-common/vocabulary/models/training-direction.enum';

export enum InputModes {
  SPEAK = 'speak',
  READ = 'read',
}

export enum OutputModes {
  PRACTICE = 'practice',
  WRITE = 'write',
  SELECT = 'select'
}

export interface VocabularyTrainingSettings {
  direction: TRAINING_DIRECTION;
  autoSpeak: boolean;
  autoSkip: number;
  autoFlip: number;
  inputModes: InputModes[];
  outputModes: OutputModes[];
  useDragDropForWrite?: boolean;
}

export const DefaultVocabularyTrainingSettings: VocabularyTrainingSettings = {
  direction: TRAINING_DIRECTION.FROM_FOREIGN,
  autoSpeak: false,
  autoSkip: 0,
  autoFlip: 0,
  inputModes: [InputModes.READ],
  outputModes: [OutputModes.PRACTICE],
  useDragDropForWrite: true
}
