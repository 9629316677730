import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ProductCombinedModel} from '../../../../../../../oj-app-common/shop/models/shop/product-combined.model';
import {CourseCombinedModel} from '../../../../../../../oj-app-common/shop/models/shop/course-combined.model';
import {CourseShopOptionsModel} from '../../../../../../../oj-app-common/shop/models/course-shop-options.model';
import {WooService} from '../../../../../../../oj-app-common/shop/woo.service';
import {RubyCalculatorService} from '../../../../../pages/base-layout-page/pages/eshop-page/services/ruby-calculator-service';
import {LoadingService} from '../../../../../services/loading.service';
import {EshopApi} from '../../../../../../../oj-app-common/eshop-api/eshop-api';
import {MessageService} from 'primeng/api';
import {CurrentUserService} from '../../../../../auth/current-user.service';
import {StudovnaApi} from '../../../../../../../oj-app-common/studovna-api/studovna-api';
import {CurrentCourseService} from '../../../../../services/current-course-service';
import {Router} from '@angular/router';
import {AppConfig} from '../../../../../config/app-config';
import {AppTranslationService} from '../../../../../services/app-translation.service';
import {PaymentDialogService} from '../../../payment-dialog.service';
import {PurchaseService} from '../../../../../pages/base-layout-page/pages/eshop-page/services/purchase.service';
import {AnyCurrency} from '../../../../../../../oj-app-common/shop/types/any-currency';
import {RubyProductsCalculator} from '../../../../../../../oj-app-common/shop/tools/ruby-products-calculator';
import {EshopCurrenciesService} from '../../../../../pages/base-layout-page/pages/eshop-page/services/eshop-currencies.service';
import {CurrencyDropdownComponent} from "../../currency-dropdown/currency-dropdown.component";
import {EshopUserPreferencesService} from '../../../../../pages/base-layout-page/pages/eshop-page/services/eshop-user-preferences.service';

@Component({
  selector: 'app-license-payment-content-mk2',
  templateUrl: './license-payment-content-mk2.component.html',
  styleUrls: ['./license-payment-content-mk2.component.scss']
})
export class LicensePaymentContentMk2Component implements OnInit {
  isOnlyEshop = false;

  rubyCalculator: RubyProductsCalculator;

  allCurrencies: AnyCurrency[] = [];

  selectedCurrency: AnyCurrency;

  subscribeDescriptions: { [id: number]: string[]} = {};

  oneTimeDescriptions: { [id: number]: string[]} = {};

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() onSubscribeCourse: EventEmitter<{ product: ProductCombinedModel, currency: AnyCurrency }> =
    new EventEmitter<{ product: ProductCombinedModel, currency: AnyCurrency }>();

  @Input() course: CourseCombinedModel;

  @Input() product: ProductCombinedModel;

  shopOptions: CourseShopOptionsModel;

  @Input() directlyProduct = false;

  @ViewChild(CurrencyDropdownComponent, {static: false}) currencyDropdown: CurrencyDropdownComponent;

  constructor(
    protected wooService: WooService,
    protected rubyService: RubyCalculatorService,
    protected loadingService: LoadingService,
    protected eshopApi: EshopApi,
    protected messageService: MessageService,
    protected currentUserService: CurrentUserService,
    protected studovnaApi: StudovnaApi,
    protected currentCourseService: CurrentCourseService,
    protected router: Router,
    protected appConfig: AppConfig,
    protected appTranslationService: AppTranslationService,
    protected paymentDialog: PaymentDialogService,
    protected purchaseService: PurchaseService,
    protected currenciesService: EshopCurrenciesService,
    protected eshopPreferencesService: EshopUserPreferencesService
  ) {
  }

  ngOnInit() {
    if (this.router.url.indexOf('/trialPicker') !== -1 ) {
      this.isOnlyEshop = true;
    }

    this.allCurrencies = [];

    this.loadRubyCalculator();
  }

  async loadRubyCalculator() {
    this.rubyCalculator = await this.rubyService.getCalculator();

    if (this.rubyCalculator) {
      this.allCurrencies = this.rubyCalculator.getAvailableCurrenciesForCourse(this.course);

      if (this.allCurrencies.length > 0) {
        this.selectedCurrency = this.allCurrencies[0];
      }

      this.shopOptions = this.rubyCalculator.getShopOptionsOfCourse(this.course.id_study, this.selectedCurrency);

      this.generateDescriptions();
    }
  }

  public changeCurrency(currency: AnyCurrency) {
    if (this.selectedCurrency !== currency) {
      this.selectedCurrency = currency;
      this.prepareShopOptions();
      this.eshopPreferencesService.setCurrency(currency);
    }
  }

  public prepareShopOptions() {
    this.shopOptions = null;
    const shopOptions = this.rubyCalculator.getShopOptionsOfCourse(this.course.id_study, this.selectedCurrency);
    if (shopOptions.subscriptions.length || shopOptions.oneTimePurchases.length) {
      this.shopOptions = shopOptions;
      this.allCurrencies = this.rubyCalculator.getAvailableCurrenciesForCourse(this.course);
    } else {
      const currencies = this.rubyCalculator.getAvailableCurrenciesForCourse(this.course);
      if (currencies.length) {
        const fallbackCurrency = currencies[0];
        this.shopOptions = this.rubyCalculator.getShopOptionsOfCourse(this.course.id_study, fallbackCurrency);
        this.selectedCurrency = fallbackCurrency;
      } else {
        this.shopOptions = shopOptions;
      }
    }

  }

  generateDescriptions() {
    this.shopOptions.oneTimePurchases.forEach(purchase => {
      if (purchase.product.description_one_time && purchase.product.description_one_time.length > 0) {
        if (!this.oneTimeDescriptions[purchase.product.id]) {
          this.oneTimeDescriptions[purchase.product.id] = purchase.product.description_one_time.split('.');
        }
      }
    })

    this.shopOptions.subscriptions.forEach(purchase => {
      if (purchase.product.description_subscription && purchase.product.description_subscription.length > 0) {
        if (!this.subscribeDescriptions[purchase.product.id]) {
          this.subscribeDescriptions[purchase.product.id] = purchase.product.description_subscription.split('\n');
        }
      }
    })
  }

  close() {
    this.onClose.emit(true);
  }

  async buyCourseClicked(e: Event, productId: number) {
    e.preventDefault();

    let route = '/eshop';

    if (this.isOnlyEshop) {
      route = '/trialPicker'
    }

    if (this.directlyProduct) {
      this.paymentDialog.buyProductInWooEshop(route, productId, 'one-time', this.selectedCurrency);
    } else {
      this.paymentDialog.buyProductInWooEshop(route, productId, 'one-time', this.selectedCurrency);
    }

  }

  subscribeCourseClicked(e: Event, product: ProductCombinedModel, currency: AnyCurrency) {
    e.preventDefault();
    this.onSubscribeCourse.emit({product, currency});
  }

  async activateDemo(e: Event) {
    e.preventDefault();

    const res = this.paymentDialog.activateDemo(this.course.id_eshop, this.course.id_study, this.course.name);

    if (res) {
      this.paymentDialog.closeModal();
    }
  }

  getModalSize(course: CourseCombinedModel): string {
    let size = 0;
    if (course.availableForOneTimePurchase) {
      size++;
    }
    if (course.availableForSubscription) {
      size++;
    }
    if (course.availableTrial) {
      size++;
    }

    switch (size) {
      case 1:
        return 'sm';
      case 2:
        return 'md';
      case 3:
        return 'lg';
      default:
        return 'md';
    }
  }

  isProductWithMoreCourses(product: ProductCombinedModel) {
    if (!product) {
      return
    }

    if (product.courses && product.courses.length <= 1) {
      return false;
    }

    return true;
  }

  changeCurrencyClicked() {
    this.currencyDropdown.openDropdown();
  }
}
