import {Component, Injectable, TemplateRef, Type} from '@angular/core';
import {Subject} from 'rxjs';
import {MyProfileResponse} from '../../../../oj-app-common/studovna-api/responses/my-profile';
import {DashboardInfoResponse} from '../../../../oj-app-common/studovna-api/responses/dashboard-info';
import {RenderedDataModel} from '../../models/rendered-data.model';

@Injectable({
  providedIn: 'root'
})
export class BaseLayoutService {
  sidebarContent: Subject<any> = new Subject<any>();

  dashboardSharedData: Subject<{
    profileDetails: MyProfileResponse,
    dashboardInfo: DashboardInfoResponse,
    renderedData: RenderedDataModel
  }> = new Subject<{ profileDetails: MyProfileResponse; dashboardInfo: DashboardInfoResponse; renderedData: RenderedDataModel }>();

  constructor() {
  }
}
