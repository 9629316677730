import { Brand } from '../types/brand';
import { Token } from '../../misc/token';
import { ShoppableType } from '../types/shoppable-type';
import { EshopLanguage } from '../types/eshop-language';
import { HttpParams } from '@angular/common/http';
import { ProductModel } from '../models/shop/product.model';
import { AnyCurrency } from '../types/any-currency';

export function createWooCommerceUrl(
	brand: Brand,
	language: EshopLanguage,
	userToken: Token,
	product: ProductModel,
	shoppableType: ShoppableType,
	currency: AnyCurrency,
	returnUrl: string,
	urlSetVersion: UrlSet = UrlSet.v2020_10,
	sourceCode = '',
): string {

	let baseUrl: string;

	switch (urlSetVersion) {
		case UrlSet.v2020_10:
			baseUrl = 'https://easylingo.com/es2/';
			break;

		case UrlSet.v2020_12:
			if (language === 'cs') {
				baseUrl = 'https://easylingo.com/cs/';
			} else {
				baseUrl = 'https://easylingo.com/es/';
			}
			break;

		default:
			let wooShop = getWooShopForProduct(product, language, brand);
			if (!wooShop) {
				wooShop = WooShop.OJ_INFO;
			}
			baseUrl = getWooShopUrl(wooShop);
	}

	let shopEntryPointUrl = baseUrl + 'externi-objednavka/';
	let authEntryPointUrl = baseUrl + 'wp-json/oj-autologin/incoming-user/' + userToken;

	let shopParameters = {
		id: product.id + '',
		type: shoppableTypeToWooCommerceType(shoppableType),
		currency,
	};

	if (sourceCode) {
		shopParameters['source'] = sourceCode;
	}

	shopParameters['return'] = returnUrl;

	let httpParams = new HttpParams({
		fromObject: shopParameters,
	});

	let authRedirectUrl = shopEntryPointUrl + '?' + httpParams.toString();

	let authHttpParams = new HttpParams({
		fromObject: {
			redirect: authRedirectUrl,
		},
	});

	return authEntryPointUrl + '?' + authHttpParams.toString();

}


export function shoppableTypeToWooCommerceType(type: ShoppableType): string {

	switch (type) {

		case 'one-time':
			return '0';

		case 'monthly':
			return '1';

		case 'quarterYearly':
			return '3';

		case 'halfYearly':
			return '6';

		case 'yearly':
			return '12';

		default:
			return '0';

	}

}

export function getWooShopForProduct(product: ProductModel, appLanguage: EshopLanguage, appBrand: Brand): WooShop {
	let possibleShops: WooShop[] = [];

	if (!product.woo_sold) {
		return null;
	}
	if (product.available_oj_eshop) {
		possibleShops.push(WooShop.OJ_ESHOP);
	}
	if (product.available_oj_info) {
		possibleShops.push(WooShop.OJ_INFO);
	}
	if (product.available_el_cs) {
		possibleShops.push(WooShop.EL_CS);
	}
	if (product.available_el_en) {
		possibleShops.push(WooShop.EL_EN);
	}
	if (product.available_el_es) {
		possibleShops.push(WooShop.EL_ES);
	}
	if (product.available_el_sk) {
		possibleShops.push(WooShop.EL_SK);
	}
	if (product.available_el_pl) {
		possibleShops.push(WooShop.EL_PL);
	}

	switch (possibleShops.length) {
		case 0:
			return null;

		case 1:
			return possibleShops[0];

		default:

			if (appBrand === 'oj') {
				if ((appLanguage === 'cs' || appLanguage === 'sk') && possibleShops.indexOf(WooShop.OJ_ESHOP) !== -1) {
					return WooShop.OJ_ESHOP;
				}
				if ((appLanguage === 'cs' || appLanguage === 'sk') && possibleShops.indexOf(WooShop.OJ_INFO) !== -1) {
					return WooShop.OJ_INFO;
				}
			}

			if ((appLanguage === 'pl') && possibleShops.indexOf(WooShop.EL_PL) !== -1) {
				return WooShop.EL_PL;
			}

			if ((appLanguage === 'sk') && possibleShops.indexOf(WooShop.EL_SK) !== -1) {
				return WooShop.EL_SK;
			}

			if ((appLanguage === 'cs' || appLanguage === 'sk') && possibleShops.indexOf(WooShop.EL_CS) !== -1) {
				return WooShop.EL_CS;
			}
			if (appLanguage === 'en' && possibleShops.indexOf(WooShop.EL_EN) !== -1) {
				return WooShop.EL_EN;
			}
			if (appLanguage === 'es' && possibleShops.indexOf(WooShop.EL_ES) !== -1) {
				return WooShop.EL_ES;
			}

			if (appBrand === 'el') {
				if (possibleShops.indexOf(WooShop.EL_CS) !== -1) {
					return WooShop.EL_CS;
				}
				if (possibleShops.indexOf(WooShop.EL_SK) !== -1) {
					return WooShop.EL_SK;
				}
				if (possibleShops.indexOf(WooShop.EL_EN) !== -1) {
					return WooShop.EL_EN;
				}
				if (possibleShops.indexOf(WooShop.EL_ES) !== -1) {
					return WooShop.EL_ES;
				}
				if (possibleShops.indexOf(WooShop.EL_PL) !== -1) {
					return WooShop.EL_PL;
				}
			}

			return possibleShops[0];
	}

}

export function getWooShopUrl(shop: WooShop): string {
	switch (shop) {
		case WooShop.OJ_ESHOP:
			return 'https://www.onlinejazyky.cz/eshop/';

		case WooShop.OJ_INFO:
			return 'https://www.onlinejazyky.cz/info/';

		case WooShop.EL_CS:
			return 'https://easylingo.com/cs/';

		case WooShop.EL_EN:
			return 'https://easylingo.com/en/';

		case WooShop.EL_ES:
			return 'https://easylingo.com/es/';

		case WooShop.EL_SK:
			return 'https://easylingo.com/sk/'

		case WooShop.EL_PL:
			return 'https://easylingo.com/pl/'

		default:
			return '';
	}
}


export enum UrlSet {
	'v2020_10',
	'v2020_12',
	'v2021_01',
	'v2021_02',
	'v2021_04',
}

export enum WooShop {
	OJ_INFO,
	OJ_ESHOP,
	EL_CS,
	EL_EN,
	EL_ES,
	EL_SK,
	EL_PL
}
