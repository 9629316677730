import { SentenceToken } from './sentence-token';
import { ANSWER_STATUS_BOOLEAN } from '../../misc/answer-status-boolean';
import { FILLABLE_TOKEN_SIZE, SentenceTokenWithSize } from './sentence-token-with-size';

export class DragDropSentenceToken extends SentenceTokenWithSize {

	filled: DragDropSentenceToken = null;
	text: string;
	alternativeWords: string[] = [];
	result: ANSWER_STATUS_BOOLEAN = ANSWER_STATUS_BOOLEAN.UNKNOWN_YET;

	type = DragDropSentenceToken.typeName;
	number: number;
	static typeName = 'fillup';


	tempTokens: DragDropSentenceToken[] = [];

	getLength(): number {
		return this.text.length;
	}

}
