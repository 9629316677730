import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {AuthGuard} from './guards/auth.guard';
import {MainNavigationLayoutComponent} from './layouts/main-navigation-layout/main-navigation-layout.component';
import {BaseLayoutPageComponent} from './pages/base-layout-page/base-layout-page.component';

/* Declaration of App's routes, loaded using lazy-loading */
const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', loadChildren: () => import('./pages/login-page/login-page.module').then(mod => mod.LoginPageModule)},
  {
    path: '',
    component: MainNavigationLayoutComponent,
    children: [
      {
        path: '',
        component: BaseLayoutPageComponent,
        children: [
          {
            path: 'dashboard',
            loadChildren: () => import('./pages/base-layout-page/pages/dashboard-page/dashboard-page.module').then(mod => mod.DashboardPageModule)
          },
          {
            path: 'profile',
            loadChildren: () => import('./pages/base-layout-page/pages/profile-page/profile-page.module').then(mod => mod.ProfilePageModule),
            data: {pageTitle: 'Profile', animation: 'Profile'}
          },
          {
            path: 'friends',
            loadChildren: () => import('./pages/base-layout-page/pages/friends-page/friends-page.module').then(mod => mod.FriendsPageModule),
            data: {pageTitle: 'Friends', animation: 'Friends'}
          },
          {
            path: 'user-detail',
            loadChildren: () => import('./pages/base-layout-page/pages/user-detail-page/user-detail-page.module').then(mod => mod.UserDetailPageModule),
            data: {pageTitle: 'UserDetail', animation: 'UserDetail'}
          },
          {
            path: 'content',
            loadChildren: () => import('./pages/base-layout-page/pages/content-page/content-page.module').then(mod => mod.ContentPageModule)
          },
          {
            path: 'leaderboard',
            loadChildren: () => import('./pages/base-layout-page/pages/leaderboard-page/leaderboard-page.module').then(mod => mod.LeaderboardPageModule)
          },
          {
            path: 'vocabulary',
            loadChildren: () => import('./pages/base-layout-page/pages/vocabulary-page/vocabulary-page.module').then(mod => mod.VocabularyPageModule)
          },
          {
            path: 'questions',
            loadChildren: () => import('./pages/base-layout-page/pages/questions-page/questions-page.module').then(mod => mod.QuestionsPageModule)
          },
          {
            path: 'settings',
            loadChildren: () => import('./pages/base-layout-page/pages/settings-page/settings-page.module').then(mod => mod.SettingsPageModule)
          },
          {
            path: 'help',
            loadChildren: () => import('./pages/base-layout-page/pages/help-page/help-page.module').then(mod => mod.HelpPageModule)
          },
          {
            path: 'courses',
            loadChildren: () => import('./pages/base-layout-page/pages/courses-page/courses-page.module').then(mod => mod.CoursesPageModule)
          },
          {
            path: 'eshop',
            loadChildren: () => import('./pages/base-layout-page/pages/eshop-page/eshop-page.module').then(mod => mod.EshopPageModule)
          },
          {
            path: 'my-courses',
            loadChildren: () => import('./pages/base-layout-page/pages/my-courses-page/my-courses-page.module').then(mod => mod.MyCoursesPageModule)
          }
        ],
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'trialPicker',
    loadChildren: () => import('./pages/trial-picker-page/trial-picker-page.module').then(mod => mod.TrialPickerPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'review',
    loadChildren: () => import('./pages/vocabulary-training-page/vocabulary-training-page.module').then(mod => mod.VocabularyTrainingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'lesson',
    loadChildren: () => import('./pages/study-page/lesson/lesson-page.module').then(mod => mod.LessonPageModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
