import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {sidebarPositions} from './sidebar.component';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  isOpenedSidebar: Subject<boolean> = new Subject<boolean>();
  sidebarType: Subject<sidebarPositions> = new Subject<sidebarPositions>();

  constructor() { }
}
