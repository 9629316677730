import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {firstTutorial} from '../definitions/first';
import {animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style} from '@angular/animations';

@Component({
  selector: 'app-tutorial-slides',
  templateUrl: './tutorial-slides.component.html',
  styleUrls: ['./tutorial-slides.component.scss']
})
export class TutorialSlidesComponent implements OnInit {
  activeSlide = 0;

  private player : AnimationPlayer;

  @Output() finished: EventEmitter<void> = new EventEmitter<void>()

  @Input() tutorial = firstTutorial;

  @ViewChild('slides', {static: false}) slides: ElementRef;

  constructor(
    private builder : AnimationBuilder
  ) { }

  ngOnInit() {
  }

  next() {
    if (this.activeSlide + 1 <= this.tutorial.slides.length) {
      this.activeSlide++;

      const myAnimation : AnimationFactory = this.builder.build([
        animate('300ms ease-in', style({ left: `${this.getLeftValue()}` }))
      ]);
      this.player = myAnimation.create(this.slides.nativeElement);
      this.player.play();
    } else {
      this.endTutorial();
    }
  }

  getLeftValue() {
    return -(100 * (this.activeSlide - 1)) + '%';
  }

  getSlideWidth() {
    return ((100 / this.tutorial.slides.length) + '%').toString().replace(',', '.');
  }

  public clickedSkip() {
    this.endTutorial();
  }

  public endTutorial() {
    this.finished.emit();
  }

}
