import { Course } from './course';
import { Chapter } from './chapter';
import { Block } from './block';
import { Lesson } from './lesson';
import { Week } from './week';

export class LessonLocationInTree {

	course: Course;
	chapter: Chapter;
	week: Week;
	block: Block;
	lesson: Lesson;


	constructor(course: Course, chapter: Chapter, week: Week, block: Block, lesson: Lesson) {
		this.course = course;
		this.chapter = chapter;
		this.week = week;
		this.block = block;
		this.lesson = lesson;
	}
}
