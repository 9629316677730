import {Injectable} from '@angular/core';
import {CheckLoginResponse, LoginResponse, VerifyTokenResponse} from '../../../oj-app-common/eshop-api/methods/responses';
import {EshopApi} from '../../../oj-app-common/eshop-api/eshop-api';
import {Token} from '../../../oj-app-common/misc/token';
import {Router} from '@angular/router';
import {StudovnaApi} from '../../../oj-app-common/studovna-api/studovna-api';
import {AppConfig} from '../config/app-config';
import {CurrentUserService} from '../auth/current-user.service';
import {UserProfile} from '../../../oj-app-common/eshop-api/models/user-profile';
import {UserDetailResponse} from '../../../oj-app-common/studovna-api/responses/user-detail';
import {CurrentCourseService} from './current-course-service';
import {CurrentStatusResponse} from '../../../oj-app-common/studovna-api/responses/current-status';
import {VideoService} from '../contents/services/video.service';
import {VideoLayerManagerService} from '../components/video-layer/video-layer-manager.service';
import {AppTranslationService} from './app-translation.service';
import {ShowInLeaderboardService} from './show-in-leaderboard.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    protected eshopApi: EshopApi,
    protected studovnaApi: StudovnaApi,
    private router: Router,
    protected appConfig: AppConfig,
    private currentUserService: CurrentUserService,
    private currentCourseService: CurrentCourseService,
    protected videoLayerService: VideoLayerManagerService,
    protected videoService: VideoService,
    protected appTranslationService: AppTranslationService,
    private showInLeaderboardService: ShowInLeaderboardService
  ) {
  }

  public async appInitialize(): Promise<boolean> {


    if (this.currentUserService.isLoggedIn) {
      // Máme uložený token, nebo si to alespoň myslíme.
      // Je třeba tedy zkontrolovat, že token je OK.

      const token = this.currentUserService.user.token;

      let response: VerifyTokenResponse;

      try {
        response = await this.eshopApi.verifyToken(token);
      } catch (e) {
        console.log('Failed eshop verifyToken()', e);
        this.currentUserService.clearProfile();
        return false;
      }

      // Máme uložený token, ale si je neplatný. Zrušíme ho a pročistíme uložený uživatelský profil.
      if (!response.valid) {
        this.currentUserService.clearProfile();
        return false;
      }

      // Token je dle e-shopu platný. Aktualizujeme vrácená data o uživatelském profilu.
      if (response.valid) {
        this.currentUserService.setProfileFromEshop(response.userData);
      }

      let studovnaResponse: UserDetailResponse;

      // Nyní ještě token ověříme proti studovně.
      try {
        studovnaResponse = await this.studovnaApi.userDetail(this.currentUserService.studovnaId, token);
      } catch (e) {
        if (this.appConfig.ignoreStudovnaEuthError && e.errorCode === 'NOT_LOGGED_IN') {
          console.log('Failed studovna userDetail(), ale je zapnuté ignoreStudovnaEuthError - asi tedy jde o nějakou chybu ve studovně. Ignoruji.', e);
          studovnaResponse = new UserDetailResponse();
          studovnaResponse.firstName = 'Franta';
          studovnaResponse.lastName = 'Vomáčka';
          studovnaResponse.language = 'cs';
          studovnaResponse.activeCourse = 0;
          studovnaResponse.userName = 'Franta Vomáčka';
        } else {
          console.log('Failed studovna userDetail()', e);
          this.currentUserService.clearProfile();
          return false;
        }

      }

      // Token ve studovně je neplatný.
      if (!studovnaResponse) {
        this.currentUserService.clearProfile();
        return false;
      }

      // Token ve studovně je platný, aktualizujeme ještě uživatelova data
      this.currentUserService.setProfileFromStudovna(this.currentUserService.studovnaId, studovnaResponse);

      // Načteme uživatelovy preference - mohly se změnit jinde
      this.loadUserSettingsFromApi(this.currentUserService.studovnaId, token);

      // Načteme aktuální status, protože se mohlo studovat i ještě někde jinde
      const currentStatusResponse = await this.studovnaApi.currentStatus(
        this.currentUserService.studovnaId,
        this.currentUserService.token
      );

      if (currentStatusResponse && currentStatusResponse.activeCourse) {


        // Nyní ověříme, zda nám neexpiroval aktuální kurz.
        const productId = await this.eshopApi.convertId(currentStatusResponse.activeCourse + '', 'course', 'product');

        if (productId) {
          const expireDateResponse = await this.eshopApi.getExpireDate(productId, this.currentUserService.eshopId, this.currentUserService.token);
          if (expireDateResponse && expireDateResponse.expires) {
            if (!expireDateResponse.active) {
              console.log('expireDateResponse.active = false');
              return false;
            }
            if (expireDateResponse.expires.getTime() < (new Date()).getTime() - 86400 * 1000) {
              console.log('expireDateResponse.time is too in the past');
              return false;
            }
          }

        }

        if (currentStatusResponse && currentStatusResponse.activeCourse) {
          this.currentCourseService.setCurrentLesson(currentStatusResponse.lastLessonBlock);
        }

        return true;

      } else {

        return true;

      }

    }

    return false;

  }


  // public async appInitialize(): Promise<boolean> {
  //   if (this.currentUserService.isLoggedIn) {
  //     const token = this.currentUserService.user.token;
  //     let response: VerifyTokenResponse;
  //     try {
  //       response = await this.eshopApi.verifyToken(token);
  //     } catch (e) {
  //       console.log('Failed eshop verifyToken()', e);
  //       this.currentUserService.clearProfile();
  //       return false;
  //     }
  //     // Token je ulozeny no zrejme je neplatny. Cistime profil
  //     if (!response.valid) {
  //       this.currentUserService.clearProfile();
  //       return false;
  //     }
  //     // Token je platny, aktualizacia dat o profile
  //     if (response.valid) {
  //       this.currentUserService.setProfileFromEshop(response.userData);
  //     }
  //     let studovnaResponse: UserDetailResponse;
  //     // Overenie tokenu v studovni
  //     try {
  //       studovnaResponse = await this.studovnaApi.userDetail(this.currentUserService.studovnaId, token);
  //     } catch (e) {
  //       if (this.appConfig.ignoreStudovnaEuthError && e.errorCode === 'NOT_LOGGED_IN') {
  //         console.log('Failed studovna userDetail(), ale je zapnuté ' +
  //           'ignoreStudovnaEuthError - asi tedy jde o nějakou chybu ve studovně. Ignoruji.', e);
  //         studovnaResponse = new UserDetailResponse();
  //         studovnaResponse.firstName = 'Franta';
  //         studovnaResponse.lastName = 'Vomáčka';
  //         studovnaResponse.language = 'cs';
  //         studovnaResponse.activeCourse = 0;
  //         studovnaResponse.userName = 'Franta Vomáčka';
  //       } else {
  //         console.log('Failed studovna userDetail()', e);
  //         this.currentUserService.clearProfile();
  //         return false;
  //       }
  //     }
  //     // Token je neplatny v studovni
  //     if (!studovnaResponse) {
  //       this.currentUserService.clearProfile();
  //       return false;
  //     }
  //     // Token je v studovni platny, aktualizujeme uzivatelove data
  //     this.currentUserService.setProfileFromStudovna(this.currentUserService.studovnaId, studovnaResponse);
  //     // Nacitame aktualny status
  //     const currentStatusResponse = await this.studovnaApi.currentStatus(
  //       this.currentUserService.studovnaId,
  //       this.currentUserService.token
  //     );
  //
  //     if (currentStatusResponse && currentStatusResponse.activeCourse) {
  //       // Overenie expirovaneho kurzu
  //       const productId = await this.eshopApi.convertId(currentStatusResponse.activeCourse + '', 'course', 'product');
  //       if (productId) {
  //         const expireDateResponse = await this.eshopApi.
  //           getExpireDate(productId, this.currentUserService.eshopId, this.currentUserService.token);
  //         if (expireDateResponse && expireDateResponse.expires) {
  //           if (!expireDateResponse.active) {
  //             console.log('expireDateResponse.active = false');
  //             return false;
  //           }
  //           if (expireDateResponse.expires.getTime() < (new Date()).getTime() - 86400 * 1000) {
  //             console.log('expireDateResponse.time is too in the past');
  //             return false;
  //           }
  //         }
  //       }
  //       try {
  //         const activationResponse = await this.currentCourseService.activateCourse(currentStatusResponse.activeCourse);
  //         if (activationResponse) {
  //           this.currentCourseService.setCurrentLesson(currentStatusResponse.lastLessonBlock);
  //           return true;
  //         }
  //       } catch (e) {
  //         console.error(e);
  //         return false;
  //       }
  //     } else {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  async login(email: string, password: string, token: string = '') {
    if (token && (email || password)) {
      throw new Error('Either token OR password and email can be supplied.');
    }
    if (!token && (!email || !password)) {
      throw new Error('Either token OR password and email can be supplied.');
    }

    let eshopUserId;
    let receivedToken;
    let studovnaUserId;
    let eshopProfile: UserProfile;
    let studovnaProfile: UserDetailResponse;

    if (!token) {
      const eshopAuthorizeResponse = await this.authorizeInEshop(email, password);
      eshopUserId = eshopAuthorizeResponse.id;
      if (!eshopUserId) {
        throw new Error('User was not found.');
      }
      const eshopLoginResponse = await this.getTokenFromEshop(email, password);
      if (!eshopLoginResponse && !eshopLoginResponse.token) {
        throw new Error('Could not sign you in.');
      }
      receivedToken = eshopLoginResponse.token;

      const verifyTokenResponse = await this.getUserProfileFromEshop(receivedToken);
      if (!verifyTokenResponse) {
        throw new Error('Invalid response received.');
      }
      eshopProfile = verifyTokenResponse.userData;

    } else {
      const verifyResponse = await this.getUserProfileFromEshop(token);
      if (!verifyResponse && !verifyResponse.valid) {
        throw new Error('Your session has expired. Please log in again.');
      }
      receivedToken = token;
      eshopUserId = verifyResponse.userData.id;
      eshopProfile = verifyResponse.userData;
      email = verifyResponse.userData.email;
    }

    const studovnaAuthResponse = await this.authenticateInStudovna(email, receivedToken);
    if (!studovnaAuthResponse) {
      throw new Error('Could not sign you in.');
    }
    studovnaUserId = studovnaAuthResponse.user;
    if (studovnaAuthResponse.token !== receivedToken) {
      throw new Error('Invalid response received.');
    }

    this.loadUserSettingsFromApi(studovnaUserId, receivedToken);

    studovnaProfile = await this.getUserProfileFromStudovna(studovnaUserId, receivedToken);

    this.currentUserService.setLoggedUserData(eshopUserId, studovnaUserId, email, receivedToken);
    this.currentUserService.setProfileFromStudovna(studovnaUserId, studovnaProfile);
    this.currentUserService.setProfileFromEshop(eshopProfile);

    this.currentUserService.onLogin.next(this.currentUserService.user);

    this.currentUserService.onLogin.next(this.currentUserService.user);

    return this.currentUserService.user;
  }

  protected async loadUserSettingsFromApi(studovnaId: number, token: Token) {
    try {
      const settings = await this.studovnaApi.settings(
        studovnaId,
        token,
      );
      if (settings && settings.language) {
        // this.appTranslationService.switchLanguage(settings.language, false);
      }
      if (settings) {
        if (settings.showVideo !== undefined && settings.showVideo !== null) {
          this.videoService.enableVideo(!!settings.showVideo, false);
        }
        if (settings.showHelpBox !== undefined && settings.showHelpBox !== null) {
          this.videoLayerService.enableTutorialVideos(!!settings.showHelpBox, false);
        }
        if (settings.showInLeaderboard !== undefined && settings.showInLeaderboard !== null) {
          this.showInLeaderboardService.setInitialAllowed(settings.showInLeaderboard);
        }
      }
    } catch (e) {
      console.error(e);
      // Nastavení jazyka a videa není kritická věc, můžeme ignorovat
    }
  }

  async logout() {
    const logoutResponse = await this.eshopApi.logout(this.currentUserService.token);

    if (!logoutResponse && !logoutResponse.loggedOut) {
      throw new Error('Error while logout user;');
    }

    this.currentUserService.onLogout.next(this.currentUserService.user);

    return logoutResponse;
  }

  public async loginWithToken(token: Token): Promise<null | { eshopId: number, studovnaId: number, email: string }> {
    const eshopVerification = await this.eshopApi.verifyToken(token);
    if (!eshopVerification || !eshopVerification.valid) {
      return null;
    }
    const studovnaVerification = await this.authenticateInStudovna(eshopVerification.userData.email, token);
    if (!studovnaVerification || !studovnaVerification.user) {
      return null;
    }
    return {
      email: eshopVerification.userData.email,
      studovnaId: studovnaVerification.user,
      eshopId: eshopVerification.userData.id,
    };
  }

  protected authorizeInEshop(email, password): Promise<CheckLoginResponse> {

    return this.eshopApi.checkLogin(email, password)
      .then(
        (response: CheckLoginResponse) => {
          if (!response.found || !response.correctPassword) {
            throw new Error('Invalid e-mail or password');
          }
          return response;
        }
      );
  }

  protected authenticateInStudovna(email: string, token: string): Promise<any> {

    return this.studovnaApi.call(
      'authenticate',
      {
        email,
        token
      },
      'post'
    )
      .then(
        (response: { activeCourse: number, firstLogin: boolean }) => {
          if (!response.activeCourse || response.firstLogin) {
          }
          return response;
        }
      )
      .catch(
        (error) => {
          if (this.appConfig.ignoreStudovnaEuthError) {
            // TODO alert
            console.error(error);
            return new Promise(
              (resolve, reject) => {
                resolve();
              }
            );

          } else {
            throw error;
          }
        }
      )

      ;
  }

  protected getTokenFromEshop(email: string, password: string): Promise<LoginResponse> {
    return this.eshopApi.login(email, password)
      .then(
        (response: LoginResponse) => {
          if (!response.token) {
            throw new Error('No token has been received');
          }
          return response;
        }
      );
  }

  protected getUserProfileFromEshop(token: Token): Promise<VerifyTokenResponse> {
    return this.eshopApi.verifyToken(token);
  }

  protected getUserProfileFromStudovna(studovnaId: number, token: Token): Promise<UserDetailResponse> {
    return this.studovnaApi.userDetail(studovnaId, token)
      .catch(
        (error) => {
          if (this.appConfig.ignoreStudovnaEuthError) {
            return Promise.resolve({
              userName: 'Fiktivní uživatel',
              firstName: 'Fiktivní',
              lastName: 'uživatel',
              language: 'cs',
              activeCourse: 0,
            });
          }
          throw error;
        }
      );
  }
}
