import {NgModule} from '@angular/core';
import {ShopYellowBannerComponent} from './shop-yellow-banner.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  exports: [
    ShopYellowBannerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [ShopYellowBannerComponent]
})

export class ShopYellowBannerModule {}
