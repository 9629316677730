import {Injectable} from '@angular/core';
import {CurrentUserService} from '../auth/current-user.service';
import {Subject} from 'rxjs';
import {AppConfig} from '../config/app-config';
import {Course} from '../../../oj-app-common/course-structure/models/course';
import {LessonStatus} from '../../../oj-app-common/course-structure/models/lesson-status';
import {Chapter} from '../../../oj-app-common/course-structure/models/chapter';
import {Week} from '../../../oj-app-common/course-structure/models/week';
import {Block} from '../../../oj-app-common/course-structure/models/block';
import {Lesson} from '../../../oj-app-common/course-structure/models/lesson';
import {StudovnaApi} from '../../../oj-app-common/studovna-api/studovna-api';
import {ServicesAccessor} from '../../../oj-app-common/tools/services-accessor';
import {LessonLocationInTree} from '../../../oj-app-common/course-structure/models/lesson-location-in-tree';
import {EvaluationResult} from '../../../oj-app-common/exercises/evaluation-result';
import {languageTypeToIsoCode, string2languageType} from '../../../oj-app-common/misc/language-types.enum';
import {AvailableCourse} from '../models/available-course';
import {ProductData} from '../../../oj-app-common/eshop-api/models/product-data';
import {EshopApi} from '../../../oj-app-common/eshop-api/eshop-api';

interface StorageDataCourse {
  course: Course;
  courseId: number;
  userId: number;
}

interface StorageDataStatus {
  status: LessonStatus[];
  courseId: number;
  userId: number;
}

@Injectable()
export class CurrentCourseService {

  protected currentCourseId: number;
  protected currentCourse?: Course;
  public currentLessonId: number;
  protected currentAvailableCourse: AvailableCourse;
  protected currentProductData?: ProductData;


  protected chaptersById: { [key: number]: Chapter } = {};
  protected weeksById: { [key: number]: Week } = {};
  protected blocksById: { [key: number]: Block } = {};
  public lessonsById: { [key: number]: Lesson } = {};

  protected _onSwitchCourse: Subject<Course> = new Subject<Course>();

  protected _availableCourseChanged: Subject<AvailableCourse>;

  protected DEV_CACHE_KEY = 'currentCourseDevCache';

  constructor(
    protected currentUser: CurrentUserService,
    protected studovnaApi: StudovnaApi,
    serviceAccessor: ServicesAccessor,
    protected appConfig: AppConfig,
    protected eshopApi: EshopApi
  ) {

    serviceAccessor.add('course', this);

    this._availableCourseChanged = new Subject<AvailableCourse>();

    this.currentUser.onLogout.subscribe(
      () => {
        this.clearActiveCourse();
      }
    );

  }

  get courseId(): number {
    return this.currentCourseId;
  }

  set courseId(id: number) {
    this.currentCourseId = id;
  }

  get productId(): number {
    if (this.currentProductData) {
      return this.currentProductData.productId;
    }
    return null;
  }

  get course(): Course {
    return this.currentCourse;
  }

  get productData(): ProductData {
    return this.currentProductData;
  }

  get onSwitchCourse(): Subject<Course> {
    return this._onSwitchCourse;
  }

  get onAvailableCourseChanged(): Subject<AvailableCourse> {
    return this._availableCourseChanged;
  }

  public getChapterById(id: number): Chapter | null {
    if (this.chaptersById[id]) {
      return this.chaptersById[id];
    }
    return null;
  }

  public getWeekById(id: number): Week | null {
    if (this.weeksById[id]) {
      return this.weeksById[id];
    }
    return null;
  }

  public getBlockById(id: number): Block | null {
    if (this.blocksById[id]) {
      return this.blocksById[id];
    }
    return null;
  }

  public getLessonById(id: number): Lesson | null {
    if (this.lessonsById[id]) {
      return this.lessonsById[id];
    }
    return null;
  }

  public getCurrentLesson(): Lesson | null {
    if (this.currentLessonId) {
      return this.getLessonById(this.currentLessonId);
    }
    return null;
  }

  public get getCurrentLessonId(): number | null {
    return this.currentLessonId;
  }

  public setCurrentLesson(lessonId: number) {
    if (this.lessonsById[lessonId]) {
      this.currentLessonId = lessonId;
    }
  }

  clearActiveCourse() {
    this.clearStructuresById();
    this.currentCourseId = null;
    this.currentCourse = null;
    this.currentProductData = null;
    this.clearDataFromDevCache();
  }

  public async activateCourse(courseId: number): Promise<boolean> {
    if (courseId === this.currentCourseId) {
      return Promise.resolve(true);
    }
    // this.clearActiveCourse();

    let loadedCourse: Course;
    let loadedLessonStatus: LessonStatus[] = [];
    let loadedProductData: ProductData;

    try {
      [loadedCourse, loadedLessonStatus, loadedProductData] = await Promise.all(
        [
          this.loadCourseDataFromApi(courseId, true),
          this.loadCourseStatusFromApi(courseId),
          this.loadProductDataFromApi(courseId),
        ]
      );
    } catch (e) {
      // if (errorAlerts) {
      //   errorAlerts.studovnaApiError(e, navCtrl, 'Nepodařilo se spustit kurz.');
      // }
      return false;
    }

    try {
      this.currentCourseId = courseId;
      this.currentCourse = loadedCourse;
      this.currentProductData = loadedProductData;
      this.buildStructuresById();
      this.useLessonStatusData(loadedLessonStatus);

      await this.currentUser.getMyProfile(this.currentUser.user.studovnaId, this.currentUser.token);
      await this.saveDataToDevCache();

      // if (this.appConfig.debugSkipInitialVerifyToken) {
      //   await this.saveDataToDevCache();
      // }

    } catch (e) {
      // if (errorAlerts) {
      //   errorAlerts.studovnaApiError(e, navCtrl, 'Nepodařilo se spustit kurz.');
      // }
      return false;
    }

    this._onSwitchCourse.next(this.currentCourse);

    return true;

  }

  public async loadCourseDataFromApi(courseId: number, progress = false): Promise<Course> {
    try {

      const responses = await Promise.all([
        this.studovnaApi.courseDetail(courseId + '', this.currentUser.studovnaId, this.currentUser.token),
        this.studovnaApi.courseContent(courseId + '', this.currentUser.studovnaId, this.currentUser.token, progress)
      ]);
      const courseDetailResponse = responses[0];
      const courseContentResponse = responses[1];
      return Course.createFromData(courseDetailResponse, courseContentResponse.chapters);
    } catch (error) {
      throw error;
    }

  }

  public async loadProductDataFromApi(courseId: number): Promise<ProductData> {
    const prodId = await this.eshopApi.convertId(courseId + '', 'course', 'product');
    if (!prodId) {
      throw new Error('This course is not in e-shop.');
    }
    const prodData = await this.eshopApi.getProductData(prodId, this.currentUser.token, this.currentUser.eshopId);
    return prodData;
  }

  public async loadCourseStatusFromApi(courseId: number): Promise<LessonStatus[]> {
    return this.studovnaApi.visitedLessons(courseId, this.currentUser.studovnaId, this.currentUser.token);
  }


  public locateLessonBlockById(id: number): LessonLocationInTree | null {

    if (!this.course) {
      return null;
    }

    for (const chapter of this.course.chapters) {
      for (const week of chapter.weeks) {
        for (const block of week.blocks) {
          for (const lesson of block.lessons) {
            if (lesson.id === id) {
              return new LessonLocationInTree(this.course, chapter, week, block, lesson);
            }
          }
        }
      }
    }

    return null;

  }

  public updateExerciseResult(lesson: Lesson, result: EvaluationResult) {
    if (!lesson || !result) return;

    if (lesson.status) {
      lesson.status.score = result.score;
      lesson.status.totalAnswers = result.totalQuestions;
      lesson.status.correctAnswers = result.correctAnswers;
    }

    const foundLesson = this.getLessonById(lesson.id);
    if (foundLesson !== lesson) {
      this.updateExerciseResult(foundLesson, result);
    }
  }


  protected useLessonStatusData(lessonStatuses: LessonStatus[]) {
    for (const lessonStatus of lessonStatuses) {
      const lesson = this.lessonsById[lessonStatus.id];
      if (lesson) {
        lesson.status = lessonStatus;
      }
    }
  }

  async forceRefreshVisitedLessonStatus() {
    if (!this.courseId) {
      return;
    }

    let loadedLessonStatus: LessonStatus[] = [];

    try {
      [loadedLessonStatus] = await Promise.all(
        [
          this.loadCourseStatusFromApi(this.courseId)
        ]
      );
    } catch (e) {
      return false;
    }

    try {
      this.useLessonStatusData(loadedLessonStatus);

      await this.saveDataToDevCache();

    } catch (e) {
      return false;
    }
  }

  protected clearStructuresById() {
    this.chaptersById = {};
    this.weeksById = {};
    this.blocksById = {};
    this.lessonsById = {};
  }

  protected buildStructuresById() {
    this.clearStructuresById();
    for (const chapter of this.course.chapters) {
      this.chaptersById[chapter.id] = chapter;
      for (const week of chapter.weeks) {
        this.weeksById[week.id] = week;
        for (const block of week.blocks) {
          this.blocksById[block.id] = block;
          for (const lesson of block.lessons) {
            this.lessonsById[lesson.id] = lesson;
          }
        }
      }
    }
  }

  public convertCourseDetailTypeToLang(type: string) {
    return languageTypeToIsoCode(string2languageType(type));
  }

  public async saveDataToDevCache(): Promise<void> {
    const data = {
      currentCourseId: this.courseId,
      currentCourse: this.course,
      productData: this.currentProductData
    };
    await localStorage.setItem(this.DEV_CACHE_KEY, JSON.stringify(data));
    return;
  }

  public async restoreDataFromDevCache(): Promise<boolean> {
    const loaded = await JSON.parse(localStorage.getItem(this.DEV_CACHE_KEY));
    if (!loaded) {
      return false;
    }
    if (!loaded.currentCourseId || !loaded.currentCourse || !loaded.productData) {
      return false;
    }

    this.currentCourseId = loaded.currentCourseId;
    this.currentCourse = loaded.currentCourse;
    this.currentProductData = loaded.productData;
    this.buildStructuresById();
    return true;
  }

  public async clearDataFromDevCache(): Promise<void> {
    return localStorage.removeItem(this.DEV_CACHE_KEY);
  }

  public findLessonsByType(type: string, subType = '', maxResults = 10): Lesson[] {
    const response = [];
    type = type.toLowerCase();
    subType = subType.toLowerCase();
    for (const chapter of this.course.chapters) {
      for (const week of chapter.weeks) {
        for (const block of week.blocks) {
          for (const lesson of block.lessons) {
            if (lesson.type.toLowerCase() === type) {
              if (!subType || lesson.subType.toLowerCase() === subType) {
                response.push(lesson);
                if (response.length >= maxResults) {
                  return response;
                }
              }
            }
          }
        }
      }
    }
    return response;
  }

  getCurrentAvailableCourse(): AvailableCourse {
    return this.currentAvailableCourse;
  }

  setCurrentAvailableCourse(course: AvailableCourse) {
    this.currentAvailableCourse = course;
    this._availableCourseChanged.next(course);
    return this.currentAvailableCourse;
  }

}
