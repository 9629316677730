import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

export class RouterHistoryItem {
  route: string;
  queryParams: any;
}

@Injectable({
  providedIn: 'root'
})
export class RouterHistoryService {
  public routerHistory: RouterHistoryItem[] = [];

  public _routerHistoryChanged: Subject<RouterHistoryItem[]> = new Subject<RouterHistoryItem[]>();

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute
  ) { }

  add(item: RouterHistoryItem, autoNavigate = false, skipLocationChange = true) {
    this.routerHistory.push(item);
    if (autoNavigate) {
      this.router.navigate([item.route], {queryParams: item.queryParams, skipLocationChange: skipLocationChange});
    }
    this._routerHistoryChanged.next(this.routerHistory);
  }

  removeLast() {
    this.routerHistory.pop();
    this._routerHistoryChanged.next(this.routerHistory);
  }

  clearAll() {
    this.routerHistory = [];
    this._routerHistoryChanged.next(this.routerHistory);
  }

  hasPrevious(route: string) {
    if (this.routerHistory && this.routerHistory.length > 1) {
      const lastRoute = this.routerHistory[this.routerHistory.length - 1];
      if (lastRoute.route === route) return true;
      return false;
    }
    return false;
  }

  goBack(autoNavigate = false) {
    if (this.routerHistory && this.routerHistory.length > 1) {
      this.removeLast();
      const lastItem = this.getLastItem();
      if (autoNavigate) {
        this.router.navigate([lastItem.route], {queryParams: lastItem.queryParams, skipLocationChange: true});
      }
      return lastItem;
    }
  }

  getLastItem(): RouterHistoryItem {
    if (this.routerHistory && this.routerHistory.length > 0) {
      return this.routerHistory[this.routerHistory.length - 1];
    }
    return null;
  }

  getAll(): RouterHistoryItem[] {
    return this.routerHistory;
  }

  getCurrentRoute(): string {
    return this.router.url.split('?')[0];
  }
}
