import { EXERCISE_STATUS } from './misc/exercise-status';
import { EvaluationResult } from './evaluation-result';
import { ToleranceSettings } from '../studovna-api/models/tolerance-settings';

export abstract class BaseExercise {

	protected _status: EXERCISE_STATUS;

	protected _result?: EvaluationResult = null;

	protected _content: any;

	protected tolerance: ToleranceSettings = {
		diacritics: true,
		punctuation: true,
		capitalization: true,
	};

	get status(): EXERCISE_STATUS {
		return this._status;
	}

	get result(): (EvaluationResult|null) {
		return this._result;
	}

	get content(): any {
		return this._content;
	}

	public constructor() {
		this._status = EXERCISE_STATUS.STUDYING;
	}

	public setStatusToStudying() {
		this._status = EXERCISE_STATUS.STUDYING;
	}

	public setStatusToSubmitted() {
		this._status = EXERCISE_STATUS.SUBMITTED;
	}

	public setStatusToPreviousResult() {
		this._status = EXERCISE_STATUS.PREVIOUS_RESULT;
	}

	/**
	 * Process data loaded from API and initialize the exercise
	 */
	public abstract init(lessonData: object): void;

	/**
	 * Reset exercise to initial state where user can start working on it
	 */
	public abstract reset(): void;

	/**
	 * Evaluate and generate result
	 */
	public abstract evaluate(): void;

	/**
	 * Convert current state of exercise to data structure that can be sent to API
	 */
	public abstract getAnswersForApi(): object;

	/**
	 * Is exercise in state when it is ready to be evaluated (ie. student has somehow completed all tasks)
	 */
	public abstract canBeEvaluated(): boolean;

	/**
	 * Initialize exercise to state with some answers prefilled from previous try
	 */
	public abstract setFromPreviousAnswers(data: object): void;

	public setResultUsingNumbers(correct: number, total: number) {
		this._result = new EvaluationResult(correct, total);
	}

	/**
	 * Touched means that student already started some kind of interaction
	 */
	public isTouched(): boolean {
		return false;
	}

	/**
	 * The app can suggest to continue to next lesson
	 */
	public isCompletedWellEnough(): boolean {
		return (this.result && this.result.score > 66.5);
	}

	public setTolerance(tolerance: ToleranceSettings) {
		this.tolerance = tolerance;
	}

}
