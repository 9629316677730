import {LANGUAGE_TYPES, string2languageType} from '../../../oj-app-common/misc/language-types.enum';
import {parseTime} from '../../../oj-app-common/tools/parse-time';
import {CourseContentResponse} from '../../../oj-app-common/studovna-api/responses/course-content';
import {ProductShopDetails} from '../../../oj-app-common/eshop-api/models/product-shop-details';

export class AvailableCourse {

  courseId: number;
  eshopProductId: number;
  name: string;
  expireDate?: Date;
  isTrial: boolean;
  image: string;
  priority: number;
  appStoreId: string;

  learnedWords: number;
  allWords: number;
  completedPercent: number;
  lastActivity?: Date;
  success: number;
  time: number;

  isWooProduct?: boolean;
  content?: CourseContentResponse
  details?: ProductShopDetails;
  price?: string;
  subscription?: boolean;

  type: LANGUAGE_TYPES;

  static createFromData(eshopData, studovnaData): AvailableCourse {
    let c = new AvailableCourse();

    c.courseId = +studovnaData.id;
    c.name = studovnaData.name;
    c.learnedWords = +studovnaData.learnedWords;
    c.allWords = +studovnaData.allWords;
    c.completedPercent = Math.round(studovnaData.complete);
    c.lastActivity = studovnaData.lastActivity ? parseTime(studovnaData.lastActivity) : null;
    c.success = +studovnaData.success;
    c.time = +studovnaData.time;
    c.type = string2languageType(studovnaData.type);

    c.eshopProductId = +eshopData.productId;
    c.appStoreId = eshopData.appStoreId + '';
    c.priority = +eshopData.priority || 0;
    c.image = eshopData.image;
    c.expireDate = eshopData.expireDate ? parseTime(eshopData.expireDate) : null;
    c.isTrial = !!eshopData.isTrial;

    /*
    if (Math.random() < 0.3) {
      c.isTrial = true;
    }
    if (Math.random() < 0.3) {
      c.expireDate = new Date();
      c.expireDate.setTime(Date.now() + 86400 * 1000 * 4);
    }
    */

    return c;
  }

}
