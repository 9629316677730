import { trim } from 'lodash-es';

export function userDisplayName(firstName: string, lastName: string, userName: string): string {
  if (firstName || lastName) {
    const name = trim(firstName + ' ' + lastName);
    if (name !== userName) {
      return name;
    }
  }

  const email = userName;
  const matched = email.match(/^(.{3,})@(.+)/);
  if (matched) {
    return matched[1];
  }

  return email;
}


export function userDisplayNameFromObject(user: {firstName: string, lastName: string, userName: string}): string {
  return userDisplayName(user.firstName, user.lastName, user.userName);
}
