import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[mouseInteraction]'
})

export class MouseInteractionDirective {

  @Output() interactionStarted: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() {}

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onMouseDown(event) {
    this.interactionStarted.emit(
      event.pageX || event.touches[0].pageX
    );
  }

}
