import { Week } from './week';

export class Chapter {
	id: number;
	name: string;
	order: number;
	weeks: Week[];
	image: string;
	allowFeedback = true;
	isNew: boolean;

	static createFromData(data: any): Chapter {

		let ch = new Chapter();
		ch.id = +data.id;
		ch.name = data.name + '';
		ch.order = +data.order || 0;
		ch.image = data.image || '';
		ch.allowFeedback = !!data.allowFeedback;
		ch.isNew = false;

		ch.weeks = [];

		if (data.weeks) {
			for (let weekId of Object.keys(data.weeks)) {
				let week = data.weeks[weekId];
				let w = Week.createFromData(week);
				if (w.isNew) {
					ch.isNew = true;
				}
				ch.weeks.push(w);
			}

			if (ch.weeks.length > 0) {
				ch.weeks.sort(
					(w1: Week, w2: Week) => {
						if (w1.order || w2.order) {
							return w1.order - w2.order;
						}
						return w1.id - w2.id;
					}
				);
			}

		}

		return ch;

	}
}


