import { AbstractAssignTextToImageExercise } from './abstract-assign-text-to-image-exercise';
import { InvalidExerciseData } from '../errors/invalid-exercise-data';
import { DragDropImage } from './models/drag-drop-image';
import { DragDropToken } from './models/drag-drop-token';
import * as arrayShuffle from 'array-shuffle';
import { ANSWER_STATUS_BOOLEAN } from '../misc/answer-status-boolean';
import { compareStringsWithTolerance } from '../../tools/text-comparator';
import { clone } from 'lodash-es';
import { ToleranceSettings } from '../../studovna-api/models/tolerance-settings';

export class DragDropAssignTextToImageExercise extends AbstractAssignTextToImageExercise {

	static typeString = 'dragdrop';

	public images: DragDropImage[] = [];
	public allTokens: DragDropToken[] = [];
	public availableTokens: DragDropToken[] = [];

	public init(lessonData: object): void {
		if (!lessonData['images']) {
			throw new InvalidExerciseData('Data must have a [images] field.');
		}

		this.images = [];
		this.allTokens = [];
		this.availableTokens = [];

		let tokenIdCounter = 1;

		let imageCounter = 1;

		for (let imageIndex of Object.keys(lessonData['images'])) {

			let imageData = lessonData['images'][imageIndex];
			let image = new DragDropImage();
			image.id = +imageData['id'];
			image.imageUrl = imageData['image'];
			image.originalImage = imageData['originalImage'] || '';
			image.number = imageCounter;

			imageCounter++;

			let correctToken = new DragDropToken();
			correctToken.isUsed = false;
			correctToken.text = imageData['text'][0];
			correctToken.id = tokenIdCounter;
			correctToken.number = tokenIdCounter;
			tokenIdCounter++;

			image.correctToken = correctToken;

			this.allTokens.push(correctToken);
			this.availableTokens.push(correctToken);

			if (imageData['wrong'] && imageData['wrong'].length) {

				for (let wrongWord of imageData['wrong']) {

					if (!wrongWord) {
						continue;
					}

					let incorrectToken = new DragDropToken();
					incorrectToken.isUsed = false;
					incorrectToken.text = wrongWord;
					incorrectToken.id = tokenIdCounter;
					incorrectToken.number = tokenIdCounter;
					tokenIdCounter++;
					this.allTokens.push(incorrectToken);
					this.availableTokens.push(incorrectToken);

				}

			}

			this.images.push(image);

		}

		this.availableTokens = arrayShuffle(this.availableTokens);

		super.init(lessonData);
	}

	public reset(): void {

		this.images.map(
			(i) => {
				i.result = ANSWER_STATUS_BOOLEAN.UNKNOWN_YET;
				this.removeTokenFromSlot(i);
			}
		);

		this.availableTokens = arrayShuffle(this.availableTokens);

	}

	public evaluate(): void {

		let total = this.images.length;
		let correct = 0;

		let fixedTolerance: ToleranceSettings = {
			diacritics: this.tolerance.diacritics,
			capitalization: true,
			punctuation: this.tolerance.punctuation,
		};

		this.images.map(
			(i) => {
				if (i.filledToken && compareStringsWithTolerance(i.filledToken.text, i.correctToken.text, fixedTolerance)) {
					correct++;
					i.result = ANSWER_STATUS_BOOLEAN.CORRECT;
				} else {
					i.result = ANSWER_STATUS_BOOLEAN.INCORRECT;
				}
			}
		);

		this.setResultUsingNumbers(correct, total);

	}

	public getAnswersForApi(): object {
		let answers = {};

		this.images.map(
			(i) => {
				answers[i.id] = i.filledToken ? i.filledToken.text : '';
			}
		)

		return {
			'answers': answers
		};
	}

	public canBeEvaluated(): boolean {
		return this.images.reduce(
			(areAllFilled, theImage) => {
				if (!areAllFilled) {
					return false;
				}
				return !!theImage.filledToken;
			},
			true
		);
	}

	public setFromPreviousAnswers(data: object): void {

		if (!data['answers']) {
			throw new InvalidExerciseData('Data must contain [answers] field.');
		}

		let imagesData = data['answers'];
		for (let imageIdStr of Object.keys(imagesData)) {
			let imageId = +imageIdStr;
			let imageAnswer = imagesData[imageId];
			let image = this.images.find((i) => (i.id === imageId));
			if (image) {
				if (imageAnswer) {
					let theToken = this.availableTokens.find(
						(token) => {
							return compareStringsWithTolerance(token.text, imageAnswer, this.tolerance);
						}
					);
					if (theToken) {
						this.putTokenIntoSlot(image, theToken);
					}
				} else {
					this.removeTokenFromSlot(image);
				}
			}
		}

	}

	public removeTokenFromSlot(image: DragDropImage) {
		if (image.filledToken) {
			let token = image.filledToken;
			token.isUsed = false;
			this.availableTokens = clone(this.availableTokens);
			this.availableTokens.push(token);
			image.filledToken = null;
		}
	}

	public putTokenIntoSlot(image: DragDropImage, token: DragDropToken) {

		this.removeTokenFromSlot(image);
		token.isUsed = true;
		image.filledToken = token;
		this.availableTokens = this.availableTokens.filter( t => (t !== token) );

	}

	public putTokenIntoSlotById(image: DragDropImage, id: number) {
		id = +id;
		let matchingToken = this.allTokens.find(
			(t: DragDropToken) => {
				return t.id === id;
			}
		);
		if (matchingToken) {
			return this.putTokenIntoSlot(image, matchingToken);
		}
	}

	public isTouched(): boolean {
		return (!!this.images.find((i) => (!!i.filledToken)));
	}

}
