import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoginService} from '../../../../services/login.service';
import {CurrentUserService} from '../../../../auth/current-user.service';
import {UserProfile} from '../../../../auth/user-profile';
import {MyProfileResponse} from '../../../../../../oj-app-common/studovna-api/responses/my-profile';
import {StudovnaApi} from '../../../../../../oj-app-common/studovna-api/studovna-api';
import {LANGUAGE_TYPES} from 'oj-app-common/misc/language-types.enum';
import {EshopApi} from '../../../../../../oj-app-common/eshop-api/eshop-api';
import {CurrentCourseService} from '../../../../services/current-course-service';
import {LoadingService} from '../../../../services/loading.service';
import {CurrentLessonSeriesService} from '../../../../services/current-lesson-series-service';
import {Router} from '@angular/router';
import {CurrentStatusResponse} from '../../../../../../oj-app-common/studovna-api/responses/current-status';
import {AvailableCourse} from '../../../../models/available-course';
import {ThemeService} from '../../../../theme/theme.service';
import {BaseLayoutService} from '../../base-layout.service';
import {ActivitySidebarComponent} from '../../components/activity-sidebar/activity-sidebar.component';
import {MessageService} from 'primeng/api';
import {_} from '../../../../../../oj-app-common/i18n/translate-placeholder';
import {PhotoCaptureComponent} from './photo-capture/photo-capture.component';
import {DashboardInfoResponse} from '../../../../../../oj-app-common/studovna-api/responses/dashboard-info';
import {RouterHistoryItem, RouterHistoryService} from '../../../../services/router-history.service';
import {CourseProgressDetails} from '../../../../../../oj-app-common/studovna-api/models/course-progress-details';
import {CourseItem, createCourseItemFromData} from '../../../../components/course/course-item.model';
import {Observable, Subscription} from 'rxjs';
import {RubyCalculatorService} from '../eshop-page/services/ruby-calculator-service';
import {RubyProductsCalculator} from '../../../../../../oj-app-common/shop/tools/ruby-products-calculator';
import {PaymentDialogService} from '../../../../components/payment-dialogs/payment-dialog.service';
import {AppAdsService} from '../../../../services/app-ads.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {EditProfileModalComponent} from './edit-profile-modal/edit-profile-modal.component';
import {RubyService} from '../../../../../../oj-app-common/ruby/ruby.service';
import { Subscription as RubySubscription } from '../../../../../../oj-app-common/ruby/models/subscription';

interface ActiveLanguage {
  language: string;
  totalLevelPoints: number;
  levelPoints: number;
  pointsToNextLevel: number;
  points: number;
}

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit, OnDestroy {
  loaded = false;
  public portraitUrl: string;
  public profileData: UserProfile;
  public profileDetails: MyProfileResponse;
  public data: DashboardInfoResponse = null;

  public activeLanguage: ActiveLanguage = null;
  public allCourses: AvailableCourse[] = [];

  public recentCourses: CourseItem[] = [];

  public coursesByLanguages: { [key: string]: AvailableCourse[] };
  public stillLoadingCourses = false;
  public languagesWithSomeCourse: LANGUAGE_TYPES[] = [];
  public LANGUAGE_TYPES = LANGUAGE_TYPES;
  public expandStatusOfLanguages: { [key: string]: boolean };
  modalRef: BsModalRef;

  courseChangedSubscription: Subscription;

  rubyCalculator: RubyProductsCalculator;

  coursesWithCertificates: CourseProgressDetails[] = [];

  visibleYellowBanner: Observable<boolean>;

  hasCustomPortrait = false;

  allSubscriptions: RubySubscription[] = [];

  @ViewChild(PhotoCaptureComponent, {static: false}) photoCapture: PhotoCaptureComponent;

  @ViewChild(EditProfileModalComponent, {static: false}) editProfileModal: EditProfileModalComponent;

  constructor(
    private _loginService: LoginService,
    protected studovnaApi: StudovnaApi,
    protected currentUserService: CurrentUserService,
    public currentCourseService: CurrentCourseService,
    public eshopApi: EshopApi,
    public loadingService: LoadingService,
    protected modalService: BsModalService,
    private currentLessonSeriesService: CurrentLessonSeriesService,
    private router: Router,
    private themeService: ThemeService,
    private baseLayoutService: BaseLayoutService,
    private messageService: MessageService,
    protected routerHistoryService: RouterHistoryService,
    protected rubyService: RubyCalculatorService,
    protected paymentDialogService: PaymentDialogService,
    protected adsService: AppAdsService,
    private rubyApi: RubyService
  ) {
  }

  ngOnInit() {
    this.loadingService.hide();

    this.themeService.setLightTheme();

    this.baseLayoutService.sidebarContent.next(ActivitySidebarComponent);

    this.loadCourses();

    this.loadRuby();

    this.loadActiveSubscriptions();

    this.visibleYellowBanner = this.adsService.showYellowBanner;

    this.courseChangedSubscription = this.currentCourseService
      .onSwitchCourse
      .subscribe(v => {
        const filteredCourse = this.recentCourses.filter(c => c.id === v.id);
        if (filteredCourse && filteredCourse.length > 0) {
          filteredCourse[0].isWithRibbon = true;
        }
      })

    this.initUserProfile()
      .then(() => {
        this.loaded = true;
      }, (err) => {
        this.loaded = false;
      });
  }

  ngOnDestroy() {
    if (this.courseChangedSubscription) {
      this.courseChangedSubscription.unsubscribe();
    }
  }

  protected async initUserProfile() {
    this.portraitUrl = this.currentUserService.profile.getPortraitUrl();
    this.hasCustomPortrait = this.currentUserService.profile.hasCustomPortrait();
    this.profileData = this.currentUserService.profile;
    this.profileDetails = null;
    this.profileDetails = await this.currentUserService.getMyProfileFromLocalStorage();
    this.activateLanguage(this.profileDetails.activeLanguage);
  }

  protected activateLanguage(language: ActiveLanguage) {
    this.activeLanguage = language;
  }

  public async loadRuby() {
    this.rubyCalculator = await this.rubyService.getCalculator();
  }

  public async loadActiveSubscriptions() {
    try {
      const data = await this.rubyApi.getSubscriptions(this.currentUserService.token);

      this.allSubscriptions = data.subscriptions;
    } catch (e) {
      console.log(e);
    }
  }

  public async loadCourses() {

    this.stillLoadingCourses = true;
    this.loadingService.show(_('Načítám data'));

    this.coursesByLanguages = {};
    this.languagesWithSomeCourse = [];
    this.allCourses = [];
    this.recentCourses = [];
    this.expandStatusOfLanguages = {};

    try {
      const dataFromStudovna = this.studovnaApi.courseList(
        this.currentUserService.studovnaId,
        this.currentUserService.token,
      );

      const dataFromEshop = this.eshopApi.getCourses(
        this.currentUserService.eshopId,
        this.currentUserService.token,
      );

      const [coursesStudovna, coursesEshop] = await Promise.all([
        dataFromStudovna,
        dataFromEshop,
      ]);

      const allCoursesFromStudovna: CourseProgressDetails[] = [];

      const courseItems = coursesEshop.courses.map(
        (courseFromEshop) => {
          const courseFromStudovna = coursesStudovna.courses[courseFromEshop.courseId];
          if (!courseFromStudovna) {
            console.warn('In eshop is a course that is not in studovna', courseFromEshop);
            return null;
          }
          allCoursesFromStudovna.push(courseFromStudovna);
          const c = createCourseItemFromData(courseFromStudovna, courseFromEshop);
          if (this.currentCourseService.courseId === c.id) {
            c.isWithRibbon = true;
          }
          return c;
        }
      ).filter(c => c);

      const courseItemsById: { [key: number]: CourseItem } = {};
      for (const course of courseItems) {
        courseItemsById[course.id] = course;
      }

      const coursesByRecentActivity: CourseProgressDetails[] = allCoursesFromStudovna.sort(
        (c1: CourseProgressDetails, c2: CourseProgressDetails) => {
          if (this.currentCourseService.courseId === c2.id) {
            return 1;
          }
          if (this.currentCourseService.courseId === c1.id) {
            return -1;
          }

          if (c1.lastActivity && c2.lastActivity) {
            return c2.lastActivity.getTime() - c1.lastActivity.getTime();
          }
          if (c2.lastActivity) {
            return 1;
          }
          if (c1.lastActivity) {
            return -1;
          }
          return 0;
        }
      );

      this.recentCourses = coursesByRecentActivity.slice(0, 3).map(
        (c) => courseItemsById[c.id]
      );

      this.coursesWithCertificates = allCoursesFromStudovna
        .filter(course => course.hasCertificate && course.complete >= 95 && course.success >= 70);
    } catch (e) {
      this.messageService.add({severity: 'error', summary: _('Nastala chyba')});
      this.loadingService.hide();
      console.log('Nelze stáhnout seznam kurzů.', 'Zkontrolujte prosím, zda je telefon připojen k internetu.', e);
      this.stillLoadingCourses = false;
      return;
    }

    this.loadingService.hide();

    let response: DashboardInfoResponse;
    try {
      response = await this.studovnaApi.dashboardInfo(
        this.currentUserService.studovnaId,
        this.currentUserService.token,
      );
    } catch (error) {
      console.log(error, 'Nelze načíst data pro dashboard.');
      return;
    }

    this.data = response;

  }

  clickedCourse(course: CourseItem) {
    if (this.rubyCalculator) {
      const combinedCourse = this.rubyCalculator.getCourseData(course.id);
      const currency = this.rubyCalculator.getAvailableCurrenciesForCourse(combinedCourse);
      const shopOptions = this.rubyCalculator.getShopOptionsOfCourse(course.id, currency[0]);

      this.paymentDialogService.handleButtonClick(
        course.button,
        combinedCourse,
        shopOptions
      )
    }

  }

  public async goToCourse(course: CourseItem) {
    try {
      const activationResponse = await this.studovnaApi.courseActivation(
        this.currentUserService.studovnaId,
        course.id,
        this.currentUserService.token
      );
      await this.currentCourseService.activateCourse(course.id);

      let response: CurrentStatusResponse;
      response = await this.studovnaApi.currentStatus(this.currentUserService.studovnaId, this.currentUserService.token);
      this.currentUserService.updateActiveCourse(course.id);
      this.currentCourseService.setCurrentLesson(response.lastLessonBlock);
      this.router.navigate(['/dashboard']);
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: _('Nelze aktivovat kurz.'),
        detail: _('Omlouváme se, nastala chyba při aktivaci kurzu.')
      });
      console.error(error);
    }
  }

  photoCaptureClicked(e: Event) {
    e.preventDefault();
    this.photoCapture.show();
  }

  async removeProfilePicture(e: Event) {
    e.preventDefault();
    try {
      const r = await this.eshopApi.editProfile(
        this.currentUserService.eshopId,
        this.currentUserService.token,
        {
          profile_picture: 'delete'
        }
      );

      this.currentUserService.setProfileFromEshop({
        portrait: '',
      });
      this.initUserProfile();

    } catch (e) {
      console.log(e)
    }
  }

  async uploadNewProfilePhoto(imageAsDataUrl: string) {
    if (!imageAsDataUrl) {
      return;
    }

    this.loadingService.show(_('Nahrávám novou profilovou fotku'));

    try {
      const r = await this.eshopApi.editProfile(
        this.currentUserService.eshopId,
        this.currentUserService.token,
        {
          profile_picture: imageAsDataUrl
        }
      );

      if (r.newProfilePicture) {
        this.currentUserService.setProfileFromEshop({
          portrait: r.newProfilePicture
        });
        this.initUserProfile();

        this.photoCapture.close();
      }
    } catch (e) {
      this.loadingService.hide();
    }

    this.loadingService.hide();
  }

  clickedMyCourses(e: Event) {
    e.preventDefault();
    this.router.navigate(['/my-courses'], {queryParams: {currentTab: 1}});
  }

  clickedMyFriends(e: Event) {
    e.preventDefault();
    this.routerHistoryService.add({route: '/profile', queryParams: {}}, false);
    const routerItem = new RouterHistoryItem();
    routerItem.route = '/friends';
    routerItem.queryParams = {};
    this.routerHistoryService.add(routerItem, true);
  }

  clickedCertificates(e: Event) {
    e.preventDefault();

    if (!this.coursesWithCertificates || this.coursesWithCertificates.length === 0) {
      return;
    }

    this.routerHistoryService.add({route: '/profile', queryParams: {}}, false);
    const routerItem = new RouterHistoryItem();
    routerItem.route = '/profile/certificates';
    routerItem.queryParams = {
      courses: JSON.stringify(this.coursesWithCertificates)
    };
    this.routerHistoryService.add(routerItem, true);
  }

  clickedMySubscriptions(e: Event) {
    e.preventDefault();

    this.routerHistoryService.add({route: '/profile', queryParams: {}}, false);
    const routerItem = new RouterHistoryItem();
    routerItem.route = '/profile/subscriptions';
    this.routerHistoryService.add(routerItem, true);
  }

  async clickedMyFollowers(e: Event) {
    e.preventDefault();
    const routerItem = new RouterHistoryItem();
    routerItem.route = '/friends';
    routerItem.queryParams = {
      users: JSON.stringify(this.profileDetails.followersList),
      myFollowers: true
    };
    this.routerHistoryService.add(routerItem, true);
  }

  async editProfile(e: Event) {
    e.preventDefault();
    this.editProfileModal.show();
  }
}
