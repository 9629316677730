import { createImageSource, ImageSource } from './image-source';
import { parseTime } from '../../tools/parse-time';

export interface DashboardBanner {
	id: string;
	alt: string;
	link: string;
	dateFrom: null | Date;
	dateTo: null | Date;
	languages: ('cs'|'en'|'es'|'pl'|'sk')[];
	active: boolean;
	imageMobile: ImageSource[];
	imageDesktop: ImageSource[];
}

export function createDashboardBanner(input: any): DashboardBanner {
	return {
		id: (input.id || '')  + '',
		alt: (input.alt || '')  + '',
		link: (input.link || '')  + '',
		dateFrom: input.date_from ? parseTime(input.date_from) : null,
		dateTo: input.date_to ? parseTime(input.date_to) : null,
		active: !!input.active,
		languages: input.languages,
		imageMobile: (input.image_mobile || []).map(createImageSource),
		imageDesktop: (input.image_desktop || []).map(createImageSource),
	};
}
