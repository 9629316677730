import { MethodDescription } from '../method-description';

export const LoginMethodDescription = new MethodDescription();

LoginMethodDescription.name = 'login';
LoginMethodDescription.fields = [
	'email',
	'password',
	'ip',
	'app',
];
