import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {ServicesAccessor} from '../../../oj-app-common/tools/services-accessor';
import {StudovnaApi} from '../../../oj-app-common/studovna-api/studovna-api';
import {CurrentUserService} from '../auth/current-user.service';
import {CurrentCourseService} from './current-course-service';
import {CourseListResponse} from '../../../oj-app-common/studovna-api/responses/course-list';
import {CourseProgressDetails} from '../../../oj-app-common/studovna-api/models/course-progress-details';
import {checkExpireDate} from '../pages/base-layout-page/pages/eshop-page/functions/course-expire-compare';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {Course} from '../../../oj-app-common/course-structure/models/course';
import {EshopApi} from '../../../oj-app-common/eshop-api/eshop-api';
import {GetCoursesResponse} from '../../../oj-app-common/eshop-api/methods/responses';


@Injectable()
export class AppAdsService {

  protected _isShowingEshop = new BehaviorSubject<boolean>(false);

  protected _userHasSomeSubscription = new BehaviorSubject<boolean>(false);

  protected loadedUserStatusData = false;

  constructor(
    sa: ServicesAccessor,
    protected eshopApi: EshopApi,
    protected currentUser: CurrentUserService,
    protected currentCourse: CurrentCourseService,
  ) {
    sa.add('appAds', this);
    this.currentUser.onLogout.subscribe(
      () => {
        this.loadedUserStatusData = false;
        this._userHasSomeSubscription.next(false);
      }
    );
    this.loadUserStatusData();
  }

  async loadUserStatusData(force = false) {
    if (this.currentUser.studovnaId) {
      if (!this.loadedUserStatusData || force) {
        const data = await this.eshopApi.getCourses(
          this.currentUser.eshopId,
          this.currentUser.token,
        );
        this.useEshopGetCoursesData(data);
      }
    }
  }

  public useEshopGetCoursesData(getCoursesResponse: GetCoursesResponse) {
    if (this.currentUser.studovnaId) {
      this.loadedUserStatusData = true;
      let hasSubscription = false;
      for (const course of getCoursesResponse.courses) {
        if (!course.isTrial) {
          if (course.expireDate) {
            const status = checkExpireDate(course.expireDate);
            if (!status.isExpired) {
              hasSubscription = true;
              break;
            }
          }
        }
      }
      this._userHasSomeSubscription.next(hasSubscription);
    } else {
      this._userHasSomeSubscription.next(false);
    }
  }

  markIfFrontPageIsEshop(isEshop: boolean) {
    this._isShowingEshop.next(isEshop);
  }

  get showYellowBanner(): Observable<boolean> {
    const obsWithFirstValue = this.currentCourse.onSwitchCourse.pipe(
      startWith(this.currentCourse.course)
    );
    return combineLatest(
      [this._isShowingEshop, this._userHasSomeSubscription, obsWithFirstValue]
    ).pipe(
      map(
        ([isShowingEshop, userHasSomeSubscription, currentCourse]) => {
          if (!currentCourse) {
            return false;
          }
          return (!isShowingEshop && !userHasSomeSubscription && (currentCourse as Course).isTrial);
        },
      ),
      distinctUntilChanged(),
    );
  }

}
