import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {OverlayDialog} from '../overlay-dialog';
import {Subject} from 'rxjs';
import {BaseExercise} from '../../../../../oj-app-common/exercises/base-exercise';
import {EvaluationResult} from '../../../../../oj-app-common/exercises/evaluation-result';
import {EXERCISE_RESULT_MODE} from './exercise-result-mode';
import {OverlayService} from '../overlay-service';
import {VideoService} from '../../../contents/services/video.service';
import {getEvalTextByPercentage} from '../../../common/eval-texts';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {MediaFilesService} from '../../../services/media-files.service';
import {_} from '../../../../../oj-app-common/i18n/translate-placeholder';
import {BaseModalComponent, ModalConfig} from '../../base-modal/base-modal.component';
import {VideoDialogMenuOutput} from '../../video-dialog-menu/video-dialog-menu-output';

export const EXERCISE_RESULT_MODE_VAR = {
  SUCCESS: 0,
  NEUTRAL: 1,
  FAILURE: 2,
};

@Component({
  selector: 'app-exercise-result-dialog',
  templateUrl: './exercise-result-dialog.component.html',
  styleUrls: ['./exercise-result-dialog.component.scss']
})

export class ExerciseResultDialogComponent implements OverlayDialog {
  public result: EvaluationResult;
  public model: BaseExercise;
  public resultMode: EXERCISE_RESULT_MODE;
  public EXERCISE_RESULT_MODE = EXERCISE_RESULT_MODE_VAR;
  public shownScore = '';
  public headingText = '';
  public text = '';
  public text2 = '';
  public swipeText = '';

  public video = '';
  public videoImage = '';

  public symbolHidden = true;
  public headingHidden = true;
  public textHidden = true;
  public mainButtonHidden = true;
  public subButtonHidden = true;
  public didHide: Subject<void>;


  public dialogHidden = true;

  public isDismissing = false;

  public receivedPoints = 0;

  public handlerNext: () => void;
  public handlerRepeat: () => void;
  public handlerSwipeButton: () => void;

  public videoPlaybackEnabled = true;
  public showVideoToggleIcon = false;

  public videoIsNotPlayingYet = true;

  protected triggerPosition = 120;
  protected wheeling;
  protected wheeldelta = {
    y: 0
  };

  showingMenu = false;



  @ViewChild('videoPlayer', {static: false})
  public videoElement: ElementRef;
  @ViewChild('dialog', {static: false})
  public dialogElement: ElementRef;
  @ViewChild(BaseModalComponent, {static: false})
  public baseModal: BaseModalComponent;

  constructor(
    protected overlayService: OverlayService,
    protected videoService: VideoService,
    protected translateService: TranslateService,
    protected router: Router,
    protected mediaFileService: MediaFilesService
  ) {

    this.didHide = new Subject<void>();

  }

  initialize(
    model: BaseExercise,
    handlerNext: () => void,
    handlerRepeatExercise: () => void,
    resultMode: EXERCISE_RESULT_MODE = null,
    video: string = null,
    videoImage: string = null,
    swipeButtonHandler: () => void,
  ) {

    this.result = model.result;
    this.model = model;
    this.handlerNext = handlerNext;
    this.handlerRepeat = handlerRepeatExercise;
    this.video = video;
    this.videoImage = videoImage;

    this.videoIsNotPlayingYet = true;

    this.handlerSwipeButton = swipeButtonHandler;

    if (resultMode === null) {

      if (this.model.isCompletedWellEnough()) {
        resultMode = EXERCISE_RESULT_MODE.SUCCESS;
      } else {
        resultMode = EXERCISE_RESULT_MODE.FAILURE;
      }

      this.resultMode = resultMode;

    } else {
      this.resultMode = resultMode;
    }

    this.setDefaultTexts();

    this.videoPlaybackEnabled = this.videoService.isVideoEnabled();
  }

  setReceivedPoints(points: number) {
    this.receivedPoints = points;
  }

  setVideo(video) {
    this.video = video;
  }

  setDefaultTexts() {
    if (!this.result) {
      throw new Error('Result has not been set. Cannot set default texts.');
    }

    const texts = getEvalTextByPercentage(this.result.score);
    this.headingText = texts.main;
    this.text = texts.sub;
    this.swipeText = _('Kliknutím sem zobrazíte výsledky');
  }

  getMeterImage(): string {
    if (this.result) {
      return Math.round(this.result.score / 10) + '';
    }
    return '';
  }

  getHideDuration(): number {
    return 0;
  }

  getShowDuration(): number {
    return 1300;
  }

  close() {
    if (!this.isDismissing) {
      this.isDismissing = true;
      this.overlayService.hide().then(
        () => {

        },
        (error) => {
          console.error(error);
          setTimeout(
            () => {
              this.overlayService.hide();
            },
            50
          );
        }
      );
    }
  }

  repeatExercise() {
    if (this.handlerRepeat) {
      this.handlerRepeat();
    }
    this.close();
  }

  goToNextExercise() {
    if (this.handlerNext) {
      this.handlerNext();
    }
    this.close();
  }

  swipeButtonClick() {
    this.handlerSwipeButton ? this.handlerSwipeButton() : null;
    this.close();
  }

  redirectToDashboard() {
    this.router.navigate(['/dashboard']);
    this.close();
  }

  hide() {
    setTimeout(
      () => {
        this.dialogHidden = true;
      },
      50
    );

    setTimeout(
      () => {
        this.didHide.next();
      },
      this.getHideDuration() + 100
    );
  }

  show() {
    this.symbolHidden = true;
    this.headingHidden = true;
    this.textHidden = true;
    this.mainButtonHidden = true;
    this.subButtonHidden = true;

    setTimeout(
      () => {
        this.dialogHidden = false;
      },
      300
    );

    setTimeout(
      () => {
        this.symbolHidden = false;
      },
      500
    );

    setTimeout(
      () => {
        this.headingHidden = false;
      },
      600
    );

    setTimeout(
      () => {
        this.textHidden = false;
      },
      700
    );

    setTimeout(
      () => {
        this.mainButtonHidden = false;
      },
      800
    );

    setTimeout(
      () => {
        this.subButtonHidden = false;
      },
      900
    );

    setTimeout(
      () => {
        if (this.result) {
          this.showScore(this.result.score);
        }
      },
      1000
    );
  }

  showScore(score: number) {

    let shownNumber = 0;
    const step = score / 9;

    const startTimeout = () => {
      setTimeout(
        () => {
          if (shownNumber < score) {
            this.shownScore = shownNumber.toFixed(0);
            shownNumber += step;
            startTimeout();
          } else {
            this.shownScore = score.toFixed(0);
          }
        },
        50
      );
    };

    startTimeout();

  }

  onWheel(e: WheelEvent) {
    clearTimeout(this.wheeling);
    this.wheeling = setTimeout(() => {
      this.wheeling = undefined;

      if (this.wheeldelta.y >= this.triggerPosition) {
        this.close();
      } else {
        this.resetDialogPosition();
      }

      this.wheeldelta.y = 0;
    }, 400);

    this.wheeldelta.y += (e.deltaY / 4);

    if (this.wheeldelta.y > 0) {
      const calculatedYPostition = `${this.wheeldelta.y}px`;
      this.dialogElement.nativeElement.style.transform = `translateY(${calculatedYPostition})`;
      this.dialogElement.nativeElement.style.opacity = 1 - (this.wheeldelta.y / 200);
    } else {
      this.dialogElement.nativeElement.style.transform = 'initial';
      this.dialogElement.nativeElement.style.opacity = 1;
    }
  }

  resetDialogPosition() {
    this.dialogElement.nativeElement.style.transform = 'initial';
    this.dialogElement.nativeElement.style.opacity = 1;
    this.wheeling = undefined;
    this.wheeldelta.y = 0;
  }

  setVideoReceivedFromBackend(video: string) {
    let videoPlayer: HTMLVideoElement;
    if (this.videoElement) {
      videoPlayer = (this.videoElement.nativeElement as HTMLVideoElement);
    }

    if (video && this.videoIsNotPlayingYet && videoPlayer) {
      if (videoPlayer.currentTime > 0) {
        videoPlayer.pause();
      }
      try {
        this.video = video;
        if (videoPlayer) {
          videoPlayer.src = this.mediaFileService.getServerImageUrl(video);
        }
      } catch (e) {
        console.error('Could not change video src', e);
      }
      if (this.videoPlaybackEnabled && this.videoIsNotPlayingYet && videoPlayer) {
        setTimeout(() => {
          videoPlayer.play();
        }, 100);
      }
      this.videoIsNotPlayingYet = false;
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.resultMode === EXERCISE_RESULT_MODE.SUCCESS) {
        this.goToNextExercise();
      } else {
        this.repeatExercise();
      }
    }
  }

  showMenu() {
    if (this.videoElement) {
      const videoPlayer: HTMLVideoElement = this.videoElement.nativeElement;
      if (videoPlayer) {
        videoPlayer.pause();
      }
    }
    this.showingMenu = true;
  }

  closedMenu(result: VideoDialogMenuOutput) {
    this.showingMenu = false;
  }

}
