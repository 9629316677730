import { _ } from '../i18n/translate-placeholder';
import { findKey, find } from 'lodash-es';

export const LANGUAGES = [
	'ENGLISH',
	'GERMAN',
	'SPANISH',
	'FRENCH',
	'ITALIAN',
	'RUSSIAN',
	'CZECH',
	'SLOVAK',
];

export enum LANGUAGE_TYPES {
	ENGLISH = 'ENGLISH',
	GERMAN = 'GERMAN',
	SPANISH = 'SPANISH',
	FRENCH = 'FRENCH',
	ITALIAN = 'ITALIAN',
	RUSSIAN = 'RUSSIAN',
	UNKNOWN = 'UNKNOWN',
	CZECH = 'CZECH',
	SLOVAK = 'SLOVAK',
	POLISH = 'POLISH',
}

export enum LANGUAGE_TYPES_SHORT {
	ENGLISH = 'en',
	GERMAN = 'de',
	SPANISH = 'es',
	FRENCH = 'fr',
	ITALIAN = 'it',
	RUSSIAN = 'ru',
	CZECH = 'cs',
	SLOVAK = 'sk',
	POLISH = 'pl',
}

export enum WOO_LANGUAGES {
	ENGLISH = 'anglictina',
	GERMAN = 'nemcina',
	SPANISH = 'spanelstina',
	FRENCH = 'francouzstina',
	ITALIAN = 'italstina',
	RUSSIAN = 'rustina',
	CZECH = 'cestina',
	SLOVAK = 'slovenstina',
	POLISH = 'polstina',
	UNKNOWN = 'nezarazene',
}

export enum LANGUAGE_TYPES_SHORT_HUMAN {
	ENGLISH = 'en',
	GERMAN = 'de',
	SPANISH = 'es',
	FRENCH = 'fr',
	ITALIAN = 'it',
	RUSSIAN = 'ру',
	CZECH = 'cz',
	SLOVAK = 'sk',
	POLISH = 'pl',
}


export function string2languageType(string: string) {
	let enumVal: LANGUAGE_TYPES = LANGUAGE_TYPES[string];
	if (!enumVal) {
		return null;
	}
	return enumVal;
}

let typesPriority = {
	[LANGUAGE_TYPES.ENGLISH]: 100,
	[LANGUAGE_TYPES.GERMAN]: 80,
	[LANGUAGE_TYPES.FRENCH]: 70,
	[LANGUAGE_TYPES.SPANISH]: 60,
	[LANGUAGE_TYPES.RUSSIAN]: 50,
	[LANGUAGE_TYPES.ITALIAN]: 40,
	[LANGUAGE_TYPES.CZECH]: 10,
	[LANGUAGE_TYPES.SLOVAK]: 9,
	[LANGUAGE_TYPES.POLISH]: 8,
	[LANGUAGE_TYPES.UNKNOWN]: 0,
};

export function languageTypePriority(type: LANGUAGE_TYPES): number {
	let pri = typesPriority[type];
	return pri || 0;
}

export function nameForLanguageType(type: LANGUAGE_TYPES): string {
	switch (type) {
		case LANGUAGE_TYPES.ENGLISH:
			return _('Angličtina');

		case LANGUAGE_TYPES.GERMAN:
			return _('Němčina');

		case LANGUAGE_TYPES.FRENCH:
			return _('Francouzština');

		case LANGUAGE_TYPES.SPANISH:
			return _('Španělština');

		case LANGUAGE_TYPES.ITALIAN:
			return _('Italština');

		case LANGUAGE_TYPES.RUSSIAN:
			return _('Ruština');

		case LANGUAGE_TYPES.CZECH:
			return _('Čeština');

		case LANGUAGE_TYPES.SLOVAK:
			return _('Slovenština');

		case LANGUAGE_TYPES.POLISH:
			return _('Polština');

		default:
			return _('Ostatní');

	}
}

export function nameForLanguageTypeAdverb(type: LANGUAGE_TYPES): string {
	let typeIso = languageTypeToIsoCode(type);
	if (typeIso) {
		return _('language-adverb-' + typeIso);
	}
	return _('Ostatní');
}

export function nameForLanguageFilterByType(language: LANGUAGE_TYPES, type: 'my' | 'taught'): string {
  const typeIso = languageTypeToIsoCode(language);
  if (typeIso) {
    return _(`language-filter-${type}-${typeIso}`);
  }
  return _('Ostatní');
}

export function languageTypeToIsoCode(type: LANGUAGE_TYPES): string {
	switch (type) {
		case LANGUAGE_TYPES.ENGLISH:
			return 'en';

		case LANGUAGE_TYPES.GERMAN:
			return 'de';

		case LANGUAGE_TYPES.FRENCH:
			return 'fr';

		case LANGUAGE_TYPES.SPANISH:
			return 'es';

		case LANGUAGE_TYPES.ITALIAN:
			return 'it';

		case LANGUAGE_TYPES.RUSSIAN:
			return 'ru';

		case LANGUAGE_TYPES.CZECH:
			return 'cs';

		case LANGUAGE_TYPES.SLOVAK:
			return 'sk';

		case LANGUAGE_TYPES.POLISH:
			return 'pl';

		default:
			return 'xx';

	}
}

export function isoCode2languageType(code: string): LANGUAGE_TYPES {
	switch (code) {
		case 'en':
			return LANGUAGE_TYPES.ENGLISH;

		case 'de':
			return LANGUAGE_TYPES.GERMAN;

		case 'fr':
			return LANGUAGE_TYPES.FRENCH;

		case 'es':
			return LANGUAGE_TYPES.SPANISH;

		case 'it':
			return LANGUAGE_TYPES.ITALIAN;

		case 'ru':
			return LANGUAGE_TYPES.RUSSIAN;

		case 'cs':
			return LANGUAGE_TYPES.CZECH;

		case 'sk':
			return LANGUAGE_TYPES.SLOVAK;

		case 'pl':
			return LANGUAGE_TYPES.POLISH;

		default:
			return LANGUAGE_TYPES.UNKNOWN;

	}
}

export function isoCode2WooType(code: string): WOO_LANGUAGES {
	switch (code) {
		case 'en':
			return WOO_LANGUAGES.ENGLISH;

		case 'de':
			return WOO_LANGUAGES.GERMAN;

		case 'fr':
			return WOO_LANGUAGES.FRENCH;

		case 'es':
			return WOO_LANGUAGES.SPANISH;

		case 'it':
			return WOO_LANGUAGES.ITALIAN;

		case 'ru':
			return WOO_LANGUAGES.RUSSIAN;

		case 'cs':
			return WOO_LANGUAGES.CZECH;

		case 'sk':
			return WOO_LANGUAGES.SLOVAK;

		case 'pl':
			return WOO_LANGUAGES.POLISH;

		default:
			return WOO_LANGUAGES.UNKNOWN;

	}
}

export function isoCode2HumanCode(isoCode: string): string {
	return LANGUAGE_TYPES_SHORT_HUMAN[isoCode2languageType(isoCode)];
}

export function wooType2language(wooType: WOO_LANGUAGES): LANGUAGE_TYPES {
	return findKey(WOO_LANGUAGES, (a) => a === wooType);
}
