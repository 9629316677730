import { MethodDescription } from '../method-description';

export const EditStudentMethodDescription = new MethodDescription();

EditStudentMethodDescription.name = 'editStudent';
EditStudentMethodDescription.fields = [
	'id',
	'password',
	'email',
	'language',
];
EditStudentMethodDescription.usesToken = true;
