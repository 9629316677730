import { MethodDescription } from '../method-description';

export const StarCourseMethodDescription = new MethodDescription();

StarCourseMethodDescription.name = 'starCourse';
StarCourseMethodDescription.fields = [
	'studentId',
	'productId',
	'starred',
];
StarCourseMethodDescription.usesToken = true;
