import { Injectable } from '@angular/core';
import {StudovnaApi} from '../../../oj-app-common/studovna-api/studovna-api';
import {CurrentUserService} from '../auth/current-user.service';
import {Subject} from 'rxjs';

@Injectable()
export class ShowInLeaderboardService {

	protected allowed = true;

  public $allowed: Subject<boolean> = new Subject<boolean>();

	constructor(
		protected studovnaApi: StudovnaApi,
		protected currentUser: CurrentUserService,
	) {
	}

	setInitialAllowed(allowed: boolean) {
		this.allowed = allowed;
    this.$allowed.next(allowed);
	}

	setAllowed(allowed: boolean, saveToBackend = true) {
		this.allowed = allowed;
		if (saveToBackend) {
			this.studovnaApi.showUserInLeaderboard(
				this.currentUser.studovnaId,
				this.currentUser.token,
				allowed,
			);
		}
    this.$allowed.next(allowed);
  }

	getAllowed() {
		return this.allowed;
	}

}
