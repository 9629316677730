import { Component, OnInit } from '@angular/core';
import {StudyPlanStatusResponse} from '../../../../../oj-app-common/studovna-api/responses/study-plan-status';
import { _ } from '../../../../../oj-app-common/i18n/translate-placeholder';
import {StudovnaApi} from '../../../../../oj-app-common/studovna-api/studovna-api';
import {CurrentUserService} from '../../../auth/current-user.service';
import {CurrentCourseService} from '../../../services/current-course-service';
import { forOwn } from 'lodash-es';
import {OverlayDialog} from '../overlay-dialog';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-daily-goal-picker',
  templateUrl: './daily-goal-picker.component.html',
  styleUrls: ['./daily-goal-picker.component.scss']
})
export class DailyGoalPickerComponent implements OnInit, OverlayDialog {
  public loading = true;
  public dialogHidden = true;
  public saving = false;
  public saved = false;

  public plan: StudyPlanStatusResponse;
  public noUiSliderConfig = {
    range: {
      min: 5,
      max: 60,
    },
    start: 5,
    step: 5,
    margin: 5
  };
  public days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  public daysShort = {
    monday: _('Po'),
    tuesday: _('Út'),
    wednesday: _('St'),
    thursday: _('Čt'),
    friday: _('Pá'),
    saturday: _('So'),
    sunday: _('Ne'),
  };
  errorMessages: string[] = [];
  didHide: Subject<any> = new Subject<any>();

  public nextStudyPlanAsString = '';
  constructor(
    protected studovnaApi: StudovnaApi,
    protected currentUserService: CurrentUserService,
    protected currentCourseService: CurrentCourseService
  ) { }

  ngOnInit() {
  }

  public async initData() {

    this.loading = true;

    let response: StudyPlanStatusResponse;

    try {

      response = await this.studovnaApi.studyPlanStatus(
        this.currentUserService.studovnaId,
        this.currentUserService.token,
      );

      this.plan = response;
      this.plan.dailyGoal.active.data /= 60;

      this.noUiSliderConfig.start = this.plan.dailyGoal.active.data;

      if (this.plan.dailyGoal.next) {
        this.plan.dailyGoal.next.data /= 60;

        if (this.plan.dailyGoal.active.data === this.plan.dailyGoal.next.data) {
          this.plan.dailyGoal.next = null;
        }
      }


      if (this.plan.studyPlan.next) {
        this.nextStudyPlanAsString = '';
        const days = Object.keys(this.plan.studyPlan.next.data).filter(
          (day) => {
            return this.plan.studyPlan.next.data[day];
          }
        );
        this.nextStudyPlanAsString = days.map(
          (day) => {
            return this.daysShort[day];
          }
        ).join(', ');

        if (JSON.stringify(this.plan.studyPlan.active.data) === JSON.stringify(this.plan.studyPlan.next.data)) {
          this.nextStudyPlanAsString = '';
          this.plan.studyPlan.next = null;
        }
      }

    } catch (error) {

      this.loading = false;
      this.plan = {
        dailyGoal: {
          active: {
            data: 20,
            since: new Date()
          },
          next: null,
        },
        studyPlan: {
          active: {
            data: {
              monday: true,
              tuesday: true,
              wednesday: true,
              thursday: true,
              friday: true,
              saturday: false,
              sunday: false,
            },
            since: new Date()
          },
          next: null
        }
      };
      return;

    }

    this.loading = false;

  }

  public async save() {

    this.errorMessages = [];
    if (this.plan.dailyGoal.active.data < 5 || this.plan.dailyGoal.active.data > 60 || this.plan.dailyGoal.active.data % 5 !== 0) {
      this.errorMessages.push(_('Denní cíl by měl být od 5 do 60 minut a násobkem 5 minut.'))
    }
    let activeDays = 0;
    forOwn(this.plan.studyPlan.active.data, (value, key) => {
      if (value) {
        activeDays++;
      }
    });
    if (activeDays < 3) {
      this.errorMessages.push(_('Ve studijním plánu by měly být alespoň tři dny.'))
    }

    if (this.errorMessages && this.errorMessages.length > 0) {
      return;
    }

    this.saving = true;

    try {

      await this.studovnaApi.setDailyGoal(
        this.currentUserService.studovnaId,
        this.currentUserService.token,
        this.currentCourseService.courseId,
        this.plan.dailyGoal.active.data * 60
      );

      await this.studovnaApi.setStudyPlan(
        this.currentUserService.studovnaId,
        this.currentUserService.token,
        this.currentCourseService.courseId,
        this.plan.studyPlan.active.data
      );

      this.saving = false;

      this.saved = true;

      setTimeout(
        () => {
          this.saved = false;
        },
        2000
      );

      this.hide(true);

    } catch (e) {
      console.log(_('Selhalo ukládání studijního plánu.'));
      // this.navCtrl.pop();
    }


  }

  getHideDuration(): number {
    return 0;
  }

  getShowDuration(): number {
    return 0;
  }

  hide(submitted = false) {
    setTimeout(
      () => {
        this.dialogHidden = true;
      },
      50
    );

    this.didHide.next(submitted);
  }

  show() {
    this.initData()
      .then(() => {
        this.dialogHidden = false;
      })
  }

}
