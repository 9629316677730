import {AppTranslationService} from '../services/app-translation.service';
import {CurrencyPipe} from '@angular/common';
import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'price',
  pure: true
})
export class PricePipe extends CurrencyPipe implements PipeTransform{

  constructor(
    protected translateService: AppTranslationService,
    @Inject(LOCALE_ID) protected locale: string,
  ) {
    super(locale);
  }

  transform(value: number, currency: string): string {
    const lang = this.translateService.currentLanguage;
    value = +value; // What if it is a string?

    // We do this because 39,90 semestrally results in 6,6499999999 after dividing by 6
    // due to floating point math operations are inperfect in JS and would result in displaying 6,64
    const valueLittleBitHigher = value + 0.0001;
    const roundedDownValue = (valueLittleBitHigher) - valueLittleBitHigher % 0.01;

    const isDecimal = (Math.abs(roundedDownValue - Math.round(roundedDownValue)) >= 0.01);

    if (isDecimal) {
      return super.transform(roundedDownValue, currency, 'symbol-narrow', '0.2', AppTranslationService.languageCode2Locale(lang));
    } else {
      return super.transform(roundedDownValue, currency, 'symbol-narrow', '0.0', AppTranslationService.languageCode2Locale(lang));
    }
  }
}
