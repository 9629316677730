import {
  Component,
  OnInit
} from '@angular/core';
import {BaseOverlayDialogComponent} from '../base-overlay-dialog/base-overlay-dialog';

@Component({
  selector: 'task-done-dialog',
  templateUrl: 'task-done-dialog.html',
  styleUrls: ['task-done-dialog.scss']
})
export class TaskDoneDialogComponent extends BaseOverlayDialogComponent implements OnInit {

  public image: string;

  public icon: string;

  public text: string;

  public buttonText;

  public buttonAction: () => void = null;

  public swipeAction: () => void = null;

  public continueButtonText = '';

  public continueButtonAction: () => void = null;

  ngOnInit() {
    if (!this.buttonText) {
      this.buttonText = this.translateService.instant('Pokračovat');
    }
    if (!this.swipeText || this.swipeText.length === 0) {
      this.swipeText = 'Kliknutím sem zobrazíte výsledky';
    }
  }

  initialize(image: string, icon: string, text: string, showSwipeButton = true) {
    this.image = image;
    this.icon = icon;
    this.text = text;
    setTimeout(() => {
      if (!showSwipeButton) {
        this.swipeText = null;
      }
    }, 200);
  }

  setupButton(text: string, action: () => void) {
    this.buttonText = text;
    this.buttonAction = action;
  }

  setupSwipeButton(text: string, action: () => void = null) {
    this.swipeText = text;
    this.swipeAction = action;
  }

  setupContinueButton(text: string, action: () => void = null) {
    this.continueButtonText = text;
    this.continueButtonAction = action;
  }

  didDismissBySwiping() {
    if (this.swipeAction) {
      this.swipeAction();
    }
    this.close();
  }

  clickedButton() {
    this.buttonAction ? this.buttonAction() : null;
    this.close();
  }

  clickedContinue() {
    if (this.continueButtonAction) {
      this.continueButtonAction();
    }
    this.close();
  }

  clickedSwipeButton() {
    if (this.swipeAction) {
      this.swipeAction();
    }
    this.close();
  }


}
