import {NgModule} from '@angular/core';
import {BasicTopBarComponent} from './basic-top-bar.component';
import {UserInfoModule} from '../../../../components/user-info/user-info.module';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {LangSwitcherModule} from '../../../../components/lang-switcher/lang-switcher.module';
import {ShopYellowBannerModule} from '../../../../components/shop-yellow-banner/shop-yellow-banner.module';
import {MatIconModule} from "@angular/material/icon";
import {TooltipModule} from "ng2-tooltip-directive";
import {TranslateModule} from "@ngx-translate/core";
import {LoadingIndicatorModule} from "../../../../components/loading-indicator/loading-indicator.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";

@NgModule({
  declarations: [BasicTopBarComponent],
  imports: [
    UserInfoModule,
    CommonModule,
    MatButtonModule,
    LangSwitcherModule,
    ShopYellowBannerModule,
    MatIconModule,
    TooltipModule,
    TranslateModule,
    LoadingIndicatorModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatAutocompleteModule
  ],
  exports: [BasicTopBarComponent]
})

export class BasicTopBarModule {}
