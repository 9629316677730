import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {currentApp, currentAppLogoPathNoText, currentAppLogoPathText} from '../../config/app.config';
import {Router} from '@angular/router';
import {OverlayService} from '../overlay-dialog/overlay-service';
import {SecretModalSettingsComponent} from '../secret-modal-settings/secret-modal-settings.component';
import {LectorDialogComponent} from "../overlay-dialog/lector-dialog/lector-dialog.component";
import {HelpcrunchService} from '../../services/helpcrunch.service';
import {AppConfig} from '../../config/app-config';

// declare const HelpCrunch;

@Component({
  selector: 'app-navigation-panel',
  templateUrl: './navigation-panel.component.html',
  styleUrls: ['./navigation-panel.component.scss']
})
export class NavigationPanelComponent implements OnInit, OnDestroy {
  imagePath = '/assets/img/icons/';

  currentAppLogoPath = '';

  buttons = [
    {
      title: 'Domů',
      path: '/assets/img/icons/gray/',
      icon: 'home.svg',
      href: '/dashboard'
    },
    {
      title: 'Kurz',
      path: '/assets/img/icons/gray/',
      icon: 'content.svg',
      href: '/my-courses'
    },
    {
      title: 'E-shop',
      path: '/assets/img/icons/gray/',
      icon: 'cart.svg',
      href: '/eshop'
    },
    {
      title: 'Profil',
      path: '/assets/img/icons/gray/',
      icon: 'user.svg',
      href: '/profile'
    },
    {
      title: 'Žebříček',
      path: '/assets/img/icons/gray/',
      icon: 'leaderboard.svg',
      href: '/leaderboard'
    },
    {
      title: 'Slovíčka',
      path: '/assets/img/icons/gray/',
      icon: 'vocabulary.svg',
      href: '/vocabulary'
    }
  ];

  bottomBtns = [
    {
      title: 'Nastavení',
      path: '/assets/img/icons/gray/',
      icon: 'settings.svg',
      href: '/settings'
    }
  ];
  activeButton = this.buttons[0];
  secretMode = {
    clickCount: 0,
    enabled: false
  };

  routerSubscription;

  public isVisibleComponent = false;

  @Output() onChangeNavigation: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(SecretModalSettingsComponent, {static: false}) secretModalSettingsComponent: SecretModalSettingsComponent;

  constructor(
    protected router: Router,
    protected overlayService: OverlayService,
    protected helpcrunchService: HelpcrunchService,
    protected appConfig: AppConfig
  ) {
  }

  ngOnInit() {
    this.currentAppLogoPath = `/assets/img/apps/${this.appConfig.currentApp}/logo-text.png`

    this.isVisibleComponent = true;

    this.setImagePath();

    this.checkActiveRoute();

    this.routerSubscription = this.router
      .events
      .subscribe(() => {
        if (this.isVisibleComponent) {
          this.checkActiveRoute();
        }
      });

    this.secretMode.clickCount = 0;
  }

  ngOnDestroy() {
    this.isVisibleComponent = false;
    this.routerSubscription.unsubscribe();
  }

  checkActiveRoute() {
    const activeRoute = this.router.url;
    this.buttons.forEach(b => b.path = '/assets/img/icons/gray/')
    this.activeButton = this.buttons.filter(button => button.href === activeRoute)[0];
    if (!this.activeButton) {
      this.activeButton = this.bottomBtns.filter(button => button.href === activeRoute)[0];
      if (!this.activeButton) {
        return;
      }
    }
    this.activeButton.path = '/assets/img/icons/blue/';
  }

  setImagePath(isBlue = false) {
    if (isBlue) {
      this.imagePath += 'blue/';
      return;
    }
    this.imagePath += 'gray/';
  }

  mouseLeaveChangeImagePath() {
    const allButtons = this.buttons.concat(this.bottomBtns);
    if (allButtons) {
      allButtons.forEach(btn => {
        if (this.activeButton && btn.title === this.activeButton.title) {
          btn.path = '/assets/img/icons/blue/';
        } else {
          btn.path = '/assets/img/icons/gray/';
        }
      });
    }
  }

  mouseOverChangeImagePath(button) {
    let buttn = this.buttons.filter(btn => btn.title === button.title);
    if (buttn && buttn.length > 0) {
      buttn[0].path = '/assets/img/icons/blue/';
    } else {
      buttn = this.bottomBtns.filter(btn => btn.title === button.title);
      if (buttn && buttn.length > 0) {
        buttn[0].path = '/assets/img/icons/blue/';
      } else {
        return;
      }
    }
  }

  onButtonClick(e: Event, selectedButton: any) {
    this.secretMode.clickCount = 0;
    this.secretMode.enabled = false;
    e.preventDefault();
    this.activeButton = selectedButton;
    this.onChangeNavigation.emit(selectedButton);
    this.mouseLeaveChangeImagePath();
  }

  logoClick() {
    this.secretMode.clickCount++;

    const activeRoute = this.router.url;
    if (activeRoute !== '/dashboard') {
      this.router.navigate(['/dashboard']);
    }

    if (this.secretMode.clickCount >= 7) {
      this.secretMode.enabled = true;
      this.openSecretModal();
      this.secretMode.enabled = false;
      this.secretMode.clickCount = 0;
    }
  }

  openLectorModal() {
    this.helpcrunchService.openChat()
  }

  openSecretModal() {
    this.secretModalSettingsComponent.show();
  }

  onHelpButtonClick(event: Event) {
    event.preventDefault();
  }

}
