export enum EXERCISE_TYPE {
	UNKNOWN = '',
	TRUE_FALSE = 'true_false',
	NOT_IN_GROUP = 'not_in_group',
	TEST = 'test',
	FILLUP = 'fill_up',
	SORT_WORDS = 'sort_words',
	SORT_SENTENCES = 'sort_sentences',
	ASSIGN_TO_GROUPS = 'assign_to_groups',
	ASSIGN_TEXT_TO_IMAGE = 'assign_text_to_image',
	CROSSWORD = 'crossword',
}
