import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'floorValue'
})
export class FloorValuePipe implements PipeTransform {

  transform(value: number): string {
    let val: number;
    val = Math.floor(value * 100) / 100;
    return val.toFixed(2);
  }

}
