import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInArray'
})
export class IsInArrayPipe implements PipeTransform {

  transform(value: any, array: any[]): any {
    let val = value;

    if (typeof value === 'string') {
      val = value.toUpperCase();
    }

    if (array.indexOf(val) === -1) {
      return false;
    }
    return true;
  }

}
