import { Component, OnInit } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss']
})
export class ModalContentComponent implements OnInit {

  title: string;

  body: string;

  imageSrc: string;

  buttons: any[] = [];

  showTopCloseButton = true;

  magnifier = false;

  glassMouseMoveListener: EventListener;
  imgMouseMoveListener: EventListener;

  glassTouchMoveListener: EventListener;
  imgTouchMoveListener: EventListener;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {

  }

  ngOnDestroy() {
    this.removeListeners();
  }

  hide() {
    this.bsModalRef.hide();
  }

  public getActionForButton(button: any) {
    if (button && button.role && button.role === 'cancel') {
      return this.hide();
    }

    if (button && button.handler) {
      this.hide();
      return button.handler.apply(this);
    }

    return;
  }

  toggleMagnifier() {
    this.magnifier = !this.magnifier;

    if (this.magnifier) {
      this.addMagnifier();
    } else {
      this.deleteMagnifier();
    }
  }

  addMagnifier() {
    if (!this.body && this.imageSrc) {
      setTimeout(() => {
        this.magnify('myimage', 1.5);
      }, 200);
    }
  }

  deleteMagnifier() {
    document.getElementById('img-magnifier-glass').remove();
    this.removeListeners();
  }

  removeListeners() {
    if (this.glassMouseMoveListener) {
      removeEventListener('mousemove', this.glassMouseMoveListener);
    }

    if (this.imgMouseMoveListener) {
      removeEventListener('mousemove', this.imgMouseMoveListener);
    }

    if (this.glassTouchMoveListener) {
      removeEventListener('touchmove', this.glassTouchMoveListener);
    }

    if (this.imgTouchMoveListener) {
      removeEventListener('touchmove', this.imgTouchMoveListener);
    }
  }

  magnify(imgID, zoom) {
    let img, glass, w, h, bw;
    img = document.getElementById(imgID);
    /*create magnifier glass:*/
    glass = document.createElement('DIV');
    glass.setAttribute('class', 'img-magnifier-glass');
    glass.setAttribute('id', 'img-magnifier-glass');
    /*insert magnifier glass:*/
    img.parentElement.insertBefore(glass, img);
    /*set background properties for the magnifier glass:*/
    glass.style.backgroundImage = 'url(\'' + img.src + '\')';
    glass.style.backgroundRepeat = 'no-repeat';
    glass.style.backgroundSize =
      img.width * zoom + 'px ' + img.height * zoom + 'px';
    bw = 3;
    w = glass.offsetWidth / 2;
    h = glass.offsetHeight / 2;
    /*execute a function when someone moves the magnifier glass over the image:*/
    this.glassMouseMoveListener = glass.addEventListener('mousemove', moveMagnifier);
    this.imgMouseMoveListener = img.addEventListener('mousemove', moveMagnifier);
    /*and also for touch screens:*/
    this.glassTouchMoveListener = glass.addEventListener('touchmove', moveMagnifier);
    this.imgTouchMoveListener = img.addEventListener('touchmove', moveMagnifier);

    function moveMagnifier(e) {
      let pos, x, y;
      /*prevent any other actions that may occur when moving over the image*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      x = pos.x;
      y = pos.y;
      /*prevent the magnifier glass from being positioned outside the image:*/
      if (x > img.width - w / zoom) {
        x = img.width - w / zoom;
      }
      if (x < w / zoom) {
        x = w / zoom;
      }
      if (y > img.height - h / zoom) {
        y = img.height - h / zoom;
      }
      if (y < h / zoom) {
        y = h / zoom;
      }
      /*set the position of the magnifier glass:*/
      glass.style.left = x - w + 'px';
      glass.style.top = y - h + 'px';
      /*display what the magnifier glass "sees":*/
      glass.style.backgroundPosition =
        '-' + (x * zoom - w + bw) + 'px -' + (y * zoom - h + bw) + 'px';
    }

    function getCursorPos(e) {
      let a,
        x = 0,
        y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x, y };
    }
  }

}
