import { ErrorHandler, Injectable } from '@angular/core';
import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import { StudovnaApiError } from './helpers/error';
import { Token } from '../misc/token';
import { UserDetailResponse } from './responses/user-detail';
import { CourseDetailResponse } from './responses/course-detail';
import { Chapter } from '../course-structure/models/chapter';
import { CourseContentResponse } from './responses/course-content';
import { VisitedLessonsResponse } from './responses/visited-lessons';
import { LessonStatus } from '../course-structure/models/lesson-status';
import { ServicesAccessor } from '../tools/services-accessor';
import { ExerciseEvaluationResponse } from './responses/exercise-evaluation';
import { EvaluationResult } from '../exercises/evaluation-result';
import { CourseProgressDetails } from './models/course-progress-details';
import { CourseListResponse } from './responses/course-list';
import { CurrentStatusResponse } from './responses/current-status';
import { CourseActivationResponse } from './responses/course-activation';
import { exportTime } from '../tools/export-time';
import { BOOKMARK_STATUS } from '../bookmarks/models/bookmark-status';
import { Bookmark } from '../bookmarks/models/bookmark';
import { parseTime } from '../tools/parse-time';
import { DashboardInfoResponse } from './responses/dashboard-info';
import { DailyTasksResponse } from './responses/daily-tasks';
import { VocabularyWord } from '../vocabulary/models/vocabulary-word';
import { WORD_RESULT } from '../vocabulary/models/word-result';
import { VocabularyTrainingWord } from '../vocabulary/models/vocabulary-training-word';
import { LEVEL } from '../vocabulary/models/level';
import * as qs from 'qs';
import { Achievements } from './models/achievements';
import { StudyPlanStatusResponse } from './responses/study-plan-status';
import { MyProfileResponse } from './responses/my-profile';
import { AppConfigStudovnaApi } from './app-config-studovna-api';
import { UserListResponse } from './responses/user-list';
import { FriendListResponse } from './responses/friend-list';
import { PublicProfileResponse } from './responses/public-profile';
import { UserSearchItem, UserSearchResponse } from './responses/user-search';
import { LeaderboardList } from './responses/leaderboard-list';
import { createLeaderboardStudentFromObject } from './models/leaderboard-student';
import { Settings } from './models/settings';
import { ToleranceSettings } from './models/tolerance-settings';
import { isArray } from 'lodash-es';
import { VocabularyGenerateTypesEnum } from '../misc/vocabulary-generate-types.enum';

// tslint:disable:indent


@Injectable()
export class StudovnaApi {

  protected axios: AxiosInstance;

  protected errorHandler: ErrorHandler;

  protected MAX_LOG_ACTIVITY_MINUTES = 90;

  constructor(
    protected appConfig: AppConfigStudovnaApi,
    serviceAccessor: ServicesAccessor,
    errorHandler: ErrorHandler,
  ) {

    // if (errorHandler['isAppHandler']) {
    // This leads to custom app's error handler derived from Ionic, that was
    // too difficult to separate from rest of the mobile app.
    // Can be safely used with standard Angular's ErrorHandler
    this.errorHandler = errorHandler /* as AppErrorHandler */;
    // }

    this.createAxios();
    serviceAccessor.add('studovnaApi', this);
  }

  protected createAxios() {
    this.axios = Axios.create({
      baseURL: this.appConfig.studovnaApiUrl,
      headers: {
        // 'X-OJ-App': 'unknown yet'
      },
      timeout: 15000,
      validateStatus: (status: number) => {
        // Status 500 is handled later
        if (status === 200 || status === 500) {
          return true;
        }
        return false;
      },
    });
  }

  public reloadBaseApiAddress() {
    this.createAxios();
  }

  public userDetail(userId: number, token: Token): Promise<UserDetailResponse> {

    return <Promise<UserDetailResponse>>this.call(
      'user-detail',
      {
        user: userId,
        token: token,
      },
      'post',
    )
      .then(
        (response: UserDetailResponse | any) => {
          if (response.active_course) {
            response.activecourse = response.active_course;
          }
          return response;
        },
      )

      ;

  }

  public courseDetail(courseId: string, userId: number, token: Token): Promise<CourseDetailResponse> {
    return <Promise<CourseDetailResponse>>this.call(
      'course-detail',
      {
        user: userId,
        token: token,
        course: courseId,
      },
      'post',
    );
  }

  public courseList(userId: number, token: Token, forceUpdate = false): Promise<CourseListResponse> {

    return this.call(
      'course-list',
      {
        user: userId,
        token: token,
        update: forceUpdate ? 1 : 0,
      },
      'post',
    )
      .then(
        (response) => {
          let returnObj = {};
          let ids = Object.keys(response);
          for (let id of ids) {
            let course = response[id];
            let r = new CourseProgressDetails();
            Object.assign(r, course);
            if (!r.allWords && course.allWodrs) {
              r.allWords = course.allWodrs;
            }
            r.id = +r.id;
            r.isDemo = !!r.isDemo;
            try {
              r.started = r.started ? parseTime(r.started) : null;
            } catch (e) {
              console.error(e);
              r.started = null;
            }
            try {
              r.expiration = r.expiration ? parseTime(r.expiration) : null;
            } catch (e) {
              console.error(r);
              r.expiration = null;
            }
            r.lastActivity = r.lastActivity ? parseTime(r.lastActivity, true) : null;
            r.complete = +r.complete;
            r.success = +r.success;
            r.time = +r.time;
            r.learnedWords = +r.learnedWords;
            r.allWords = +r.allWords;
            r.isDemo = !!course.trial;
            returnObj[r.id] = r;
          }
          let resp = new CourseListResponse();
          resp.courses = returnObj;
          return resp;
        },
      )
      ;

  }

  public courseContent(courseId: string, userId: number, token: Token, progress = false): Promise<CourseContentResponse> {

    return <Promise<any>>this.call(
      'course-content',
      {
        user: userId,
        token: token,
        course: courseId,
        progress: progress ? 1 : 0,
      },
      'post',
      {
        timeout: 30000,
      },
    )
      .then(
        (response) => {
          let chapters = [];
          for (let chapterId of Object.keys(response)) {
            let chapterData = response[chapterId];
            if (!chapterData || !chapterData.id) {
              continue;
            }
            let chapter = Chapter.createFromData(chapterData);
            chapters.push(chapter);
          }

          chapters.sort(
            (ch1: Chapter, ch2: Chapter) => {
              if (ch1.order || ch2.order) {
                return ch1.order - ch2.order;
              }
              return ch1.id - ch2.id;
            },
          );

          let processedResponse = new CourseContentResponse();
          processedResponse.chapters = chapters;
          return processedResponse;
        },
      );

  }

  public lessonBlockData(lessonId: number, userId: number, token: Token, markAccess = false, lastResult = false, activeCampaign = true): Promise<any> {

    return <Promise<any>>this.call(
      'lesson-block-data',
      {
        lessonBlock: lessonId,
        token: token,
        user: userId,
        access: (markAccess ? 1 : 0),
        lastResult: (lastResult ? 1 : 0),
        activeCampaign: (activeCampaign ? 1 : 0),
      },
      'post',
    );

  }

  public visitedLessons(courseId: number, userId: number, token: Token): Promise<LessonStatus[]> {

    return <Promise<LessonStatus[]>>this.call(
      'visited-lessons',
      {
        user: userId,
        token: token,
        course: courseId,
      },
      'post',
      {
        timeout: 30000,
      },
    )
      .then(
        (response: VisitedLessonsResponse) => {
          const returned = [];
          for (let item of response) {
            returned.push(
              LessonStatus.createFromApi(item),
            );
          }
          return returned;
        },
      )
      ;

  }

  public exerciseEvaluation(userId: number, lessonBlockId: number, answersData: string, token: Token, start: Date = null, end: Date = null, idle: number = 0): Promise<ExerciseEvaluationResponse> {

    let params: any = {
      user: userId,
      token: token,
      lessonBlock: lessonBlockId,
      data: answersData,
    };

    if (start && end) {

      let movedStart = new Date(start);
      movedStart.setTime(movedStart.getTime() + 1000);

      if (end.getTime() - movedStart.getTime() <= idle * 1000 - 200) {
        end = null;
        start = null;
      }

      if (idle < 0) {
        end = null;
        start = null;
      }

      // Pojistka - nikdy nedáme log activity na více než 4 minuty najednou
      // Změněno 26.8.2022 - #17570
      if (end.getTime() - movedStart.getTime() > this.MAX_LOG_ACTIVITY_MINUTES * 60 * 1000) {
        movedStart.setTime(end.getTime() - this.MAX_LOG_ACTIVITY_MINUTES * 60 * 1000);
      }

      if (start && end) {
        params.start = exportTime(start);
        params.end = exportTime(end);
        params.idle = idle || 0;
      }

    }


    return <Promise<ExerciseEvaluationResponse>>this.call(
      'exercise-evaluation',
      params,
      'post',
    )
      .then(
        (response: any) => {
          let resp = new ExerciseEvaluationResponse();
          Object.assign(resp, response);
          resp.lessonBlock = +response.lessonBlock;
          resp.evaluation = new EvaluationResult(+response.correctAnswers, +response.questionNumber);
          resp.data = response.data;
          resp.points = +response.points;
          resp.achievements = Achievements.createFromObject(response.achievements);

          return resp;
        },
      )
      ;
  }

  public currentStatus(userId: number, token: Token): Promise<CurrentStatusResponse> {
    return this.call(
      'current-status',
      {
        user: userId,
        token: token,
      },
      'post',
    ).then(
      (response) => {
        let r = new CurrentStatusResponse();
        Object.assign(r, response);
        r.user = +r.user;
        r.activeCourse = +r.activeCourse;
        r.lastLessonBlock = +r.lastLessonBlock;
        return r;
      },
    ).catch(e => {
      return e;
    })
      ;
  }

  public courseActivation(userId: number, courseId: number, token: Token): Promise<CourseActivationResponse> {
    return this.call(
      'course-activation',
      {
        user: userId,
        course: courseId,
        token: token,
      },
      'post',
    )
      .then(
        (rawResponse: CourseActivationResponse) => {
          let response = new CourseActivationResponse();
          Object.assign(response, rawResponse);
          response.user = +response.user;
          response.lastLessonBlock = +response.lastLessonBlock;
          return response;
        },
      )
      ;
  }

  public logActivity(userId: number, token: Token, lessonBlockId: number, start: Date, end: Date, idle: number = 0, courseId = 0, exercise = false, shouldBeTimeShortened = true): Promise<{ achievement: Achievements }> {

    if (!end) {
      end = new Date();
    }

    let movedStart = new Date(start);
    movedStart.setTime(movedStart.getTime() + 1000);

    if (end.getTime() - movedStart.getTime() <= idle * 1000 - 200) {
      return Promise.resolve({achievement: new Achievements()});
    }

    if (idle < 0) {
      return Promise.resolve({achievement: new Achievements()});
    }

    if (shouldBeTimeShortened) {
      // Pojistka - nikdy nedáme log activity na více než 4 minuty najednou
      // Změněno 26.8.2022 - #17570
      if (end.getTime() - movedStart.getTime() > this.MAX_LOG_ACTIVITY_MINUTES * 60 * 1000) {
        movedStart.setTime(end.getTime() - this.MAX_LOG_ACTIVITY_MINUTES * 60 * 1000);
      }
    }

    let data = {
      user: userId,
      token: token,
      lessonBlock: lessonBlockId,
      course: courseId,
      start: exportTime(movedStart),
      end: exportTime(end),
      idle: idle || 0,
    };

    if (exercise) {
      data['exercise'] = 1;
    }

    return this.call(
      'log-activity',
      data,
      'post',
    ).then(
      (response) => {
        let resp = {
          achievement: Achievements.createFromObject(response['achievements']),
        };

        return resp;
      },
    );
  }

  public textToSpeech(userId: number, token: Token, text: string, course = 0, lessonId: number | null = null): Promise<any> {

    let params = {
      user: userId,
      token: token,
      text: text,
    };
    if (course) {
      params['course'] = course;
    }
    if (lessonId !== null) {
      params['lesson'] = lessonId;
    }

    return this.call(
      'text-to-speech',
      params,
      'get',
      {
        allowNonStandardResponse: true,
      },
    );

  }

  public getTextToSpeechUrl(userId: number, token: Token, text: string, course = 0, lessonId: number | null = null): string {

    let url = this.appConfig.studovnaApiUrl + '/text-to-speech';

    let params = {
      user: userId,
      token: token,
    };

    if (course) {
      params['course'] = course;
    }
    if (lessonId) {
      params['lesson'] = lessonId;
    }
    params['text'] = text; // keep text as last parameter to see it nicely in network tab in devtools

    for (let key of Object.keys(params)) {
      url += '/' + encodeURIComponent(key) + '/' + encodeURIComponent(params[key]);
    }

    return url;

  }

  public textToSpeechExtended(userId: number, token: Token, words: string[], owner: string, courseId: number, lessonId: number);
  public textToSpeechExtended(userId: number, token: Token, text: string, owner: string, courseId: number, lessonId: number);
  public textToSpeechExtended(userId: number, token: Token, words: string[], owner: string, courseId: number);
  public textToSpeechExtended(userId: number, token: Token, text: string, owner: string, courseId: number);
  public textToSpeechExtended(userId: number, token: Token, words: string[], owner: string);
  public textToSpeechExtended(userId: number, token: Token, text: string, owner: string);
  public textToSpeechExtended(userId: number, token: Token, somethingToSay: string | string[], owner: string, courseId: number = 0, lessonId: number | null = null) {
    let params = {
      user: userId,
      token: token,
      owner: owner,
    };

    if (courseId) {
      params['course'] = courseId;
    }
    if (lessonId !== null) {
      params['lesson'] = lessonId;
    }

    if (typeof somethingToSay === 'string') {
      params['text'] = somethingToSay;
    } else if (isArray(somethingToSay)) {
      params['words'] = JSON.stringify(somethingToSay);
    } else {
      throw new Error('Supply euther string or string[] array as the third argument.');
    }

    return this.call(
      'text-to-speech',
      params,
      'post',
      {
        allowNonStandardResponse: true,
      },
    );
  }

  public getBookmarks(userId: number, token: Token, courseId: number, bookmarkStatus: BOOKMARK_STATUS = null): Promise<Bookmark[]> {

    let params = {
      user: userId,
      token: token,
      course: courseId,
    };
    if (bookmarkStatus) {
      params['state'] = bookmarkStatus;
    }

    return this.call(
      'bookmarks',
      params,
      'post',
    )
      .then(
        (response: object[]) => {
          let bookmarks: Bookmark[] = [];
          for (let item of response) {
            bookmarks.push(Bookmark.fromData(item));
          }
          return bookmarks;
        },
      )

      ;

  }

  public addBookmark(userId: number, token: Token, lessonBlockId: number): Promise<Bookmark> {

    return this.call(
      'add-bookmark',
      {
        user: userId,
        token: token,
        lessonBlock: lessonBlockId,
      },
      'post',
    )
      .then(
        (response: any) => {
          if (response.id) {
            return Bookmark.fromData(response);
          } else {
            throw new Error('API did not return a bookmark with ID.');
          }
        },
      )
      ;

  }

  public removeBookmark(userId: number, token: Token, bookmarkId: number): Promise<any> {

    return this.call(
      'remove-bookmark',
      {
        user: userId,
        token: token,
        bookmark: bookmarkId,
      },
      'post',
    );

  }

  public completeBookmark(userId: number, token: Token, bookmarkId: number, newStatus: BOOKMARK_STATUS = BOOKMARK_STATUS.COMPLETED): Promise<any> {

    return this.call(
      'complete-bookmark',
      {
        user: userId,
        token: token,
        bookmark: bookmarkId,
        completed: (newStatus === BOOKMARK_STATUS.COMPLETED) ? 1 : 0,
      },
      'post',
    );

  }

  public dashboardInfo(userId: number, token: Token): Promise<DashboardInfoResponse> {

    return this.call(
      'dashboard-info',
      {
        user: userId,
        token: token,
      },
      'post',
    ).then(
      (r: DashboardInfoResponse) => {
        return r;
      },
    );

  }

  public studyPlan(userId: number, token: Token, courseId: number): Promise<{ [key: string]: boolean }> {

    return this.call(
      'study-plan',
      {
        user: userId,
        token: token,
        course: courseId,
      },
      'post',
    ) as Promise<{ [key: string]: boolean }>;

  }

  public setStudyPlan(userId: number, token: Token, courseId: number, studyPlanData: { [key: string]: boolean }): Promise<any> {

    return this.call(
      'set-study-plan',
      {
        user: userId,
        token: token,
        course: courseId,
        studyPlan: studyPlanData,
      },
      'post',
    )
      ;

  }

  public dailyGoal(userId: number, token: Token, courseId: number): Promise<number> {

    return this.call(
      'daily-goal',
      {
        user: userId,
        token: token,
        course: courseId,
      },
      'post',
    ).then(
      (response) => {
        if (response) {
          return +response['dailyGoal'];
        }
      },
    )
      ;

  }

  public setDailyGoal(userId: number, token: Token, courseId: number, dailyGoal: number): Promise<void> {
    return this.call(
      'set-daily-goal',
      {
        user: userId,
        token: token,
        course: courseId,
        dailyGoal: dailyGoal,
      },
      'post',
    ).then((response) => {
    });
  }

  public studyPlanStatus(userId: number, token: Token): Promise<StudyPlanStatusResponse> {

    return this.call(
      'study-plan-status',
      {
        user: userId,
        token: token,
      },
      'post',
    ).then(
      (response: any) => {
        if (response.dailyGoal.active) {
          response.dailyGoal.active.since = parseTime(response.dailyGoal.active.since);
        }
        if (response.dailyGoal.next) {
          response.dailyGoal.next.since = parseTime(response.dailyGoal.next.since);
        }
        if (response.studyPlan.active) {
          response.studyPlan.active.since = parseTime(response.studyPlan.active.since);
        }
        if (response.studyPlan.next) {
          response.studyPlan.next.since = parseTime(response.studyPlan.next.since);
        }
        return response as StudyPlanStatusResponse;
      },
    );

  }

  public dailyTasks(userId: number, token: Token, courseId: (number | null) = null): Promise<DailyTasksResponse> {

    return this.call(
      'daily-tasks',
      {
        user: userId,
        token: token,
        course: courseId,
      },
      'post',
    ) as Promise<DailyTasksResponse>;

  }

  public reportBug(userId: number, token: Token, message: string, courseId = 0, lessonBlock = 0, techData = '', screenshot = '', wordId: number = null, owner: string = ''): Promise<void> {

    return this.call(
      'report-bug',
      {
        user: userId,
        token: token,
        message: message,
        course: courseId,
        lessonBlock: lessonBlock,
        technicalInfo: techData,
        image: screenshot,
        word: wordId,
        owner: owner,
      },
      'post-form',
    ).then(
      () => {
        return;
      },
    );

  }

  public trainingWord(userId: number, token: Token, word: VocabularyTrainingWord, result: WORD_RESULT): Promise<void> {
    if (result === WORD_RESULT.SKIP) {
      return Promise.resolve();
    }
    return this.call(
      'training-word',
      {
        user: userId,
        token: token,
        word: word.id,
        mark: (result === WORD_RESULT.CORRECT ? 1 : 0),
      },
      'post',
    ).then(() => {
      return;
    });
  }

  public vocabularyTraining(userId: number, token: Token, courseId: number, options = false): Promise<VocabularyTrainingWord[]> {

    return this.call(
      'vocabulary-training',
      {
        user: userId,
        token: token,
        course: courseId,
        options: (!!options) ? 1 : 0,
      },
      'post',
    ).then(
      (response: any[]) => {
        if (!response.map) {
          throw new Error('Invalid value from API was received - not an array.');
        }
        let words = response.map(
          (w) => {
            return VocabularyTrainingWord.createFromObject(w);
          },
        );
        words.sort((a, b) => {
          return b.order - a.order;
        });
        return words;
      },
    );
  }

  public vocabulary(
    userId: number,
    token: Token,
    courseId: number,
    chapterIds: number[] = null,
    weekIds: number[] = null,
    levelFrom: LEVEL = null,
    levelTo: LEVEL = null,
    disabled: boolean = null,
    favorite: boolean = null,
    withOptions = false,
  ): Promise<VocabularyWord[]> {

    let data: any = {
      user: userId,
      token: token,
      course: courseId,
    };

    if (chapterIds && chapterIds.length) {
      data.chapter = JSON.stringify(chapterIds);
    }

    if (weekIds && weekIds.length) {
      data.week = JSON.stringify(weekIds);
    }

    if (levelFrom) {
      data.levelFrom = levelFrom;
    }

    if (levelTo) {
      data.levelTo = levelTo;
    }

    if (disabled === true || disabled === false) {
      data.disabled = disabled ? 1 : 0;
    }

    if (favorite === true || favorite === false) {
      data.favorite = favorite ? 1 : 0;
    }
    if (withOptions) {
      data.options = 1;
    }

    return this.call(
      'vocabulary',
      data,
      'post',
    )
      .then(
        (response: any[]) => {
          if (!response.map) {
            throw new Error('Invalid value from API was received - not an array.');
          }
          let words = response.map(
            (w) => {
              return VocabularyWord.createFromObject(w);
            },
          );
          return words;
        },
      )
      ;

  }

  public disableTrainingWord(userId: number, token: Token, word: VocabularyTrainingWord, disabled: boolean): Promise<void> {
    return this.call(
      'disable-training-word',
      {
        user: userId,
        token: token,
        word: word.id,
        disabled: disabled ? 1 : 0,
      },
      'post',
    ).then(
      (r) => {
        return;
      },
    );
  }

  public disableTrainingWords(userId: number, token: Token, wordsToDisable: VocabularyWord[], wordsToEnable: VocabularyWord[]): Promise<void> {

    let wordsObject: { [key: number]: number } = {};

    for (let w of wordsToDisable) {
      wordsObject[w.id] = 1;
    }
    for (let w of wordsToEnable) {
      wordsObject[w.id] = 0;
    }

    return this.call(
      'disable-training-words',
      {
        user: userId,
        token: token,
        words: JSON.stringify(wordsObject),
      },
      'post',
    ).then(
      () => {
        return;
      },
    );

  }

  public addFavoriteVocabularyWord(userId: number, token: Token, wordId: number, favorite = true): Promise<void> {
    return this.call(
      'add-favorite-word',
      {
        user: userId,
        token: token,
        word: wordId,
        favorite: favorite ? '1' : '0',
      },
      'POST',
    ).then(
      (r) => {
        return;
      },
    );
  }

  public addFavoriteVocabularyWords(userId: number, token: Token, wordsToFavorite: VocabularyWord[], wordsToUnfavorite: VocabularyWord[]): Promise<void> {

    let wordsObject: { [key: number]: number } = {};

    for (let w of wordsToFavorite) {
      wordsObject[w.id] = 1;
    }
    for (let w of wordsToUnfavorite) {
      wordsObject[w.id] = 0;
    }

    return this.call(
      'add-favorite-words',
      {
        user: userId,
        token: token,
        words: JSON.stringify(wordsObject),
      },
      'post',
    ).then(
      () => {
        return;
      },
    );

  }

  public visitLesson(lessonId: number, userId: number, token: Token): Promise<{ user: number, lastLessonBlock: number }> {
    return this.call(
      'visit-lesson',
      {
        lessonBlock: lessonId,
        user: userId,
        token: token,
      },
      'POST',
    ).then(
      (r) => {
        return {
          user: +r['user'],
          lastLessonBlock: +r['lastLessonBlock'],
        };
      },
    );
  }

  public myProfile(userId: number, token: Token): Promise<MyProfileResponse> {
    return this.call(
      'my-profile',
      {
        user: userId,
        token: token,
      },
      'POST',
    ).then(
      (r: MyProfileResponse) => {
        let courseList = []; // API sends keyed OBJECT
        Object.keys(r['courseList']).map((k) => {
          courseList.push(r['courseList'][k]);
        });
        r.courseList = courseList;
        return r as MyProfileResponse;
      },
    );
  }

  public leaderboardList(userId: number, token: Token, courseId: number = null, order = '', filter: string = null, limit: number = 100, offset: number = null): Promise<LeaderboardList> {

    let params: any = {
      user: userId,
      token: token,
    };

    if (courseId) {
      params.course = courseId;
    }

    if (order) {
      params.order = order;
    }

    if (filter) {
      params.filter = filter;
    }

    if (limit) {
      params.limit = limit;
    }

    if (offset) {
      params.offset = offset;
    }

    return this.call(
      'leaderboard-list',
      params,
      'post',
    ).then(
      (returned: any): LeaderboardList => {
        if (!returned || !returned.list || !returned.list.length) {
          return {
            list: [],
            position: null,
          };
        }
        return {
          list: returned.list.map((studentData) => createLeaderboardStudentFromObject(studentData)),
          position: returned.position ? createLeaderboardStudentFromObject(returned.position) : null,
        };
      },
    );

  }

  public userList(userId: number, token: Token, filter: string = null, limit: number = 100, offset: number = null): Promise<UserListResponse> {

    let params: any = {
      user: userId,
      token: token,
    };

    if (filter) {
      params.filter = filter;
    }

    if (limit) {
      params.limit = limit;
    }

    if (offset) {
      params.offset = offset;
    }

    return this.call(
      'user-list',
      params,
      'post',
    ).then(
      (returned: any) => {

        if (!returned || !returned.length) {
          return [];
        }
        return returned.map((item) => {
          item.id = +item.id || 0;
          item.points = +item.points || 0;
          item.eshopId = +item.eshopId || 0;
          item.lastLogin = item.lastLogin ? parseTime(item.lastLogin, true) : null;
          return item;
        });
      },
    );

  }

  public followList(userId: number, token: Token, language: string = '', order: ('week' | 'month' | '') = ''): Promise<FriendListResponse> {

    let params: any = {
      user: userId,
      token: token,
    };

    if (language) {
      params.filter = language;
    }

    if (order) {
      params.order = order;
    }

    return this.call(
      'follow-list',
      params,
      'post',
    ).then(
      (returned: any) => {
        if (!returned || !returned.list || !returned.list.length) {
          return [];
        }
        return returned.list.map((item) => {
          item.id = +item.id || 0;
          item.points = +item.points || 0;
          item.eshopId = +item.eshopId || 0;
          item.isFriend = !!item.isFriend;
          item.lastLogin = item.lastLogin ? parseTime(item.lastLogin, true) : null;
          return item;
        });
      },
    );

  }

  public addFriend(userId: number, token: Token, followedUserId: number): Promise<void> {
    return this.call(
      'add-friend',
      {
        user: userId,
        token: token,
        followedUser: followedUserId,
      },
      'post',
    ).then(() => {
      return;
    });
  }

  public removeFriend(userId: number, token: Token, followedUserId: number): Promise<void> {
    return this.call(
      'remove-friend',
      {
        user: userId,
        token: token,
        followedUser: followedUserId,
      },
      'post',
    ).then(() => {
      return;
    });
  }

  public publicProfile(userId: number, token: Token, targetUserId: number): Promise<PublicProfileResponse> {

    return this.call(
      'public-profile',
      {
        user: userId,
        token: token,
        publicUser: targetUserId,
      },
      'post',
    ).then((resp) => {
      if (typeof resp['isFirend'] !== 'undefined') {
        resp['isFriend'] = resp['isFirend'];
      }
      return resp as PublicProfileResponse;
    });

  }

  public userSearch(userId: number, token: Token, emails: string[]): Promise<UserSearchResponse> {

    return this.call(
      'user-search',
      {
        user: userId,
        token: token,
        emails: JSON.stringify(emails),
      },
      'post-form',
    ).then((resp) => {

      let result: UserSearchItem[] = resp as UserSearchItem[];

      result.map(
        (i) => {
          i.lastLogin = i.lastLogin ? parseTime(i.lastLogin) : null;
          i.isFriend = !!i.isFriend;
        },
      );

      return result;

    });

  }

  public setUserData(userId: number, token: Token, firstName: string, lastName: string): Promise<void> {

    return this.call(
      'set-user-data',
      {
        user: userId,
        token: token,
        firstName: firstName,
        lastName: lastName,
      },
      'post',
    ).then((resp) => {
      return;
    });

  }

  public syncUserData(userId: number, token: Token): Promise<void> {

    return this.call(
      'sync-user-data',
      {
        user: userId,
        token: token,
      },
      'post',
    ).then(r => {
    });

  }

  public settings(userId: number, token: Token): Promise<Settings> {
    return this.call(
      'settings',
      {
        user: userId,
        token: token,
      },
      'post',
    ).then(
      (s) => {
        // fix a typo in API
        if (s['showInLeadeboard'] !== undefined && s['showInLeaderboard'] === undefined) {
          s['showInLeaderboard'] = s['showInLeadeboard'];
        }
        return s;
      }
    );
  }

  public setSettings(userId: number, token: Token, settings: Settings): Promise<void> {
    let params = Object.assign(
      {},
      settings,
      {
        user: userId,
        token: token,
      },
    );
    return this.call(
      'set-settings',
      params,
      'post',
    ).then(
      (r) => {
      },
    );
  }

  public courseTolerance(userId: number, token: Token, courseId: number = null): Promise<ToleranceSettings> {
    let params = {
      token: token,
      user: userId,
    };
    if (courseId) {
      params['course'] = courseId;
    }

    return this.call(
      'course-tolerance',
      params,
      'post',
    ).then(
      (r) => (r as ToleranceSettings),
    );
  }

  public setCourseTolerance(userId: number, token: Token, settings: Partial<ToleranceSettings>, courseId: number = null): Promise<void> {
    let params = {
      token: token,
      user: userId,
    };
    Object.keys(settings).map(
      (key) => {
        params[key] = !!settings[key];
      },
    );
    if (courseId) {
      params['course'] = courseId;
    }
    return this.call(
      'set-course-tolerance',
      params,
      'post',
    ).then((anything) => {
      return;
    });

  }


  addChapterMessage(userId: number, token: Token, chapterId: number, message: string, owner = '', appRateUrl = ''): Promise<void> {

    let params = {
      token: token,
      user: userId,
      chapter: chapterId,
      message: message,
    };
    if (owner) {
      params['owner'] = owner;
    }
    if (appRateUrl) {
      params['appRateUrl'] = appRateUrl;
    }
    return this.call(
      'add-chapter-message',
      params,
      'post',
    ).then((anything) => {
      return;
    });

  }

  chapterMessage(userId: number, token: Token, chapterId: number): Promise<string> {

    return this.call(
      'chapter-message',
      {
        user: userId,
        token: token,
        chapter: chapterId,
      },
      'post',
    ).then((a: any) => {
      if (a.message) {
        return a.message;
      }
      return '';
    });


  }

  lastLessonBlock(userId: number, token: Token, courseId: number): Promise<number> {
    return this.call(
      'last-lesson-block',
      {
        user: userId,
        token,
        course: courseId,
      },
      'post',
    ).then(
      (data: any) => {
        if (data && data.lastLessonBlock) {
          return +data.lastLessonBlock;
        }
        return null;
      },
    );
  }

  getGenerateCertificateUrl(userId: number, token: Token, courseId: number, firstName: string, lastName: string, dateOfBirth: string): Promise<string> {

    let params = {
      token,
      user: userId,
      course: courseId,
      firstName,
      lastName,
      dateOfBirth,
    };

    return this.call(
      'certificate-generate',
      params,
      'post',
    ).then((data: any) => {
      if (data && data.url) {
        return data.url;
      }
      return null;
    });

  }

  protected generateVocabularyPdfPrepareParameters(
    userId: number,
    token: Token,
    courseId: number,
    chapterIds: number[] = null,
    weekIds: number[] = null,
    levelFrom: LEVEL = null,
    levelTo: LEVEL = null,
    disabled: boolean = null,
    favorite: boolean = null,
    withOptions = false,
    cards: VocabularyGenerateTypesEnum = VocabularyGenerateTypesEnum.list,
    ids: number[] = null,
  ): any {
    const data: any = {
      user: userId,
      token,
      course: courseId,
      cards,
    };

    if (chapterIds && chapterIds.length) {
      data.chapter = JSON.stringify(chapterIds);
    }

    if (weekIds && weekIds.length) {
      data.week = JSON.stringify(weekIds);
    }

    if (levelFrom) {
      data.levelFrom = levelFrom;
    }

    if (levelTo) {
      data.levelTo = levelTo;
    }

    if (disabled === true || disabled === false) {
      data.disabled = disabled ? 1 : 0;
    }

    if (favorite === true || favorite === false) {
      data.favorite = favorite ? 1 : 0;
    }
    if (withOptions) {
      data.options = 1;
    }
    if (ids && Array.isArray(ids) && ids.length) {
      data.ids = JSON.stringify(ids);
    }

    return data;
  }

  public generateVocabularyPdfUrl(
    userId: number,
    token: Token,
    courseId: number,
    chapterIds: number[] = null,
    weekIds: number[] = null,
    levelFrom: LEVEL = null,
    levelTo: LEVEL = null,
    disabled: boolean = null,
    favorite: boolean = null,
    withOptions = false,
    cards: VocabularyGenerateTypesEnum = VocabularyGenerateTypesEnum.list,
    httpMethod: 'post' | 'get' = 'post',
    ids: number[] = null,
  ): string {

    let data = this.generateVocabularyPdfPrepareParameters(
      userId,
      token,
      courseId,
      chapterIds,
      weekIds,
      levelFrom,
      levelTo,
      disabled,
      favorite,
      withOptions,
      cards,
      ids,
    );

    if (httpMethod === 'get') {
      let url = this.axios.defaults.baseURL + '/vocabulary-generate';
      for (let key of Object.keys(data)) {
        url += '/' + encodeURIComponent(key) + '/' + encodeURIComponent(data[key]);
      }
      return url;
    } else {
      let url = new URL(this.axios.defaults.baseURL + '/vocabulary-generate');
      Object.keys(data).forEach(
        (key) => {
          url.searchParams.append(key, data[key]);
        },
      );
      return url.toString();
    }

  }

  public generateVocabularyPdf(
    userId: number,
    token: Token,
    courseId: number,
    chapterIds: number[] = null,
    weekIds: number[] = null,
    levelFrom: LEVEL = null,
    levelTo: LEVEL = null,
    disabled: boolean = null,
    favorite: boolean = null,
    withOptions = false,
    cards: VocabularyGenerateTypesEnum = VocabularyGenerateTypesEnum.list,
    ids: number[] = null,
  ): Promise<Blob> {

    let data = this.generateVocabularyPdfPrepareParameters(
      userId,
      token,
      courseId,
      chapterIds,
      weekIds,
      levelFrom,
      levelTo,
      disabled,
      favorite,
      withOptions,
      cards,
      ids,
    );

    return this.call(
      'vocabulary-generate',
      data,
      'post',
      {
        allowNonStandardResponse: true,
        responseType: 'blob',
      },
    )
      .then(
        (response: any) => {
          return new Blob([response], {type: 'application/pdf'});
        },
      );

  }

  public endOfTrialClose(courseId: number, userId: number, token: Token, brand?: 'oj' | 'el'): Promise<void> {
    return this.call(
      'end-of-trial-close',
      {
        user: userId,
        course: courseId,
        token: token,
        source: brand,
      },
      'post',
    ).then(() => {
      return;
    });
  }

  public async uploadFile(userId: number, lessonBlockId: number, token: Token, file: Blob): Promise<string> {
    let data = new FormData();
    data.append('user', userId + '');
    data.append('lessonBlock', lessonBlockId + '');
    data.append('token', token);
    data.append('file', file);

    let response = await this.axios.post(
      '/upload-file',
      data,
      {
        method: 'post'
      }
    );

    if (response && response.data && response.data.data && response.data.data.path) {
      return response.data.data.path;
    }

    throw new Error('Invalid response from server received');

  }

  public showUserInLeaderboard(userId: number, token: Token, enabled: boolean = true): Promise<void> {
    return this.call(
      'show-user-in-leaderboard',
      {
        user: userId,
        token,
        enable: enabled ? 1 : 0,
      },
      'post'
    ).then(() => { return; } );
  }

  public call(methodUrl: string, params = {}, httpMethod, options: {responseType?: string, allowNonStandardResponse?: boolean, timeout?: number} = {}): Promise<object> {

    if (httpMethod === 'get') {
      for (let key of Object.keys(params)) {
        methodUrl += '/' + encodeURIComponent(key) + '/' + encodeURIComponent(params[key]);
      }

      params = {};
    }

    let data = '';

    if (httpMethod === 'post-form') {
      data = qs.stringify(params);
      httpMethod = 'post';
      params = null;
    }

    const axiosConfig = {
      method: httpMethod,
      url: methodUrl,
      params,
      data,
    };

    if (options && options.responseType) {
      axiosConfig['responseType'] = options.responseType;
    }

    if (options && options.timeout) {
      axiosConfig['timeout'] = options.timeout;
    }

    return this.axios.request(
      axiosConfig,
    )
      .then(
        (response: AxiosResponse) => {
          if (response.status === 200 && response.data && response.data.success) {
            return Promise.resolve(response.data.data);
          }
          if (options.allowNonStandardResponse && response.status === 200) {
            return Promise.resolve(response.data);
          }
          if (response.data && !response.data.success && (response.data.errorCode || response.data.errorText)) {

            let error = new StudovnaApiError(response.data.errorText, response.data.errorCode, this.appConfig.debuggingMessages).toString();
            if (this.errorHandler && this.errorHandler['addApiError']) {
              this.errorHandler['addApiError'](error, {
                'method': httpMethod,
                'url': methodUrl,
                'params': params,
                'whichApi': 'studovna',
                'response': {
                  'errorCode': response.data.errorCode,
                  'errorText': response.data.errorText,
                },
              });
            }
            return Promise.reject(
              {
                errorCode: response.data.errorCode,
                errorText: response.data.errorText,
              },
            );
          }

          let responseStub = response.data;
          if (typeof responseStub === 'string') {
            if (responseStub.length > 5000) {
              responseStub = responseStub.substr(0, 5000) + '...';
            }
          }
          if (this.errorHandler && this.errorHandler['addApiError']) {
            this.errorHandler['addApiError'](
              new Error('Invalid response from API received.'),
              {
                'method': httpMethod,
                'url': methodUrl,
                'params': params,
                'whichApi': 'studovna',
                'response': responseStub,
              },
            );
          }

          return Promise.reject('Invalid response from API received.');
        },

        (error) => {
          if (this.errorHandler && this.errorHandler['addApiError']) {
            this.errorHandler['addApiError'](
              error,
              {
                'method': httpMethod,
                'url': methodUrl,
                'params': params,
                'whichApi': 'studovna',
              },
            );
          }
          throw error;
        },
      )
      ;
  }

}
