import { Injectable } from '@angular/core';
import {RubyProductsCalculator} from '../../../../../../../oj-app-common/shop/tools/ruby-products-calculator';
import {CurrentUserService} from '../../../../../auth/current-user.service';
import {EshopApi} from '../../../../../../../oj-app-common/eshop-api/eshop-api';
import {EshopUserPreferencesService} from './eshop-user-preferences.service';
import {RubyService} from '../../../../../../../oj-app-common/ruby/ruby.service';

@Injectable()
export class RubyCalculatorService {

  protected calculator: RubyProductsCalculator;

  protected pending: Promise<void> = null;

  constructor(
    protected rubyService: RubyService,
    protected eshopApi: EshopApi,
    protected currentUser: CurrentUserService,
    protected eshopPrefs: EshopUserPreferencesService,
  ) {

  }

  public invalidate() {
    this.calculator = null;
  }

  async getCalculator(forceReload = false): Promise<RubyProductsCalculator> {
    if (this.calculator && !forceReload) {
      return this.calculator;
    }
    if (this.pending) {
      await this.pending;
      return this.calculator;
    }

    this.pending = new Promise<void>(
      async (resolve, reject) => {
        const [rubyProducts, rubyCategories, eshopData] = await Promise.all(
          [
            this.rubyService.products(),
            this.rubyService.courseCategories(),
            this.eshopApi.getAvailableDemos(
              this.currentUser.eshopId,
              this.currentUser.token,
              true,
            ),
          ]
        );

        this.calculator = new RubyProductsCalculator(
          rubyProducts,
          rubyCategories,
          eshopData,
        );

        this.pending = null;
        resolve();
        return;
      }
    );

    return this.pending.then(
      () => {
        return this.calculator;
      }
    );

  }

  public updateFavoriteStatusOfCourse(idCourse: number, isFavorite: boolean) {
    if (this.calculator) {
      this.calculator.updateFavoriteStatusOfCourse(idCourse, isFavorite);
    }
  }

}
