import { MethodDescription } from '../method-description';

export const GetCoursesMethodDescription = new MethodDescription();

GetCoursesMethodDescription.name = 'getCourses';
GetCoursesMethodDescription.fields = [
	'id',
	'email',
];
GetCoursesMethodDescription.usesToken = true;
