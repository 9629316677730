import {NgModule} from '@angular/core';
import {LeaderboardUserItemComponent} from './leaderboard-user-item.component';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    LeaderboardUserItemComponent
  ],
    imports: [
        CommonModule,
        MatButtonModule,
        TranslateModule
    ],
  exports: [
    LeaderboardUserItemComponent
  ]
})

export class LeaderboardUserItemModule {}
