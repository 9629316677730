import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CourseCombinedModel} from '../../../../../../../oj-app-common/shop/models/shop/course-combined.model';
import {ShoppableType} from '../../../../../../../oj-app-common/shop/types/shoppable-type';
import {ProductCombinedModel} from '../../../../../../../oj-app-common/shop/models/shop/product-combined.model';
import {CourseShopOptionsModel} from '../../../../../../../oj-app-common/shop/models/course-shop-options.model';
import {AppConfig} from '../../../../../config/app-config';
import {AppTranslationService} from '../../../../../services/app-translation.service';
import {CurrentUserService} from '../../../../../auth/current-user.service';
import {PurchaseService} from '../../../../../pages/base-layout-page/pages/eshop-page/services/purchase.service';
import {Router} from '@angular/router';
import {PaymentDialogService} from '../../../payment-dialog.service';
import {AnyCurrency} from '../../../../../../../oj-app-common/shop/types/any-currency';
import {RubyService} from "../../../../../../../oj-app-common/ruby/ruby.service";
import {RubyCalculatorService} from "../../../../../pages/base-layout-page/pages/eshop-page/services/ruby-calculator-service";
import {RubyProductsCalculator} from "../../../../../../../oj-app-common/shop/tools/ruby-products-calculator";
import {EshopUserPreferencesService} from '../../../../../pages/base-layout-page/pages/eshop-page/services/eshop-user-preferences.service';

@Component({
  selector: 'app-payment-plans-content',
  templateUrl: './payment-plans-content.component.html',
  styleUrls: ['./payment-plans-content.component.scss']
})
export class PaymentPlansContentComponent implements OnInit {
  isOnlyEshop = false;

  annualPayment = false;

  familyPayment = false;

  PAYMENT_PLANS = {
    regular: 'regular',
    pro: 'pro'
  }
  selectedPlan;

  allCurrencies: AnyCurrency[] = [];

  selectedCurrency: AnyCurrency;

  public subscriptionInterval: ShoppableType = 'yearly';

  rubyCalculator: RubyProductsCalculator;

  @Input()
  currentSelectedSubscription: {product: ProductCombinedModel, currency: AnyCurrency};

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Input() course: CourseCombinedModel;

  @Input() product: ProductCombinedModel;

  @Input() directlyProduct = false;

  @Input() shopOptions: CourseShopOptionsModel;

  @Input() displayedInPackagePage = false;

  constructor(
    protected appConfig: AppConfig,
    protected appTranslationService: AppTranslationService,
    protected currentUserService: CurrentUserService,
    protected purchaseService: PurchaseService,
    protected router: Router,
    protected paymentDialog: PaymentDialogService,
    protected rubyService: RubyCalculatorService,
    protected eshopPreferencesService: EshopUserPreferencesService
  ) { }

  ngOnInit() {
    this.loadRubyCalculator();

    this.selectedPlan = this.PAYMENT_PLANS.regular;

    if (this.currentSelectedSubscription.currency) {
      this.selectedCurrency = this.currentSelectedSubscription.currency;
    }

    if (this.shopOptions && this.shopOptions.subscriptions && this.shopOptions.subscriptions.length > 0) {
      // this.displayedSubscription = this.shopOptions.subscriptions[0];
    }

    // if (!this.currentSelectedSubscription && this.shopOptions.subscriptions.length === 1) {
    //   this.currentSelectedSubscription = {
    //     product: this.shopOptions.subscriptions[0].product,
    //     currency: this.shopOptions.subscriptions[0].currency
    //   }
    // }

    if (!this.currentSelectedSubscription) {
      return;
    }

    if (this.router.url.indexOf('/trialPicker') !== -1 ) {
      this.isOnlyEshop = true;
    }
  }

  async loadRubyCalculator() {
    this.rubyCalculator = await this.rubyService.getCalculator();

    if (this.rubyCalculator) {
      this.allCurrencies = this.rubyCalculator.getAvailableCurrenciesForCourse(this.course);
    }
  }

  public changeCurrency(currency: AnyCurrency) {
    if (this.selectedCurrency !== currency) {
      this.selectedCurrency = currency;
      this.prepareShopOptions();
      this.eshopPreferencesService.setCurrency(currency);
    }
  }

  public prepareShopOptions() {
    this.shopOptions = null;
    const shopOptions = this.rubyCalculator.getShopOptionsOfCourse(this.course.id_study, this.selectedCurrency);
    if (shopOptions.subscriptions.length || shopOptions.oneTimePurchases.length) {
      this.shopOptions = shopOptions;
      this.allCurrencies = this.rubyCalculator.getAvailableCurrenciesForCourse(this.course);
    } else {
      const currencies = this.rubyCalculator.getAvailableCurrenciesForCourse(this.course);
      if (currencies.length) {
        const fallbackCurrency = currencies[0];
        this.shopOptions = this.rubyCalculator.getShopOptionsOfCourse(this.course.id_study, fallbackCurrency);
        this.selectedCurrency = fallbackCurrency;
      } else {
        this.shopOptions = shopOptions;
      }
    }

  }

  close() {
    this.onClose.emit(true);
  }

  changeSubscriptionInterval(interval: ShoppableType) {
    this.subscriptionInterval = interval;
    // this.planChanged();
  }

  changeFamilyPlan() {
    this.familyPayment = !this.familyPayment;
  }

  subscribeClicked(e: Event) {
    e.preventDefault();

    let route = '/eshop';

    if (this.isOnlyEshop) {
      route = '/trialPicker'
    }

    let productId;
    if (this.product && this.directlyProduct) {
      productId = this.product.id;
    } else {
      productId = this.currentSelectedSubscription.product.id;
    }

    if (!productId) {
      return;
    }

    if (this.directlyProduct) {
      this.paymentDialog.buyProductInWooEshop(route, productId, this.subscriptionInterval, this.selectedCurrency);
    } else {
      this.paymentDialog.buyProductInWooEshop(route, productId, this.subscriptionInterval, this.selectedCurrency);
    }
  }

}
