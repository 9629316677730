import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-three-figures',
  templateUrl: './three-figures.component.html',
  styleUrls: ['./three-figures.component.scss']
})
export class ThreeFiguresComponent implements OnInit {

  @Input()
  config: ThreeFiguresConfig;

  @Input()
  value1: string;

  @Input()
  value2: string;

  @Input()
  value3: string;

  @Input()
  withBorder = true;

  @Input()
  forceSigns = false;

  @Input()
  color = 'white';

  constructor() { }

  ngOnInit() {
  }

}

export class ThreeFiguresConfig {
  name1: string;
  name2: string;
  name3: string;

  icon1: string;
  icon2: string;
  icon3: string;
}
