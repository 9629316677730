import {NgModule} from "@angular/core";
import {BannerComponent} from "./banner.component";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";

@NgModule({
  imports: [
    TranslateModule,
    CommonModule
  ],
  exports: [
    BannerComponent
  ],
  declarations: [
    BannerComponent
  ]
})

export class BannerModule {}
