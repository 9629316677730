import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductModel} from '../../../../../../../oj-app-common/shop/models/shop/product.model';

@Component({
  selector: 'app-plans-periods-content',
  templateUrl: './plans-periods-content.component.html',
  styleUrls: ['./plans-periods-content.component.scss']
})
export class PlansPeriodsContentComponent implements OnInit {

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Input() product: ProductModel;

  constructor() { }

  ngOnInit() {
  }

  close() {
    this.onClose.emit(true);
  }
}
