import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidebarModule} from '../../components/sidebar/sidebar.module';
import {RouterModule} from '@angular/router';
import {MainLayoutComponent} from './main-layout.component';

@NgModule({
  declarations: [
    MainLayoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SidebarModule
  ],
  providers: [
  ],
  exports: [
    MainLayoutComponent
  ]
})
export class MainLayoutModule { }
