import { VisitedLessonResponse } from '../../studovna-api/responses/visited-lessons';

export class LessonStatus {

	id: number;
	visited: boolean;
	score: number;
	correctAnswers: number;
	totalAnswers: number;

	public static createFromApi(data: VisitedLessonResponse): LessonStatus {
		let status = new LessonStatus();
		status.id = +data.lbId;
		status.score = +data.result;
		status.correctAnswers = +data.correctAnswers;
		status.totalAnswers = +data.questionNumber;
		status.visited = true;

		if (!status.id) {
			throw new Error('Invalid data from API. There is no lbId.');
		}

		return status;
	}

}
