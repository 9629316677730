import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';

export const enum sidebarStates {
  opened = 'opened',
  closed = 'closed',
  partiallyClosed = 'partiallyClosed'
}

export const timing = 300;

export const sidebarAnimation = trigger('sidebarAnimation', [
  state(sidebarStates.opened, style({
    width: '430px',
    right: 0
  })),
  state(sidebarStates.closed, style({
    width: 0,
    right: 0
  })),
  state(sidebarStates.partiallyClosed, style({
    width: '70px'
  })),
  transition(`${sidebarStates.opened}=>${sidebarStates.closed}`, animate(`${timing}ms cubic-bezier(0.25, 0.46, 0.45, 0.94)`, keyframes([
    style({
      width: '430px',
      right: 0,
      offset: 0
    }),
    style({
      width: '0',
      right: 0,
      offset: 0.5
    }),
    style({
      width: '25px',
      right: 0,
      offset: 0.7
    }),
    style({
      width: 0,
      right: 0,
      offset: 1
    })
  ]))),
  transition(`${sidebarStates.closed}=>${sidebarStates.opened}`, animate(`${timing}ms cubic-bezier(0.25, 0.46, 0.45, 0.94)`, keyframes([
    style({
      width: 0,
      right: 0,
      offset: 0
    }),
    style({
      width: '430px',
      right: '30px',
      offset: 0.5
    }),
    style({
      right: '-10px',
      offset: 0.7
    }),
    style({
      width: '430px',
      right: 0,
      offset: 1
    })
  ]))),
  // transition(`${sidebarStates.opened}=>${sidebarStates.partiallyClosed}`, animate(`${timing}ms cubic-bezier(0.25, 0.46, 0.45, 0.94)`, keyframes([
  transition(`${sidebarStates.opened}=>${sidebarStates.partiallyClosed}`, animate(`${timing}ms`, keyframes([
    style({
      width: '430px',
      right: 0,
      offset: 0
    }),
    style({
      width: '5px',
      right: 0,
      offset: 0.5
    }),
    style({
      width: '80px',
      right: 0,
      offset: 0.7
    }),
    style({
      width: '70px',
      right: 0,
      offset: 1
    })
  ]))),
  transition(`${sidebarStates.partiallyClosed}=>${sidebarStates.opened}`, animate(`${timing}ms cubic-bezier(0.25, 0.46, 0.45, 0.94)`, keyframes([
    style({
      width: '70px',
      right: 0,
      offset: 0
    }),
    // style({
    //   width: '430px',
    //   right: '30px',
    //   offset: 0.5
    // }),
    // style({
    //   width: '430px',
    //   right: '-30px',
    //   offset: 0.8
    // }),
    style({
      width: '460px',
      right: 0,
      offset: 0.6
    }),
    style({
      width: '430px',
      right: 0,
      offset: 1
    })
  ])))
]);
