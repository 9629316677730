import { Injectable } from '@angular/core';
import {UserPreferencesService} from '../../../../oj-app-common/services/user-preferences.service';
import {StudovnaApi} from '../../../../oj-app-common/studovna-api/studovna-api';
import {CurrentUserService} from '../../auth/current-user.service';
import {EvaluationResult} from '../../../../oj-app-common/exercises/evaluation-result';

export const videoImages: {[key: number]: string[]} = {
  1: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
  2: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg'],
  3: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg', '9.jpg'],
  4: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg'],
  5: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
};

export function getImagePath(level: number, fileName: string): string {
  return 'assets/grades-static/' + level + '/' + fileName;
}

@Injectable({
  providedIn: 'root'
})

export class VideoService {

  protected PREF_VIDEO_ENABLED = 'resultVideosEnabled';

  constructor(
    protected userPrefs: UserPreferencesService,
    protected studovnaApi: StudovnaApi,
    protected currentUser: CurrentUserService
  ) { }

  public isVideoEnabled() {
    return this.userPrefs.get(this.PREF_VIDEO_ENABLED, true);
  }

  public enableVideo(enable = true, saveToBackend = true) {
    this.userPrefs.set(this.PREF_VIDEO_ENABLED, enable);
    if (saveToBackend) {
      this.studovnaApi.setSettings(
        this.currentUser.studovnaId,
        this.currentUser.token,
        {
          showVideo: enable
        }
      );
    }
  }

  public getImageForResult(result: EvaluationResult): string {
    let level = 0;

    if (result.score >= 99) {
      level = 5;
    } else if (result.score >= 80) {
      level = 4;
    } else if (result.score >= 60) {
      level = 3;
    } else if (result.score >= 30) {
      level = 2;
    } else {
      level = 1;
    }

    const possibleVideoImages = videoImages[level];

    const image = possibleVideoImages[Math.floor(Math.random() * possibleVideoImages.length)];

    return getImagePath(level, image);
  }
}
