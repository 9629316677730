import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CertificateUserModel} from '../certificate-user.model';

@Component({
  selector: 'app-certifate-data-modal-content',
  templateUrl: './certifate-data-modal-content.component.html',
  styleUrls: ['./certifate-data-modal-content.component.scss']
})
export class CertifateDataModalContentComponent implements OnInit {

  @Input() public user: CertificateUserModel = new CertificateUserModel();

  @Output() submitEvent: EventEmitter<CertificateUserModel> = new EventEmitter<CertificateUserModel>();

  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
  ) { }

  ngOnInit() {

  }

  close(e: Event) {
    e.preventDefault();
    this.closeEvent.emit(true);
  }

  onSubmit(e: Event) {
    e.preventDefault();
    this.submitEvent.emit(this.user);
  }
}
