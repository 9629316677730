import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-main-navigation-layout',
  templateUrl: './main-navigation-layout.component.html',
  styleUrls: ['./main-navigation-layout.component.scss']
})
export class MainNavigationLayoutComponent implements OnInit {
  navigationPanelWidth = 0;
  sidebarWidth = 0;
  mainContainerWidth = 0;

  constructor(
    protected router: Router
  ) { }

  ngOnInit() {
  }

  navigationChanged(button) {
    if (button) {
      this.router.navigate([button.href]);
    }
  }

  changeNavigationPanelWidth(width: number) {
    this.navigationPanelWidth = width;
    this.mainContainerWidth = window.innerWidth - (this.navigationPanelWidth + this.sidebarWidth);
  }

}
