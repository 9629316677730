import {
  Component,
  OnInit
} from '@angular/core';
import {BaseOverlayDialogComponent} from '../base-overlay-dialog/base-overlay-dialog';
import {TrialEndResult} from './trial-end-result';

@Component({
  selector: 'trial-end-dialog',
  templateUrl: 'trial-end-dialog.html',
  styleUrls: ['trial-end-dialog.scss']
})
export class TrialEndDialog extends BaseOverlayDialogComponent implements OnInit {

  courseName = '';

  courseImage = '';

  ngOnInit() {
  }

  initialize(courseName: string, courseImage: string) {
    this.courseName = courseName;
    this.courseImage = courseImage;
  }

  clickedGetCourseButton() {
    this.close(TrialEndResult.BUY_COURSE);
  }

  clickedNoThanks() {
    this.close(TrialEndResult.NO_THANKS);
  }

}
