import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

export enum ReportProblemType {
  ERROR = 'e',
  LECTURER = 'l',
}

@Injectable({
  providedIn: 'root'
})
export class ReportBugService {
  reportBug: Subject<{
    type: ReportProblemType,
    ignoreScreen: boolean,
    courseId: number,
    lessonId: number,
    wordId: number
  }> = new Subject<{ type: ReportProblemType, ignoreScreen: boolean, courseId: number, lessonId: number, wordId: number }>();
  onClose: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isShown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }
}
