import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BaseOverlayDialogComponent} from '../overlay-dialog/base-overlay-dialog/base-overlay-dialog';
import {VideoModel} from './video.model';
import {VideoDialogMenuOutput} from '../video-dialog-menu/video-dialog-menu-output';

@Component({
  selector: 'app-video-layer',
  templateUrl: './video-layer.component.html',
  styleUrls: ['./video-layer.component.scss'],
})
export class VideoLayerComponent extends BaseOverlayDialogComponent implements OnInit {

  public videoSrcUrl: string;

  @ViewChild('videoPlayer', {static: false, read: ElementRef})
  public videoPlayer: ElementRef;

  public videoIsPlaying = false;

  public videoLengthText = '';
  public videoPositionText = '';
  public videoProgressPercentage = 0;

  protected playedEnoughToMarkAsSeen = false;
  public playedEverything = false;

  public customButtonText = '';

  showingMenu = false;

  ngOnInit() {
  }

  public initialize(video: VideoModel, buttonText = '') {
    this.videoSrcUrl = video.urlXl2x;

    if (buttonText) {
      this.customButtonText = buttonText;
    }
  }

  clickedButton() {
    this.close(this.playedEnoughToMarkAsSeen || this.playedEverything);
  }

  public videoPlayStateChanged(playing: boolean) {
    this.videoIsPlaying = playing;
  }

  videoCanPlay() {
    this.updateTexts();
  }

  videoProgress() {
    this.updateTexts();
    if (this.videoPlayer.nativeElement.currentTime >= 5) {
      this.playedEnoughToMarkAsSeen = true;
    }
    if (this.videoPlayer.nativeElement.currentTime >= this.videoPlayer.nativeElement.duration - 1) {
      this.playedEverything = true;
    }
  }

  protected updateTexts() {
    if (this.videoPlayer.nativeElement) {
      this.videoLengthText = this.formatTime(this.videoPlayer.nativeElement.duration);
      this.videoPositionText = this.formatTime(this.videoPlayer.nativeElement.currentTime);
      if (this.videoPlayer.nativeElement.duration) {
        this.videoProgressPercentage = this.videoPlayer.nativeElement.currentTime / this.videoPlayer.nativeElement.duration;
      } else {
        this.videoProgressPercentage = 0;
      }
    }
  }

  clickedPlayButton() {
    if (this.videoIsPlaying) {
      this.videoPlayer.nativeElement.pause();
    } else {
      if (this.videoPlayer.nativeElement.currentTime >= this.videoPlayer.nativeElement.duration) {
        this.videoPlayer.nativeElement.currentTime = 0;
      }
      this.videoPlayer.nativeElement.play();
    }
  }

  clickedBackButton() {
    if (this.videoPlayer) {
      const time = this.videoPlayer.nativeElement.currentTime;
      if (time > 5) {
        this.videoPlayer.nativeElement.currentTime = time - 5;
      } else {
        this.videoPlayer.nativeElement.currentTime = 0;
      }
    }
  }

  clickedForwardButton() {
    if (this.videoPlayer) {
      const time = this.videoPlayer.nativeElement.currentTime;
      if (time < this.videoPlayer.nativeElement.duration - 5) {
        this.videoPlayer.nativeElement.currentTime = time + 5;
      } else {
        this.videoPlayer.nativeElement.currentTime = this.videoPlayer.nativeElement.duration;
      }
    }
  }

  tapProgressBar($event: number) {
    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.currentTime = $event * this.videoPlayer.nativeElement.duration;
      this.videoPlayer.nativeElement.play();
    }
  }

  protected formatTime(seconds: number): string {

    if (isNaN(seconds)) {
      return '';
    }

    if (seconds < 60) {
      return '0:' + this.padTimeNumber(Math.ceil(seconds));
    }

    if (seconds < 3600) {
      return Math.floor(seconds / 60) + ':' + this.padTimeNumber(Math.ceil(seconds % 60));
    }

    return Math.floor(seconds / 3600) + ':' + this.padTimeNumber(Math.floor((seconds / 3600) % 60)) + ':' + this.padTimeNumber(Math.ceil(seconds % 60));
  }

  protected padTimeNumber(n: number): string {
    if (n < 10) {
      return '0' + n;
    }
    return '' + n;
  }

  progressClicked(e: MouseEvent) {
    const elements = e.composedPath();
    if (!elements) {
      return;
    }
    // @ts-ignore
    const el = elements.filter(ele => ele.className === 'video-progress-container');
    if (!el || el.length === 0) {
      return;
    }
    // @ts-ignore
    const elWidth = el[0].offsetWidth;
    if (!elWidth || elWidth < 0) {
      return;
    }
    const x = e.offsetX;

    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.currentTime = (x / elWidth) * this.videoPlayer.nativeElement.duration;
      this.videoPlayer.nativeElement.play();
    }
  }

  showMenu() {
    this.videoPlayStateChanged(false);
    this.showingMenu = true;
  }

  closedMenu(result: VideoDialogMenuOutput) {
    this.showingMenu = false;
  }
}
