import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DashboardInfoResponse} from '../../../../../../oj-app-common/studovna-api/responses/dashboard-info';
import {CurrentUserService} from '../../../../auth/current-user.service';
import {StudovnaApi} from '../../../../../../oj-app-common/studovna-api/studovna-api';
import {_} from '../../../../../../oj-app-common/i18n/translate-placeholder';
import {CurrentCourseService} from '../../../../services/current-course-service';
import {BaseChartDirective} from 'ng2-charts';

const NUMBER_OF_DISPLAYED_MONTHS = 5;

@Component({
  selector: 'app-year-overview',
  templateUrl: './year-overview.component.html',
  styleUrls: ['./year-overview.component.scss']
})
export class YearOverviewComponent implements OnInit {
  months = [
    {
      number: 1,
      text: _('leden')
    },
    {
      number: 2,
      text: _('únor')
    },
    {
      number: 3,
      text: _('březen')
    },
    {
      number: 4,
      text: _('duben')
    },
    {
      number: 5,
      text: _('květen')
    },
    {
      number: 6,
      text: _('červen')
    },
    {
      number: 7,
      text: _('červenec')
    },
    {
      number: 8,
      text: _('srpen')
    },
    {
      number: 9,
      text: _('září')
    },
    {
      number: 10,
      text: _('říjen')
    },
    {
      number: 11,
      text: _('listopad')
    },
    {
      number: 12,
      text: _('prosinec')
    },
  ];
  public overviewChartData: {time: number[], success: number[]};
  public overviewChartLabels: string[];
  public overviewChartColors = [{borderColor: '#C2D588'}, {borderColor: '#005F8A'}];
  public overviewChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    animation: {
      duration: 1000
    },
    scales: {
      yAxes: [
        {
          display: false,
          type: 'linear',
          position: 'left',
          id: 'time',
          ticks: {
            maxTicksLimit: 2,
            min: -1,
            stepSize: 1
          }
        },
        {
          display: false,
          type: 'linear',
          position: 'right',
          ticks: {
            maxTicksLimit: 2,
            min: -4,
            max: 105,
            stepSize: 1
          },
          id: 'success',
        }
      ],
      xAxes: [
        {
          gridLines: {
            color: 'rgba(255, 255, 255, 0.2)',
            offsetGridLines: true,
            zeroLineWidth: 0,
          },
          ticks: {
            padding: 0,
            fontSize: 12,
            fontColor: 'rgba(255, 255, 255, 0.5)',
            stepSize: 1
          }
        }
      ]
    }
  };
  loaded = false;
  filter = {
    currentYear: '',
    years: []
  };

  @Input() dashboardInfo: DashboardInfoResponse;

  @ViewChild(BaseChartDirective, {static: false}) private _chart;
  constructor(
    protected currentUser: CurrentUserService,
    protected currentCourseService: CurrentCourseService,
    protected api: StudovnaApi
  ) { }

  ngOnInit() {
    this.prepareOverviewChartData()
      .then(() => this.loaded = true)
      .catch(e => {
        this.loaded = true;
        console.log(e);
      })

    this.currentCourseService
      .onSwitchCourse
      .subscribe(() => {
        this.prepareOverviewChartData();
      })
  }

  public async prepareOverviewChartData() {

    if (this.dashboardInfo) {
      this.filter.years = [];
      Object.keys(this.dashboardInfo.overall.overview)
        .forEach(year => {
          this.filter.years.push(year);
        });
      this.filter.currentYear = this.filter.years[this.filter.years.length - 1];
    }
    this.regenerateChartData();
  }

  regenerateChartData() {
    let thisMonth = (new Date()).getMonth() + 1;

    if (thisMonth > 12) {
      thisMonth = 1;
    }

    const firstMonth = thisMonth - NUMBER_OF_DISPLAYED_MONTHS;

    if (!this.dashboardInfo.overall || !this.dashboardInfo.overall.overview) {
      return;
    }

    this.overviewChartData = {
      time: [],
      success: [],
    };

    const sourceYear = this.dashboardInfo.overall.overview[this.filter.currentYear];

    if (!sourceYear) {
      return;
    }

    this.overviewChartLabels = [];

    if (firstMonth < 0) {
      const prevMonths = this.months.slice(this.months.length + firstMonth, this.months.length);
      prevMonths.forEach(m => {
        this.overviewChartLabels.push(m.text);
        this.overviewChartData.success.push(sourceYear[m.number].success);
        this.overviewChartData.time.push(sourceYear[m.number].time);
      });
      const nextMonths = this.months.slice(0, thisMonth);
      nextMonths.forEach(m => {
        this.overviewChartLabels.push(m.text);
        this.overviewChartData.success.push(sourceYear[m.number].success);
        this.overviewChartData.time.push(sourceYear[m.number].time);
      });
    } else {
      const slicedMonths = this.months.slice(firstMonth, thisMonth);
      slicedMonths.forEach(m => {
        this.overviewChartLabels.push(m.text);
        this.overviewChartData.success.push(sourceYear[m.number].success);
        this.overviewChartData.time.push(sourceYear[m.number].time);
      });
    }
  }

  onYearChange(year: number) {
    this.filter.currentYear = year.toString();
    this.regenerateChartData();
    this._chart.refresh();
  }


}
