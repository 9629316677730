import { MethodDescription } from '../method-description';

export const GetProductDataMethodDescription = new MethodDescription();

GetProductDataMethodDescription.name = 'getProductData';
GetProductDataMethodDescription.fields = [
	'id',
	'studentId',
];
GetProductDataMethodDescription.usesToken = true;
