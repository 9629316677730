import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardPageComponent} from './dashboard-page.component';
import {Routes, RouterModule} from '@angular/router';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatToolbarModule
} from '@angular/material';
import {MatTabsModule} from '@angular/material';
import {UserInfoModule} from '../../../../components/user-info/user-info.module';
import {SidebarModule} from '../../../../components/sidebar/sidebar.module';
import {ProgressBarModule} from 'angular-progress-bar';
import {TranslateModule} from '@ngx-translate/core';
import {ChartsModule} from 'ng2-charts';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {BasicTopBarModule} from '../../components/basic-top-bar/basic-top-bar.module';
import {ActivitySidebarModule} from '../../components/activity-sidebar/activity-sidebar.module';
import {CircleProgressbarModule} from '../../../../components/circle-progressbar/circle-progressbar.module';
import {SuperRadialMeterModule} from '../../components/super-radial-meter/super-radial-meter.module';
import {PluralPipeModule} from '../../../../pipes/plural-pipe.module';
import {ThreeFiguresModule} from '../../components/three-figures/three-figures.module';
import {DashboardSidebarContentComponent} from './dashboard-sidebar-content/dashboard-sidebar-content.component';
import {FriendsLeaderboardComponent} from './friends-leaderboard/friends-leaderboard.component';
import {YearOverviewModule} from '../../components/year-overview/year-overview.module';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import {LeaderboardUserItemModule} from '../../components/leaderboard-user-item/leaderboard-user-item.module';
import {ToastModule} from 'primeng/toast';
import {IntroTutorialModalModule} from '../../../../components/tutorial/intro-tutorial-modal/intro-tutorial-modal.module';
import {TooltipModule} from 'ng2-tooltip-directive';
import {WooService} from '../../../../../../oj-app-common/shop/woo.service';
import {SlideshowModule} from '../../../../components/slideshow/slideshow.module';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {BannerModule} from "../../../../components/banner/banner.module";

const routes: Routes = [
  {
    path: '', component: DashboardPageComponent, data: {pageTitle: 'Dashboard', animation: 'DashboardPage'}
  }
];

@NgModule({
  declarations: [
    DashboardPageComponent,
    DashboardSidebarContentComponent,
    FriendsLeaderboardComponent,
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        UserInfoModule,
        SidebarModule,
        MatSidenavModule,
        ProgressBarModule,
        MatRadioModule,
        TranslateModule,
        MatFormFieldModule,
        MatSelectModule,
        ChartsModule,
        NgCircleProgressModule,
        BasicTopBarModule,
        ActivitySidebarModule,
        CircleProgressbarModule,
        SuperRadialMeterModule,
        PluralPipeModule,
        ThreeFiguresModule,
        YearOverviewModule,
        DropdownModule,
        FormsModule,
        LeaderboardUserItemModule,
        BsDropdownModule,
        ToastModule,
        TooltipModule,
        IntroTutorialModalModule,
        SlideshowModule,
        BannerModule,
    ],
  exports: [],
  providers: [
    WooService
  ],
  entryComponents: [
    DashboardSidebarContentComponent
  ]
})
export class DashboardPageModule {
}
