export class CourseModel {
  id: number;
  id_eshop: number;
  id_study: number;
  id_podio: number;
  name: string;
  description: string;
  html_description: string;
  course_level: string;
  exam_level: string;
  language: string;
  taught_language: string;
  categories: number[];
}
