import {NgModule} from '@angular/core';
import {PluralPipe} from './plural.pipe';
import {IsValuePipe} from './is-value.pipe';
import {LetterPipe} from './letter.pipe';
import {IsInArrayPipe} from './is-in-array.pipe';
import {SanitizeHtmlPipe} from './sanitize-html.pipe';
import {SanitizeStylePipe} from './sanitize-style.pipe';
import {SanitizeUrlPipe} from './sanitize-url.pipe';
import {MediaFilePipe} from './media-file.pipe';
import { WhitespaceTruncatePipe } from './whitespace-truncate.pipe';
import { ImageByLangPipe } from './image-by-lang.pipe';
import { CharacterCountPipe } from './character-count.pipe';
import {PricePipe} from './price.pipe';
import { NumberLengthPipe } from './number-length.pipe';
import { FloorValuePipe } from './floor-value.pipe';
import { NumberToFixedPipe } from './number-to-fixed.pipe';
import { IsWordLongForTrainingPipe } from './is-word-long-for-training.pipe';

@NgModule({
  declarations: [
    PluralPipe,
    IsValuePipe,
    LetterPipe,
    IsInArrayPipe,
    SanitizeHtmlPipe,
    SanitizeStylePipe,
    SanitizeUrlPipe,
    MediaFilePipe,
    WhitespaceTruncatePipe,
    ImageByLangPipe,
    CharacterCountPipe,
    PricePipe,
    NumberLengthPipe,
    FloorValuePipe,
    NumberToFixedPipe,
    IsWordLongForTrainingPipe
  ],
  imports: [],
	exports: [
		PluralPipe,
		IsValuePipe,
		LetterPipe,
		IsInArrayPipe,
		SanitizeHtmlPipe,
		SanitizeStylePipe,
		SanitizeUrlPipe,
		MediaFilePipe,
		WhitespaceTruncatePipe,
		ImageByLangPipe,
		CharacterCountPipe,
		PricePipe,
		NumberLengthPipe,
		FloorValuePipe,
		NumberToFixedPipe,
		IsWordLongForTrainingPipe,
	],
  providers: [
    WhitespaceTruncatePipe
  ]
})
export class PluralPipeModule {
}
