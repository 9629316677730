import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseLayoutPageComponent} from './base-layout-page.component';
import {RouterModule} from '@angular/router';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatToolbarModule
} from '@angular/material';
import {MatTabsModule} from '@angular/material';
import {UserInfoModule} from '../../components/user-info/user-info.module';
import {SidebarModule} from '../../components/sidebar/sidebar.module';
import {ProgressBarModule} from 'angular-progress-bar';
import {TranslateModule} from '@ngx-translate/core';
import {ChartsModule} from 'ng2-charts';
import {ProfilePageModule} from './pages/profile-page/profile-page.module';
import {PluralPipeModule} from '../../pipes/plural-pipe.module';
import {CircleProgressbarModule} from '../../components/circle-progressbar/circle-progressbar.module';
import {DashboardPageModule} from './pages/dashboard-page/dashboard-page.module';
import {ActivitySidebarModule} from './components/activity-sidebar/activity-sidebar.module';
import {BasicTopBarModule} from './components/basic-top-bar/basic-top-bar.module';

@NgModule({
  declarations: [
    BaseLayoutPageComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    UserInfoModule,
    SidebarModule,
    MatSidenavModule,
    ProgressBarModule,
    MatRadioModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    ChartsModule,
    ProfilePageModule,
    DashboardPageModule,
    PluralPipeModule,
    CircleProgressbarModule,
    RouterModule,
    ActivitySidebarModule,
    BasicTopBarModule,
  ],
  exports: [
  ],
  providers: [],
  entryComponents: [

  ]
})
export class BaseLayoutPageModule {
}
