import { Pipe, PipeTransform } from '@angular/core';
import {AppTranslationService} from '../services/app-translation.service';

@Pipe({
  name: 'imageByLang'
})
export class ImageByLangPipe implements PipeTransform {

  constructor(
    protected translationService: AppTranslationService
  ) {
  }

  transform(imageSrc: string): any {
    const currLang = this.translationService.currentLanguage;
    if (currLang) {
      return `${imageSrc}-${currLang}`;
    }
    return `${imageSrc}-en`;
  }

}
