import {NgModule} from '@angular/core';
import {MouseInteractionDirective} from './mouse-interaction.directive';
import {SizeDirective} from './size.directive';
import {LongPressDirective} from './long-press.directive';
import { CdkDropListActualContainerDirective } from './cdk-drop-list-actual-container.directive';

@NgModule({
  declarations: [
    MouseInteractionDirective,
    SizeDirective,
    LongPressDirective,
    CdkDropListActualContainerDirective,
  ],
  imports: [
  ],
  providers: [
    SizeDirective
  ],
    exports: [
        MouseInteractionDirective,
        SizeDirective,
        LongPressDirective,
        CdkDropListActualContainerDirective
    ]
})
export class DirectivesModule { }
