export enum LEVEL {
	L0 = 'L0',
	L1 = 'L1',
	L2 = 'L2',
	L3 = 'L3',
	L4 = 'L4',
	L5 = 'L5',
}

export function levelToNumber(level: LEVEL): number {
	switch (level) {
		case LEVEL.L0: return 0;
		case LEVEL.L1: return 1;
		case LEVEL.L2: return 2;
		case LEVEL.L3: return 3;
		case LEVEL.L4: return 4;
		case LEVEL.L5: return 5;
	}
	return 0;
}

export function levelFromNumber(number): LEVEL {
	switch (number) {
		case 0: return LEVEL.L0;
		case 1: return LEVEL.L1;
		case 2: return LEVEL.L2;
		case 3: return LEVEL.L3;
		case 4: return LEVEL.L4;
		case 5: return LEVEL.L5;
	}
	return LEVEL.L0;
}

export function levelFromString(input: string): LEVEL {
	switch (input) {
		case 'L0': return LEVEL.L0;
		case 'L1': return LEVEL.L1;
		case 'L2': return LEVEL.L2;
		case 'L3': return LEVEL.L3;
		case 'L4': return LEVEL.L4;
		case 'L5': return LEVEL.L5;
	}
	return LEVEL.L0;
}
