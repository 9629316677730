import {Component, OnInit} from '@angular/core';
import {AppConfig} from '../../config/app-config';
import {AppTranslationService} from '../../services/app-translation.service';
import {isoCode2languageType, nameForLanguageType, string2languageType} from '../../../../oj-app-common/misc/language-types.enum';

interface Language {
  code: string;
  name: string;
  icon: string;
}

@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss']
})
export class LangSwitcherComponent implements OnInit {
  public availableLanguages: Language[] = [];

  public currentLanguage: Language = null;

  public expanded = false;

  constructor(
    protected appConfig: AppConfig,
    protected appTranslateService: AppTranslationService,
  ) {

    this.availableLanguages = this.appConfig.availableLanguages.map(
      (langCode) => ({
        code: langCode,
        name: nameForLanguageType(isoCode2languageType(langCode)),
        icon: 'assets/img/course-languages/no-margin/' + langCode + '.png',
      })
    );

    this.currentLanguage = this.availableLanguages.find((l) => l.code === this.appTranslateService.currentLanguage);
    if (!this.currentLanguage) {
      this.currentLanguage = this.availableLanguages[0];
    }

    this.appTranslateService.changedLanguage.subscribe(
      (newLang) => {
        this.currentLanguage = this.availableLanguages.find((l) => l.code === newLang);
      }
    );

  }

  ngOnInit(): void {

  }

  clickedIcon() {
    this.expanded = !this.expanded;
  }

  async selectLanguage(lang: Language) {
    await this.appTranslateService.switchLanguage(lang.code);
    this.expanded = false;
  }

}
