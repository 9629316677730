import {Component, OnInit} from '@angular/core';
import {CurrentCourseService} from '../../../../../services/current-course-service';
import {AvailableCourse} from '../../../../../models/available-course';
import {CurrentUserService} from '../../../../../auth/current-user.service';
import {StudovnaApi} from '../../../../../../../oj-app-common/studovna-api/studovna-api';
import {clone} from 'lodash';
import {EshopApi} from '../../../../../../../oj-app-common/eshop-api/eshop-api';
import {MyProfileResponse} from '../../../../../../../oj-app-common/studovna-api/responses/my-profile';
import {CurrentStatusResponse} from '../../../../../../../oj-app-common/studovna-api/responses/current-status';
import {
  getFormattedPercentage,
  getFormattedTimeWithoutSeconds
} from '../../../../../misc/format-functions';
import {ThreeFiguresConfig} from '../../../components/three-figures/three-figures.component';
import {_} from '../../../../../../../oj-app-common/i18n/translate-placeholder';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {DashboardInfoResponse} from '../../../../../../../oj-app-common/studovna-api/responses/dashboard-info';
import {Course} from '../../../../../../../oj-app-common/course-structure/models/course';

@Component({
  selector: 'app-dashboard-sidebar-content',
  templateUrl: './dashboard-sidebar-content.component.html',
  styleUrls: ['./dashboard-sidebar-content.component.scss']
})
export class DashboardSidebarContentComponent implements OnInit {
  currentCourse: AvailableCourse = new AvailableCourse();
  public allCourses: AvailableCourse[] = [];
  public recentCourses: AvailableCourse[] = [];
  public profileDetails: MyProfileResponse;
  public data: DashboardInfoResponse;
  userLanguages = [];
  public renderedData = {
    formattedTimeOverall: '0',
    formattedPercentageOverall: '0'
  };
  loaded = false;
  public threeFiguresConfig: ThreeFiguresConfig = {
    name1: _('Body'),
    name2: _('Čas'),
    name3: _('Úspěšnost'),
    icon1: 'shield',
    icon2: 'time',
    icon3: 'success',
  };

  currentDisplayedCourse: AvailableCourse;

  constructor(
    protected currentCourseService: CurrentCourseService,
    protected currentUserService: CurrentUserService,
    protected studovnaApi: StudovnaApi,
    protected eshopApi: EshopApi,
    protected messageService: MessageService,
    protected router: Router
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.loadData();
    }, 200);

    this.currentCourseService
      .onSwitchCourse
      .subscribe(v => {
        this.recalculateCurrentCourse(v);
      })
  }

  recalculateCurrentCourse(course: Course) {
    if (this.allCourses && course) {
      this.currentDisplayedCourse = this.allCourses.filter(c => c.courseId === course.id)[0];
      // this.currentCourse = this.recentCourses.filter(c => c.courseId === course.id)[0];
    }

  }

  loadData() {
    this.initUserProfile()
      .then(() => {
        if (this.profileDetails) {
          this.userLanguages = [];
          this.userLanguages.push(this.profileDetails.activeLanguage);
          // this.userLanguages = this.profileDetails.languageList.slice(0, 3);
        }
        return this.loadCourses();
      })
      .then((courses) => setTimeout(() => {
        this.loaded = true;
      }, 200))
      .catch(() => setTimeout(() => this.loaded = true, 200));
  }

  protected async initUserProfile() {
    this.profileDetails = await this.currentUserService.getMyProfileFromLocalStorage();
  }

  protected prepareRenderedData() {
    if (this.data && this.data.overall) {
      this.renderedData.formattedTimeOverall = getFormattedTimeWithoutSeconds(this.data.overall.time);
      this.renderedData.formattedPercentageOverall = getFormattedPercentage(this.data.overall.success);
    } else {
      this.renderedData.formattedTimeOverall = getFormattedTimeWithoutSeconds(this.currentCourse.time);
      this.renderedData.formattedPercentageOverall = getFormattedPercentage(this.currentCourse.success);
    }
  }


  async onActiveCourseChange(course: AvailableCourse) {
    if (course.expireDate && course.expireDate.getTime && course.expireDate.getTime() < (new Date()).getTime() - 86400 * 1000) {
      this.messageService.add({severity: 'error', summary: _('Kurz expiroval'), detail: _('Předplatné kurzu již vypršelo.')});
      return;
    }

    this.currentCourse = course;

    this.currentCourseService.setCurrentAvailableCourse(this.currentCourse);

    try {
      const activationResponse = await this.studovnaApi.courseActivation(
        this.currentUserService.studovnaId,
        course.courseId,
        this.currentUserService.token
      );
      await this.currentCourseService.activateCourse(course.courseId);

      let res: DashboardInfoResponse;
      try {
        res = await this.studovnaApi.dashboardInfo(
          this.currentUserService.studovnaId,
          this.currentUserService.token,
        );
      } catch (error) {
        // this.messageService.add({severity: 'error', detail: _('Nelze načíst data pro dashboard.')});
        console.log(error, 'Nelze načíst data pro dashboard.');
        return;
      }

      this.data = res;

      this.prepareRenderedData();
      let response: CurrentStatusResponse;
      response = await this.studovnaApi.currentStatus(this.currentUserService.studovnaId, this.currentUserService.token);
      this.currentUserService.updateActiveCourse(course.courseId);
      this.currentCourseService.setCurrentLesson(response.lastLessonBlock);
      this.initUserProfile();
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: _('Nelze aktivovat kurz.'),
        detail: _('Omlouváme se, nastala chyba při aktivaci kurzu.')
      });
      console.error(error);
    }
  }


  onShowMoreCoursesClick() {
    this.router.navigate(['/my-courses'], {queryParams: {currentTab: 1}});
  }

  public async loadCourses() {
    let res: DashboardInfoResponse;
    try {
      res = await this.studovnaApi.dashboardInfo(
        this.currentUserService.studovnaId,
        this.currentUserService.token,
      );
    } catch (error) {
      // this.messageService.add({severity: 'error', detail: _('Nelze načíst data pro dashboard.')});
      console.log(error, 'Nelze načíst data pro dashboard.');
      return;
    }

    if (!this.currentCourseService.courseId) {

      await this.currentCourseService.restoreDataFromDevCache();

    }

    this.data = res;

    this.allCourses = [];
    this.recentCourses = [];
    this.loaded = false;

    let responseEshop, responseStudovna;


    try {
      const dataFromStudovna = this.studovnaApi.courseList(
        this.currentUserService.studovnaId,
        this.currentUserService.token,
      );

      const dataFromEshop = this.eshopApi.getCourses(
        this.currentUserService.eshopId,
        this.currentUserService.token,
      );

      [responseEshop, responseStudovna] = await Promise.all([dataFromEshop, dataFromStudovna]);
    } catch (e) {
      this.messageService.add({
        severity: 'error',
        summary: _('Nelze stáhnout seznam kurzů.'),
        detail: _('Zkontrolujte prosím, zda je počítač připojen k internetu.')
      });
      this.router.navigate(['/login']);
      console.log('Nelze stáhnout seznam kurzů.', 'Zkontrolujte prosím, zda je telefon připojen k internetu.', e);
      return;
    }
    this.allCourses = [];

    let somethingIsMissing = false;

    for (const eshopData of responseEshop.courses) {
      if (!responseStudovna.courses[eshopData.courseId]) {
        somethingIsMissing = true;
      }
    }

    if (somethingIsMissing) {
      responseStudovna = await this.studovnaApi.courseList(
        this.currentUserService.studovnaId,
        this.currentUserService.token,
        true,
      );
    }

    for (const eshopData of responseEshop.courses) {
      if (responseStudovna.courses[eshopData.courseId]) {
        const c = AvailableCourse.createFromData(eshopData, responseStudovna.courses[eshopData.courseId]);
        this.allCourses.push(c);
      } else {
        somethingIsMissing = true;
      }
    }

    const coursesByLatestActivity: AvailableCourse[] = clone(this.allCourses);

    coursesByLatestActivity.sort(
      (c1, c2) => {

        if (this.currentCourseService.courseId) {
          if (this.currentCourseService.courseId === c2.courseId) {
            return 1;
          }
          if (this.currentCourseService.courseId === c1.courseId) {
            return -1;
          }
        }

        if (!this.currentCourseService.courseId && res.courseId) {
          if (res.courseId === c2.courseId) {
            return 1;
          }
          if (res.courseId === c1.courseId) {
            return -1;
          }
        }

        if (c1.lastActivity && c2.lastActivity) {
          return c2.lastActivity.getTime() - c1.lastActivity.getTime();
        }
        if (c2.lastActivity) {
          return 1;
        }
        if (c1.lastActivity) {
          return -1;
        }
        return 0;
      }
    );

    if (coursesByLatestActivity.length > 2) {
      this.recentCourses = [
        coursesByLatestActivity[0],
        coursesByLatestActivity[1],
        coursesByLatestActivity[2]
      ];
    } else {
      this.recentCourses = coursesByLatestActivity;
    }

    // this.currentCourse = this.recentCourses.filter(c => c.courseId === this.currentCourseService.course.id)[0];
    this.currentCourse = this.recentCourses.filter(c => c.courseId === res.courseId)[0];

    this.currentDisplayedCourse = this.currentCourse;

    this.currentCourseService.setCurrentAvailableCourse(this.currentCourse);

    this.prepareRenderedData();

    this.allCourses.sort(
      (c1, c2) => {
        return c2.priority - c1.priority;
      }
    );
  }

  showBorderForLanguage(language, userLanguages: any[]) {
    if (language && userLanguages) {
      const length = userLanguages.length; // 3
      const langIndex = userLanguages.findIndex(l => l.language === language.language); // 0,1,2
      if (length - langIndex > 1) {
        return true;
      }
      return false;
    }
  }
}
