import { ProductModel } from '../models/shop/product.model';
import { AvailableDemo } from '../../eshop-api/models/available-demo';
import { CourseModel } from '../models/shop/course.model';
import { CourseCombinedModel } from '../models/shop/course-combined.model';
import { ProductCombinedModel } from '../models/shop/product-combined.model';
import { CourseShopOptionsModel } from '../models/course-shop-options.model';
import { ShoppableProductFilter } from '../types/shoppable-product-filter';
import { LanguageProductFilter } from '../types/language-product-filter';
import { intersection, deburr } from 'lodash-es';
import { calculateLowestPrice } from '../methods/calculate-lowest-price';
import { CourseCategoryModel } from '../models/shop/course-category.model';
import { CourseLanguage } from '../types/course-language';
import { AnyCurrency } from '../types/any-currency';
import { CourseCategoryCombinedModel } from '../models/shop/course-category-combined.model';
import { CategoriesCourseFilter } from '../types/categories-course-filter';


/**
 * This tool can combine data from Ruby and OJ shop together and
 * lets you query the combined data for various results used in app's e-shop UI.
 *
 * Meant to be instanciated once and then passed between pages and services.
 * All data is cached and nothing is retrieved from backend.
 * All get- methods can be called in performant way.
 *
 * RubyProductsCalculator's data are not specific to any language or currency and can be used
 * after user switches language or currency without need to rebuild its data.
 *
 */
export class RubyProductsCalculator {

	protected products: ProductCombinedModel[];
	protected courses: CourseCombinedModel[];
	protected courseCategories: CourseCategoryCombinedModel[];

	protected productsById: { [id: number]: ProductCombinedModel };
	protected coursesById: { [id: number]: CourseCombinedModel };
	protected courseCategoriesById: { [id: number]: CourseCategoryCombinedModel };

	/**
	 * @param rubyProductsData Response from Ruby's "products" API (RubyService.products)
	 * @param rubyCategoriesData Response from Ruby's "course_categories" API (RubyService.courseCategories)
	 * @param ojShopAvailableDemoData Response from OJ shop's "getAvailableDemos" API call.
	 */
	constructor(
		protected rubyProductsData: ProductModel[],
		protected rubyCategoriesData: CourseCategoryModel[],
		protected ojShopAvailableDemoData: AvailableDemo[],
	) {
		this.crunchAllData();
	}


	protected crunchAllData() {

		this.products = [];
		this.courses = [];
		this.courseCategories = [];
		this.productsById = {};
		this.coursesById = {};
		this.courseCategoriesById = {};

		// First the easy part - categories

		this.courseCategories = this.rubyCategoriesData.map(
			(origCategory) => ({
				...origCategory,
				courses: [],
			}),
		);
		this.courseCategories.sort((c1, c2) => (c2.priority - c1.priority));
		this.courseCategories.forEach(
			(c) => {
				this.courseCategoriesById[c.id] = c;
			},
		);

		// Prepare trial courses data from OJ shop.
		// We use it to know which courses has the user already activated as a trial

		let trialsByCourseId: { [id: number]: AvailableDemo } = {};

		this.ojShopAvailableDemoData.forEach(
			(c) => {
				trialsByCourseId[c.courseId] = c;
			},
		);

		// Filter only shoppable products.
		// = products with at least one price in any currency and at least one available_XYZ flag
		this.products = this.rubyProductsData.filter(
			(p) => {
				if (
					!p.available_el_cs
					&& !p.available_el_en
					&& !p.available_el_es
					&& !p.available_el_sk
					&& !p.available_oj_eshop
					&& !p.available_oj_info
					&& !p.available_el_pl
				) {
					return false;
				}

				if (Object.keys(p.prices).length === 0) {
					return false;
				}

				let hasAnyPrice = Object.keys(p.prices).some(
					(currency) => {
						let pricesInCurrency = p.prices[currency];
						return !!(
							pricesInCurrency.oneTime
							|| pricesInCurrency.halfYearly
							|| pricesInCurrency.monthly
							|| pricesInCurrency.yearly
							|| pricesInCurrency.quarterYearly
						);
					},
				)
				if (!hasAnyPrice) {
					return false;
				}

				return true;
			},
		).map(
			// Create ProductCombinedModel - we add some calculated properties
			(p) => {
				let availableForOneTimePurchase = Object.keys(p.prices).some(
					(currency) => (!!p.prices[currency].oneTime),
				);
				let availableForSubscription = Object.keys(p.prices).some(
					(currency) => (
						!!(
							p.prices[currency].yearly
							|| p.prices[currency].monthly
							|| p.prices[currency].quarterYearly
							|| p.prices[currency].halfYearly
						)
					),
				);
				let productCombined: ProductCombinedModel = {
					...p,
					availableForOneTimePurchase,
					availableForSubscription,
				}
				return productCombined;
			},
		);

		// Sort products by priority
		this.products.sort((p1, p2) => (p2.priority - p1.priority));

		// Create products by ID map
		this.productsById = {};
		this.products.forEach((p) => {
			this.productsById[p.id] = p;
		});

		// Walk over all courses, add them to our array and to our coursesById map
		// Only purchasable courses are allowed. Trial-only courses are skipped.
		// Also create backreferences from courses to appropriate products
		this.rubyProductsData.forEach(
			(p) => {

				// Skip unpurchasable courses
				let theProduct = this.productsById[p.id];
				if (!theProduct) {
					return;
				}
				if (!theProduct.availableForSubscription && !theProduct.availableForOneTimePurchase) {
					return;
				}

				// Walk over and process all the courses
				p.courses.forEach(
					(course: CourseModel) => {

						// It is a course yet unknown
						if (!this.coursesById[course.id_study]) {

							// If it is triallable, we have it from OJ shop
							let dataFromEshop = trialsByCourseId[course.id_study];

							// It is also possible it is not triallable - that's why dataFromEshop must be always checked
							this.coursesById[course.id_study] = {
								...course,
								userHasAsTrial: dataFromEshop ? dataFromEshop.activeTrial : false,
								userHasAsFullCourse: dataFromEshop ? dataFromEshop.activeFull : false,
								priority: dataFromEshop ? dataFromEshop.priority : 0,
								availableTrial: !!dataFromEshop,
								availableForOneTimePurchase: false,
								availableForSubscription: false,
								productIds: [],
								imageUrl: dataFromEshop ? dataFromEshop.image : '',
								favorite: dataFromEshop ? dataFromEshop.favoriteCourse : false,
								starred: dataFromEshop ? dataFromEshop.starredCourse : false,
								expireDate: dataFromEshop ? dataFromEshop.expireDate : null,
							};

							if (!this.coursesById[course.id_study].imageUrl && p.image_url) {
								this.coursesById[course.id_study].imageUrl = p.image_url;
							}

						}

						let theCourse = this.coursesById[course.id_study];

						// Check if the wrapping product is shoppable and if so, mark the course also as shoppable
						if (theProduct) {
							theCourse.productIds.push(p.id);
							if (!theCourse.availableForOneTimePurchase && theProduct.availableForOneTimePurchase) {
								theCourse.availableForOneTimePurchase = true;
							}
							if (!theCourse.availableForSubscription && theProduct.availableForSubscription) {
								theCourse.availableForSubscription = true;
							}
						}

					},
				);
			},
		);

		// Finally, get the array of all sorted courses
		this.courses = Object.keys(this.coursesById).map((k) => this.coursesById[k]);
		this.courses.sort((c1, c2) => c2.priority - c1.priority);

		// And also, we need to sort courses in products-packages, because Ruby doesn't sort them by priority.
		this.products.forEach(
			(p) => {
				if (p.courses.length > 1) {
					p.courses.sort(
						(c1, c2) => {
							let cc1 = this.coursesById[c1.id_study];
							let cc2 = this.coursesById[c2.id_study];
							if (cc1 && cc2) {
								return cc2.priority - cc1.priority;
							}
							return 0;
						},
					)
				}
			},
		)


	}

	/**
	 * Get all courses, optionally filtered by language, category or shoppable state.
	 *
	 * Courses are sorted by priority.
	 *
	 * @param langFilter
	 * @param shoppableFilter
	 * @param categoriesFilter
	 */
	getCourses(
		langFilter: LanguageProductFilter = null,
		shoppableFilter: ShoppableProductFilter = null,
		categoriesFilter: CategoriesCourseFilter = null,
	): CourseCombinedModel[] {
		return this.filterCourses(this.courses, langFilter, shoppableFilter, categoriesFilter);
	}

	/**
	 * List all packages.
	 *
	 * Optionally, you can filter the list to contain only these packages
	 * that user can buy and get a new course from it (meaning that if a
	 * package contains only courses that the user already owns, then it
	 * will be omitted).
	 *
	 * @param onlyPurchaseable
	 * @param languageFilter
	 */
	getPackages(
		onlyPurchaseable: boolean = false,
		languageFilter: LanguageProductFilter = null,
	): ProductCombinedModel[] {
		let packages = this.products.filter((p) => (p.courses.length > 1));

		if (languageFilter) {
			packages = packages.filter(
				(product: ProductCombinedModel) => {
					return this.doesProductMatchLangFilter(product, languageFilter);
				},
			);
		}

		if (onlyPurchaseable) {
			packages = packages.filter(
				(product: ProductCombinedModel) => {
					return product.courses.some(
						(course: CourseModel) => {
							let combinedCourse = this.getCourseData(course.id_study);
							if (!combinedCourse) {
								return false;
							}
							if (combinedCourse.availableForSubscription || combinedCourse.availableForOneTimePurchase) {
								if (!combinedCourse.userHasAsFullCourse) {
									return true;
								}
							}
							return false;
						},
					)
				},
			);
		}

		return packages;
	}

	/**
	 * Returns data for courses in one package.
	 *
	 * Returns CourseCombinedModel with shop data, not only CourseModel
	 * that is already part of ProductModel.
	 *
	 * @param rubyProductId
	 */
	getCoursesInPackage(rubyProductId): CourseCombinedModel[] {
		let product = this.getProductData(rubyProductId);
		if (!product) {
			return null;
		}
		return product.courses.map(
			(c) => this.getCourseData(c.id_study),
		);
	}

	/**
	 * Filter list of courses by various filters
	 *
	 * @param courses
	 * @param langFilter
	 * @param shoppableFilter
	 * @param categoriesFilter
	 */
	filterCourses(
		courses: CourseCombinedModel[],
		langFilter: LanguageProductFilter = null,
		shoppableFilter: ShoppableProductFilter = null,
		categoriesFilter: CategoriesCourseFilter = null,
	): CourseCombinedModel[] {
		if (!shoppableFilter && !langFilter && !categoriesFilter) {
			return courses;
		}
		return courses.filter((c) => this.doesCourseMatchFilter(c, langFilter, shoppableFilter, categoriesFilter));
	}

	protected doesCourseMatchFilter(
		course: CourseCombinedModel,
		langFilter: LanguageProductFilter = null,
		shoppableFilter: ShoppableProductFilter = null,
		categoryFilter: CategoriesCourseFilter = null,
	): boolean {

		if (categoryFilter) {
			let intersection = categoryFilter.onlyTheseCategories.filter(
				(c) => (course.categories.indexOf(c.id) > -1),
			);
			if (intersection.length === 0) {
				return false;
			}
		}

		if (langFilter) {

			if (langFilter.studentLanguages && langFilter.studentLanguages.indexOf(course.language) === -1) {
				return false;
			}

			if (langFilter.taughtLanguages && langFilter.taughtLanguages.indexOf(course.taught_language) === -1) {
				return false;
			}
		}

		if (shoppableFilter) {
			if (shoppableFilter.noActiveFullCourses && course.userHasAsFullCourse) {
				return false;
			}

			if (shoppableFilter.noActiveTrials && course.userHasAsTrial) {
				return false;
			}

			if (shoppableFilter.onlyFavorites && !course.favorite) {
				return false;
			}

			if (shoppableFilter.onlyCoursesWithTrialAvailable && !course.availableTrial) {
				return false;
			}

			if (shoppableFilter.onlyPurchasableCourses
				&& !course.availableForSubscription
				&& !course.availableForOneTimePurchase
			) {
				return false;
			}

			if (shoppableFilter.onlyCoursesWithTrialOrPurchasable
				&& !course.availableTrial
				&& !course.availableForOneTimePurchase
				&& !course.availableForSubscription
			) {
				return false;
			}
		}

		return true;
	}

	protected doesProductMatchLangFilter(product: ProductModel, langFilter: LanguageProductFilter) {
		if (langFilter.studentLanguages) {
			let commonLanguages = intersection(langFilter.studentLanguages, product.languages);
			if (!commonLanguages.length) {
				return false;
			}
		}
		if (langFilter.taughtLanguages) {
			let commonLanguages = intersection(langFilter.taughtLanguages, product.taught_languages);
			if (!commonLanguages.length) {
				return false;
			}
		}

		return true;

	}


	/**
	 * Aux method - get all data for one ruby product
	 * @param rubyProductId
	 */
	getProductData(rubyProductId: number): ProductCombinedModel | null {
		return this.productsById[rubyProductId] || null;
	}

	/**
	 * Aux method - get all data for one course.
	 * @param studyCourseId
	 */
	getCourseData(studyCourseId: number): CourseCombinedModel | null {
		return this.coursesById[studyCourseId] || null;
	}

	/**
	 * For one course in certain currency, get all possible ways to buy or activate the course.
	 *
	 * ie. get all possible subscriptions or purchases which includes the course
	 * and also whether the course can be activated as a trial.
	 *
	 * @param courseStudyId
	 * @param currency
	 */
	getShopOptionsOfCourse(
		courseStudyId: number,
		currency: AnyCurrency,
	): CourseShopOptionsModel {

		let theCourse = this.coursesById[courseStudyId];

		if (!theCourse || !currency) {
			if (!currency) {
				console.warn('No currency given to getShopOptionsOfCourse()');
			}
			return {
				availableAsTrial: false,
				trialAlreadyActive: false,
				oneTimePurchases: [],
				subscriptions: [],
				fullCourseAlreadyActive: false,
				currency,
			}
		}

		let isExpired = (theCourse.expireDate && theCourse.expireDate < new Date());

		let options: CourseShopOptionsModel = {
			availableAsTrial: theCourse.availableTrial && !isExpired,
			fullCourseAlreadyActive: theCourse.userHasAsFullCourse,
			trialAlreadyActive: theCourse.userHasAsTrial && !isExpired,
			subscriptions: [],
			oneTimePurchases: [],
			currency,
		}

		theCourse.productIds.forEach(
			(productId) => {
				let theProduct = this.productsById[productId];
				if (!theProduct) {
					return;
				}

				if (!theProduct.prices[currency]) {
					return;
				}

				let priceInCurrency = theProduct.prices[currency];

				if (priceInCurrency.oneTime) {
					options.oneTimePurchases.push({
						product: theProduct,
						priceFrom: priceInCurrency.oneTime,
					});
				}

				if (
					priceInCurrency.yearly
					|| priceInCurrency.monthly
					|| priceInCurrency.halfYearly
					|| priceInCurrency.quarterYearly
				) {
					let priceFrom = calculateLowestPrice(priceInCurrency);
					options.subscriptions.push({
						product: theProduct,
						priceFrom: priceFrom,
					});
				}
			},
		)

		return options;

	}

	/**
	 * For one product in certain currency, get all possible ways to buy or activate it.
	 *
	 * It just transforms data from product to fit into CourseShopOptionsModel interface.
	 *
	 * @param productRubyId
	 * @param currency
	 */
	getShopOptionsOfProduct(productRubyId: number, currency: AnyCurrency): CourseShopOptionsModel {

		let theProduct = this.productsById[productRubyId];

		let options: CourseShopOptionsModel = {
			availableAsTrial: false,
			fullCourseAlreadyActive: false,
			trialAlreadyActive: false,
			oneTimePurchases: [],
			subscriptions: [],
			currency,
		};

		if (!currency) {
			console.warn('No currency given to getShopOptionsOfProduct()!');
			return options;
		}

		if (!theProduct) {
			return options;
		}


		let priceInCurrency = theProduct.prices[currency];

		if (!priceInCurrency) {
			return options;
		}

		if (
			priceInCurrency.yearly
			|| priceInCurrency.monthly
			|| priceInCurrency.halfYearly
			|| priceInCurrency.quarterYearly
		) {
			let priceFrom = calculateLowestPrice(priceInCurrency);
			options.subscriptions.push({
				product: theProduct,
				priceFrom: priceFrom,
			});
		}

		if (priceInCurrency.oneTime) {
			options.oneTimePurchases.push({
				product: theProduct,
				priceFrom: priceInCurrency.oneTime,
			});
		}

		return options;

	}

	/**
	 * Gets a list of all applicable currencies for a certain course
	 *
	 * ie. currencies that the course has any shoppable option
	 *
	 * @param course
	 */
	getAvailableCurrenciesForCourse(course: CourseCombinedModel): AnyCurrency[] {
		let products = course.productIds;
		let currencies = {};
		products.map((id) => this.productsById[id]).forEach(
			(p) => {
				if (!p) {
					return;
				}
				let productCurrencies = Object.keys(p.prices);
				for (let currency of productCurrencies) {
					if (currencies[currency]) {
						continue;
					}
					let prices = p.prices[currency];
					if (prices.oneTime || prices.yearly || prices.halfYearly || prices.oneTime || prices.monthly) {
						currencies[currency] = true;
					}
				}
			},
		);
		return Object.keys(currencies);
	}

	/**
	 * Get a list of currencies that this product has some price in
	 *
	 * ie. currencies that the product has any shoppable option
	 *
	 * @param product
	 */
	getAvailableCurrenciesForProduct(product: ProductCombinedModel): AnyCurrency[] {
		return Object.keys(product.prices).filter(
			(currency) => {
				let p = product.prices[currency];
				return !!(p.monthly || p.yearly || p.monthly || p.quarterYearly || p.oneTime);
			},
		)
	}

	/**
	 * Group given courses into categories.
	 *
	 * @param courses
	 */
	groupCoursesByCategories(courses: CourseCombinedModel[]): { category: CourseCategoryCombinedModel, courses: CourseCombinedModel[] }[] {
		let coursesByCategory = {};

		for (let course of courses) {
			course.categories.forEach(
				(c) => {
					if (!coursesByCategory[c]) {
						coursesByCategory[c] = [];
					}
					coursesByCategory[c].push(course);
				},
			);
		}

		return this.courseCategories.map(
			(category) => ({
				category,
				courses: coursesByCategory[category.id] || [],
			}),
		).filter((c) => (c.courses.length > 0));
	}

	/**
	 * Fulltext search in names.
	 *
	 * Uses naive regexp search.
	 *
	 * @param query
	 */
	searchCoursesByString(query: string): CourseCombinedModel[] {

		// exploit this: https://unicode-table.com/en/#latin-extended-a
		query = deburr(query);
		let onlyAllowedChars = query.replace(/[^a-zA-Z0-9À-ž+\-\s]/g, '');
		let words = onlyAllowedChars.split(/\s+/);

		let matches: CourseCombinedModel[] = [];

		let fixRegexpRegexp = new RegExp(/[+\-]/g);

		let regexps: RegExp[] = words.map(
			(word) => {
				let regExpWord = word.replace(fixRegexpRegexp, (match) => ('\\' + match)).trim();
				if (regExpWord) {
					return new RegExp(regExpWord, 'i');
				}
				return null;
			},
		).filter((s) => !!s);

		// Filter only those courses that does not have any regexp not-matching its name
		return this.courses.filter(
			(course) => !regexps.some((regexp) => !deburr(course.name).match(regexp)),
		);

	}

	/**
	 * Get a list of possible student languages that we have courses to choose from.
	 * Optionally can take into account only languages with a certain shoppable state.
	 *
	 * Languages are sorted by number of courses.
	 */
	getStudentLanguages(shoppableFilter: ShoppableProductFilter = null): CourseLanguage[] {
		let langs = {};
		this.getCourses(null, shoppableFilter).forEach(
			(c) => {
				if (!c.language) {
					return;
				}
				if (!langs[c.language]) {
					langs[c.language] = 0;
				}
				langs[c.language]++;
			},
		);
		let langCodes = Object.keys(langs);
		langCodes.sort((l1, l2) => (langs[l2] - langs[l1]));
		return langCodes;
	}

	/**
	 * Get a list of languages that our courses can teach student speaking the given language
	 *
	 * Languages are sorted by number of courses.
	 *
	 * @param studentLanguage
	 * @param shoppableFilter
	 */
	getTaughtLanguagesForStudentLanguage(studentLanguage: string, shoppableFilter: ShoppableProductFilter = null): CourseLanguage[] {
		let coursesByStudentLanguage = this.getCourses({studentLanguages: [studentLanguage]}, shoppableFilter);
		let langs = {};
		coursesByStudentLanguage.forEach(
			(c) => {
				if (!c.taught_language) {
					return;
				}
				if (!langs[c.taught_language]) {
					langs[c.taught_language] = 0;
				}
				langs[c.taught_language]++;
			},
		);
		let langCodes = Object.keys(langs);
		langCodes.sort((l1, l2) => (langs[l2] - langs[l1]));
		return langCodes;
	}


	/**
	 * This method can change a course's favorite status without refreshing the data from backend.
	 *
	 * This do not change anything on backend, it just injects a new value to RubyCalculator's
	 * internal data cache.
	 *
	 * The rubycalc's data are otherwise immutable, this is only exception to save you from reloading
	 * the data just because user added something to favorites.
	 *
	 * @param idCourse
	 * @param isFavorite
	 */
	updateFavoriteStatusOfCourse(idCourse: number, isFavorite: boolean) {
		let c = this.coursesById[idCourse];
		if (c) {
			c.favorite = isFavorite;
		}
	}

}
