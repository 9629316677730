import { ANSWER_STATUS_BOOLEAN } from '../../misc/answer-status-boolean';
import { FILLABLE_TOKEN_SIZE, SentenceTokenWithSize } from './sentence-token-with-size';

export class FillableSentenceToken extends SentenceTokenWithSize {

	correctWords: string[] = [];
	value: string = '';
	result: ANSWER_STATUS_BOOLEAN = ANSWER_STATUS_BOOLEAN.UNKNOWN_YET;

	type = FillableSentenceToken.typeName;

	sizeMode: FILLABLE_TOKEN_SIZE = FILLABLE_TOKEN_SIZE.MD;
	static typeName = 'fillup';

	getCorrectWordLength(): number {
		if (!this.correctWords.length) {
			return 0;
		}
		return this.correctWords[0].length;
	}

	getLength(): number {
		return this.correctWords.reduce(
			(prev, curr) => {
				let len = curr.length;
				if (len > prev) {
					return len;
				}
				return prev;
			},
			0
		);
	}

}
