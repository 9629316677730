import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {WebcamComponent, WebcamImage, WebcamInitError, WebcamMirrorProperties} from 'ngx-webcam';
import {Observable, Subject} from 'rxjs';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-photo-capture',
  templateUrl: './photo-capture.component.html',
  styleUrls: ['./photo-capture.component.scss']
})
export class PhotoCaptureComponent implements OnInit {
  public showWebcam = false;

  public webcamErrors: WebcamInitError[] = [];

  public webcamImage: WebcamImage;

  public mirrorImage = 'never';

  webcamAvailable = false;

  @Output() sendPhoto: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('staticModal', {static: false}) modal: ModalDirective;

  @ViewChild(WebcamComponent, {static: false}) webcam: WebcamComponent;

  @ViewChild('fileInput', {static: false}) public fileInput;

  private trigger: Subject<void> = new Subject<void>();

  public portraitUrl: string;

  constructor() { }

  ngOnInit() {
  }

  close() {
    this.deletePicture();
    this.showWebcam = false;
    this.modal.hide();
  }

  show() {
    this.modal.show();
    this.showWebcam = true;
  }

  checkWebcamAvailable() {
    this.webcamAvailable = this.showWebcam && this.webcamErrors && this.webcamErrors.length > 0
  }

  public triggerSnapshot(): void {
    if (this.webcamImage) {
      this.sendPhoto.emit(this.webcamImage.imageAsDataUrl);
      return;
    }
    this.trigger.next();
  }

  public deletePicture() {
    this.webcamImage = null;
    this.portraitUrl = '';
  }

  public toggleMirrorWebcam() {
        if (this.mirrorImage === 'never') {
      this.mirrorImage = 'always';
      return;
    }
    this.mirrorImage = 'never';
    return;
  }

  cameraInitError(e: WebcamInitError) {
    console.log(e)
    this.webcamErrors.push(e);
  }

  takePicture(image: WebcamImage) {
    this.webcamImage = image;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  changePhoto() {

    console.log('running changePhoto');

    const file: File = this.fileInput.nativeElement.files[0];
    if (!file) {
      console.log('no file selected.');
      console.log('fileinput nativelemenent: ', this.fileInput.nativeElement);
      return;
    }

    if (file.type.toString() !== 'image/jpeg' && file.type.toString() !== 'image/png' && file.type.toString() !== 'image/jpg') {
      this.fileInput.nativeElement.value = '';
      return;
    }


    const reader = new FileReader();

    reader.onload = (data) => {
      console.log('reader onload running');
      const result = data.target['result'];
      this.portraitUrl = result;
      this.sendPhoto.emit(result);
    };

    reader.readAsDataURL(file);
    console.log('reader readAsDataURL running', file);

  }

  clickedChangePhoto() {
    this.fileInput.nativeElement.click();
  }

}
