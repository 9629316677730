import * as moment from 'moment';


export function exportTime(input: Date|moment.Moment) {

	if (input instanceof Date) {
		input = moment(input);
	}

	return input.format('Y-MM-DD HH:mm:ss');

}
