import { AbstractFillUpExercise } from './abstract-fill-up-exercise';
import { FillableSentenceToken } from './models/fillable-sentence-token';
import { ANSWER_STATUS_BOOLEAN } from '../misc/answer-status-boolean';
import { StaticToken } from './models/static-token';
import { SentenceToken } from './models/sentence-token';
import { Sentence } from './models/sentence';
import { InvalidExerciseData } from '../errors/invalid-exercise-data';
import { isInCorrectStringWithTolerance } from '../../tools/text-comparator';
import { trim } from 'lodash-es';
import { DomSanitizer } from '@angular/platform-browser';

export class WriteFillUpExercise extends AbstractFillUpExercise {

	static fillUpType = 'fillup';


	isTouched(): boolean {
		for (let sentence of this.sentences) {
			for (let token of sentence.tokens) {
				if (token instanceof FillableSentenceToken) {
					if (token.value && token.value.length > 0) {
						return true;
					}
				}
			}
		}
		return false;
	}

	public createToken(tokenData: any, sentence: Sentence = null): SentenceToken|null {

		if (tokenData.type !== FillableSentenceToken.typeName) {

			let t = new StaticToken();
			t.id = +tokenData['id'];
			t.html = tokenData['content'];

			let trimmed = trim(tokenData['content']);
			if (!trimmed) {
				return null;
			}


			if (t.html) {
				t.html = t.html.replace(/<div>/ig, '<br />');
				t.html = t.html.replace(/<\/div>/ig, '');
				t.html = t.html.replace(/<br\s?\/?>/ig, '<div style="height: 0.5em"></div>');
				// t.html = t.html.replace(/^\s*\.\s*<div/, '<div');

				t.html = trim(t.html);

				if (!t.html) {
					return null;
				}

			}

			return t;

		} else {

			let t = new FillableSentenceToken();
			t.id = +tokenData['id'];
			t.correctWords = tokenData['content'];
			t.value = '';
			t.result = ANSWER_STATUS_BOOLEAN.UNKNOWN_YET;
			t.autoSetSize();
			return t;

		}
	}

	public init(lessonData: object): void {
		super.init(lessonData);
	}

	public reset(): void {
		this.sentences.map(
			(sentence) => {
				sentence.tokens.map(
					(token) => {
						if (token instanceof FillableSentenceToken) {
							token.value = '';
							token.result = ANSWER_STATUS_BOOLEAN.UNKNOWN_YET;
						}
					}
				);
				sentence.result = ANSWER_STATUS_BOOLEAN.UNKNOWN_YET;
			}
		);
	}

	public evaluate(): void {

		let correct = 0;
		let total = 0;


		this.sentences.map(
			(sentence) => {
				let isSentenceCorrect = true;
				sentence.tokens.map(
					(token) => {
						if (token instanceof FillableSentenceToken) {
							total++;
							let val = token.value.trim().toLowerCase().replace(/[ \t\n]{2,}/g, ' ');
							let ok = isInCorrectStringWithTolerance(token.value, token.correctWords, this.tolerance);
							if (ok) {
								correct++;
								token.result = ANSWER_STATUS_BOOLEAN.CORRECT;
							} else {
								token.result = ANSWER_STATUS_BOOLEAN.INCORRECT;
								isSentenceCorrect = false;
							}
						}
					}
				);

				if (isSentenceCorrect) {
					sentence.result = ANSWER_STATUS_BOOLEAN.CORRECT;
				} else {
					sentence.result = ANSWER_STATUS_BOOLEAN.INCORRECT;
				}
			}
		);

		this.setResultUsingNumbers(correct, total);

	}

	public getAnswersForApi(): object {

		let answers = {};
		this.sentences.map(
			(sentence: Sentence) => {
				let words = {};
				for (let token of sentence.tokens) {
					if (token instanceof FillableSentenceToken) {
						words[token.id] = token.value;
					}
				}
				answers[sentence.id] = words;
			}
		);

		return {
			'answers': answers
		};

	}

	public canBeEvaluated(): boolean {
		return this.sentences.reduce(
			(allSentencesFilled, sentence) => {
				if (!allSentencesFilled) {
					return false;
				}
				return sentence.tokens.reduce(
					(allTokensFilled, token) => {
						if (!allTokensFilled) {
							return false;
						}
						if (token.type === StaticToken.typeName) {
							return allTokensFilled;
						}
						if (token instanceof FillableSentenceToken) {
							return !!token.value;
						}
					},
					true
				);
			},
			true
		);
	}

	public setFromPreviousAnswers(data: any): void {

		if (!data) return;

		if (!data.answers) {
			throw new InvalidExerciseData('Data does not contain data.answers.fillups.');
		}

		for (let sentenceIdStr of Object.keys(data.answers)) {
			let sentenceId = +sentenceIdStr;
			let sentence = this.sentences.find(s => s.id === sentenceId);
			if (!sentence) {
				continue;
			}
			let values = {};
			for (let tokenIdStr of Object.keys(data.answers[sentenceId])) {
				let tokenId = +tokenIdStr;
				values[tokenId] = data.answers[sentenceId][tokenIdStr];
			}
			for (let token of sentence.tokens) {
				if (token instanceof FillableSentenceToken) {
					try {
						token.value = values[token.id] || '';
					} catch (e) {
						console.error('Weird value of previous exercise for token ' + token.id, data.answers);
					}
				}
			}
		}

	}


	createUnsafeTokenHtml(sanitizer: DomSanitizer) {

		for (let sentence of this.sentences) {
			for (let token of sentence.tokens) {
				if (token instanceof StaticToken) {
					token.unsafeHtml = sanitizer.bypassSecurityTrustHtml(token.html);
				}
			}
		}

	}
}
