import {NgModule} from '@angular/core';
import {SecretModalSettingsComponent} from './secret-modal-settings.component';
import {TranslateModule} from "@ngx-translate/core";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {CommonModule} from "@angular/common";
import {ToastModule} from "primeng/toast";
import {FormsModule} from "@angular/forms";
import {MatSelectModule} from '@angular/material';
import {ModalModule} from "ngx-bootstrap/modal";

@NgModule({
  declarations: [
    SecretModalSettingsComponent
  ],
    imports: [
        ModalModule,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        CommonModule,
        ToastModule,
        FormsModule,
        MatSelectModule
    ],
  exports: [
    SecretModalSettingsComponent
  ]
})

export class SecretModalSettingsModule {
}
