import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {CurrentUserService} from '../auth/current-user.service';
import {AppNavigateService} from '../services/app-navigate.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private currentUserService: CurrentUserService,
    private appNavigateService: AppNavigateService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.currentUserService.isLoggedIn) {
      return true;
    }

    if (url) {
      // Store the attempted URL for redirecting
      this.appNavigateService.setRedirectUrl(url);
    }

    // Navigate to the login page with extras
    this.router.navigate(['/login']);
    return false;
  }
}
