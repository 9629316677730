import { trim } from 'lodash-es';

export function parseUserFirstLastName(name: string): {firstName: string, lastName: string} {
	let trimmed = trim(name);

	let split = trimmed.split(/[\s+]/);
	split = split.map(trim).filter((a) => !!a);
	if (split.length > 2) {
		split = [split[0], split.slice(1).join(' ')];
	}
	if (split.length === 2 && split[1]) {
		return {
			firstName: split[0],
			lastName: split[1],
		};
	}

	return {
		firstName: '',
		lastName: trimmed,
	};

}
