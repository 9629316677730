import { LEVEL } from './level';
import { sortBy } from 'lodash-es';

export class VocabularyFilter {

	fromLevel: LEVEL = LEVEL.L0;
	toLevel: LEVEL = LEVEL.L5;

	chapterId: number[] = [];
	weeksOfChapter: {[key: number]: number[]} = {};

	excluded: true | false | null = null; // True = only excluded. False = without excluded.
	onlyFavorites = false;

	weeks = [];

	static createFrom(input: any): VocabularyFilter {
		let f = new VocabularyFilter();

		Object.keys(f).map(
			(key) => {
				if (typeof f[key] !== 'function' && input[key] !== null) {
					f[key] = input[key];
				}
			}
		);

		return f;
	}

	isActive(): boolean {
		if (this.fromLevel && this.fromLevel !== LEVEL.L0) {
			return true;
		}

		if (this.toLevel && this.toLevel !== LEVEL.L5) {
			return true;
		}

		if (this.chapterId.length > 0) {
			return true;
		}

		if (this.excluded !== null) {
			return true;
		}

		if (this.onlyFavorites) {
			return true;
		}

		return false;
	}


	isSameAs(filter: VocabularyFilter) {
		if (this.fromLevel !== filter.fromLevel) {
			return false;
		}
		if (this.toLevel !== filter.toLevel) {
			return false;
		}
		if (JSON.stringify(sortBy(this.chapterId)) !== JSON.stringify(sortBy(filter.chapterId))) {
			return false;
		}
		if (JSON.stringify(sortBy(this.weeksOfChapter)) !== JSON.stringify(sortBy(filter.weeksOfChapter))) {
			return false;
		}
		if (this.excluded !== filter.excluded) {
			return false;
		}
		if (this.onlyFavorites !== filter.onlyFavorites) {
			return false;
		}
		return true;
	}

}
