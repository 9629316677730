import { MethodDescription } from '../method-description';

export const FavoriteCourseMethodDescription = new MethodDescription();

FavoriteCourseMethodDescription.name = 'favoriteCourse';
FavoriteCourseMethodDescription.fields = [
	'studentId',
	'productId',
	'favorite',
];
FavoriteCourseMethodDescription.usesToken = true;
