export abstract class SentenceToken {
	id: number;
	type: string;
	uniqueId: string;
	static typeName: string;

  text?: string;
  html?: string;
  value?: string;
}
