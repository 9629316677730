import {AppConfig} from './app-config';
import {appCodes, appSecrets} from './app.config';

export const devElConfig = new AppConfig({
  studovnaApiUrl: 'https://api.onlinejazyky.cz',
  eshopApiUrl: 'https://www.onlinejazyky.cz/api',
  eshopAppCode: appCodes.easylingo,
  eshopAppSecret: appSecrets.easylingo,
  eshopApiVersion: '1.22',
  studovnaMediaFilesBase: 'https://studovna.onlinejazyky.cz/',
  subscriptionPageUrl: 'https://www.onlinejazyky.cz/info/my-account/',

  // debugging only
  debuggingMessages: true,
  debuggingCordovaFallbacks: true,
  // debugSkipInitialVerifyToken: true,
  debugSkipInitialVerifyToken: false,
  ignoreStudovnaEuthError: false,
  debuggingServiceAccessor: true,
  brandCode: 'el',
  brandAppName: 'EasyLingo',
  brandLogoFilename: 'easy_lingo_logo',
  defaultLanguage: 'cs',
  availableLanguages: ['cs', 'en', 'es', 'sk', 'pl'],

  ttsOwner: 'webapp',

  apps: {
    onlinejazyky: 'onlinejazyky',
    easylingo: 'easylingo'
  },
  currentApp: 'onlinejazyky',

  facebookAppId: '252968332441983',
  facebookAppSecret: '740b300659dd8635e7a32bd7e2545d6d',
  facebookReturnUrl: 'https://student.easylingo.com/',

  supportEmail: 'info@easylingo.com',

  helpcrunch: {
    organizationDomain: 'easylingo',
    applicationId: 1,
    applicationSecret: 'R0tafToPkkRMB+S9HSOrd6dbaM5Vzb1wqoiKX8qednTD8tz6DDPDzDE5d6cTI+j/e7iJajnStvv2aE7Q1Zz4TQ=='
  }
});

