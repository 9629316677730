import {Injectable} from '@angular/core';
import {UserPreferencesService} from './user-preferences.service';
import {LANGUAGE_TYPES_SHORT} from '../misc/language-types.enum';

@Injectable()
export class SpecialLettersService {
  protected PREF_LETTERS_ENABLED = 'specialLettersEnabled';

  constructor(
    protected userPrefs: UserPreferencesService,
  ) {
  }

  public isSpecialLettersEnabled() {
    return this.userPrefs.get(this.PREF_LETTERS_ENABLED, true);
  }

  public enableSpecialLetters(enable = true, saveToBackend = true) {
    this.userPrefs.set(this.PREF_LETTERS_ENABLED, enable);
  }

  public getLettersByLanguage(language: LANGUAGE_TYPES_SHORT) {
    switch (language) {
      case LANGUAGE_TYPES_SHORT.CZECH:
        return ['ě', 'ř', 'ů'];
      case LANGUAGE_TYPES_SHORT.ENGLISH:
        return ['\'', '@', '"'];
      case LANGUAGE_TYPES_SHORT.FRENCH:
        return ['à', 'â', 'ä', 'æ', 'ç', 'é', 'è', 'ê', 'ë', 'î', 'ï', 'ô', 'œ', 'ù', 'û', 'ü'];
      case LANGUAGE_TYPES_SHORT.GERMAN:
        return ['ä', 'ö', 'ü', 'ß'];
      case LANGUAGE_TYPES_SHORT.ITALIAN:
        return ['à', 'è', 'é', 'ì', 'ò', 'ó', 'ù'];
      case LANGUAGE_TYPES_SHORT.RUSSIAN:
        return ['а́', 'е́', 'о́', 'и́', 'у́', 'я́', 'ю́', 'э́', 'ы́'];
      case LANGUAGE_TYPES_SHORT.SPANISH:
        return ['á', 'é', 'í', 'ó', 'ú', 'ü', 'ñ', '¡', '¿'];
      default:
        return ['\'', '@', '"'];
    }
  }
}
