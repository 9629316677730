import {
  Component,
  OnInit
} from '@angular/core';
import {BaseOverlayDialogComponent} from '../base-overlay-dialog/base-overlay-dialog';
import {DAILY_GOAL_RESULT} from './daily-goal-result';


@Component({
  selector: 'daily-goal-dialog',
  templateUrl: 'daily-goal-dialog.html',
  styleUrls: ['daily-goal-dialog.scss']
})
export class DailyGoalDialog extends BaseOverlayDialogComponent implements OnInit {

  public canContinue = true;

  public canFinish = true;


  ngOnInit() {
    // this.swipeText = this.translateService.instant('Odtažením nahoru ukončíte studium');
  }

  setupCanContinue(canContinue = true) {
    this.canContinue = canContinue;
  }

  setupCanFinish(canFinish = true) {
    this.canFinish = canFinish;
  }

  clickedSwipeButton() {
    this.close(DAILY_GOAL_RESULT.RETURN_TO_DASHBOARD);
  }

  clickedCancelButton() {
    this.close(DAILY_GOAL_RESULT.RETURN_TO_DASHBOARD);
  }

  clickedButton() {
    this.close(DAILY_GOAL_RESULT.CONTINUE);
  }


}
