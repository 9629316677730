import { VideoModel } from './video.model';

export const TrialEndVideoCz: VideoModel = {
	urlMd2x: 'https://www.onlinejazyky.cz/video/CZ_v14_CTA-trial_600p.mp4',
	urlXl1x: 'https://www.onlinejazyky.cz/video/CZ_v14_CTA-trial_420p.mp4',
	urlXl2x: 'https://www.onlinejazyky.cz/video/CZ_v14_CTA-trial_840p.mp4',
};

export const TrialEndVideoSk: VideoModel = {
	urlMd2x: 'https://www.onlinejazyky.cz/video/SK_v14_CTA-trial_600p.mp4',
	urlXl1x: 'https://www.onlinejazyky.cz/video/SK_v14_CTA-trial_420p.mp4',
	urlXl2x: 'https://www.onlinejazyky.cz/video/SK_v14_CTA-trial_840p.mp4',
};
