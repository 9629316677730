import {Injectable} from '@angular/core';
import {CourseCombinedModel} from '../../../../oj-app-common/shop/models/shop/course-combined.model';
import {CourseShopOptionsModel} from '../../../../oj-app-common/shop/models/course-shop-options.model';
import {Subject} from 'rxjs';
import {LoadingService} from '../../services/loading.service';
import {_} from '../../../../oj-app-common/i18n/translate-placeholder';
import {StudovnaApi} from '../../../../oj-app-common/studovna-api/studovna-api';
import {CurrentUserService} from '../../auth/current-user.service';
import {CurrentCourseService} from '../../services/current-course-service';
import {EshopApi} from '../../../../oj-app-common/eshop-api/eshop-api';
import {RubyCalculatorService} from '../../pages/base-layout-page/pages/eshop-page/services/ruby-calculator-service';
import {Router} from '@angular/router';
import {ProductCombinedModel} from '../../../../oj-app-common/shop/models/shop/product-combined.model';
import {UserPreferencesService} from '../../../../oj-app-common/services/user-preferences.service';
import {createWooCommerceUrl, UrlSet} from '../../../../oj-app-common/shop/tools/woocommerce-bridge';
import {EshopLanguage} from '../../../../oj-app-common/shop/types/eshop-language';
import {AppConfig} from '../../config/app-config';
import {PurchaseService} from '../../pages/base-layout-page/pages/eshop-page/services/purchase.service';
import {AppTranslationService} from '../../services/app-translation.service';
import {ShoppableType} from '../../../../oj-app-common/shop/types/shoppable-type';
import {delay} from '../../pages/study-page/components/pronounce-training/delay';
import {MessageService} from 'primeng/api';
import {resolve} from '@angular/compiler-cli/src/ngtsc/file_system';
import {AnyCurrency} from '../../../../oj-app-common/shop/types/any-currency';
import {AvailableCourse} from '../../models/available-course';
import {CurrentStatusResponse} from '../../../../oj-app-common/studovna-api/responses/current-status';
import {DashboardInfoResponse} from '../../../../oj-app-common/studovna-api/responses/dashboard-info';
import {DailyGoalPickerComponent} from '../overlay-dialog/daily-goal-picker/daily-goal-picker.component';
import {OverlayService} from '../overlay-dialog/overlay-service';
import {AffiliateService} from '../../services/affiliate.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentDialogService {

  constructor(
    protected loadingService: LoadingService,
    protected studovnaApi: StudovnaApi,
    protected currentUser: CurrentUserService,
    protected currentCourse: CurrentCourseService,
    protected eshopApi: EshopApi,
    protected rubyCalcService: RubyCalculatorService,
    protected router: Router,
    protected userPrefs: UserPreferencesService,
    protected appConfig: AppConfig,
    protected purchaseService: PurchaseService,
    protected appTranslationService: AppTranslationService,
    protected messageService: MessageService,
    protected overlayService: OverlayService,
    protected affiliateService: AffiliateService,
  ) {
  }

  static URL_SET_PREF_KEY = 'wooUrlSet';

  doAfterEnd: () => void = null;

  public _isOpened: Subject<{
    type: ('license' | 'payment' | 'period'),
    course: CourseCombinedModel,
    product: ProductCombinedModel | null,
    shopOptions: CourseShopOptionsModel
  } | null> = new Subject<{
    type: ('license' | 'payment' | 'period'),
    course: CourseCombinedModel,
    product: ProductCombinedModel | null,
    shopOptions: CourseShopOptionsModel
  } | null>();

  async handleButtonClick(
    buttonType: 'get' | 'study' | 'activate' | 'start',
    course: CourseCombinedModel,
    shopOptions: CourseShopOptionsModel,
    ojEshopProductId?: number,
    product: ProductCombinedModel = null,
    studyCourseId: number = null,
    doAfterEnd: () => void = null
  ) {

    this.doAfterEnd = doAfterEnd;

    if (buttonType === 'get') {

      // if there is only one subscription, open payment dialog directly
      // if (
      //   shopOptions.subscriptions.length === 1
      //   && (!shopOptions.availableAsTrial || shopOptions.trialAlreadyActive)
      //   && shopOptions.oneTimePurchases.length === 0
      // ) {
      //   this.openModal(
      //     'payment',
      //     product ? null : course,
      //     product ? product : null,
      //     shopOptions,
      //   );
      //   return;
      // }

      this.openModal(
        'license',
        course,
        null,
        shopOptions,
      );
      return;
    }

    if (buttonType === 'activate') {
      await this.switchToAnotherCourse(course.id_study);
      await this.addToFavorites(course.id_study, ojEshopProductId);
      await this.router.navigate(['/dashboard']);
      return;
    }

    if (buttonType === 'study') {

      if (!course && studyCourseId) {

        try {
          const res = await this.switchToAnotherCourse(studyCourseId);

          if (res) {
            await this.router.navigate(['/dashboard']);
          }
        } catch (e) {
          console.error(e);
        }

      } else {

        try {
          const res = await this.switchToAnotherCourse(course.id_study);

          if (res) {
            await this.router.navigate(['/dashboard']);
          }
        } catch (e) {
          console.error(e);
        }

      }

      return;
    }

  }

  openModal(
    type: ('license' | 'payment' | 'period'),
    course: CourseCombinedModel,
    product: ProductCombinedModel = null,
    shopOptions: CourseShopOptionsModel
  ) {
    this._isOpened.next({
      type,
      course,
      product,
      shopOptions
    });
  }

  closeModal() {
    this._isOpened.next(null);
  }

  clickedDoAfterEnd() {
    if (this.doAfterEnd) {
      this.doAfterEnd();
      this.doAfterEnd = null;
    }
  }

  public async switchToAnotherCourse(courseId: number): Promise<boolean> {
    this.loadingService.show(_('Spouštím kurz'));

    try {

      const activationResponse = await this.studovnaApi.courseActivation(
        this.currentUser.studovnaId,
        courseId,
        this.currentUser.token,
      );


      let response: CurrentStatusResponse;
      response = await this.studovnaApi
        .currentStatus(this.currentUser.studovnaId, this.currentUser.token);

      await this.currentCourse.activateCourse(courseId);
      this.currentUser.updateActiveCourse(courseId);
      this.currentCourse.setCurrentLesson(response.lastLessonBlock);

      await this.currentUser.forceRefreshMyProfile();

      let dashboard: DashboardInfoResponse;

      try {
        dashboard = await this.studovnaApi.dashboardInfo(
          this.currentUser.studovnaId,
          this.currentUser.token,
        );
      } catch (error) {
        switch (error.errorCode) {
          case 'NO_DAILY_GOAL':
          case 'NO_STUDY_PLAN':
            this.messageService.add({
              severity: 'info',
              detail: _('Nemáte nastaven denní cíl. Nejprve je třeba zvolit, které dny v týdnu chcete studovat a jak dlouho, anebo si vybrat jiný kurz.'),
              life: 7000
            })
            this.overlayService
              .show(DailyGoalPickerComponent)
              .then((dialogResult) => {
                const dialog = dialogResult.componentRef.instance;
                dialog.didHide.subscribe(r => {
                  if (r) {
                    this.router.navigate(['/dashboard']);
                  }
                })
              })
            return;
          default:
            this.router.navigate(['/trialPicker/']);
            return Promise.reject();
        }
      }

      if (dashboard) {
        this.loadingService.hide();

        return true;
      }
    } catch (error) {
      this.loadingService.hide();
      console.error(error);
      return false;
    }

  }

  protected async addToFavorites(courseId: number, ojEshopProductId: number = null) {
    this.loadingService.show(_('shop-activating-courses'));

    try {
      if (!ojEshopProductId) {
        ojEshopProductId = await this.eshopApi.convertId(
          courseId + '',
          'course',
          'product',
        );
      }
      await this.eshopApi.favoriteCourse(
        this.currentUser.eshopId,
        ojEshopProductId,
        true,
        this.currentUser.token,
      );
      this.rubyCalcService.updateFavoriteStatusOfCourse(courseId, true);
      this.loadingService.hide();
    } catch (e) {
      console.error(e);
      this.loadingService.hide();
    }

  }

  public buyProductInWooEshop(
    route: string,
    productId: number,
    shoppableType: ShoppableType,
    currency: AnyCurrency
  ) {

    const returnUrl = window.location.protocol + '//' + window.location.host + route + '?finalizeOrder=' + productId;
    this.getProductById(productId)
      .then(product => {
        const buyUrl = createWooCommerceUrl(
          this.appConfig.brandCode,
          this.appTranslationService.currentLanguage as EshopLanguage,
          this.currentUser.token,
          product,
          shoppableType,
          currency,
          encodeURI(returnUrl),
          this.getPreferredWooUrlSet(),
          this.appConfig.brandCode
        );
        this.purchaseService.setPurchase(true);
        window.open(buyUrl, '_self');
      })
  }

  async getProductById(productId: number): Promise<ProductCombinedModel> {
    const calc = await this.rubyCalcService.getCalculator();
    return calc.getProductData(productId)
  }

  public async activateDemo(productId: number, courseId: number, courseName: string): Promise<boolean> {
    this.loadingService.show(_('Spouštím kurz zdarma'));

    this.affiliateService.googleAnalyticsAfterDemo(productId, courseName)

    return this.eshopApi.activateDemo(
      productId,
      this.currentUser.eshopId,
      this.appConfig.brandCode,
      this.currentUser.token,
    )
      .then(
        async (resp) => {
          try {
            if (resp.activated) {

              await delay(1000);
              const res = await this.startCourse(courseId);

              this.loadingService.hide();

              return Promise.resolve(res);

            } else {
              this.messageService.add(
                {
                  severity: 'error',
                  detail: _('Nepovedlo se aktivovat demo kurz.'),
                  summary: _('Kurz se nepovedlo aktivovat')
                });
              return Promise.reject();
            }

          } catch (error) {
            this.loadingService.hide();
            this.messageService.add(
              {
                severity: 'error',
                detail: _('Bohužel, nepovedlo se aktivovat kurz zdarma.'),
                summary: _('Kurz se nepovedlo aktivovat')
              });
            return Promise.reject();
          }
        }
      )
      .catch(
        (er) => {
          console.log(er);
          this.loadingService.hide();
          this.messageService.add(
            {
              severity: 'error',
              detail: _('Bohužel, nepovedlo se aktivovat kurz zdarma.'),
              summary: _('Kurz se nepovedlo aktivovat')
            });
          return Promise.reject();
        }
      )
    ;
  }

  protected async startCourse(courseId: number): Promise<boolean> {

    try {

      this.loadingService.show(_('Spouštím kurz'));

      this.studovnaApi.courseList(
        this.currentUser.studovnaId,
        this.currentUser.token,
        true
      );

      await delay(2500);

      const activationResponse = await this.studovnaApi.courseActivation(
        this.currentUser.studovnaId,
        courseId,
        this.currentUser.token
      );

      await this.currentCourse.activateCourse(courseId);

      this.currentUser.updateActiveCourse(courseId);

      this.loadingService.hide();

      this.router.navigate(['/dashboard']);

      return true;

    } catch (e) {

      console.error(e);

      this.messageService.add(
        {
          severity: 'error',
          detail: (e && e.message) ? e.message : e,
          summary: _('Nastala chyba')
        });

      this.loadingService.hide();

      return false;

    }

    return false;
  }

  public getPreferredWooUrlSet(): UrlSet {
    // const valueInPreferences = this.userPrefs.get(PaymentDialogService.URL_SET_PREF_KEY);
    // if (valueInPreferences === UrlSet.v2020_10) {
    //   return UrlSet.v2020_10;
    // } else if (valueInPreferences === UrlSet.v2020_12) {
    //   return UrlSet.v2020_12;
    // } else {
      return UrlSet.v2021_01;
    // }
  }
}
