import {YES_NO_CHOICE} from './yes-no-choice';

export enum ANSWER_STATUS_BOOLEAN {
	UNKNOWN_YET = 0,
	CORRECT = 1,
	INCORRECT = 2,
}

export function getAnswerStatusTextForYesNo(answer: YES_NO_CHOICE) {
  switch (answer) {
    case YES_NO_CHOICE.YES: return 'CORRECT';
    case YES_NO_CHOICE.NO: return 'INCORRECT';
    case YES_NO_CHOICE.UNKNOWN: return 'UNKNOWN';
  }
}
