import {Injectable} from '@angular/core';
import {AppConfig} from '../config/app-config';
import {ServicesAccessor} from '../../../oj-app-common/tools/services-accessor';
import {YoutubeVideoParams} from '../contents/components/common-parts/youtube-video-params';

@Injectable({
  providedIn: 'root',
})
export class MediaFilesService {
  constructor(
    protected appConfig: AppConfig,
    serviceAccessor: ServicesAccessor,
  ) {
    serviceAccessor.add('media', this);
  }

  public getServerImageUrl(relativeUrl: string): string {
    if (!relativeUrl) {
      return '';
    }
    if (relativeUrl[0] === '/') {
      relativeUrl = relativeUrl.substr(1);
    }

    return this.appConfig.studovnaMediaFilesBase + relativeUrl;
  }

  public processEditorText(html: string): string {

    // URL adresy obrázků

    // Začínající na /
    html = html.replace(/src="(\/[^"]+)"/ig, (string, relUrl) => {
      return 'src="' + this.getServerImageUrl(relUrl) + '"';
    });
    html = html.replace(/src='(\/[^']+)'/ig, (string, relUrl) => {
      return 'src="' + this.getServerImageUrl(relUrl) + '"';
    });
    html = html.replace(/href='(\/[^']+)'/ig, (string, relUrl) => {
      return 'href="' + this.getServerImageUrl(relUrl) + '"';
    });
    html = html.replace(/href="(\/[^']+)"/ig, (string, relUrl) => {
      return 'href="' + this.getServerImageUrl(relUrl) + '"';
    });

    // Začínající na ../
    html = html.replace(/src="(\.\.\/)+([^"]+)"/ig, (string, dots, relUrl) => {
      return 'src="' + this.getServerImageUrl(relUrl) + '"';
    });
    html = html.replace(/src='(\.\.\/)+([^']+)'/ig, (string, dots, relUrl) => {
      return 'src="' + this.getServerImageUrl(relUrl) + '"';
    });

    // Obrázky mající width a height atribut
    html = html.replace(/(<img [^>])*width=["'][^"']+["']/ig, (string, before) => {
      return before;
    });
    html = html.replace(/(<img [^>])*height=["'][^"']+["']/ig, (string, before) => {
      return before;
    });

    html = html.replace(/<iframe([^<]+)<\/iframe>/ig, '');

    html = html.replace(/<p>\s*(&nbsp;)?\s*<\/p>/ig, '');

    html = html.replace(/<a ([^>]*)>/ig, '<a $1 onclick="window.openExternalLink(this);return false;">');

    // Fix pro natrvdo nastylované podtržení textu
    html = html.replace(/(style="text-decoration:\s?underline)/ig, 'class="underline" $1');

    // Na velkých displejích zkoušíme zarovnat osamocené obrázky na střed
    html = html.replace(/<p>\s?(<img[^>]+>)\s?<\/p>/, (match, imageTag) => {
      return '<p class="paragraph-only-with-image">' + imageTag + '</p>';
    });

    return html;
  }

  public extractYoutubeVideo(html: string): (YoutubeVideoParams[] | null) {

    if (!html) {
      return null;
    }

    const videos: YoutubeVideoParams[] = [];

    const matches = html.match(/<iframe([^<]+)>\s*<\/iframe>/ig);
    if (matches && matches.length > 0) {

      for (const match of matches) {

        const srcMatch = match.match(/src="([^"]+)"/);
        const widthMatch = match.match(/width="([0-9%]+)"/);
        const heightMatch = match.match(/height="([0-9%]+)"/);
        const startMatch = match.match(/\?start=(\d+)/);

        let youtubeId = '';
        let provider: 'youtube' | 'vimeo';
        let aspectRatio: number = (16 / 9);
        let startTime = 0;

        if (srcMatch) {
          const src = srcMatch[1];
          if (src.indexOf('vimeo') !== -1) {
            const idMatch = src.match(/\/video\/(\d+)/);
            if (idMatch) {
              youtubeId = idMatch[1];
              provider = 'vimeo';
            }
          } else if (src.indexOf('youtu') !== -1) {
            const idMatch = src.match(/embed\/([a-zA-Z0-9_-]{11})/);
            if (idMatch) {
              provider = 'youtube';
              youtubeId = idMatch[1];
            }
          }
        }

        if (widthMatch && heightMatch) {
          aspectRatio = (+(widthMatch[1]) / +(heightMatch[1]));
          if (!aspectRatio) {
            aspectRatio = (16 / 9);
          }
        }

        if (startMatch) {
          startTime = +startMatch[1];
        }

        if (youtubeId && provider) {
          videos.push({
            id: youtubeId,
            aspectRatio,
            start: startTime,
            provider,
          });
        }

      }

    }

    return videos;

  }
}
