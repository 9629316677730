/// <reference types="@types/dom-mediacapture-record" />

import { BehaviorSubject, Observable } from 'rxjs';
import {RecordingProgress, SoundRecorderInterface} from './sound-recorder.interface';
import {Seconds} from '../common/sound/seconds.type';
import {delay} from '../pages/study-page/components/pronounce-training/delay';
import {_} from '../../../oj-app-common/i18n/translate-placeholder';

export class HtmlSoundRecorder implements SoundRecorderInterface {

	protected audioSupportedMimeType = HtmlSoundRecorder.getSuitableAudioRecordingFormat();

	protected isRecording = false;

	protected mediaStream?: MediaStream;
	protected mediaRecorder?: MediaRecorder;
	protected audioChunks = [];

	protected cancelAfterStop = false;
	protected autostopTimeout;

	protected _recordingPosition = new BehaviorSubject<RecordingProgress>({
		seconds: 0,
		secondsMaximum: 0,
		percentage: 0,
	});

	constructor() {

	}

  static getSuitableAudioRecordingFormat(): string {
    if (!MediaRecorder) {
      return '';
    }
    const possibleFormats = ['audio/mp3', 'audio/mp4', 'audio/webm'];
    for (const format of possibleFormats) {
      if (MediaRecorder.isTypeSupported(format)) {
        return format;
      }
    }
    return '';
  }

	static isSupported(): boolean {
		if (!window['MediaRecorder'] || !window.navigator.mediaDevices || !window.navigator.mediaDevices.getUserMedia) {
			return false;
		}

		if (MediaRecorder.isTypeSupported('audio/mp3')) {
			return true;
		} else if (MediaRecorder.isTypeSupported('audio/webm')) {
			return true;
		} else if (MediaRecorder.isTypeSupported('audio/mp4')) {
			return true;
		}

		return false;
	}

	get recordingPositionChanged(): Observable<RecordingProgress> {
		return this._recordingPosition.asObservable();
	}

	stopRecording() {
		if (this.isRecording) {
			this.mediaRecorder.stop();
      this.stopMediaStream();
      this.resetRecordingPosition();
		}
	}

	get isRecordingNow(): boolean {
		return this.isRecording;
	}

	cancelRecording() {
		if (this.mediaRecorder) {
			this.cancelAfterStop = true;
			if (this.mediaRecorder.state === 'recording') {
				this.mediaRecorder.stop();
			}
			this.mediaRecorder = null;
		}
		this.stopMediaStream();

		this.audioChunks = [];
		this.isRecording = null;
	}

	async startRecording(maxLength: Seconds, returnAlsoBlob: true): Promise<{ blob: Blob, url: string } | null>;
	async startRecording(maxLength: Seconds, returnAlsoBlob: false): Promise<string | null>;
	async startRecording(maxLength: Seconds): Promise<string | null>;
	async startRecording(maxLength: Seconds, returnAlsoBlob: boolean = false): Promise<{ blob: Blob, url: string } | string | null> {

		if (maxLength <= 0.1) {
			maxLength = 1;
		}

		if (this.isRecording) {
			throw new Error('Recorder is already recording something!');
		}

		if (!this.audioSupportedMimeType) {
			throw new Error('Recorder is already recording something!');
		}

    // const allowed = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    //
    // if (!allowed) {
    //   return null;
    // }

		this.audioChunks = [];

		this.isRecording = true;
		this.cancelAfterStop = false;


		return new Promise<string | null | {blob: Blob, url: string}>(
			async (resolve, reject) => {

				navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(
					async (stream: MediaStream) => {

						this.mediaStream = stream;

						let mediaRecorder;

						try {
							mediaRecorder = new MediaRecorder(
								stream,
								{
									mimeType: this.audioSupportedMimeType,
									audioBitsPerSecond: 48000,
								},
							);
							this.mediaRecorder = mediaRecorder;
						} catch (e) {
							throw e;
						}

            // add 3s delay to avoid the first 3s of the recording if is mac and firefox
            if (navigator.userAgent.indexOf('Mac') !== -1 && (navigator.userAgent.indexOf('Firefox') !== -1)) {
              await delay(3000);
            }

						this.audioChunks = [];

						let startRecordingTime = Date.now();

						let updatingInterval;

						this.mediaRecorder.addEventListener('dataavailable', (event) => {
							this.audioChunks.push(event.data);
						});


						this.mediaRecorder.addEventListener('stop', () => {
							if (updatingInterval) {
								clearInterval(updatingInterval);
								updatingInterval = null;
							}
							clearTimeout(this.autostopTimeout);
							this.autostopTimeout = null;
							this.isRecording = false;
							this.stopMediaStream();

              this.resetRecordingPosition();

							if (this.cancelAfterStop) {
								resolve(null);
								return;
							}

							this.exportChunks(returnAlsoBlob).then(
								(returnUrl) => {
									resolve(returnUrl);
								},
							);
						});

						this.mediaRecorder.addEventListener('error', (e) => {
							if (updatingInterval) {
								clearInterval(updatingInterval);
								updatingInterval = null;
							}
							this.isRecording = false;
							this.stopMediaStream();
							clearTimeout(this.autostopTimeout);
							this.autostopTimeout = null;
							this._recordingPosition.next({
								seconds: 0,
								percentage: 0,
								secondsMaximum: 0
							});
							reject(e);
						});

						this.mediaRecorder.start();

						startRecordingTime = Date.now();

						this._recordingPosition.next({
							seconds: 0,
							percentage: 0,
							secondsMaximum: maxLength,
						});

						updatingInterval = setInterval(
							() => {
								let time = (Date.now() - startRecordingTime) / 1000;
								if (time < 0) {
									time = 0;
								}
								let percentage = time / maxLength;
								if (percentage > 1) {
									percentage = 1;
								}
								if (percentage < 0) {
									percentage = 0;
								}
								this._recordingPosition.next({
									seconds: Math.round(time * 10) / 10,
									percentage: Math.round(percentage * 100) / 100,
									secondsMaximum: maxLength,
								});
							},
							200,
						);

						this.autostopTimeout = setTimeout(
							() => {
								if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
									this.mediaRecorder.stop();
								}
							},
							maxLength * 1000,
						);

					},
					(e) => {
						console.error(e);
						reject(e);
					},
				);

			},
		);


	}

	protected async exportChunks(returnAlsoBlob = false): Promise<string | null | { blob: Blob, url: string }> {

		await delay(100);

		if (this.audioChunks.length === 0) {
			await delay(200);
		}
		if (this.audioChunks.length === 0) {
			await delay(1000);
		}
		if (this.audioChunks.length === 0) {
			await delay(1000);
		}

		if (this.audioChunks.length === 0) {
			return null;
		}

		const recordingBlob = new Blob(this.audioChunks, {
			type: this.audioSupportedMimeType,
		});

		if (returnAlsoBlob) {
			return {
				blob: recordingBlob,
				url: URL.createObjectURL(recordingBlob),
			};
		} else {
			return URL.createObjectURL(recordingBlob);
		}
	}

	destroy() {
		this.cancelRecording();
	}

  resetRecordingPosition() {
    this._recordingPosition.next({
      seconds: 0,
      percentage: 0,
      secondsMaximum: 0
    });
  }

	protected stopMediaStream() {
		if (this.mediaStream) {
			this.mediaStream.getTracks().forEach((t) => {
				if (t.readyState === 'live') {
					t.stop();
				}
			});
			this.mediaStream = null;
		}
	}

}
