import {NgModule} from '@angular/core';
import {TutorialSlidesComponent} from './tutorial-slides/tutorial-slides.component';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {HotNewsComponent} from './hot-news/hot-news.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    TutorialSlidesComponent,
    HotNewsComponent
  ],
  entryComponents: [
    TutorialSlidesComponent,
    HotNewsComponent
  ],
    imports: [
        MatButtonModule,
        MatIconModule,
        CommonModule,
        TranslateModule
    ],
  exports: [
    TutorialSlidesComponent,
    HotNewsComponent
  ]
})

export class TutorialModule {}
