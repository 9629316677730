import {UserDetailResponse} from '../../../oj-app-common/studovna-api/responses/user-detail';
import { UserProfile as EshopProfile } from '../../../oj-app-common/eshop-api/models/user-profile';

export class UserProfile {

  eshopId: number;
  email: string;
  name: string;
  eshopLanguage: string;
  language: string;
  portrait: string;
  phone: string;
  street: string;
  city: string;
  zip: string;
  company: string;
  ico: string;
  dic: string;
  companyTesting: string;

  studovnaId: number;
  studovnaUserName: string;
  firstName: string;
  lastName: string;
  languageStudovna: string;
  activeCourse: number;


  public getPortraitUrl(): string {
    if (this.portrait) {
      return this.portrait;
    }

    return 'assets/img/unknown-user.png';
  }

  public patchFromEshop(profile: Partial<EshopProfile>) {
    Object.assign(this, profile);
    if (profile.id) {
      this.eshopId = profile.id;
    }
    if (profile.language) {
      this.eshopLanguage = profile.language;
    }
  }

  public patchFromStudovna(studovnaId: number, profile: UserDetailResponse) {
    Object.assign(this, profile);
    this.languageStudovna = profile.language;
    this.studovnaId = studovnaId;
  }

  public hasCustomPortrait(): boolean {
    return !!this.portrait;
  }

}
