export enum COMPONENT_TYPE {

  CONTENT_TEXT = 'content_text',
  CONTENT_VIDEO = 'content_video',
  CONTENT_PRONOUNCE = 'content_pronounce',

  VOCABULARY = 'vocabulary',
  VOCABULARY_PHRASE = 'phrase',

  TRUE_FALSE = 'true_false',
  NOT_IN_GROUP = 'not_in_group',
  TEST = 'test',
  FILLUP_DRAG_DROP = 'fill_up_drag_drop',
  FILLUP_WRITE = 'fill_up_write',
  SORT_WORDS = 'sort_words',
  SORT_SENTENCES = 'sort_sentences',
  ASSIGN_TO_GROUPS = 'assign_to_groups',
  ASSIGN_TEXT_TO_IMAGE_DRAG_DROP = 'assign_text_to_image',
  ASSIGN_TEXT_TO_IMAGE_FILL_IN = 'assign_text_to_image_fill_in',
  CROSSWORD = 'crossword',

  DEFAULT = 'default',

}
