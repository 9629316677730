import { CourseModel } from './course.model';
import { ProductPrices } from '../../types/product-prices';

export class ProductModel {
	id?: number;
	name: string;
	taught_languages: string[];
	languages: string[];
	courses: CourseModel[];
	price_czk: number | null;
	price_eur: number | null;
	price_monthly_czk: number | null;
	price_monthly_eur: number | null;
	price_3months_czk: number | null;
	price_3months_eur: number | null;
	price_6months_czk: number | null;
	price_6months_eur: number | null;
	price_yearly_czk: number | null;
	price_yearly_eur: number | null;
	vat_rate: number;
	woo_sold: boolean;
	available_oj_info: boolean;
	available_oj_eshop: boolean;
	available_el_cs: boolean;
	available_el_en: boolean;
	available_el_es: boolean;
	available_el_sk: boolean;
	available_el_pl: boolean;
	oj_page: string;
	priority: number;
	image_url: string;
	small_image_url: string;
	description_one_time: string | null;
	description_subscription: string | null;
	prices: { [key: string]: ProductPrices };
}
