import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LeaderboardItemData} from '../../../../models/leaderboard-item-data';

export const LEADERBOARD_USER_TYPES = {
  SIDEBAR_USER: 'sidebar_user',
  LAYOUT_USER: 'layout_user',
  FLOAT_USER: 'float_user'
};

@Component({
  selector: 'app-leaderboard-user-item',
  templateUrl: './leaderboard-user-item.component.html',
  styleUrls: ['./leaderboard-user-item.component.scss']
})
export class LeaderboardUserItemComponent implements OnInit {

  @Input() user: LeaderboardItemData;

  @Input() withPosition = false;

  @Input() userType: string;

  @Output() public addFriendEvent = new EventEmitter<{student: LeaderboardItemData, add: boolean}>();

  @Output() public removeFriendEvent = new EventEmitter<{student: LeaderboardItemData}>();

  @Input() position: number = null;

  @Input() hasBorderBottom = true;

  @Input() showRemoveButton = false;

  constructor() { }

  ngOnInit() {
  }

  clickedAddUser(student: LeaderboardItemData, add = true) {
    this.addFriendEvent.emit({student, add});
  }

  clickedRemoveUser(student: LeaderboardItemData) {
    this.removeFriendEvent.emit({student})
  }

}
