import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';

export const enum layoutStates {
  full = 'full',
  pushed = 'pushed',
  pushedOver = 'pushedOver'
}

export const timing = 500;

export const layoutAnimation = trigger('layoutAnimation', [
  state(layoutStates.full, style({
    width: '100%'
  })),
  state(layoutStates.pushed, style({
    width: 'calc(100% - {{sidebarOpenedWidth}}px)'
  }), {params: {sidebarOpenedWidth: '100%'}}),
  state(layoutStates.pushedOver, style({
    width: '100%',
    left: '-20px'
  }), {params: {sidebarOpenedWidth: '100%'}}),
  transition(`${layoutStates.full}=>${layoutStates.pushed}`, animate(`${timing}ms ease-out`, keyframes([
    style({
      left: 0,
      width: '100%',
      offset: 0
    }),
    style({
      left: '-50px',
      width: 'calc(100% - {{sidebarOpenedWidth}}px)',
      offset: 0.5
    }),
    style({
      left: 0,
      width: 'calc(100% - {{sidebarOpenedWidth}}px)',
      offset: 1
    })
  ]))),
  transition(`${layoutStates.pushed}=>${layoutStates.full}`, animate(`${timing}ms ease-out`, keyframes([
    style({
      left: 0,
      width: 'calc(100% - {{sidebarOpenedWidth}}px)',
      offset: 0
    }),
    style({
      left: '30px',
      width: 'calc(100% - {{sidebarOpenedWidth}}px)',
      offset: 0.5
    }),
    style({
      left: 0,
      width: '100%',
      offset: 1
    })
  ]))),
  transition(`${layoutStates.full}=>${layoutStates.pushedOver}`, animate(`${timing}ms ease-out`, keyframes([
    style({
      left: '0px',
      offset: 0
    }),
    style({
      left: '-30px',
      offset: 0.6
    }),
    style({
      left: '-20px',
      offset: 1
    })
  ]))),
  transition(`${layoutStates.pushedOver}=>${layoutStates.full}`, animate(`${timing}ms ease-out`, keyframes([
    style({
      left: '-20px',
      offset: 0
    }),
    style({
      left: '10px',
      offset: 0.6
    }),
    style({
      left: 0,
      offset: 1
    })
  ])))
]);
