import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isValue'
})
export class IsValuePipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      return true;
    }
    return false;
  }

}
