import {Injectable} from '@angular/core';
import {DailyTaskType} from '../../../models/daily-task-type';
import {Lesson} from '../../../../../oj-app-common/course-structure/models/lesson';
import {CurrentLessonSeriesService} from '../../../services/current-lesson-series-service';

export class ReloadDataModel {
  dailyTaskType: DailyTaskType;
  currentLessonId: number;
  currentLesson: Lesson;
  lessons: Lesson[];
}

@Injectable({
  providedIn: 'root'
})
export class RestoreLessonService {
  storageKey = 'RELOAD_DATA';

  constructor(
    protected lessonSeries: CurrentLessonSeriesService
  ) {
  }

  storeData(dailyTaskType: DailyTaskType = null) {
    const data = new ReloadDataModel();
    data.currentLesson = this.lessonSeries.getCurrentSeries().getCurrent();
    data.lessons = this.lessonSeries.getCurrentSeries().getAll();
    data.currentLessonId = this.lessonSeries.getCurrentSeries().getCurrent().id;

    if (dailyTaskType) {
      data.dailyTaskType = dailyTaskType;
    }

    localStorage.setItem(this.storageKey, JSON.stringify(data));
  }

  loadData(): ReloadDataModel {
    const data = localStorage.getItem(this.storageKey);

    if (data) {
      return JSON.parse(data) as ReloadDataModel;
    }
    return null;
  }

  removeData() {
    localStorage.removeItem(this.storageKey);
  }
}
