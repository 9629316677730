import {NgModule} from '@angular/core';

/* Custom components && modules import */
import {NavigationPanelComponent} from './navigation-panel.component';
import {MatButtonModule} from '@angular/material';
import {CommonModule} from '@angular/common';
import { UserInfoComponent } from '../user-info/user-info.component';
import {TranslateModule} from '@ngx-translate/core';
import {UserInfoModule} from '../user-info/user-info.module';
import {SecretModalSettingsModule} from "../secret-modal-settings/secret-modal-settings.module";

@NgModule({
  declarations: [
    NavigationPanelComponent
  ],
  imports: [
    MatButtonModule,
    CommonModule,
    TranslateModule,
    UserInfoModule,
    SecretModalSettingsModule
  ],
  providers: [
  ],
  exports: [
    NavigationPanelComponent
  ]
})
export class NavigationPanelModule {
}
