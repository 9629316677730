import {NgModule} from '@angular/core';
import {PaymentDialogComponent} from './components/payment-dialog/payment-dialog.component';
import {MatButtonModule, MatCheckboxModule, MatIconModule, MatSlideToggleModule} from '@angular/material';
import { PaymentPlansContentComponent } from './components/contents/payment-plans-content/payment-plans-content.component';
import { PlansPeriodsContentComponent } from './components/contents/plans-periods-content/plans-periods-content.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {PluralPipeModule} from '../../pipes/plural-pipe.module';
import {RubyCalculatorService} from '../../pages/base-layout-page/pages/eshop-page/services/ruby-calculator-service';
import {ShopService} from '../../../../oj-app-common/shop/services/shop.service';
import { LicensePaymentContentMk2Component } from './components/contents/license-payment-content-mk2/license-payment-content-mk2.component';
import { CurrencyDropdownComponent } from './components/currency-dropdown/currency-dropdown.component';
import {BsModalRef, ModalModule} from "ngx-bootstrap/modal";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
    PaymentDialogComponent,
    PaymentPlansContentComponent,
    PlansPeriodsContentComponent,
    LicensePaymentContentMk2Component,
    CurrencyDropdownComponent
  ],
  imports: [
    ModalModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    CommonModule,
    TranslateModule,
    PluralPipeModule,
    BsDropdownModule.forRoot(),
    NgbDropdownModule
  ],
    exports: [
        PaymentDialogComponent,
        PaymentPlansContentComponent
    ],
  entryComponents: [
    LicensePaymentContentMk2Component
  ],
  providers: [
    BsModalRef,
    RubyCalculatorService,
    ShopService
  ]
})

export class PaymentDialogsModule {}
