import {Injectable} from '@angular/core';
import {Course} from '../../../oj-app-common/course-structure/models/course';
import {CurrentCourseService} from './current-course-service';
import {EshopApi} from '../../../oj-app-common/eshop-api/eshop-api';
import {AppTranslationService} from './app-translation.service';
import {CurrentUserService} from '../auth/current-user.service';
import {AppConfig} from '../config/app-config';
import {Observable, Subject} from 'rxjs';
import {lang} from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HelpcrunchService {

  protected initialized = false;
  protected isHelpCrunchSourceScriptAttached = false;

  protected _unreadChatCountChanged = new Subject<number>();
  protected unreadChatCount = 0;

  protected fixingChatInterval = null;
  protected chatIsVisible = false;

  constructor(
    protected appSettings: AppConfig,
    protected currentUserService: CurrentUserService,
    protected appTranslationService: AppTranslationService,
    protected eshopApi: EshopApi,
    protected currentCourseService: CurrentCourseService,
  ) {

    this.currentUserService.onLogout.subscribe(
      () => {
        this.logout();
      }
    );

    this.currentCourseService.onSwitchCourse.subscribe(
      (course: Course) => {
        this.updateLastCourse();
      }
    );
  }

  get unreadChatCountChanged(): Observable<number> {
    return this._unreadChatCountChanged.asObservable();
  }

  get unreadCount(): number {
    return this.unreadChatCount;
  }

  public openChat() {
    if (this.initialized) {
      window['HelpCrunch']('openChat');
    }
  }

  public async initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;

    if (!this.appSettings.helpcrunch) {
      return;
    }

    if (!this.isHelpCrunchSourceScriptAttached) {
      (function(w: any, d) {
        w.HelpCrunch = function() {
          w.HelpCrunch.q.push(arguments);
        };
        w.HelpCrunch.q = [];

        function r() {
          const s1 = document.createElement('script');
          s1.async = true;
          s1.type = 'text/javascript';
          s1.text = `window.helpcrunchSettings = {    organization: 'easylingo',    appId: '3671969c-c424-4083-8648-d1beac2a785a',  };`;
          (d.body || d.head).appendChild(s1);

          const s = document.createElement('script');
          s.async = true;
          s.type = 'text/javascript';
          s.text = `(function(w,d){var hS=w.helpcrunchSettings;if(!hS||!hS.organization){return;}var widgetSrc='https://'+hS.organization+'.widget.helpcrunch.com/';w.HelpCrunch=function(){w.HelpCrunch.q.push(arguments)};w.HelpCrunch.q=[];function r(){if (d.querySelector('script[src="' + widgetSrc + '"')) { return; }var s=d.createElement('script');s.async=1;s.type='text/javascript';s.src=widgetSrc;(d.body||d.head).appendChild(s);}if(d.readyState === 'complete'||hS.loadImmediately){r();} else if(w.attachEvent){w.attachEvent('onload',r)}else{w.addEventListener('load',r,false)}})(window, document)`;
          (d.body || d.head).appendChild(s);
        }

        r();

      })(window, document);
      this.isHelpCrunchSourceScriptAttached = true;
    }

    const hashedUserId = await this.getUserIdHash(this.currentUserService.eshopId);

    const userData = {
      email: this.currentUserService.profile.email,
      name: this.currentUserService.profile.name,
      user_id: hashedUserId,
    };

    const currentCourse = this.currentCourseService.course;
    let currentCourseName = '';
    if (currentCourse) {
      currentCourseName = currentCourse.name;
    }
    const richUserData = {
      studovna_user_id: this.currentUserService.studovnaId,
      eshop_user_id: this.currentUserService.eshopId,
      last_course: currentCourseName,
    };

    window['HelpCrunch']('init', this.appSettings.helpcrunch.organizationDomain, {
      applicationId: this.appSettings.helpcrunch.applicationId,
      applicationSecret: this.appSettings.helpcrunch.applicationSecret,
      user: userData,
    });

    window['HelpCrunch']('updateUserData', richUserData);

    window['HelpCrunch']('onReady', () => {

      const language = this.appTranslationService.currentLanguage;
      // window['HelpCrunch']('setPhraseList', language);

      switch (language) {
        case 'en':
          window['HelpCrunch']('setPhraseList', 'en');
          break;
        case 'es':
          window['HelpCrunch']('setPhraseList', 'es');
          break;
        case 'cs':
          window['HelpCrunch']('setPhraseList', 'cz');
          break;
        default:
          window['HelpCrunch']('setPhraseList', 'en');
      }

      // window['HelpCrunch']('onNewUnreadChat', (data) => {
      //   console.log('HelpCrunch onNewUnreadChat', data);
      //   this.unreadChatCount = +data.unreadChats || 0;
      //   if (this.unreadChatCount > 0) {
      //     // this.audioClip.play();
      //   }
      //   this._unreadChatCountChanged.next(this.unreadChatCount);
      // });

    });

    window['HelpCrunch']('onChatClose', () => {
      this.chatIsVisible = false;
      if (this.fixingChatInterval) {
        clearInterval(this.fixingChatInterval);
        this.fixingChatInterval = null;
      }
    });

    this.appTranslationService.changedLanguage.subscribe(
      (language) => {
        switch (language) {
          case 'en':
            window['HelpCrunch']('setPhraseList', 'en');
            break;
          case 'es':
            window['HelpCrunch']('setPhraseList', 'es');
            break;
          case 'cs':
            window['HelpCrunch']('setPhraseList', 'cz');
            break;
          default:
            window['HelpCrunch']('setPhraseList', 'en');
        }
      }
    );

  }

  public logout() {
    window['HelpCrunch']('logout');
    this.initialized = false;
  }

  protected async getUserIdHash(userId: number) {
    return this.eshopApi.hashStudentId(userId, this.currentUserService.token);
  }

  protected updateLastCourse() {
    const c = this.currentCourseService.course;
    if (this.initialized && c) {
      window['HelpCrunch']('updateUserData', {
        last_course: c.name
      });
    }
  }
}

