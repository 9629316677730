import {Component, Input, OnInit} from '@angular/core';
import {AppConfig} from "../../config/app-config";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  showBanner = false;

  @Input() theme: 'dark' | 'light' = 'dark';

  constructor(
    protected appConfig: AppConfig,
    protected translationService: TranslateService
  ) { }

  ngOnInit() {
    if (this.translationService.currentLang === 'cs') {
      this.showBanner = true;
    }
  }

  async buyGiftClick(e: Event) {
    e.preventDefault();
    window.open('https://jazykovedarky.cz/', '_blank');
  }
}
