import { MethodDescription } from '../method-description';

export const IsUsernameFreeMethodDescription = new MethodDescription();

IsUsernameFreeMethodDescription.name = 'isEmailFree';
IsUsernameFreeMethodDescription.fields = [
	'email',
	'userId',
];
IsUsernameFreeMethodDescription.usesToken = true;
