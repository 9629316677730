import {Injectable} from '@angular/core';
import {CurrentCourseService} from './current-course-service';
import {Lesson} from '../../../oj-app-common/course-structure/models/lesson';
import {Chapter} from '../../../oj-app-common/course-structure/models/chapter';
import {Week} from '../../../oj-app-common/course-structure/models/week';
import {LessonSeries} from '../models/lesson-series';

@Injectable()
export class CurrentLessonSeriesService {

  protected currentSeries: LessonSeries;

  constructor(
    protected currentCourse: CurrentCourseService,
  ) {

  }

  getCurrentSeries(): LessonSeries {

    return this.currentSeries;
  }

  startStudyWithGivenLessons(
    lessons: { id: number, name: string }[],
    currentLesson: Lesson = null,
    alwaysStartExercises = false,
    markAccessToLessons = true,
    whatToDoAfterEnd: () => Promise<void> = null,
    markAsExercise = false,
  ): boolean {

    const lessonSeries = [];

    for (const lessonDef of lessons) {
      const lesson = this.currentCourse.getLessonById(lessonDef.id);
      if (lesson) {
        if (lesson.trialOnly) {
          // Musíme vyfiltrovat trial-only bloky
          if (!this.currentCourse.course.isTrial) {
            continue;
          }
        }

        lessonSeries.push(lesson);
      }
    }

    if (lessonSeries.length) {
      const series = new LessonSeries(lessonSeries, alwaysStartExercises, currentLesson);
      series.markLessonAccess = markAccessToLessons;
      series.markAsExercise = markAsExercise;
      series.afterReachingEndAction = whatToDoAfterEnd;
      this.currentSeries = series;
      return true;
    }

    return false;

  }

  startStudyWithThisSeries(series: LessonSeries): boolean {
    this.currentSeries = series;
    return true;
  }

  startStudyOnExactPoint(lesson: Lesson, whatToDoAfterEnd: () => Promise<void> = null, peekMode = false): boolean {

    const lessonSeries = [];

    const location = this.currentCourse.locateLessonBlockById(lesson.id);
    if (location) {
      const week = location.week;

      for (const block of week.blocks) {
        for (const iteratedLesson of block.lessons) {

          if (iteratedLesson.trialOnly) {
            // Musíme vyfiltrovat trial-only bloky
            if (!this.currentCourse.course.isTrial) {
              continue;
            }
          }

          lessonSeries.push(iteratedLesson);
        }
      }

      const series = new LessonSeries(lessonSeries, false, lesson);
      series.setPositionInCourse(location.chapter, location.week);
      series.afterReachingEndAction = whatToDoAfterEnd;
      series.peekMode = peekMode;
      this.currentSeries = series;
      return true;
    }

    return false;

  }

  startStudyingNextChapter(lesson: Lesson) {
    let nextChapter: Chapter = null;
    let nextWeek: Week = null;

    const position = this.currentCourse.locateLessonBlockById(lesson.id);
    if (!position) {
      throw new Error('Lesson not found in current course');
    }

    const nextWeekIndex = null;
    const thisWeekIndex = position.chapter.weeks.indexOf(position.week);
    if (position.chapter.weeks[thisWeekIndex + 1]) {
      nextChapter = position.chapter;
      nextWeek = position.chapter.weeks[thisWeekIndex + 1];
    } else {
      const thisChapterIndex = this.currentCourse.course.chapters.indexOf(position.chapter);
      if (this.currentCourse.course.chapters[thisChapterIndex + 1]) {
        nextChapter = this.currentCourse.course.chapters[thisChapterIndex + 1];
        nextWeek = nextChapter.weeks[0];
      } else {
        nextChapter = null;
        nextWeek = null;
      }
    }

    if (!nextChapter || !nextWeek) {
      return null;
    }

    const firstLesson = nextWeek.blocks[0].lessons[0];
    if (firstLesson) {
      return this.startStudyOnExactPoint(firstLesson);
    }

    return null;
  }

}
