import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DashboardModel} from '../models/shop/dashboard.model';
import {ProductModel} from '../models/shop/product.model';
import { ShopFilters } from '../models/shop/shop-filters';

export const SHOP_BASE_URL = 'https://ruby-api.onlinejazyky.cz/api';

export const WOO_BASE_URL = 'https://easylingo.com/es2/externi-objednavka/';

@Injectable()
export class ShopService {

  constructor(
    protected http: HttpClient
  ) { }

  get dashboard(): Observable<DashboardModel> {
    return this.http
      .get(`${SHOP_BASE_URL}/dashboard`, {})
      .pipe(map((res: any) => {
        return res as DashboardModel;
      }));
  }

  get products(): Observable<ProductModel[]> {

    return this.http
      .get(`${SHOP_BASE_URL}/products`, {})
      .pipe(map((res: any) => {
        return res as ProductModel[];
      }));
  }

  getProductsWithFilters(filters?: ShopFilters): Observable<ProductModel[]> {

  	if (!filters) {
  		return this.products;
	}

  	let params = new HttpParams();
  	if (filters.categories) {
  		filters.categories.forEach((category) => { params = params.append('categories[]', category); });
	}
  	if (filters.taught_languages) {
  		filters.taught_languages.forEach((lang) => { params = params.append('taught_languages[]', lang); });
	}

    return this.http
      .get(`${SHOP_BASE_URL}/products`, {
      	params,
	  })
      .pipe(map((res: any) => {
        return res as ProductModel[];
      }));
  }

}
