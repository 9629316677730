import {NgModule} from "@angular/core";
import {SlideshowComponent} from "./slideshow.component";
import {CarouselModule} from "primeng/carousel";
import {MatButtonModule} from "@angular/material/button";
import {PluralPipeModule} from '../../pipes/plural-pipe.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CarouselModule,
    MatButtonModule,
    PluralPipeModule,
    TranslateModule
  ],
  exports: [
    SlideshowComponent
  ],
  declarations: [
    SlideshowComponent
  ]
})

export class SlideshowModule {}
