import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {TutorialSlidesComponent} from '../tutorial-slides/tutorial-slides.component';
import {firstTutorial, firstTutorialEs} from '../definitions/first';
import {TutorialSet} from '../models/tutorial-set';
import {TUTORIAL} from '../models/tutorial.enum';
import {TutorialService} from '../../../services/tutorial.service';
import {Router} from '@angular/router';
import {AppTranslationService} from '../../../services/app-translation.service';
import {ModalDirective} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-intro-tutorial-modal',
  templateUrl: './intro-tutorial-modal.component.html',
  styleUrls: ['./intro-tutorial-modal.component.scss']
})
export class IntroTutorialModalComponent implements OnInit {

  public tutorial:TutorialSet = firstTutorial;

  public tutorialToPass = TUTORIAL.first;

  public redirectLocation = null;

  public afterClose: () => void;

  @ViewChild('staticModal', {static: false}) modal: ModalDirective;

  @ViewChild(TutorialSlidesComponent, {static: false}) tutorialSlides: TutorialSlidesComponent;

  constructor(
    protected tutorialService: TutorialService,
    protected router: Router,
    protected appTranslationService: AppTranslationService
  ) { }

  ngOnInit() {
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.tutorialSlides.next();
    }
    if (event.key === 'Esc') {
      event.preventDefault();
      this.tutorialSlides.clickedSkip();
    }
  }

  tutorialFinished(e) {
    this.tutorialService.markAsPassed(this.tutorialToPass);
    this.hide();
  }

  show(
    tutorial = firstTutorial,
    tutorialToPass = TUTORIAL.first,
    redirectLocation = null,
    afterClose: () => void | null
  ) {
    this.tutorial = tutorial;

    if (tutorial === firstTutorial) {
      this.tutorial = this.getTutorialForCurrentLanguage();
    }

    this.tutorialToPass = tutorialToPass;
    this.redirectLocation = redirectLocation;
    this.afterClose = afterClose;
    this.modal.show();
  }

  getTutorialForCurrentLanguage(): TutorialSet {
    const lang = this.appTranslationService.currentLanguage;
    switch (lang) {
      case 'es':
        return firstTutorialEs;
      default:
        return firstTutorial;
    }
  }

  hide() {
    if (this.redirectLocation) {
      this.router.navigate([this.redirectLocation]);
    }
    if (this.afterClose) {
      this.afterClose();
    }
    this.modal.hide();
  }
}
