import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {WooProductModel} from './models/woo-product.model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {WooOrderModel} from './models/woo-order.model';
import {LANGUAGE_TYPES} from '../misc/language-types.enum';
import {CurrentUserService} from '../../src/app/auth/current-user.service';

export const WOO_BASE_URL = 'https://www.onlinejazyky.cz/info/wp-json/wc/v3';
export const WOO_USERNAME = 'ck_a0535784f7d13071ec98a7c45638e50ecd00a60f';
export const WOO_PASS = 'cs_2231725740de62d9f425a585f0918dc16d070458';
export const WOO_AUTOLOGIN_URL = 'https://www.onlinejazyky.cz/info/wp-json/oj-autologin/incoming-user/';

export const WOO_CHECKOUT = 'https://easylingo.cz/handsome-checkout/objednavka/?add-to-cart=';
export const WOO_CHECKOUT_OJ = 'https://www.onlinejazyky.cz/info/handsome-checkout/objednavka/?add-to-cart=';

@Injectable()
export class WooService {
  public WOO_CATEGORIES = {
    anglictina: {
      id: 0,
      language: LANGUAGE_TYPES.ENGLISH
    },
    francouzstina: {
      id: 0,
      language: LANGUAGE_TYPES.FRENCH
    },
    italstina: {
      id: 0,
      language: LANGUAGE_TYPES.ITALIAN
    },
    nemcina: {
      id: 0,
      language: LANGUAGE_TYPES.GERMAN
    },
    nezarazene: {
      id: 0,
      language: LANGUAGE_TYPES.UNKNOWN
    },
    rustina: {
      id: 0,
      language: LANGUAGE_TYPES.RUSSIAN
    },
    spanelstina: {
      id: 0,
      language: LANGUAGE_TYPES.SPANISH
    },
    'nejazykove-kurzy': {
      id: 0,
      language: LANGUAGE_TYPES.UNKNOWN
    }
  };
  private STORE_KEY = 'WOO_PRODUCTS';

  constructor(
    protected httpClient: HttpClient,
    protected currentUser: CurrentUserService
  ) { }

  public getProducts(categoryId?: number): Observable<WooProductModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic '+ btoa(`${WOO_USERNAME}:${WOO_PASS}`)
      })
    }
    return this.httpClient
      .get(`${WOO_BASE_URL}/products?per_page=100${categoryId ? ('&category=' + categoryId) : ''}`, httpOptions)
      .pipe(map((res: any[]) => {
        return res as WooProductModel[]
      }));
  }

  public retrieveOrder(orderId: number): Observable<WooOrderModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic '+ btoa(`${WOO_USERNAME}:${WOO_PASS}`)
      })
    }
    return this.httpClient
      .get(`${WOO_BASE_URL}/orders/${orderId}`, httpOptions)
      .pipe(map((res: any) => res as WooOrderModel));
  }

  public addProductToCart(productId: number): string {
    // return WOO_CHECKOUT_OJ + productId;
    return `${WOO_AUTOLOGIN_URL}${this.currentUser.token}?redirect=${encodeURIComponent(WOO_CHECKOUT_OJ + productId)}`;
  }

  public getAllProductCategories(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic '+ btoa(`${WOO_USERNAME}:${WOO_PASS}`)
      })
    }
    return this.httpClient
      .get(`${WOO_BASE_URL}/products/categories?per_page=100`, httpOptions)
      .pipe(map((res: any[]) => res as any[]));
  }

  // public saveToStore() {
  //   window.localStorage.setItem(
  //     this.STORE_KEY,
  //     JSON.stringify(this._tutorialPassed),
  //   );
  // }
  //
  // public loadFromStore() {
  //   const stored = window.localStorage.getItem(this.STORE_KEY);
  //   if (stored) {
  //     const parsed = JSON.parse(stored);
  //     if (parsed) {
  //       this._tutorialPassed = parsed;
  //     }
  //   }
  // }
}
