import { ProductPrices } from '../types/product-prices';
import { ShoppableType } from '../types/shoppable-type';
import { min } from 'lodash-es';

export function calculateLowestPrice(prices: ProductPrices): number {

	let possiblePrices = [
		prices.monthly,
		prices.quarterYearly / 3,
		prices.halfYearly / 6,
		prices.yearly / 12,
		prices.oneTime,
	]

  const val = Math.floor(min(possiblePrices.filter(p => !!p)) * 100) / 100;

	return val;

}
