import { SentenceToken } from './sentence-token';

export enum FILLABLE_TOKEN_SIZE {
	XS = 0,
	SM = 1,
	MD = 2,
	LG = 3,
	BLOCK = 4,
}

export function filterOutSentenceTokensWithSize(tokens: SentenceToken[]): SentenceTokenWithSize[] {
	return tokens.filter((token) => !!token['getLength']) as SentenceTokenWithSize[];
}

export abstract class SentenceTokenWithSize extends SentenceToken {

	sizeMode = FILLABLE_TOKEN_SIZE.MD;

	abstract getLength(): number;

	autoSetSize() {
		let length = this.getLength();
		this.sizeMode = SentenceTokenWithSize.calculateDefaultSize(length);
	}

	static calculateDefaultSize(length): FILLABLE_TOKEN_SIZE {
		if (length < 4) {
			return FILLABLE_TOKEN_SIZE.XS;
		}
		if (length < 7) {
			return FILLABLE_TOKEN_SIZE.SM;
		}
		if (length < 11) {
			return FILLABLE_TOKEN_SIZE.MD;
		}
		if (length < 20) {
			return FILLABLE_TOKEN_SIZE.LG;
		}
		return FILLABLE_TOKEN_SIZE.BLOCK;

	}

}
