import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appSize]'
})
export class SizeDirective {
  observer;

  @Output() onElementWidthEvent: EventEmitter<number> = new EventEmitter();
  constructor(
    private el: ElementRef
  ) {
    this.observer = new MutationObserver((mutations) => {
      this.onElementWidthEvent.emit(this.el.nativeElement.children[0].offsetWidth);
    });
    this.observer.observe(this.el.nativeElement, {
      attributes: true
    });
    setTimeout(() => {
      if (this.el.nativeElement.children) {
        this.onElementWidthEvent.emit(this.el.nativeElement.children[0].offsetWidth);
      } else {
        this.onElementWidthEvent.emit(0);
      }
    }, 100);
  }

  @HostListener('window:resize', ['$event.target'])
  onResize() {
    if (this.el.nativeElement.children) {
      this.onElementWidthEvent.emit(this.el.nativeElement.children[0].offsetWidth);
    } else {
      this.onElementWidthEvent.emit(0);
    }
  }

  getSizeOfElement(el: ElementRef) {
    if (el.nativeElement.children) {
      return el.nativeElement.children[0].offsetWidth;
    } else {
      return 0;
    }
  }

}
