import { MethodDescription } from '../method-description';

export const VerifyAppStoreReceiptDescription = new MethodDescription();

VerifyAppStoreReceiptDescription.name = 'verifyAppStoreReceipt';
VerifyAppStoreReceiptDescription.fields = [
	'userId',
	'transactionId',
	'appIdentifier',
	'receipt',
];
VerifyAppStoreReceiptDescription.usesToken = true;
