import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class UserPreferencesService {
  protected storageKey = 'userPrefs';

  preferencesChanged: Subject<any> = new Subject<any>();

  protected values: { [key: string]: any } = {};

  constructor() {
    this.load();
  }

  save() {
    window.localStorage.setItem(this.storageKey, JSON.stringify(this.values));
  }

  protected load() {
    const loaded = window.localStorage.getItem(this.storageKey);
    if (loaded) {
      try {
        const val = JSON.parse(loaded);
        if (val) {
          this.values = val;
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  set(key: string, value: any, save = true) {
    this.values[key] = value;
    if (save) {
      this.save();
    }
    this.preferencesChanged.next({key, value});
  }

  get(key: string, defaultValue = undefined) {

    if (typeof this.values[key] !== 'undefined') {
      return this.values[key];
    }
    return defaultValue;
  }
}
