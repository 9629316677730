import { Pipe, PipeTransform } from '@angular/core';
import {VocabularyTrainingWord} from '../../../oj-app-common/vocabulary/models/vocabulary-training-word';
import {TRAINING_DIRECTION} from '../../../oj-app-common/vocabulary/models/training-direction.enum';

@Pipe({
  name: 'isWordLongForTraining'
})
export class IsWordLongForTrainingPipe implements PipeTransform {

  transform(word: string, trainingWord: VocabularyTrainingWord, trainingDirection: TRAINING_DIRECTION, side: 'front' | 'back'): any {

    if (side === 'front') {
      if (trainingDirection === TRAINING_DIRECTION.FROM_FOREIGN) {
        if (trainingWord.prefix) {
          word += trainingWord.prefix + ' ';
        }
        if (trainingWord.postfix) {
          word += ' ' + trainingWord.postfix;
        }
      }
    } else {
      if (trainingDirection !== TRAINING_DIRECTION.FROM_FOREIGN) {
        if (trainingWord.prefix) {
          word += trainingWord.prefix + ' ';
        }
        if (trainingWord.postfix) {
          word += ' ' + trainingWord.postfix;
        }
      }
    }

    let w = '';

    w += word;

    return w.length >= 18;
  }

}
