import {Injectable} from '@angular/core';
import {OverlayService} from '../overlay-dialog/overlay-service';
import {VideoLayerComponent} from './video-layer.component';
import {UserPreferencesService} from '../../../../oj-app-common/services/user-preferences.service';
import {CurrentUserService} from '../../auth/current-user.service';
import {Video} from '../../../../oj-app-common/ruby/models/video';
import {RubyService} from '../../../../oj-app-common/ruby/ruby.service';
import {StudovnaApi} from '../../../../oj-app-common/studovna-api/studovna-api';
import {AppTranslationService} from '../../services/app-translation.service';
import {VideoModel} from './video.model';
import {TrialEndVideoCz, TrialEndVideoSk} from './trial-end-video';

@Injectable({
  providedIn: 'root'
})
export class VideoLayerManagerService {

  protected PREF_KEY_LAST_TIME = 'tutorialVideoLastSeen';

  protected MINIMUM_INTERVAL_BETWEEN_VIDEOS = 1000 * 60 * 10;

  protected PREF_KEY = 'tutorialVideoEnabled';

  constructor(
    protected overlayService: OverlayService,
    protected userPrefs: UserPreferencesService,
    protected currentUser: CurrentUserService,
    protected rubyApi: RubyService,
    protected studovnaApi: StudovnaApi,
    protected appTranslateService: AppTranslationService,
  ) {

    this.currentUser.onLogout.subscribe(
      () => {
        this.userPrefs.set(this.PREF_KEY_LAST_TIME, 0);
      }
    );

  }

  get tutorialVideosEnabled(): boolean {
    return this.userPrefs.get(this.PREF_KEY, true);
  }

  public async enableTutorialVideos(enable: boolean, saveToBackend = true): Promise<void> {
    this.userPrefs.set(this.PREF_KEY, enable);
    if (saveToBackend) {
      return this.studovnaApi.setSettings(
        this.currentUser.studovnaId,
        this.currentUser.token,
        {
          showHelpBox: enable,
        }
      );
    } else {
      return Promise.resolve();
    }
  }

  public async possibleOpportunityToShowVideo() {

    if (!this.tutorialVideosEnabled) {
      return;
    }

    if (this.didPassEnoughTimeFromLastVideo()) {
      this.markVideoShowTime();
      const videoResult = await this.loadNextVideoAndShowIt();
      if (videoResult && videoResult.watched) {
        this.rubyApi.videosSeen(
          this.currentUser.token,
          videoResult.video.id,
        );
      }
    }
  }

  async loadNextVideoAndShowIt(): Promise<{ video: Video, watched: boolean }> {
    const videoDataFromApi = await this.rubyApi.videosAvailable(
      this.currentUser.token,
    );

    if (videoDataFromApi && videoDataFromApi.next) {
      const watched = await this.showVideoLayer(videoDataFromApi.next);
      return {
        video: videoDataFromApi.next,
        watched,
      };
    }
    return null;
  }

  async showVideoLayer(videoSources: Video): Promise<boolean> {

    if (!videoSources || !videoSources.square600px) {
      return;
    }

    let resolver;

    const returnedPromise = new Promise<boolean>(
      (resolve, reject) => {
        resolver = resolve;
      },
    );

    this.overlayService.show<VideoLayerComponent>(VideoLayerComponent).then(
      (response) => {
        const dialog = response.componentRef.instance;
        dialog.initialize(
          {
            urlXl1x: videoSources.square420px,
            urlMd2x: videoSources.square600px,
            urlXl2x: videoSources.square840px,
          }
        );
        dialog.didHide.subscribe(
          (v) => {
            resolver(v);
          },
        );
      },
    );

    return returnedPromise;

  }

  protected getTimeFromLastVideo(): number {
    const last = this.userPrefs.get(this.PREF_KEY_LAST_TIME, null);
    if (!last) {
      return Infinity;
    }
    return Date.now() - last;
  }

  protected markVideoShowTime() {
    this.userPrefs.set(this.PREF_KEY_LAST_TIME, Date.now());
  }

  protected didPassEnoughTimeFromLastVideo(): boolean {
    return (this.getTimeFromLastVideo() >= this.MINIMUM_INTERVAL_BETWEEN_VIDEOS);
  }

  async showTemp(): Promise<void> {

    let resolver;

    const returnedPromise = new Promise<void>(
      (resolve, reject) => {
        resolver = resolve;
      }
    );

    this.overlayService.show<VideoLayerComponent>(VideoLayerComponent).then(
      (response) => {
        const dialog = response.componentRef.instance;
        dialog.initialize({
          urlXl1x: 'https://www.onlinejazyky.cz/temp/video/ukazka.840.mp4',
          urlXl2x: 'https://www.onlinejazyky.cz/temp/video/ukazka.840.mp4',
          urlMd2x: 'https://www.onlinejazyky.cz/temp/video/ukazka.840.mp4'
        });
        dialog.didHide.subscribe(
          (v) => {
            resolver(v);
          }
        );
      }
    );

    return returnedPromise;

  }

  async showTrialEndPopup(doAfterEnd: () => void = null): Promise<boolean> {
    const lang = this.appTranslateService.currentLanguage;

    let video: VideoModel = null;
    if (lang === 'sk') {
      video = TrialEndVideoSk;
    } else {
      video = TrialEndVideoCz;
    }

    if (video) {
      return this.showVideoNow(video, 'trial-end-button', doAfterEnd);
    }

    return false;
  }

  protected showVideoNow(videoModel: VideoModel, customButtonText = '', doAfter: () => void = null): Promise<boolean> {

    return new Promise(
      (resolve, reject) => {
        this.overlayService.show<VideoLayerComponent>(VideoLayerComponent).then(
          (response) => {
            const dialog = response.componentRef.instance;
            dialog.initialize(videoModel, customButtonText);
            dialog.didHide.subscribe(
              (v) => {
                if (doAfter) {
                  doAfter();
                }
                resolve(v);
              },
            );
          },
        );
      }
    );

  }
}
