import {
  Component, ElementRef,
  OnInit, ViewChild
} from '@angular/core';
import {BaseOverlayDialogComponent} from '../base-overlay-dialog/base-overlay-dialog';
import {_} from '../../../../../oj-app-common/i18n/translate-placeholder';
import confetti from 'canvas-confetti';

@Component({
  selector: 'certificate-dialog',
  templateUrl: 'certificate-dialog.html',
  styleUrls: ['certificate-dialog.scss']
})
export class CertificateDialog extends BaseOverlayDialogComponent implements OnInit {

  public image: string;

  public courseTitle: string;

  public courseText: string;

  public buttonText = 'Pokračovat';

  public buttonAction: () => void = null;

  @ViewChild('myCanvas', {static: false}) myCanvas: ElementRef<HTMLCanvasElement>;

  ngOnInit() {
    if (!this.buttonText) {
      this.buttonText = this.translateService.instant('Pokračovat');
    }
    if (!this.swipeText || this.swipeText.length === 0) {
      this.swipeText = 'Kliknutím sem zobrazíte výsledky';
    }
  }

  showConfetti() {
    setTimeout(() => {

      if (this.myCanvas) {
        confetti.create(this.myCanvas.nativeElement, {
          resize: true
        })({
          shapes: ['square'],
          particleCount: 350,
          zIndex: 9999,
          spread: 100,
          ticks: 400,
          gravity: 0.3
        });
      }

    }, 350);
  }

  initialize(courseTitle: string, courseImage: string) {
    this.image = courseImage;
    this.courseText = _('certificate-dialog-text').replace(/[%{]+\s?course\s?[%}]+/, courseTitle);
    this.courseTitle = courseTitle;
    this.showConfetti();
  }

  setupButton(text: string, action: () => void) {
    this.buttonText = text;
    this.buttonAction = action;
  }

  clickedButton() {
    this.buttonAction ? this.buttonAction() : null;
    this.close();
  }


}
