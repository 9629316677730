import {NgModule} from '@angular/core';
import {ActivitySidebarComponent} from './activity-sidebar.component';
import {CommonModule} from '@angular/common';
import {MatButtonModule, MatIconModule, MatTabsModule} from '@angular/material';
import {BaseLayoutPageModule} from '../../base-layout-page.module';
import {TranslateModule} from '@ngx-translate/core';
import {CircleProgressbarModule} from '../../../../components/circle-progressbar/circle-progressbar.module';
import {ChartsModule} from 'ng2-charts';
import {PluralPipeModule} from '../../../../pipes/plural-pipe.module';
import {ProgressBarModule} from 'angular-progress-bar';
import {SuperRadialMeterModule} from '../super-radial-meter/super-radial-meter.module';
import {ThreeFiguresModule} from '../three-figures/three-figures.module';

@NgModule({
  declarations: [
    ActivitySidebarComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    TranslateModule,
    CircleProgressbarModule,
    MatIconModule,
    MatButtonModule,
    ChartsModule,
    PluralPipeModule,
    ProgressBarModule,
    SuperRadialMeterModule,
    ThreeFiguresModule
  ],
  exports: [
    ActivitySidebarComponent
  ],
  entryComponents: [
    ActivitySidebarComponent
  ]
})

export class ActivitySidebarModule {}
