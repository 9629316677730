import {NgModule} from '@angular/core';
import {SuperRadialMeterComponent} from './super-radial-meter.component';

@NgModule({
  declarations: [
    SuperRadialMeterComponent
  ],
  exports: [
    SuperRadialMeterComponent
  ]
})

export class SuperRadialMeterModule {}
