import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberLength'
})
export class NumberLengthPipe implements PipeTransform {

  transform(value: number): number {
    if (!value) {
      return 0;
    }

    const str = value.toString();
    return str.length;
  }

}
