import {Component, OnInit} from '@angular/core';
import {BaseOverlayDialogComponent} from '../base-overlay-dialog/base-overlay-dialog';

@Component({
  selector: 'app-delete-account-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrls: ['./delete-account-dialog.component.scss']
})
export class DeleteAccountDialogComponent extends BaseOverlayDialogComponent implements OnInit {

  public supportEmail = '';
  public accountEmail = '';

  public dialogHidden = true;

  buttonAction: () => void;

  ngOnInit() {
    this.supportEmail = this.appSettings.supportEmail;
    this.accountEmail = this.currentUser.profile.email;
  }

  dismiss() {
    this.hide();
  }

  hide() {
    setTimeout(
      () => {
        this.dialogHidden = true;
      },
      50
    );
  }

  show() {
    this.dialogHidden = false;
  }

  confirm() {
    this.didHide.next(true);
    this.hide();
  }

}
