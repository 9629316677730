import sample from 'lodash-es/sample';
import {_, getTranslatedText} from 'oj-app-common/i18n/translate-placeholder';

export function getEvalText(textClass: number): ResultTextStructure {
  const bank = EVAL_TEXTS.cs[textClass] || [];
  const sampled: ResultTextStructure = sample(bank);
  sampled.main = getTranslatedText(sampled.main);
  sampled.sub = getTranslatedText(sampled.sub);
  return sampled;
}

export function getEvalTextByPercentage(percentage: number): ResultTextStructure {
  return getEvalText(getClassForPercentage(percentage));
}

export function getClassForPercentage(percentage: number): number {
  if (percentage < 30) {
    return 1;
  }
  if (percentage < 60) {
    return 2;
  }
  if (percentage < 85) {
    return 3;
  }
  if (percentage <= 99) {
    return 4;
  }
  return 5;
}

export interface ResultTextStructure {
  main: string;
  sub: string;
}

export const EVAL_TEXTS = {
  // Překlady musí zajistit ten, kdo tyto texty používá.
  cs: {
    1: [
      {main: _('Nic moc.', false), sub: _('Tohle si zaslouží opravu.', false)},
      {main: _('Ale notak.', false), sub: _('Určitě máte na víc.', false)},
      {main: _('Tohle se moc nepovedlo.', false), sub: _('Nevadí, zkuste to znovu.', false)},
      {main: _('Není každý den posvícení.', false), sub: _('Nevadí, zkuste to znovu.', false)},
      {main: _('Nic není tak zlé, jak to vypadá.', false), sub: _('I za mraky září slunce.', false)},
      {main: _('Zkuste to ještě jednou.', false), sub: _('Cvičení dělá mistra', false)},
    ],
    2: [
      {main: _('Ano, mohlo to být horší.', false), sub: _('Ale taky mnohem lepší!', false)},
      {main: _('Těžko na cvičišti,', false), sub: _('lehko na bojišti.', false)},
      {main: _('Nezoufejte.', false), sub: _('Vše má svůj čas.', false)},
      {main: _('Jednou je lépe,', false), sub: _('jindy hůře.', false)},
      {main: _('Nic není tak zlé, jak to vypadá.', false), sub: _('I za mraky září slunce.', false)},
      {main: _('Zkuste to ještě jednou.', false), sub: _('Cvičení dělá mistra.', false)},
    ],
    3: [
      {main: _('Dobrá práce.', false), sub: _('', false)},
      {main: _('Solidní výkon.', false), sub: _('', false)},
      {main: _('To by šlo.', false), sub: _('', false)},
      {main: _('Nebylo to špatné,', false), sub: _('ale stále je co zlepšovat.', false)},
    ],
    4: [
      {main: _('Skvělý výsledek!', false), sub: _('Klobouk dolů.', false)},
      {main: _('Bravo!', false), sub: _('K dokonalosti tomu chybí jen kousek!', false)},
      {main: _('Pravé bohatství', false), sub: _('je ve vědomostech!', false)},
      {main: _('Kuj železo,', false), sub: _('dokud je žhavé.', false)},
      {main: _('Když se chce,', false), sub: _('tak to jde.', false)},
    ],
    5: [
      {main: _('Dokonalá práce!', false), sub: _('K tomu není co dodat.', false)},
      {main: _('Úžasné!', false), sub: _('Jak to děláte?', false)},
      {main: _('Kdo ví všechno,', false), sub: _('neví nic. ;)', false)},
    ]
  }
};
