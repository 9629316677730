import {
  Component,
  OnInit
} from '@angular/core';
import {BaseOverlayDialogComponent} from '../base-overlay-dialog/base-overlay-dialog';
import {CHAPTER_FINISHED_DIALOG_RESULT} from './chapter-finished-dialog-result';
import {Chapter} from '../../../../../oj-app-common/course-structure/models/chapter';
import {Week} from '../../../../../oj-app-common/course-structure/models/week';
import {StudovnaApi} from "../../../../../oj-app-common/studovna-api/studovna-api";
import {CurrentUserService} from "../../../auth/current-user.service";
import {AppConfig} from "../../../config/app-config";


@Component({
  selector: 'chapter-finished-dialog',
  templateUrl: 'chapter-finished-dialog.html',
  styleUrls: ['chapter-finished-dialog.scss']
})
export class ChapterFinishedDialogComponent extends BaseOverlayDialogComponent implements OnInit {
  public chapter: Chapter;
  public week?: Week;
  public isLastWeekOfChapter = false;

  protected studovnaApi: StudovnaApi;
  protected currentUser: CurrentUserService;
  protected appSettings: AppConfig;

  public feedbackText = '';

  public feedbackFieldExpanded = false;
  public displayingThankYou = false;
  public displayShakeWithTextarea = false;

  public feedbackFormEnabled = false;

  ngOnInit() {
    this.swipeText = this.translateService.instant('Kliknutím sem ukončíte studium');
  }

  initializeFeedbackForm(chapterId: number, studovnaApi: StudovnaApi, currentUser: CurrentUserService, appSettings: AppConfig) {
    this.studovnaApi = studovnaApi;
    this.currentUser = currentUser;
    this.appSettings = appSettings;
    this.studovnaApi.chapterMessage(
      this.currentUser.studovnaId,
      this.currentUser.token,
      chapterId
    ).then(
      (message) => {
        if (message) {
          this.feedbackText = message;
        }
      }
    );

    if (this.chapter && this.chapter.allowFeedback && this.chapter.id  && this.studovnaApi && this.isLastWeekOfChapter) {
      this.feedbackFormEnabled = true;
    }

  }

  setChapterAndWeek(chapter: Chapter, week?: Week) {
    this.chapter = chapter;
    this.week = week;


    if (week) {
      const indexOfWeek = this.chapter.weeks.indexOf(week);
      if (indexOfWeek === -1) {
        this.isLastWeekOfChapter = true;
      } else {
        if (this.chapter.weeks[indexOfWeek + 1]) {
          this.isLastWeekOfChapter = false;
        } else {
          this.isLastWeekOfChapter = true;
        }
      }
    } else {
      this.isLastWeekOfChapter = true;
    }

    if (this.chapter && this.chapter.id && this.chapter.allowFeedback && this.studovnaApi && this.isLastWeekOfChapter) {
      this.feedbackFormEnabled = true;
    }
  }

  clickedButton() {
    this.close(CHAPTER_FINISHED_DIALOG_RESULT.CONTINUE);
  }

  clickedSwipeButton(): void {
    this.close(CHAPTER_FINISHED_DIALOG_RESULT.RETURN_TO_DASHBOARD);
  }

  clickedSendFeedback() {

    if (!this.feedbackText || this.feedbackText.length <= 3) {
      this.shakeTextArea();
    } else {

      this.showThankYou();

      this.studovnaApi.addChapterMessage(
        this.currentUser.studovnaId,
        this.currentUser.token,
        this.chapter.id,
        this.feedbackText,
        this.appSettings.ttsOwner,
      ).then(() => {}, (e) => { console.error(e); });

      setTimeout(
        () => {
          this.clickedButton();
        },
        1500
      );

    }


  }

  shakeTextArea() {
    if (this.displayShakeWithTextarea) {
      return;
    }
    this.displayShakeWithTextarea = true;
    setTimeout(
      () => {
        this.displayShakeWithTextarea = false;
      },
      1500
    );
  }

  showThankYou() {
    this.displayingThankYou = true;
  }

  feedbackFieldFocus() {
    this.feedbackFieldExpanded = true;
  }

  feedbackFormBlur() {
    this.feedbackFieldExpanded = false;
  }

}
