export class StudovnaApiError extends Error {


	constructor(
		public errorText: string,
		public errorCode: string,
		protected debuggingMessages: boolean
	) {
		super(errorText);
  }

	toString(): string {
		if (this.debuggingMessages) {
			return this.errorText + ' (' + this.errorCode + ')';
		} else {
			return this.errorText;
		}
	}

}
