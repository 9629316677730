import { Injectable } from '@angular/core';
import {NgxCookiebotService} from '@halloverden/ngx-cookiebot';
import {Cookie} from 'ng2-cookies/ng2-cookies';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class CookieManagementService {

  constructor(private cookiebotService: NgxCookiebotService) {}

// Method to check for marketing consent
  hasMarketingConsent(): boolean {
    if (window['Cookiebot'] && window['Cookiebot'].consent) {
      return window['Cookiebot'].consent.marketing;
    }
    return false;
  }

  // Method to set cookies only if consent is given
  setCookieIfConsented(cookieName: string, cookieValue: string): void {
    this.cookiebotService.onConsentReady$.subscribe(() => {
      if (this.hasMarketingConsent()) {
        Cookie.set(cookieName, cookieValue);
        console.log(`Cookie ${cookieName} set successfully.`);
      } else {
        console.log(`User has not consented to marketing cookies. Cookie ${cookieName} not set.`);
      }
    });
  }

  // Method to get a cookie
  getCookie(cookieName: string): string | null {
    return Cookie.get(cookieName);
  }

  // Method to delete a cookie
  deleteCookie(cookieName: string): void {
    Cookie.delete(cookieName);
  }

  // Method to listen for consent changes
  onConsentChanged(): Observable<void> {
    return this.cookiebotService.onConsentReady$;
  }
}
