export interface Theme {
  name: string;
  properties: any;
}

export const light: Theme = {
  name: 'light',
  properties: {
    '--background-default': 'linear-gradient(to bottom, #e2f3fb, #e8f6fb, #eff9fc, #f7fcfd, #feffff)',

    '--sidebar-background': 'linear-gradient(148.87deg, #1390AE 0%, #0285C0 89.7%, #0084C2 100%)',

    '--primary-gradient': 'radial-gradient(circle, #0084C2 0%, #0285C0 10.3%, #1390AE 100%)',
    '--dialog-bg': '#fff',

    '--box-shadow': 'rgba(0, 149, 218, 0.25)',
    '--border-color': '#E1E0EA',

    '--btn-text-primary': 'rgba(51, 51, 51, 0.4)',
    '--btn-image-color': '#999999',
    '--btn-active-image-color': '#0095DA',

    '--success-color': 'linear-gradient(33.97deg, #B3D871 0%, #C2D588 100%)',

    '--primary-default': '#3782be',
    '--primary-dark': '#24B286',
    '--primary-light': '#B2FFE7',

    '--primary-text': '#00A2E1',

    '--incorrect-default': '#F88F33',

    '--yellow-color': '#f8d11c',

    '--secondary-default': '#C2D588',

    '--error-default': '#f53d3d',
    '--error-dark': '#800600',
    '--error-light': '#FFCECC',

    '--description-color': '#999999',

    '--background-tertiary-shadow': '0 1px 3px 0 rgba(92, 125, 153, 0.5)'
  }
};

export const dark: Theme = {
  name: 'dark',
  properties: {
    '--background-default': 'linear-gradient(148.87deg, #0084C2 0%, #0285C0 10.3%, #1390AE 100%)',

    '--sidebar-background': '#fff',

    '--primary-gradient': 'radial-gradient(circle, #0084C2 0%, #0285C0 10.3%, #1390AE 100%)',
    '--dialog-bg': '#fff',

    '--box-shadow': 'rgba(0, 149, 218, 0.25)',
    '--border-color': '#E1E0EA',

    '--btn-text-primary': 'rgba(255,255,255,0.6)',
    '--success-color': 'linear-gradient(33.97deg, #B3D871 0%, #C2D588 100%)',
    '--btn-image-color': '#999999',
    '--btn-active-image-color': '#0095DA',

    '--primary-default': '#3782be',
    '--primary-dark': '#24B286',
    '--primary-light': '#B2FFE7',

    '--primary-text': '#00A2E1',

    '--secondary-default': '#C2D588',
    '--incorrect-default': '#F88F33',


    '--error-default': '#EF3E36',
    '--error-dark': '#800600',
    '--error-light': '#FFCECC',

    '--description-color': '#999999'
  }
};

export const APP_THEMES = {
  light,
  dark
};
