import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CurrentUserService} from '../../../../auth/current-user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ThemeService} from "../../../../theme/theme.service";
import {APP_THEMES, Theme} from "../../../../theme/theme";
import {LeaderboardStudent} from "../../../../../../oj-app-common/studovna-api/models/leaderboard-student";
import {Subject, Subscription} from "rxjs";
import {LeaderboardItemData} from "../../../../models/leaderboard-item-data";
import {_} from "../../../../../../oj-app-common/i18n/translate-placeholder";
import {LEADERBOARD_TYPE} from "../../pages/leaderboard-page/misc/leaderboard-type.enum";
import {StudovnaApi} from "../../../../../../oj-app-common/studovna-api/studovna-api";
import {RouterHistoryItem, RouterHistoryService} from "../../../../services/router-history.service";
import {MessageService} from "primeng/api";
import {LoadingService} from "../../../../services/loading.service";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {userDisplayNameFromObject} from "../../../../tools/user-display-name";

@Component({
  selector: 'app-basic-top-bar',
  templateUrl: './basic-top-bar.component.html',
  styleUrls: ['./basic-top-bar.component.scss']
})
export class BasicTopBarComponent implements OnInit, OnDestroy {
  loggedUser: any;

  loaded = false;

  currentTheme: Theme = APP_THEMES.light;

  isSearchingUser = false;
  students: LeaderboardStudent[] = [];
  filteredUsers = [];
  searchString = '';
  stringChanged: Subject<string> = new Subject<string>();
  searchingStudent = false;
  searchStringSubscriber: Subscription;

  @Input() title = '';

  @Input() subtitle: string;

  @Input() showBackButton = false;

  @Output() backButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() onlyEshopSection = false;

  @Input() showAddFriendButton = false;

  @ViewChild('searchStudentInput', {static: false}) searchStudentInput: ElementRef;

  constructor(
    private currentUserService: CurrentUserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private themeService: ThemeService,
    private studovnaApi: StudovnaApi,
    private messageService: MessageService,
    protected routerHistoryService: RouterHistoryService,
    private loadingService: LoadingService
  ) {
  }

  ngOnInit() {
    this.themeService
      .themeChanged
      .subscribe(t => this.currentTheme = t);
    this.initUserProfile()
      .then(() => {
        setTimeout(() => this.loaded = true, 400);
      });

    if (this.showAddFriendButton) {
      this.searchStringSubscriber = this.stringChanged
        .pipe(
          debounceTime(500)
        )
        .pipe(
          distinctUntilChanged()
        )
        .subscribe(model => {
          this.searchString = model;
          this.searchByText(this.searchString);
        });
    }
  }

  ngOnDestroy() {
    if (this.searchStringSubscriber) {
      this.searchStringSubscriber.unsubscribe();
    }
  }

  protected async initUserProfile() {
    this.loggedUser = this.currentUserService.profile;
  }

  get pageTitle() {
    const currentPage = this.activatedRoute.snapshot.data['pageTitle'];

    const currentUrl = this.router.url;
    if (currentUrl) {
      if (currentUrl === '/dashboard') {
        return this.loggedUser.name;
      } else {
        return this.translateService.instant(currentPage);
      }
    }
  }

  clickedBackButton(e: Event) {
    e.preventDefault();
    this.backButtonClicked.emit(true);
  }

  clickedAddUser(student: LeaderboardItemData | LeaderboardStudent, add = true, notify = false) {

    const orig = student.isFriend;
    let promise: Promise<void> = null;
    student.isFriend = add;
    if (add) {
      promise = this.studovnaApi.addFriend(
        this.currentUserService.studovnaId,
        this.currentUserService.token,
        student.id
      );
    } else {
      promise = this.studovnaApi.removeFriend(
        this.currentUserService.studovnaId,
        this.currentUserService.token,
        student.id
      );
    }

    if (notify) {
      promise
        .then(r => {
          if (add) {
            this.messageService.add({severity: 'success', detail: _('Profil přidán mezi přátele.')})
          } else {
            this.messageService.add({severity: 'success', detail: _('Profil odebrán z přátel.')})
          }
        })
    }

    promise.catch(
      (e) => {
        student.isFriend = orig;
        console.error(e);
      }
    );

  }

  protected preprocessPortraitImage(src: string) {
    if (!src) {
      return '/assets/img/unknown-user.png';
    }
    if (src.match(/\d+-small/)) {
      return src.replace(/-small/ig, '');
    }
    return src;
  }

  clickedAddStudent() {
    this.isSearchingUser = true;
    setTimeout(() => {
      this.searchStudentInput.nativeElement.focus();
    }, 200);
  }

  clickedCloseAddStudent() {
    this.isSearchingUser = false;
    this.students = [];
    this.searchString = '';
  }

  onFriendClick(userId: number) {
    this.loadingService.show();
    const routerItem = new RouterHistoryItem();
    routerItem.route = '/user-detail';
    routerItem.queryParams = {id: userId};
    this.routerHistoryService.add(routerItem, true);
  }

  searchByText(searchEvent) {
    this.searchingStudent = true;

    const text = this.searchString;

    this.students = [];

    this.searchFriendsByString(text).then(
      (result) => {
        this.students = result.students;
        this.processNamesOfStudents();
        this.searchingStudent = false;
      },
      (e) => {
        this.searchingStudent = false;
        this.students = [];
        console.error(e);
      }
    );


  }

  protected async searchFriendsByString(input: string): Promise<{foundMore: boolean, students: LeaderboardStudent[]}> {

    if (input.length < 3) {
      return {
        foundMore: false,
        students: [],
      };
    }

    const foundMore = false;

    const result = await this.studovnaApi.userList(
      this.currentUserService.studovnaId,
      this.currentUserService.token,
      input,
      21
    );

    const resultLength = result.length;

    return {
      foundMore: resultLength >= 21,
      students: result.slice(0, 20)
    };

  }

  protected processNamesOfStudents() {
    this.students.map(
      (s) => {
        s.displayedName = userDisplayNameFromObject(s);
      }
    );
  }

  searchedStringChanged(text: string) {
    this.stringChanged.next(text);
  }
}
