import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToFixed'
})
export class NumberToFixedPipe implements PipeTransform {

  transform(value: string, places: number): any {
    const number = Number.parseFloat(value);
    console.log(value, number)
    return number.toFixed(places);
  }

}
