import { Pipe, PipeTransform } from '@angular/core';
import {pluralize} from '../tools/pluralize';

@Pipe({
  name: 'plural',
  pure: true
})
export class PluralPipe implements PipeTransform {
  transform(value: number, ...args): string {
    return pluralize.apply(this, [value, ...args]);
  }
}


