import { SentenceToken } from './sentence-token';
import { ANSWER_STATUS_BOOLEAN } from '../../misc/answer-status-boolean';
import { DragDropSentenceToken } from './drag-drop-sentence-token';

export class Sentence {

	id: number;
	tokens: SentenceToken[] = [];
	number: number;
	displayedNumber: number;
	result: ANSWER_STATUS_BOOLEAN = ANSWER_STATUS_BOOLEAN.UNKNOWN_YET;

	availableTokens: DragDropSentenceToken[] = [];
	allTokens: DragDropSentenceToken[] = [];


}
