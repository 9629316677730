import {Component, OnInit} from '@angular/core';
import {RouterHistoryService} from '../../../../../services/router-history.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from '../../../../../../../oj-app-common/ruby/models/subscription';
import {AppConfig} from '../../../../../config/app-config';
import {CurrentUserService} from '../../../../../auth/current-user.service';
import {RubyService} from '../../../../../../../oj-app-common/ruby/ruby.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {

  subscriptions: Subscription[] = [];

  activeSubscriptions: Subscription[] = [];

  subscriptionsInactive: Subscription[] = [];

  public supportEmail = '';

  constructor(
    private routerHistoryService: RouterHistoryService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appConfig: AppConfig,
    private currentUser: CurrentUserService,
    private rubyApi: RubyService
  ) {
  }

  ngOnInit() {
    this.supportEmail = this.appConfig.supportEmail;

    this.loadData();
  }

  async loadData() {
    try {
      const data = await this.rubyApi.getSubscriptions(this.currentUser.token);
      this.activeSubscriptions = data.subscriptions.filter(s => s.is_active);
      this.subscriptionsInactive = data.subscriptions.filter(s => !s.is_active);
    } catch (e) {
      console.error(e);
    }
  }

  backButtonClicked() {
    this.routerHistoryService.goBack(true);
  }

  goToEshop(e: Event) {
    e.preventDefault();

    this.router.navigate(['/eshop'], {queryParams: {currentTab: 1}});
  }
}
