import { LEVEL } from './level';
import {VocabularyWord} from './vocabulary-word';
import {TRAINING_DIRECTION} from './training-direction.enum';

export class VocabularyTrainingWord {

	id: number;
	source: string;
	translation: string;
	level: LEVEL;
	order?: number;
	favorite?: boolean;
	excluded?: boolean;

	wrongAnswer?: string;
	direction?: TRAINING_DIRECTION;
  translationAlternatives?: string[];
	sourceAlternatives?: string[];

  translationDescription?: string;
  extraInfo?: string;
  extraInfoType?: number;
  description?: string;
  avatarText?: string;

	prefix = '';
	postfix = '';

	options?: VocabularyTrainingWord[] = [];

	static createFromObject(object: any): VocabularyTrainingWord {
		let w = new VocabularyTrainingWord();
		w.id = +object.id;
		w.source = object.source;
		w.translation = object.translation;
		w.level = object.level;
		w.order = +object.order;
		w.favorite = !!object.favorite;
		w.excluded = !!object.disabled;
		w.prefix = object.prefix || '';
		w.postfix = object.postfix || '';

		w.translationAlternatives = object.translationAlternatives;
		if (!w.translationAlternatives) {
			w.translationAlternatives = [];
		}
		if (typeof w.translationAlternatives === 'string') {
			const stringAlts = w.translationAlternatives as string;
			w.translationAlternatives = stringAlts.split(';');
		}

		w.sourceAlternatives = object.sourceAlternatives;
		if (!w.sourceAlternatives) {
			w.sourceAlternatives = [];
		}

		if (typeof w.sourceAlternatives === 'string') {
			const stringAlts = w.sourceAlternatives as string;
			w.sourceAlternatives = stringAlts.split(';');
		}

		if (object.options) {
			w.options = object.options.map((opt) => this.createFromObject(opt));
		} else {
			w.options = [];
		}

    w.translationDescription = object.translationDescription || '';
    w.extraInfo = object.extraInfo || '';
    w.extraInfoType = object.extraInfoType;
    w.description = object.description || '';
    w.avatarText = object.avatarText || '';

		return w;
	}

	static createFromVocabularyWord(word: VocabularyWord, order = 0): VocabularyTrainingWord {
		let w = VocabularyTrainingWord.createFromObject(word);
		w.excluded = word.excluded;
		w.order = order;
		return w;
	}

}
