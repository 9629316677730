import { Component, OnInit } from '@angular/core';
import {LeaderboardItemData} from '../../../../../models/leaderboard-item-data';
import {LeaderboardStudent} from '../../../../../../../oj-app-common/studovna-api/models/leaderboard-student';
import { maxBy } from 'lodash-es';
import {userDisplayNameFromObject} from '../../../../../tools/user-display-name';
import {StudovnaApi} from '../../../../../../../oj-app-common/studovna-api/studovna-api';
import {CurrentUserService} from '../../../../../auth/current-user.service';
import {Router} from '@angular/router';
import {LEADERBOARD_USER_TYPES} from '../../../components/leaderboard-user-item/leaderboard-user-item.component';
import {CurrentCourseService} from '../../../../../services/current-course-service';

@Component({
  selector: 'app-friends-leaderboard',
  templateUrl: './friends-leaderboard.component.html',
  styleUrls: ['./friends-leaderboard.component.scss']
})
export class FriendsLeaderboardComponent implements OnInit {
  public data: LeaderboardItemData[] = [];
  public LEADERBOARD_USER_TYPES = LEADERBOARD_USER_TYPES;
  public myLeaderboardData: LeaderboardItemData;

  constructor(
    protected studovnaApi: StudovnaApi,
    protected currentUser: CurrentUserService,
    private router: Router,
    private currentCourseService: CurrentCourseService
  ) { }

  ngOnInit() {
    this.loadData();

    // this.currentCourseService
    //   .onSwitchCourse
    //   .subscribe(() => {
    //     this.loadData();
    //   });
  }

  protected async loadData() {
    let result: LeaderboardStudent[];

    let orderAsString: (''|'month'|'week');
    orderAsString = 'week';

    result = await this.studovnaApi.followList(
      this.currentUser.studovnaId,
      this.currentUser.token,
      '',
      orderAsString
    );

    const data = result.map(i => this.createItemFromResponse(i));

    const myData = data.filter(d => d.id === this.currentUser.profile.studovnaId);

    if (myData && myData.length > 0) {
      this.myLeaderboardData = myData[0];
    }

    if (data) {
      this.data = data.slice(0, 3);
    }

  }


  protected createItemFromResponse(source: LeaderboardStudent, preferredLang = ''): LeaderboardItemData {

    const data:LeaderboardItemData = {
      id: source.id,
      name: userDisplayNameFromObject(source),
      language: '',
      portrait: this.preprocessPortraitImage(source.image),
      position: source.position,
      points: source.points,
      shieldNumber: 0,
      isFriend: !!source.isFriend,
    };

    if (preferredLang && source.languageList) {
      const languageData = source.languageList.find(
        (languageInList) => languageInList.language === preferredLang
      );
      if (languageData) {
        data.language = preferredLang;
        data.shieldNumber = languageData.level;
      }
    }

    if (!preferredLang && source.languageList) {
      const bestLanguage = maxBy(source.languageList, (languageInList) => languageInList.points);
      if (bestLanguage && bestLanguage.level >= 1) {
        data.language = bestLanguage.language;
        data.shieldNumber = bestLanguage.level;
      }
    }

    return data;
  }

  protected preprocessPortraitImage(src: string) {
    if (!src) {
      return 'assets/img/unknown-user.png';
    }
    if (src.match(/\d+-small/)) {
      return src.replace(/-small/ig, '');
    }
    return src;
  }

  seeAllClick() {
    this.router.navigate(['/leaderboard']);
  }

}
