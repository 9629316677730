import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {OverlayService} from './components/overlay-dialog/overlay-service';
import {UnsupportedDeviceDialogComponent} from './components/unsupported-device-dialog/unsupported-device-dialog.component';
import {CurrentUserService} from './auth/current-user.service';
import {LoadingService} from './services/loading.service';
import {AppConfig} from './config/app-config';
import {AppTranslationService} from './services/app-translation.service';
import {NavigationEnd, Router} from '@angular/router';
import * as html2canvas from 'html2canvas';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ReportBugService, ReportProblemType} from './services/report-bug.service';
import {StudovnaApi} from '../../oj-app-common/studovna-api/studovna-api';
import {MessageService} from 'primeng/api';
import {ReportBugDialogComponent} from './components/report-bug-dialog/report-bug-dialog.component';
import * as smoothscroll from 'smoothscroll-polyfill';
import {PaymentDialogComponent} from './components/payment-dialogs/components/payment-dialog/payment-dialog.component';
import {PaymentDialogService} from './components/payment-dialogs/payment-dialog.service';
import {CourseCombinedModel} from '../../oj-app-common/shop/models/shop/course-combined.model';
import {CourseShopOptionsModel} from '../../oj-app-common/shop/models/course-shop-options.model';
import {AppLoaderService} from './services/app-loader.service';
import {ExerciseToleranceService} from './services/exercise-tolerance.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  unsupportedDevice = false;

  isLoaded = true;

  paymendDialogData: {
    type: ('license' | 'payment' | 'period'),
    course: CourseCombinedModel,
    shopOptions: CourseShopOptionsModel
  };

  reportBugData = {
    ignoreScreen: false,
    courseId: 0,
    lessonId: 0,
    wordId: 0
  };

  currentRoute = '';

  isShowedLoading = false;

  @ViewChild(ReportBugDialogComponent, {static: false}) reportBugDialog: ReportBugDialogComponent;

  @ViewChild(UnsupportedDeviceDialogComponent, {static: false}) unsupportedDeviceDialog: UnsupportedDeviceDialogComponent;

  @ViewChild(PaymentDialogComponent, {static: false}) paymentDialog: PaymentDialogComponent;

  constructor(
    public _translateService: TranslateService,
    protected overlayService: OverlayService,
    protected currentUserService: CurrentUserService,
    protected loadingService: LoadingService,
    protected appConfig: AppConfig,
    private router: Router,
    private appTranslationService: AppTranslationService,
    private deviceService: DeviceDetectorService,
    private reportBugService: ReportBugService,
    private studovnaApi: StudovnaApi,
    private messageService: MessageService,
    protected appLoader: AppLoaderService,
    private paymentDialogService: PaymentDialogService,
    public toleranceService: ExerciseToleranceService
  ) {
    if (smoothscroll) {
      smoothscroll.polyfill();
    }
  }

  ngOnInit() {
    this.isLoaded = false;

    this.appLoader.appInit();

    this.appLoader.setAppBranding();

    this.loadingService.isShowed.subscribe(v => this.isShowedLoading = v);

    this.router
      .events
      .subscribe(e => {
        if (e instanceof NavigationEnd) {
          this.currentRoute = e.url;
          this.checkSupportedScreenSize(window.innerWidth);
        }
      });

    this.reportBugService.reportBug.subscribe(report => {
      this.showReportBugDialog(report.type, report.ignoreScreen, report.courseId, report.lessonId, report.wordId);
    });

    if (this.unsupportedDeviceDialog && !this.unsupportedDeviceDialog.isShownDialog()) {
    }

    setTimeout(() => {
      this.isLoaded = true;
    }, 200);

    this.paymentDialogService
      ._isOpened
      .subscribe(v => {

        if (!v) {
          this.paymentDialog.close();
          return;
        }

        this.handlePaymentDialog(v);
      });
  }

  ngAfterViewInit() {
    smoothscroll.polyfill();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event) {
      this.checkSupportedScreenSize(event.target.innerWidth);
    }
  }

  handlePaymentDialog(modalOptions) {
    let directlyProduct = false;
    if (modalOptions.product) {
      directlyProduct = true;
    }
    this.paymentDialog.openModal(
      modalOptions.type,
      modalOptions.course,
      modalOptions.product,
      modalOptions.shopOptions,
      null,
      directlyProduct
    );
  }

  checkSupportedScreenSize(windowWidth: number) {
    if (this.currentRoute === '/' || this.currentRoute.indexOf('/login') !== -1) {

      if (this.unsupportedDevice) {
        this.unsupportedDeviceDialog.hide();
      }

      this.unsupportedDevice = false;

      return;
    }

    if (windowWidth < 960) {
      if (this.unsupportedDeviceDialog.isShownDialog()) {
        return;
      }

      setTimeout(() => {
        if (this.unsupportedDevice && this.isShowedLoading) {
          this.loadingService.hide();
        }
      }, 5000);

      this.unsupportedDevice = true;

      this.unsupportedDeviceDialog.show();

    } else {

      if (this.unsupportedDeviceDialog.isShownDialog()) {

        this.unsupportedDevice = false;

        this.unsupportedDeviceDialog.hide();
      }

    }
  }

  showReportBugDialog(type: ReportProblemType, ignoreScreen: boolean, courseId: number, lessonId: number, wordId: number) {
    this.reportBugData.ignoreScreen = ignoreScreen;
    this.reportBugData.courseId = courseId;
    this.reportBugData.lessonId = lessonId;
    this.reportBugData.wordId = wordId;
    this.reportBugDialog.openModal(type);
  }

  onReportBugDialogSubmit(message: string) {
    if (message) {
      setTimeout(() => {
        this.clickedReportBug(message,
          this.reportBugData.ignoreScreen,
          this.reportBugData.courseId,
          this.reportBugData.lessonId,
          this.reportBugData.wordId);
      }, 500);
    }
  }

  async clickedReportBug(message: string, ignoreScreen: boolean, courseId: number, lessonId: number, wordId: number) {
    let deviceType = '';
    if (this.deviceService.isDesktop()) {
      deviceType = 'desktop';
    } else if (this.deviceService.isTablet()) {
      deviceType = 'tablet';
    } else if (this.deviceService.isMobile()) {
      deviceType = 'mobile';
    }

    const techData = {
      url: window.location.href.slice(0, 40),
      tolerance: this.toleranceService.tolerance,
      isTouchDevice: 'ontouchstart' in document.documentElement,
      page: this.router.url.slice(0, 10),
      deviceInfo: this.deviceService.getDeviceInfo(),
      deviceType,
      screen: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    };

    // @ts-ignore
    const canvas = await html2canvas(ignoreScreen ? document.getElementById('main') : document.body);
    if (canvas) {
      const image = canvas.toDataURL('image/jpeg');

      // console.log(
      //         this.currentUserService.studovnaId,
      //         this.currentUserService.token,
      //         message,
      //         courseId,
      //         lessonId,
      //         wordId,
      //         this.appConfig.ttsOwner
      // );

      // var a = document.createElement('a');
      // a.href = image;
      // a.download = "output.jpeg";
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);

      try {
        await this.studovnaApi
          .reportBug(
            this.currentUserService.studovnaId,
            this.currentUserService.token,
            message,
            courseId,
            lessonId,
            JSON.stringify(techData),
            image ? image : '',
            wordId,
            this.appConfig.ttsOwner
          );
      } catch (e) {
        this.messageService.add({
          severity: 'error',
          summary: this._translateService.instant('Nebylo možné odeslat hlášení.')
        });
        console.log(e);
        return;
      }

      this.messageService.add({
        severity: 'success',
        summary: this._translateService.instant('Hlášení bylo odesláno.'),
        detail: this._translateService.instant('Děkujeme za zpětnou vazbu. Vážíme si toho.')
      });
    }
  }
}
