import {Component, OnInit, ViewChild} from '@angular/core';
import {RouterHistoryService} from '../../../../../services/router-history.service';
import {ActivatedRoute} from '@angular/router';
import {CourseProgressDetails} from '../../../../../../../oj-app-common/studovna-api/models/course-progress-details';
import {StudovnaApi} from '../../../../../../../oj-app-common/studovna-api/studovna-api';
import {CurrentUserService} from '../../../../../auth/current-user.service';
import {MediaFilesService} from '../../../../../services/media-files.service';
import {LANGUAGE_TYPES, languageTypeToIsoCode} from '../../../../../../../oj-app-common/misc/language-types.enum';
import {LoadingService} from '../../../../../services/loading.service';
import {_} from '../../../../../../../oj-app-common/i18n/translate-placeholder';
import {BaseModalComponent} from '../../../../../components/base-modal/base-modal.component';
import {CertifateDataModalContentComponent} from './certifate-data-modal-content/certifate-data-modal-content.component';
import {CertificateUserModel} from './certificate-user.model';
import {MessageService} from 'primeng/api';
import {BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {

  coursesWithCertificates: CourseProgressDetails[] = []

  @ViewChild(BaseModalComponent, {static: false}) baseModal: BaseModalComponent;

  constructor(
    protected routerHistoryService: RouterHistoryService,
    protected activatedRoute: ActivatedRoute,
    protected studovnaApi: StudovnaApi,
    protected userService: CurrentUserService,
    protected mediaFiles: MediaFilesService,
    protected loadingService: LoadingService,
    protected modal: BsModalService,
    protected messageService: MessageService
  ) { }

  ngOnInit() {
    this.activatedRoute
      .queryParams
      .subscribe(v => {
        if (v.courses) {
          this.coursesWithCertificates = JSON.parse(v.courses);
        }
      })
  }

  backButtonClicked() {
    this.routerHistoryService.goBack(true);
  }

  getCourseLanguage(langType: LANGUAGE_TYPES) {
    return languageTypeToIsoCode(langType);
  }

  generateCertificate(c: CourseProgressDetails, user: CertificateUserModel) {
    this.loadingService.show(_('certificate-generate-loading'));
    this.studovnaApi.getGenerateCertificateUrl(
      this.userService.studovnaId,
      this.userService.token,
      c.id,
      user.name,
      user.surname,
      user.dateOfBirth
    ).then(url => {
      if (url) {
        const completeUrl = this.mediaFiles.getServerImageUrl(url);
        window.open(completeUrl, '_blank');
        this.loadingService.hide();
      }
    }, (err) => {
      console.log(err);
      this.loadingService.hide();
      this.messageService.add({severity: 'error', summary: 'error-generation-certificate-alert'})
    })
  }

  showCertificateDataModal(course: CourseProgressDetails) {
    const modalRef = this.modal.show(CertifateDataModalContentComponent);
    modalRef.setClass('modal-dialog-centered certificate-data-dialog');
    const user = new CertificateUserModel()
    user.name = this.userService.profile.firstName;
    user.surname = this.userService.profile.lastName;
    modalRef.content.user = user;
    modalRef.content.submitEvent.subscribe((v: CertificateUserModel) => {
      this.generateCertificate(course, v);
      modalRef.hide();
    });
    modalRef.content.closeEvent.subscribe(v => {
      modalRef.hide();
    });

  }
}
