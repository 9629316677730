import { Lesson } from './lesson';

export class Block {
	id: number;
	type: string;
	lessons: Lesson[];
	isNew?: boolean;

	static createFromData(data: any): Block {

		let b = new Block();

		b.id = +data.id;
		b.type = data.type + '';
		b.lessons = [];
		b.isNew = false;

		if (data.lessons) {
			for (let lessonId of Object.keys(data.lessons)) {
				let lesson = data.lessons[lessonId];
				let l = Lesson.createFromData(lesson);
				if (l.isNew) {
					b.isNew = true;
				}
				b.lessons.push(l);
			}

			if (b.lessons.length) {
				b.lessons.sort(
					(b1: Lesson,  b2: Lesson) => {
						if (b1.position || b2.position) {
							return b1.position - b2.position;
						}
						return b1.id - b2.id;
					}
				);
			}
		}

		return b;

	}

}
