import { MethodDescription } from '../method-description';

export const RestorePassword2MethodDescription = new MethodDescription();

RestorePassword2MethodDescription.name = 'restorePassword2';
RestorePassword2MethodDescription.fields = [
	'code',
	'requestId',
	'password',
	'userId',
];
RestorePassword2MethodDescription.usesToken = false;
