import {NgModule} from '@angular/core';

/* Custom components && modules import */
import {CommonModule} from '@angular/common';
import {SidebarComponent} from './sidebar.component';
import {MatButtonModule, MatTabsModule} from '@angular/material';
import {DirectivesModule} from '../../directives/directives.module';
import {PluralPipeModule} from '../../pipes/plural-pipe.module';
import {SidebarService} from './sidebar.service';

@NgModule({
  declarations: [
    SidebarComponent,
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    MatButtonModule,
    DirectivesModule,
    PluralPipeModule
  ],
  providers: [
    SidebarService
  ],
  exports: [
    SidebarComponent,
  ],
  entryComponents: []
})
export class SidebarModule {
}
