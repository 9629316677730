import {NgModule} from '@angular/core';
import {IntroTutorialModalComponent} from './intro-tutorial-modal.component';
import {TutorialModule} from '../tutorial.module';
import {ModalModule} from "ngx-bootstrap/modal";

@NgModule({
  declarations: [
    IntroTutorialModalComponent
  ],
  imports: [
    ModalModule,
    TutorialModule
  ],
  exports: [
    IntroTutorialModalComponent
  ]
})

export class IntroTutorialModalModule {

}
