import {Component, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AppConfig} from '../../config/app-config';
import {allowRedirectToMobileApp} from '../../config/constants';
import {CurrentUserService} from '../../auth/current-user.service';
import {AppTranslationService} from '../../services/app-translation.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {BsModalRef, ModalDirective} from "ngx-bootstrap/modal";

export enum UNSUPPORTED_DIALOG_TYPE {
  'RESIZE_WINDOW',
  'SWITCH_MOBILE'
}

@Component({
  selector: 'app-unsupported-device-dialog',
  templateUrl: './unsupported-device-dialog.component.html',
  styleUrls: ['./unsupported-device-dialog.component.scss']
})

export class UnsupportedDeviceDialogComponent {
  public didHide: Subject<void>;

  public dialogHidden = true;

  public overlayHidden = true;

  public isShown = false;

  modalRef: BsModalRef;

  @ViewChild('unsupportedDeviceModal', {static: false}) unsupportedDeviceModal: ModalDirective;

  allowRedirectToMobileApp = allowRedirectToMobileApp;

  currentDialogType: UNSUPPORTED_DIALOG_TYPE;

  dialogTypes = UNSUPPORTED_DIALOG_TYPE;

  mobileTypeOS = '';

  currentAppBrand = '';

  mobileOSIcon = '';

  texts = {
    heading: '',
    subheading: '',
    button: ''
  };

  constructor(
    protected translateService: TranslateService,
    protected appConfig: AppConfig,
    protected currentUser: CurrentUserService,
    protected appTranslationService: AppTranslationService,
    protected deviceDetect: DeviceDetectorService
  ) {
  }

  public isShownDialog(): boolean {
    return this.isShown;
  }

  close() {
    this.isShown = false;

    this.unsupportedDeviceModal.hide();
  }

  hide() {

    setTimeout(
      () => {
        this.dialogHidden = true;
      },
      50
    );

    setTimeout(
      () => {
        this.overlayHidden = true;
      },
      250
    );

    this.close();

  }

  show() {
    this.dialogHidden = true;
    this.overlayHidden = true;

    this.currentAppBrand = this.appConfig.brandCode;

    setTimeout(
      () => {
        this.overlayHidden = false;
      },
      100
    );

    setTimeout(
      () => {
        this.dialogHidden = false;
      },
      300
    );

    this.isShown = true;

    this.findTheRightDialogType();

    this.getDialogTexts();

    this.unsupportedDeviceModal.show();

  }

  findTheRightDialogType() {

    // resize window
    if (window.screen.width > 960 && window.innerWidth < 960) {
      this.currentDialogType = UNSUPPORTED_DIALOG_TYPE.RESIZE_WINDOW;
      return;
    }

    // switch to mobile
    if (window.screen.width < 960) {
      this.getMobileOperatingSystem();
      this.currentDialogType = UNSUPPORTED_DIALOG_TYPE.SWITCH_MOBILE;
      return;
    }

  }

  getDialogTexts() {

    switch (this.currentDialogType) {
      case UNSUPPORTED_DIALOG_TYPE.RESIZE_WINDOW:
        this.texts.heading = 'resize-window-dialog-heading';
        this.texts.subheading = 'resize-window-dialog-text';
        break;
      case UNSUPPORTED_DIALOG_TYPE.SWITCH_MOBILE:
        this.texts.heading = 'switch-to-mobile-version-dialog-heading';
        this.texts.subheading = 'switch-to-mobile-version-dialog-text';
        break;
    }

  }

  googleLinkClicked() {
    const currApp = this.appConfig.currentApp;
    if (!currApp) {
      return;
    }
    switch (currApp) {
      case this.appConfig.apps.easylingo:
        window.open('https://play.google.com/store/apps/details?id=com.easylingo.app', '_blank');
        break;
      case this.appConfig.apps.onlinejazyky:
        window.open('https://play.google.com/store/apps/details?id=cz.onlinejazyky.app', '_blank');
        break;
      default:
        window.open('https://play.google.com/store/apps/details?id=com.easylingo.app', '_blank');
        break;
    }
  }

  appleLinkClicked() {
    const currApp = this.appConfig.currentApp;
    if (!currApp) {
      return;
    }
    switch (currApp) {
      case this.appConfig.apps.easylingo:
        window.open('https://apps.apple.com/cz/app/easylingo-fluent-in-no-time/id1481075253', '_blank');
        break;
      case this.appConfig.apps.onlinejazyky:
        window.open('https://apps.apple.com/sk/app/online-jazyky-nau%C4%8D%C3%AD-v%C3%A1s-v%C3%ADc/id1414565168', '_blank');
        break;
      default:
        window.open('https://apps.apple.com/cz/app/easylingo-fluent-in-no-time/id1481075253', '_blank');
        break;
    }
  }

  continueInMobileApp() {
    if (this.appConfig.brandCode === 'oj') {
      window.open('https://m.onlinejazyky.cz?token=' + this.currentUser.token + '&lang=' + this.appTranslationService.currentLanguage, '_self');
    } else {
      window.open('https://m.easylingo.com?token=' + this.currentUser.token + '&lang=' + this.appTranslationService.currentLanguage, '_self');
    }
  }

  getMobileApp() {

    if (this.mobileTypeOS === 'ios') {

      this.appleLinkClicked();

    } else {

      this.googleLinkClicked();

    }

  }

  getMobileOperatingSystem() {
    if (this.deviceDetect.os.toLowerCase() === 'ios' || this.deviceDetect.os.toLowerCase() === 'mac') {
      this.mobileTypeOS = 'ios';
      this.mobileOSIcon = '/assets/img/apple-icon.png';
      return;
    }

    this.mobileTypeOS = 'android';
    this.mobileOSIcon = '/assets/img/google-play-icon.png';
  }

}
