export class EvaluationResult {
	totalQuestions: number;
	correctAnswers: number;
	score: number;

	constructor(correct: number, total: number) {
		this.correctAnswers = correct;
		this.totalQuestions = total;

		if (total < correct) {
			throw new Error('Invalid arguments. Total can not be less than correct.');
		}

		if (total) {
			this.score = Math.floor(correct / total * 100);
		} else {
			this.score = ((correct > 0) ? 100 : 0);
		}
	}
}
