import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";

export interface ModalConfig {
  title?: string;
  contentRef?: TemplateRef<any>;
  body?: string | HTMLElement;
  actions?: {
    title: string;
    action: () => void;
    class: string;
  }[];
  height?: string;
}

@Component({
  selector: 'app-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss']
})
export class BaseModalComponent implements OnInit {
  modalConfig: ModalConfig;

  @ViewChild('staticModal', {static: false}) modal: ModalDirective;

  constructor() {
  }

  ngOnInit() {
  }

  init(config: ModalConfig) {
    this.modalConfig = config;
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

}
