import {Injectable} from '@angular/core';
import {VocabularyFilter} from '../../../oj-app-common/vocabulary/models/vocabulary-filter';
import {VocabularyTrainingWord} from '../../../oj-app-common/vocabulary/models/vocabulary-training-word';
import {VocabularyWord} from '../../../oj-app-common/vocabulary/models/vocabulary-word';
import {VocabularyDeck} from '../../../oj-app-common/vocabulary/models/vocabulary-deck';
import {StudovnaApi} from '../../../oj-app-common/studovna-api/studovna-api';
import {CurrentUserService} from '../auth/current-user.service';
import {CurrentCourseService} from './current-course-service';
import {ServicesAccessor} from '../../../oj-app-common/tools/services-accessor';
import {WORD_RESULT} from '../../../oj-app-common/vocabulary/models/word-result';
import {Subject} from 'rxjs';
import {
  DefaultVocabularyTrainingSettings,
  InputModes,
  OutputModes,
  VocabularyTrainingSettings
} from '../models/vocabulary-training-settings';
import {TRAINING_DIRECTION} from '../../../oj-app-common/vocabulary/models/training-direction.enum';
import {cloneDeep, shuffle} from 'lodash-es';
import {UserPreferencesService} from '../../../oj-app-common/services/user-preferences.service';
import {VocabularyGenerateTypesEnum} from '../../../oj-app-common/misc/vocabulary-generate-types.enum';
import {isToday} from '../misc/is-today';

@Injectable({
  providedIn: 'root'
})
export class VocabularyService {
  protected vocabularyFilters: { [key: number]: VocabularyFilter } = {};
  public vocabularySubject: Subject<VocabularyFilter> = new Subject<VocabularyFilter>();

  protected KEY_VOCAB_FILTER = 'vocabFilter';
  protected KEY_SETTINGS = 'vocabTraining';
  protected KEY_VOCAB_TRAINED_BACKUP = 'trainedWords';

  protected trainingSettings: VocabularyTrainingSettings;

  constructor(
    protected studovnaApi: StudovnaApi,
    protected currentUser: CurrentUserService,
    protected currentCourse: CurrentCourseService,
    protected serviceAccessor: ServicesAccessor,
    protected userPrefs: UserPreferencesService,
  ) {

    this.serviceAccessor.add('vocab', this);
    this.loadVocabularyFilters();
    this.loadVocabularySettings();

  }

  public async loadDailyTrainingDeck(): Promise<VocabularyDeck> {

    const data = await this.studovnaApi.vocabularyTraining(
      this.currentUser.studovnaId,
      this.currentUser.token,
      this.currentCourse.courseId,
      true
    );

    const deck = new VocabularyDeck();
    deck.words = shuffle(data);
    return deck;

  }

  public getTrainingSettings(): VocabularyTrainingSettings {
    if (!this.trainingSettings) {
      this.trainingSettings = DefaultVocabularyTrainingSettings;
    }
    return cloneDeep(this.trainingSettings);
  }

  public updateTrainingSettings(newSettings: VocabularyTrainingSettings) {

    const settings: VocabularyTrainingSettings = cloneDeep(newSettings);

    if (settings.inputModes.length === 0) {
      settings.inputModes = [InputModes.READ];
    }
    if (settings.outputModes.length === 0) {
      settings.outputModes = [OutputModes.PRACTICE];
    }
    if (!settings.direction) {
      settings.direction = TRAINING_DIRECTION.FROM_FOREIGN;
    }
    this.userPrefs.set(this.KEY_SETTINGS, settings, true);
    this.trainingSettings = settings;
  }

  public validateTrainingSettings(settings: VocabularyTrainingSettings) {
    if (!settings.inputModes || settings.inputModes.length <= 0) {
      return false;
    }
    if (!settings.outputModes || settings.outputModes.length <= 0) {
      return false;
    }
    if (settings.direction !== TRAINING_DIRECTION.FROM_FOREIGN
      && settings.direction !== TRAINING_DIRECTION.FROM_MY
      && settings.direction !== TRAINING_DIRECTION.RANDOM
    ) {
      return false;
    }
    return true;
  }

  recordResult(word: VocabularyTrainingWord, result: WORD_RESULT): Promise<void> {
    if (!word) {
      return Promise.resolve();
    }
    return this.studovnaApi.trainingWord(
      this.currentUser.studovnaId,
      this.currentUser.token,
      word,
      result
    );
  }

  public async downloadVocabularyPdf(
    filter?: VocabularyFilter,
    withOptions = false,
    cards: VocabularyGenerateTypesEnum = VocabularyGenerateTypesEnum.list,
    ids: number[] = null
  ): Promise<any> {
    if (!filter || (ids && ids.length > 0)) {

      return this.studovnaApi.generateVocabularyPdfUrl(
        this.currentUser.studovnaId,
        this.currentUser.token,
        this.currentCourse.courseId,
        null,
        null,
        null,
        null,
        null,
        null,
        withOptions,
        cards,
        'get',
        ids
      );

    } else {
      let flattenedWeeks = [];

      if (filter && filter.weeksOfChapter) {
        Object.keys(filter.weeksOfChapter)
          .forEach(key => {
            flattenedWeeks = flattenedWeeks.concat(filter.weeksOfChapter[key]);
          });
      }

      return this.studovnaApi.generateVocabularyPdf(
        this.currentUser.studovnaId,
        this.currentUser.token,
        this.currentCourse.courseId,
        filter.chapterId.length > 0 ? filter.chapterId : null,
        flattenedWeeks.length > 0 ? flattenedWeeks : null,
        filter.fromLevel || null,
        filter.toLevel || null,
        filter.excluded,
        filter.onlyFavorites ? true : null,
        withOptions,
        cards
      );
    }

  }

  public async loadVocabularyOfCourse(filter?: VocabularyFilter, withOptions = false): Promise<VocabularyWord[]> {
    if (!filter) {

      return this.studovnaApi.vocabulary(
        this.currentUser.studovnaId,
        this.currentUser.token,
        this.currentCourse.courseId,
        null,
        null,
        null,
        null,
        null,
        null,
        withOptions
      );

    } else {
      let flattenedWeeks = [];
      // for (const chapter of filter.chapterId) {
      //   if (filter.weeksOfChapter[chapter] && filter.weeksOfChapter[chapter].length > 0) {
      //     for (const weekId of filter.weeksOfChapter[chapter]) {
      //       flattenedWeeks.push(weekId);
      //     }
      //   }
      // }
      if (filter && filter.weeksOfChapter) {
        Object.keys(filter.weeksOfChapter)
          .forEach(key => {
            flattenedWeeks = flattenedWeeks.concat(filter.weeksOfChapter[key]);
          });
      }

      return this.studovnaApi.vocabulary(
        this.currentUser.studovnaId,
        this.currentUser.token,
        this.currentCourse.courseId,
        filter.chapterId.length > 0 ? filter.chapterId : null,
        flattenedWeeks.length > 0 ? flattenedWeeks : null,
        filter.fromLevel || null,
        filter.toLevel || null,
        filter.excluded,
        filter.onlyFavorites ? true : null,
        withOptions
      );
    }

  }

  public createDeckFromWords(words: VocabularyWord[]): VocabularyTrainingWord[] {
    const newWords = words.filter(
      (w) => {
        return !w.excluded;
      }
    ).map(
      (w) => {
        const t = VocabularyTrainingWord.createFromObject(w);
        t.level = w.level;
        t.source = w.source;
        t.translation = w.translation;
        t.favorite = w.favorite;
        t.excluded = w.excluded;
        return t;
      }
    );

    const shuffledWords = shuffle(newWords);

    shuffledWords.map(
      (w, index) => {
        w.order = index + 1;
      }
    );

    return shuffledWords;
  }

  public getVocabularyFilter(courseId: number): VocabularyFilter | null {
    this.vocabularySubject.next(this.vocabularyFilters[courseId]);
    return this.vocabularyFilters[courseId] || null;
  }

  public setVocabularyFilter(courseId: number, filter: VocabularyFilter) {
    this.vocabularyFilters[courseId] = filter;
    this.vocabularySubject.next(this.vocabularyFilters[courseId]);
    this.saveVocabularyFilters();
  }

  protected saveVocabularyFilters() {
    const dataToSave = [];
    Object.keys(this.vocabularyFilters).map(
      (key) => {
        const filter = this.vocabularyFilters[key];
        dataToSave.push({course: key, filter});
      }
    );
    window.localStorage.setItem(this.KEY_VOCAB_FILTER, JSON.stringify(dataToSave));
  }

  protected loadVocabularyFilters() {
    const loaded = window.localStorage.getItem(this.KEY_VOCAB_FILTER);
    if (loaded) {
      try {
        const parsed = JSON.parse(loaded);
        if (parsed && parsed.length) {
          this.vocabularyFilters = {};
          parsed.map(
            (item: any) => {
              const courseId = item.course;
              const filter = item.filter;
              if (courseId && filter) {
                this.vocabularyFilters[courseId] = VocabularyFilter.createFrom(filter);
              }
            }
          );
        }
      } catch (e) {
        console.log(e);
        // do nothing
      }
    }
  }

  public loadVocabularySettings() {
    const loaded = this.userPrefs.get(this.KEY_SETTINGS);
    if (loaded) {
      this.trainingSettings = loaded;
    }
  }

  public backupTrainedWords(words: VocabularyTrainingWord[]) {
    const w = words.map(word => {
      delete word.options;
      delete word.translationAlternatives;
      delete word.sourceAlternatives;
      return word;
    });


    const date = new Date();

    // get current data
    const backup = localStorage.getItem(this.KEY_VOCAB_TRAINED_BACKUP);
    const courseId = this.currentCourse.courseId || null;

    if (backup) {
      const restored = JSON.parse(backup);

      if (restored && restored.date && restored.data) {
        const today = isToday(new Date(restored.date));

        if (!today) {
          this.clearBackupedTrainedWords();
        }

        restored.data = restored.data.filter(d => d.courseId !== courseId);

        restored.data.push({
          courseId,
          words: w
        });
      }
      this.clearBackupedTrainedWords();

      localStorage.setItem(this.KEY_VOCAB_TRAINED_BACKUP, JSON.stringify(restored));
    } else {
      localStorage.setItem(this.KEY_VOCAB_TRAINED_BACKUP, JSON.stringify({
        date,
        data: [
          {
            courseId,
            words: w
          }
        ]
      }));
    }
  }

  public restoreTrainedWords(): VocabularyTrainingWord[] {
    const backup = localStorage.getItem(this.KEY_VOCAB_TRAINED_BACKUP);
    if (backup) {
      const restored = JSON.parse(backup);

      if (restored && restored.date && restored.data) {
        const today = isToday(new Date(restored.date));

        const savedCurrentCourseWords = restored.data.find(d => d.courseId === this.currentCourse.courseId);

        if (today && savedCurrentCourseWords) {
          return savedCurrentCourseWords.words as VocabularyTrainingWord[];
        }
      }

      return [];
    }
    return [];
  }

  clearBackupedTrainedWords() {
    localStorage.removeItem(this.KEY_VOCAB_TRAINED_BACKUP);
  }
}
