import clamp from 'lodash-es/clamp';

export function getDailyTaskProgress(task: { studyTime: number, goalTime: number }) {
  let progress: number;
  if (task.goalTime) {
    progress = Math.round(clamp(task.studyTime / task.goalTime, 0, 1) * 100);
  } else {
    progress = 0;
  }
  return progress;
}

export function getFormattedTime(nmbr: number, withSpace = false): string {
  const space = withSpace ? ' ' : '';
  if (nmbr <= 60) {
    return nmbr + space + 's';
  }
  if (nmbr < 3600) {
    return Math.round(nmbr / 60) + space + 'm';
  }
  return Math.round(nmbr / 3600 * 10) / 10 + space + 'h';
}

export function getFormattedTimeWithoutSeconds(nmbr: number, withSpace = false): string {
  const space = withSpace ? ' ' : '';
  if (nmbr < 3600) {
    return Math.round(nmbr / 60) + space + 'm';
  }
  return Math.round(nmbr / 3600 * 10) / 10 + space + 'h';
}

export function getFormattedPercentage(nmbr: number, withSpace = false): string {
  const space = withSpace ? ' ' : '';
  return Math.round(nmbr * 100) / 100 + space + '%';
}
