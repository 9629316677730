import {Token} from '../../../oj-app-common/misc/token';

export class LoggedUser {

  public readonly token: Token;
  public readonly studovnaId: number;
  public readonly eshopId: number;
  public readonly email: string;


  public isLoggedIn(): boolean {
    return !!(this.studovnaId && this.eshopId && this.token);
  }

  constructor(token: Token, studovnaId: number, eshopId: number, email: string) {
    this.token = token;
    this.studovnaId = studovnaId;
    this.eshopId = eshopId;
    this.email = email;
  }
}
