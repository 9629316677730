import {
  Component,
  OnInit
} from '@angular/core';
import {BaseOverlayDialogComponent} from '../base-overlay-dialog/base-overlay-dialog';


@Component({
  selector: 'joker-dialog',
  templateUrl: 'joker-dialog.html',
  styleUrls: ['joker-dialog.scss']
})
export class JokerDialog extends BaseOverlayDialogComponent implements OnInit {


  ngOnInit() {
    this.swipeText = null;
  }

  clickedSwipeButton() {
    this.close();
  }

  clickedButton() {
    this.close();
  }


}
