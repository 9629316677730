import { MethodDescription } from '../method-description';

export const LogoutMethodDescription = new MethodDescription();

LogoutMethodDescription.name = 'logout';
LogoutMethodDescription.fields = [
	'thisToken',
	'allFromUserId',
];
LogoutMethodDescription.usesToken = true;
