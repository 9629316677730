import {NgModule} from '@angular/core';
import {YearOverviewComponent} from './year-overview.component';
import {ChartsModule} from 'ng2-charts';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    YearOverviewComponent
  ],
    imports: [
        ChartsModule,
        CommonModule,
        MatSelectModule,
        TranslateModule
    ],
  exports: [
    YearOverviewComponent
  ]
})

export class YearOverviewModule {}
