import {SoundClip} from './sound-clip.interface';
import {Observable, Subject} from 'rxjs';
import {Seconds} from './seconds.type';

export class HtmlSoundClip implements SoundClip {

  protected audio: HTMLAudioElement;
  protected _isPlaying = false;
  protected _isPrepared = false;
  protected _isLoading = false;
  protected _isError = false;

  public lastError: string;

  protected _didFinishLoading = new Subject<void>();
  protected _loadingProgress = 0;

  protected _didChangePosition = new Subject<Seconds>();
  protected _didChangePlayingStatus = new Subject<boolean>();
  protected _didChangeLoadingProgress = new Subject<number>();
  protected _didChangePercentage = new Subject<number>();
  protected _errorHappened = new Subject<string>();

  protected _blockedPlayingAfterLoading = false;

  readonly supportSpeedSetting = true;

  protected playbackRate = 1;

  constructor(
    protected url: string,
  ) {

  }

  get sourceUrl(): string {
    return this.url;
  }

  get isPlaying(): boolean {
    return this._isPlaying;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get isError(): boolean {
    return this._isError;
  }

  get loadingProgress(): number {
    return this._loadingProgress;
  }

  get didChangePlayingStatus(): Observable<boolean> {
    return this._didChangePlayingStatus.asObservable();
  }

  get didChangePosition(): Observable<Seconds> {
    return this._didChangePosition.asObservable();
  }

  get didChangePercentage(): Observable<number> {
    return this._didChangePercentage.asObservable();
  }

  get didChangeLoadingProgress(): Observable<number> {
    return this._didChangeLoadingProgress.asObservable();
  }

  get errorHappened(): Observable<string> {
    return this._errorHappened.asObservable();
  }

  get duration(): Seconds {
    if (this.audio) {
      return +(this.audio.duration);
    }
    return 0;
  }

  get isPrepared(): boolean {
    return this._isPrepared;
  }

  get position(): Seconds {
    if (this.audio) {
      return +(this.audio.currentTime);
    }
    return 0;
  }

  get percentage(): number {
    if (this.audio) {
      const d = this.audio.duration;
      if (d) {
        return +(this.audio.currentTime / this.audio.duration);
      }
      return 0;
    }
    return 0;
  }

  get didFinishLoading(): Promise<void> {
    if (this._isPrepared) {
      return Promise.resolve();
    }
    return new Promise<void>(
      (resolve, reject) => {
        let subscription = this._didFinishLoading.subscribe(
          () => {
            if (subscription) {
              subscription.unsubscribe();
            }
            subscription = null;
            resolve();
          }
        );
      }
    );
  }

  destroy(): void {
    this.audio = null;
    this._isPrepared = false;
  }

  goBackABit(): void {
    if (this.audio) {
      let pos = this.audio.currentTime;
      pos -= 3;
      if (pos < 0) {
        pos = 0;
      }
      this.audio.currentTime = pos;
    }
  }

  pause(): void {

    if (this._isLoading) {
      this.blockPlayingAfterLoading();
    }

    if (this.audio) {
      this.audio.pause();
    }
  }

  play(): void {
    this.createAudioIfNeeded();
    this._blockedPlayingAfterLoading = false;
    if (this.audio) {
      this.audio.play();
    }
  }

  rewind(): void {
    if (this.audio) {
      this.audio.currentTime = 0;
    }
  }

  stop(): void {

    if (this._isLoading) {
      this.blockPlayingAfterLoading();
    }

    if (this.audio) {

      this.audio.pause();
      this.audio.currentTime = 0;
    }
  }

  seekToTime(time: number): void {
    if (this.audio) {
      this.audio.currentTime = time;
    }
  }

  seekToPercentage(percentage: number): void {
    if (this.audio) {
      this.audio.currentTime = this.audio.duration * percentage;
    }
  }

  setPlaybackSpeed(speed:number): void {
    this.playbackRate = speed;
    if (this.audio) {
      this.audio.playbackRate = speed;
    }
  }

  protected async createAudioIfNeeded() {
    if (!this.audio) {

      if (this._isLoading) {
        return;
      }

      this._isLoading = true;
      this._loadingProgress = 0;
      this._isError = false;
      this.lastError = '';

      let data;

      try {
        data = await this.downloadFile(
          (percentage) => {
            this._loadingProgress = percentage;
            this._didChangeLoadingProgress.next(percentage);
          }
        );
      } catch (e) {
        console.error(e);
        this._isError = true;
        this.lastError = e.message || e;
        this._errorHappened.next(e.message || e);
        this._isLoading = false;
        return;
      }

      this.audio = new Audio(data);
      data = '';

      this._isPrepared = true;
      this._isLoading = false;
      this._loadingProgress = 1;

      this.audio.addEventListener('play', () => {
        this._isPlaying = true;
        this._didChangePlayingStatus.next(true);
      });
      this.audio.addEventListener('pause', () => {
        this._isPlaying = false;
        this._didChangePlayingStatus.next(false);
      });
      this.audio.addEventListener('timeupdate', () => {
        if (!this.audio) {
          return;
        }
        const c = this.audio.currentTime;
        const d = this.audio.duration;
        this._didChangePosition.next(c);
        if (d) {
          this._didChangePercentage.next(c / d);
        } else {
          this._didChangePercentage.next(0);
        }
      });

      let firedPlay = false;
      this.audio.addEventListener('error', (e) => {
        console.error(e);
        this.lastError = e.message;
        this._isError = true;
        this._errorHappened.next(e.message);
      });
      this.audio.addEventListener('canplaythrough', () => {
        if (!firedPlay) {
          firedPlay = true;
          this.startPlaybackAfterLoading();

        }
      });
      this.audio.addEventListener('canplay', () => {
        if (!firedPlay) {
          firedPlay = true;
          this.startPlaybackAfterLoading();
        }
      });

      this.audio.load();

      setTimeout(
        () => {
          if (!firedPlay) {
            firedPlay = true;
            this.startPlaybackAfterLoading();
          }
        },
        500
      );


    }
  }

  protected async downloadFile(progressCallback: (percentage: number) => void): Promise<string> {

    // See https://stackoverflow.com/questions/50573752/javascript-get-datauri-from-url-images-synchronous-xmlhttprequest-blob-reques

    return new Promise<string>(
      (resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.response) {
              const reader = new FileReader();
              reader.onload = () => {
                // @ts-ignore
                resolve(reader.result);
              };
              reader.onerror = (e) => {
                reject(e);
              };
              reader.readAsDataURL(xhr.response);
            } else {
              reject('no response received from server');
            }
          }
        };
        xhr.overrideMimeType('audio/mpeg');
        xhr.onerror = (e) => {
          reject(e);
        };
        xhr.onprogress = (e) => {
          if (e.total) {
            progressCallback(e.loaded / e.total);
          }
        };
        xhr.open('GET', this.url);
        xhr.send();
      }
    );

  }

  protected blockPlayingAfterLoading() {
    if (!this._blockedPlayingAfterLoading) {
      this._blockedPlayingAfterLoading = true;
      setTimeout(
        () => {
          this._blockedPlayingAfterLoading = false;
        },
        5000
      );
    }
  }

  protected startPlaybackAfterLoading() {
    this._didFinishLoading.next();
    if (!this._blockedPlayingAfterLoading) {
      this.audio.play();
    } else {
      this._didChangePlayingStatus.next(true);
      this.audio.pause();
      this._didChangePlayingStatus.next(false);
    }
    this._blockedPlayingAfterLoading = false;
  }

}
