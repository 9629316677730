import { MethodDescription } from '../method-description';

export const CreateStudentMethodDescription = new MethodDescription();

CreateStudentMethodDescription.name = 'createStudent';
CreateStudentMethodDescription.fields = [
	'email',
	'password',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'language',
	':::1',
	'referrer',
	'partnerCompany',
	'partnerId',
];
CreateStudentMethodDescription.usesToken = false;
