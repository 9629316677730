import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, ModalDirective} from 'ngx-bootstrap/modal';
import {CurrentUserService} from '../../../../../auth/current-user.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {IsUsernameFreeResponse} from '../../../../../../../oj-app-common/eshop-api/methods/responses';
import {EshopApi} from '../../../../../../../oj-app-common/eshop-api/eshop-api';
import {_} from '../../../../../../../oj-app-common/i18n/translate-placeholder';
import {StudovnaApiError} from '../../../../../../../oj-app-common/studovna-api/helpers/error';
import {LoadingService} from '../../../../../services/loading.service';
import {EditProfileRequest} from '../../../../../../../oj-app-common/eshop-api/methods/requests';
import {StudovnaApi} from '../../../../../../../oj-app-common/studovna-api/studovna-api';
import {MessageService} from 'primeng/api';
import {parseUserFirstLastName} from '../../../../../common/parse-user-first-last-name';
import {LoginService} from '../../../../../services/login.service';
import {OverlayService} from '../../../../../components/overlay-dialog/overlay-service';
import {
  DeleteAccountDialogComponent
} from '../../../../../components/overlay-dialog/delete-account-dialog/delete-account-dialog.component';
import {AppConfig} from '../../../../../config/app-config';
import {ShowInLeaderboardService} from '../../../../../services/show-in-leaderboard.service';

@Component({
  selector: 'app-edit-profile-modal',
  templateUrl: './edit-profile-modal.component.html',
  styleUrls: ['./edit-profile-modal.component.scss']
})
export class EditProfileModalComponent implements OnInit {
  modalRef: BsModalRef;

  public form: FormGroup;

  public freeUsernameValidatorTimeout;

  public checkingFormValidity = false;

  @ViewChild('editProfileModal', {static: false}) editProfileModal: ModalDirective;

  constructor(
    public currentUser: CurrentUserService,
    protected formBuilder: FormBuilder,
    protected eshopApi: EshopApi,
    protected loadingService: LoadingService,
    protected studovnaApi: StudovnaApi,
    protected messageService: MessageService,
    private loginService: LoginService,
    private overlayService: OverlayService,
    private appConfig: AppConfig,
  ) {
    this.formBuilder = formBuilder;
  }

  ngOnInit() {
    this.createForm();
  }

  show() {
    this.editProfileModal.show();
  }

  close() {
    this.editProfileModal.hide();
  }

  protected createForm() {

    const profile = this.currentUser.profile;

    this.form = this.formBuilder.group(
      {
        name: [profile.name],
        phone: [profile.phone, Validators.pattern(/^\+?[\d ]{9,15}$/)],
        street: [profile.street],
        city: [profile.city],
        zip: [profile.zip],
        company: [profile.company],
        ico: [profile.ico, Validators.pattern(/^\d*$/)],
        dic: [profile.dic],
        portrait: ['']
      }
    );
  }

  public isUsernameFreeValidator(control: FormControl): Promise<{[id: string]: any}> {

    if (this.freeUsernameValidatorTimeout) {
      clearTimeout(this.freeUsernameValidatorTimeout);
      this.freeUsernameValidatorTimeout = null;
      this.checkingFormValidity = false;
    }

    const val = control.value;
    if (!val) {
      return Promise.resolve({usernameInvalid: true});
    }
    if (val === this.currentUser.profile.email || val === this.currentUser.user.email) {
      return Promise.resolve({});
    }

    return new Promise(
      (resolve, reject) => {
        this.freeUsernameValidatorTimeout = setTimeout(
          () => {
            this.checkingFormValidity = true;
            this.eshopApi.isUsernameFree(this.currentUser.eshopId, val, this.currentUser.token)
              .then(
                (response: IsUsernameFreeResponse) => {
                  resolve(response.isFree ? {} : { usernameAlreadyInUse: true });
                  this.checkingFormValidity = false;
                }
              ).catch(
              (error) => {
                this.checkingFormValidity = false;
                throw error;
              }
            );
          },
          1000
        );
      }
    );
  }

  saveChanges() {

    if (!this.form.valid) {
      return;
    }

    this.loadingService.show(_('Ukládám změny'));

    const request: any = {};
    let control;
    const fields = [
      'name',
      'phone',
      'street',
      'city',
      'zip',
      'ico',
      'dic',
      'company'
    ];

    let anythingChanged = false;
    let nameChanged = false;
    for (const field of fields) {
      control = this.form.get(field);
      if (control.valid && control.value && control.value !== this.currentUser.profile[field]) {
        anythingChanged = true;
        request[field] = control.value;
        if (field === 'name') {
          nameChanged = true;
        }
      }
    }

    this.sendChangesToApi(anythingChanged ? request : null, '')
      .then(
        () => {
          this.studovnaApi.syncUserData(
            this.currentUser.studovnaId,
            this.currentUser.token,
          );
          this.loadingService.hide();
          this.messageService.add({severity: 'success', detail: _('Změny uloženy.')})

          this.close();
        }
      )
      .catch(
        (error) => {
          console.log(error)
          this.loadingService.hide();
          this.messageService.add({severity: 'error', detail: _('Selhalo ukládání změn.')})
        }
      )
    ;

    if (nameChanged && request.name) {

      const parsedNames = parseUserFirstLastName(request.name);

      this.studovnaApi.setUserData(
        this.currentUser.studovnaId,
        this.currentUser.token,
        parsedNames.firstName,
        parsedNames.lastName,
      ).catch(
        (error: Error|StudovnaApiError) => {
          this.messageService.add({severity: 'error', detail: _('Pozor - změna jména neproběhla')})
          console.error(error);
        }
      );

    }

  }

  protected async sendChangesToApi(request?: EditProfileRequest, email = '') {

    if (request) {
      const profileResponse = await this.eshopApi.editProfile(this.currentUser.eshopId, this.currentUser.token, request);
      const response = await this.eshopApi.verifyToken(this.currentUser.token);
      this.currentUser.setProfileFromEshop(response.userData);
      if (profileResponse.newProfilePicture) {

      }
    }

  }

  onSubmit() {

  }

  clickedKillMyAccount() {
    const d = this.overlayService
      .show(DeleteAccountDialogComponent)
      .then(dialogResult => {
        const dialog = dialogResult.componentRef.instance
        dialog.didHide.subscribe(confirmed => {
          this.loadingService.show();
          if (confirmed) {
            this.eshopApi.blockStudentAccount(this.currentUser.eshopId, this.currentUser.token).then(
              () => {
                this.messageService.add({severity: 'success', summary: _('kill-my-account-success'), detail: _('kill-my-account-success-desc')
                    .replace(/\{\{\s?email\s?\}\}/, this.currentUser.profile.email)
                    .replace(/\{\{\s?support\s?\}\}/, this.appConfig.supportEmail)});
                this.currentUser.clearProfile();
                this.loginService.logout();
                setTimeout(
                  () => {
                    location.reload();
                  },
                  100
                );
                this.close();
                this.loadingService.hide();
              },
              () => {
                this.loadingService.hide();
              }
            );
          }
        })
      });

  }
}
