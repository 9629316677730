import {Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BaseComponent} from './base.component';
import { ANSWER_STATUS_BOOLEAN } from 'oj-app-common/exercises/misc/answer-status-boolean';
import { EXERCISE_STATUS } from 'oj-app-common/exercises/misc/exercise-status';

@Component({
  selector: 'app-base-content',
  template: '<div>Base content component</div>'
})

export class BaseContentComponent extends BaseComponent implements OnChanges, OnDestroy, OnInit {
  protected receivedPoints: number;

  public EXERCISE_STATUS = EXERCISE_STATUS;
  public ANSWER_STATUS_BOOLEAN = ANSWER_STATUS_BOOLEAN;

  public text;

  @ViewChild('editorText', {static: false})
  public editorTextNode: ElementRef;

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes['content']) {
      this.text = '';
      if (this.content.text) {
        this.text += this.mediaFiles.processEditorText(this.content.text);
      }
      if (this.content.exerciseText) {
        this.text += this.mediaFiles.processEditorText(this.content.exerciseText);
      }
    }

  }

  ngOnInit(): void {
    // this.startTimer();
    super.ngOnInit();
  }
}
