import { Injectable } from '@angular/core';
import {SoundClip} from './sound-clip.interface';
import {HtmlSoundClip} from './html-sound-clip';
import {Observable, Subject} from 'rxjs';
import {RecordingClip} from './recording-clip.interface';
import { without } from 'lodash-es';
import {SoundRecorderInterface} from '../../sound/sound-recorder.interface';
import {HtmlSoundRecorder} from '../../sound/html-sound-recorder';

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  protected sounds: SoundClip[] = [];
  protected recordings: RecordingClip[] = [];

  protected _pauseAllObservable = new Subject<void>();
  protected _stopAllObservable  = new Subject<void>();
  protected _destroyAllObservable  = new Subject<void>();

  constructor() { }

  static isPlayAbortException(e: any): boolean {
    let text = '';
    if (typeof e === 'string') {
      text = e;
    } else if (typeof e === 'object') {
      text = e.name;
    }

    if (text.match(/(abortexception)|(call to pause)|(aborterror)/i)) {
      return true;
    }
    return false;
  }

  async askForMicPermissionIfNeeded(): Promise<boolean> {

    return true;


  }

  isDeviceThatSometimesSkipsFirstSecond(): boolean {
    // check if is mac and firefox or safari
    return navigator.userAgent.indexOf('Mac') !== -1 && (navigator.userAgent.indexOf('Firefox') !== -1 || navigator.userAgent.indexOf('Safari') !== -1);
  }

  get didPauseAllSounds(): Observable<void> {
    return this._pauseAllObservable.asObservable();
  }

  get didStopAllSounds(): Observable<void> {
    return this._stopAllObservable.asObservable();
  }

  get didDestroyAllSounds(): Observable<void> {
    return this._destroyAllObservable.asObservable();
  }

  createSound(url: string): SoundClip {
    const clip = new HtmlSoundClip(url);
    this.sounds.push(clip);
    return clip;
  }

  isDeviceCapableOfRecording(): boolean {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && window.URL) {
      return true;
    }
    return false
  }

  createRecorder(): SoundRecorderInterface {
    return new HtmlSoundRecorder();
  }

  destroyAllSounds() {
    this._stopAllObservable.next();
    this.sounds.map(
      (s) => {
        s.stop();
        s.destroy();
      }
    );
    this.sounds = [];

    this.recordings.map(
      (r) => {
        r.stopRecording();
        r.stop();
        r.destroy();
      }
    );

    this.recordings = [];

    this._destroyAllObservable.next();
  }

  destroySound(sound: SoundClip) {
    for (const s of this.sounds) {
      if (s === sound) {
        s.destroy();
        this.sounds = without(this.sounds, s);
      }
    }
  }

  destroyRecording(recording: RecordingClip) {
    for (const r of this.recordings) {
      if (r === recording) {
        r.destroy();
        this.recordings = without(this.recordings, r);
      }
    }
  }

  pauseAllPlayingSounds() {
    this._pauseAllObservable.next();
    this.sounds.map(
      (s) => {
        s.pause();
      }
    );
    this.recordings.map(
      (r) => {
        r.stopRecording();
        r.pause();
      }
    );
  }

  stopAllPlayingSounds() {
    this._stopAllObservable.next();
    this.sounds.map(
      (s) => {
        s.stop();
      }
    );
    this.recordings.map(
      (r) => {
        r.stopRecording();
        r.stop();
      }
    );

  }

}
