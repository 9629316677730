import {AppConfigStudovnaApi} from '../../../oj-app-common/studovna-api/app-config-studovna-api';
import {AppConfigEshopApi} from '../../../oj-app-common/eshop-api/app-config-eshop-api';
import {HelpcrunchSettings} from '../models/helpcrunch-settings.interface';
import {Brand} from '../../../oj-app-common/shop/types/brand';

export class AppConfig implements AppConfigStudovnaApi, AppConfigEshopApi {

  eshopApiUrl: string;
  eshopAppCode: string;
  eshopAppSecret: string;
  eshopApiVersion: string;

  studovnaApiUrl: string;
  studovnaMediaFilesBase: string;

  subscriptionPageUrl: string;

  debuggingMessages: boolean;
  debuggingCordovaFallbacks: boolean;
  debuggingServiceAccessor: boolean;

  debugSkipInitialVerifyToken: boolean;
  ignoreStudovnaEuthError: boolean;

  defaultLanguage: string;
  availableLanguages: string[];

  ttsOwner: ('app' | 'webapp');

  helpcrunch?: HelpcrunchSettings;

  supportEmail: string;

  apps?: any;
  currentApp?: string;
  brandCode?: Brand;
  brandAppName?: string;
  brandLogoFilename?: string;

  facebookAppId?: string;
  facebookAppSecret?: string;
  facebookReturnUrl?: string;

  constructor(config: AppConfig) {
    Object.assign(this, config);
  }
}
