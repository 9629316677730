import { Injectable } from '@angular/core';
import {ServicesAccessor} from '../../../oj-app-common/tools/services-accessor';
import {TUTORIAL} from '../components/tutorial/models/tutorial.enum';

@Injectable({
  providedIn: 'root'
})

export class TutorialService {
  protected _tutorialPassed: {[key: number]: boolean} = {};

  private STORE_KEY = 'tutorialsPassed';

  constructor(
    protected serviceAccessor: ServicesAccessor
  ) {
    this.loadFromStore();
    this.serviceAccessor.add('tutorials', this);
  }

  public markAsPassed(tutorial: TUTORIAL) {
    this._tutorialPassed[tutorial] = true;
    this.saveToStore();
  }

  public markAsUnpassed(tutorial: TUTORIAL) {
    this._tutorialPassed[tutorial] = false;
    this.saveToStore();
  }

  public isPassed(tutorial: TUTORIAL): boolean {
    return !!(this._tutorialPassed[tutorial]);
  }

  public resetTutorials() {
    this._tutorialPassed = {};
    this.saveToStore();
  }

  public isAnyTutorialPassed() {
    if (!this._tutorialPassed) {
      return false;
    }
    for (const key of Object.keys(this._tutorialPassed)) {
      if (this._tutorialPassed[key]) {
        return true;
      }
    }
    return false;
  }

  public saveToStore() {
    window.localStorage.setItem(
      this.STORE_KEY,
      JSON.stringify(this._tutorialPassed),
    );
  }

  public loadFromStore() {
    const stored = window.localStorage.getItem(this.STORE_KEY);
    if (stored) {
      const parsed = JSON.parse(stored);
      if (parsed) {
        this._tutorialPassed = parsed;
      }
    }
  }
}
