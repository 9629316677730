import { BaseExercise } from '../base-exercise';
import { BaseImage } from './models/base-image';

export abstract class AbstractAssignTextToImageExercise extends BaseExercise {

	static typeString;

	public abstract images: BaseImage[] = [];

	public init(lessonData: object): void {
	}

}
