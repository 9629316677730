export const vocabChartColors = [
  'rgba(255,255,255,0)',
  '#F5515F',
  '#FF8900',
  '#FAD961',
  '#DDD727',
  '#B7D36F'
];

export const vocabChartOptions = {
  type: 'pie',
  tooltips: {
    enabled: true,
    callbacks: {
      title(tooltipItem, data) {
        return data.datasets[tooltipItem[0].datasetIndex].label;
      },
      label(tooltipItem, data) {
        const index = tooltipItem.index;
        const currentValue = data.datasets[tooltipItem.datasetIndex].data[index];
        let total = 0;
        data.datasets[0].data.forEach((el) => {
          total = total + el;
        });
        const percentage = parseFloat(((currentValue / total) * 100).toString()).toFixed(1);
        return percentage + '%';
      }
    },
    backgroundColor: '#000',
    titleFontSize: 16,
    titleFontColor: '#fff',
    bodyFontColor: '#fff',
    bodyFontSize: 14,
    displayColors: false
  }
}
