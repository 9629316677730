import {NgModule} from '@angular/core';
import {ThreeFiguresComponent} from './three-figures.component';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material';

@NgModule({
  declarations: [
    ThreeFiguresComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    ThreeFiguresComponent
  ]
})

export class ThreeFiguresModule {}
