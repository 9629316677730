import { Injectable } from '@angular/core';

@Injectable()
export class ServicesAccessor {

	protected enabled;

	protected services = {};

	constructor(
		enable: boolean
	) {
		this.enabled = enable;
	}

	public init() {
		if (this.enabled) {
			window['s'] = this;
		}
	}

	public add(key: string, service: any) {
		if (this.enabled) {
			this[key] = service;
			this.services[key] = service;
		}
	}

	public getAllServices() {
		return Object.keys(this.services);
	}

	public help() {
		console.log('Available services: ' + '\n' + this.getAllServices().join('\n') + '\nAccess them using s.serviceName, i.e. "s.' + Object.keys(this.services)[0] + '"');
	}

}
