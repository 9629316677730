import {Injectable} from '@angular/core';
import {LoggedUser} from './loggeduser';
import {UserProfile} from './user-profile';
import {Subject} from 'rxjs';
import {ServicesAccessor} from '../../../oj-app-common/tools/services-accessor';
import {Token} from '../../../oj-app-common/misc/token';
import {UserDetailResponse} from '../../../oj-app-common/studovna-api/responses/user-detail';
import {StudovnaApi} from '../../../oj-app-common/studovna-api/studovna-api';
import {MyProfileResponse} from '../../../oj-app-common/studovna-api/responses/my-profile';
import {CurrentCourseService} from '../services/current-course-service';

@Injectable()
export class CurrentUserService {

  protected _user: LoggedUser;
  protected _userProfile: UserProfile;
  protected _myProfile: MyProfileResponse;

  protected readonly STORAGE_KEY = 'current-user-data';
  protected readonly MY_PROFILE_STORAGE_KEY = 'current-user-my-profile';

  protected _onLogout: Subject<LoggedUser>;
  protected _onLogin: Subject<LoggedUser>;

  public myProfileChanged: Subject<MyProfileResponse> = new Subject<MyProfileResponse>();
  public userChanged: Subject<UserProfile> = new Subject<UserProfile>();

  constructor(
    serviceAccessor: ServicesAccessor,
    public studovnaApi: StudovnaApi
  ) {
    this._user = new LoggedUser('', 0, 0, '');
    this._userProfile = new UserProfile();
    this.loadFromStorage();
    this.getMyProfile(this.user.studovnaId, this.user.token);
    this._onLogout = new Subject<LoggedUser>();
    this._onLogin = new Subject<LoggedUser>();

    serviceAccessor.add('user', this._user);
    serviceAccessor.add('currentUser', this);
    serviceAccessor.add('myProfile', this._myProfile);
  }

  get user(): LoggedUser {
    return this._user;
  }

  get profile(): UserProfile {
    return this._userProfile;
  }

  get token(): Token {
    return this._user ? this._user.token : '';
  }

  get eshopId(): number {
    return this._user ? this._user.eshopId : 0;
  }

  get studovnaId(): number {
    return this._user ? this._user.studovnaId : 0;
  }

  get isLoggedIn(): boolean {
    return this._user.isLoggedIn();
  }

  get onLogout(): Subject<LoggedUser> {
    return this._onLogout;
  }

  get onLogin(): Subject<LoggedUser> {
    return this._onLogin;
  }

  public setLoggedUserData(
    eshopId: number,
    studovnaId: number,
    email: string,
    token: Token
  ) {
    const newUser = new LoggedUser(token, studovnaId, eshopId, email);
    this._user = newUser;
    this.saveToStorage();
    this.onLogin.next(newUser);
  }

  public setProfileFromEshop(profile: Partial<UserProfile>) {
    this._userProfile.patchFromEshop(profile);
    this.userChanged.next(this._userProfile);
    this.saveToStorage();
  }

  public setProfileFromStudovna(studovnaId: number, profile: UserDetailResponse) {
    this._userProfile.patchFromStudovna(studovnaId, profile);
    this.saveToStorage();
  }

  public patchEmail(email: string) {
    this._userProfile.email = email;
    this.setLoggedUserData(
      this.eshopId,
      this.studovnaId,
      email,
      this.token
    );
  }

  public clearProfile() {
    this._userProfile = new UserProfile();
    this._user = new LoggedUser('', 0, 0, '');
    this.saveToStorage();
  }

  protected saveToStorage() {
    const data = {
      user: this._user,
      profile: this._userProfile,
    };

    const json = JSON.stringify(data);
    localStorage.setItem(this.STORAGE_KEY, json);
  }

  public updateActiveCourse(courseId: number) {
    this._userProfile.activeCourse = courseId;
    this.saveToStorage();
  }

  removeFromStorage() {
    localStorage.removeItem(this.MY_PROFILE_STORAGE_KEY);
    return localStorage.removeItem(this.STORAGE_KEY);
  }

  protected loadFromStorage() {
    const json = localStorage.getItem(this.STORAGE_KEY);
    if (json) {
      try {
        const parsed = JSON.parse(json);
        if (parsed && parsed.user) {
          this._user = new LoggedUser('', 0, 0, '');
          Object.assign(this._user, parsed.user);
        }
        if (parsed && parsed.profile) {
          this._userProfile = new UserProfile();
          Object.assign(this._userProfile, parsed.profile);
        }
      } catch (e) {
        console.error('Error when loading user data from storage.', e);
      }
    }
  }

  public getMyProfile(userId: number, token: Token): Promise<MyProfileResponse> {
    if (!userId || !token) {
      return;
    }
    return this.studovnaApi
      .myProfile(userId, token)
      .then(profile => {
        const localProfile = JSON.parse(localStorage.getItem(this.MY_PROFILE_STORAGE_KEY)) as MyProfileResponse;
        if (!localProfile || localProfile.activeLanguage !== profile.activeLanguage) {
          this.setMyProfileToLocalStorage(profile);
        }
        this.myProfileChanged.next(profile);
        this._myProfile = profile;
        return this._myProfile;
      })
  }

  setMyProfileToLocalStorage(profile: MyProfileResponse) {
    localStorage.removeItem(this.MY_PROFILE_STORAGE_KEY);
    localStorage.setItem(this.MY_PROFILE_STORAGE_KEY, JSON.stringify(profile));
  }

  async getMyProfileFromLocalStorage() {
    const profile = JSON.parse(localStorage.getItem(this.MY_PROFILE_STORAGE_KEY)) as MyProfileResponse;
    if (!profile || ((this.user && this.user.studovnaId) && profile.id !== this.user.studovnaId) &&  this.user.token) {
      const response = await this.getMyProfile(this.user.studovnaId, this.user.token);
      if (response) {
        this.setMyProfileToLocalStorage(response);
        return response;
      }
    }
    return profile;
  }

  async forceRefreshMyProfile() {
    const response = await this.getMyProfile(this.user.studovnaId, this.user.token);
    if (response) {
      this.setMyProfileToLocalStorage(response);
      return response;
    }
  }

}
