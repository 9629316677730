import { LESSON_TYPE } from './lesson-type.enum';
import { LessonStatus } from './lesson-status';
import { EXERCISE_TYPE } from './exercise-type.enum';
import { LESSON_CONTENT_TYPE } from './lesson-content-type.enum';

export class Lesson {

	id: number;
	name: string;
	previousId: number;
	nextId: number;
	position: number;
	type: LESSON_TYPE;
	subType: (EXERCISE_TYPE | LESSON_CONTENT_TYPE);
	trialOnly = false;
	isNew: boolean;

	status?: LessonStatus;
	visited?: boolean;

	static createFromData(data: any): Lesson {

		let lesson = new Lesson();
		lesson.id = data.lbId;
		lesson.name = data.lessonName;
		lesson.nextId = data.nextLbId;
		lesson.previousId = data.previousLbId;
		lesson.position = data.lbPosition;
		lesson.type = data.lessonType;
		lesson.subType = data.specificLessonType;
		lesson.trialOnly = !!data.trialOnly;
		lesson.isNew = !!data.isNew;

		if (typeof data.visited !== 'undefined') {
			lesson.visited = data.visited;
		}

		return lesson;

	}

}
