import {TutorialSet} from '../models/tutorial-set';
import {TUTORIAL} from '../models/tutorial.enum';
import {_} from '../../../../../oj-app-common/i18n/translate-placeholder';


export const firstTutorial: TutorialSet = {
	code: TUTORIAL.first,
	nextText: 'Další',
	finishedText: 'Rozumím',
	skipText: 'Přeskočit',
	slides: [
		{
			image: 'first/1.png',
			title: 'Volba kurzu',
			text: 'V levém horním rohu si zvolte kurz, který chcete právě studovat.',
		},
		{
			image: 'first/2.png',
			title: 'Denní cíl',
			text: 'Na hlavní obrazovce máte vždy přehled o plnění denního cíle, který si jednoduše nastavíte podle svých představ. A pak už nezbývá než jít na to!',
		},
		{
			image: 'first/3.png',
			title: 'Denní úkoly',
			text: 'Každý den pro Vás bude připravena pečlivě zvolená porce opakování a nové látky. Plnit denní úkoly se rozhodně vyplatí a pořadí si můžete zvolit sami.',
		},
	]
};

export const firstTutorialEs: TutorialSet = {
  code: TUTORIAL.first,
  nextText: 'Další',
  finishedText: 'Rozumím',
  skipText: 'Přeskočit',
  slides: [
    {
      image: 'first/1-es.png',
      title: 'Volba kurzu',
      text: 'V levém horním rohu si zvolte kurz, který chcete právě studovat.',
    },
    {
      image: 'first/2-es.png',
      title: 'Denní cíl',
      text: 'Na hlavní obrazovce máte vždy přehled o plnění denního cíle, který si jednoduše nastavíte podle svých představ. A pak už nezbývá než jít na to!',
    },
    {
      image: 'first/3-es.png',
      title: 'Denní úkoly',
      text: 'Každý den pro Vás bude připravena pečlivě zvolená porce opakování a nové látky. Plnit denní úkoly se rozhodně vyplatí a pořadí si můžete zvolit sami.',
    },
  ]
};
