import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
  localStorageKey = 'purchase';

  isPendingPurchase: Subject<boolean> = new Subject<boolean>();

  isPending = false;

  constructor() { }

  setPurchase(val: boolean) {
    this.isPending = val;
    this.savePurchase();
  }

  public savePurchase() {
    localStorage.setItem(this.localStorageKey, JSON.stringify({pending: this.isPending}));
  }

  public getPurchaseFromLocalStorage(): boolean {
    const purchase = localStorage.getItem(this.localStorageKey);
    if (purchase) {
      const val = JSON.parse(purchase).isPending as boolean;
      this.isPending = val;
      this.isPendingPurchase.next(this.isPending);
      return this.isPending;
    } else {
      this.isPending = false;
      return this.isPending;
    }
  }

  removePurchaseFromLocalStorage() {
    localStorage.removeItem(this.localStorageKey);
    this.isPending = false;
  }
}
