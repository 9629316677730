import { VocabularyTrainingWord } from './vocabulary-training-word';

export class VocabularyDeck {
	public words: VocabularyTrainingWord[] = [];

	static createClone(from: VocabularyDeck): VocabularyDeck {
		const d = new VocabularyDeck();
		d.words = [...from.words];
		return d;
	}
}
