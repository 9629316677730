import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LANGUAGE_TYPES_SHORT} from '../../../../oj-app-common/misc/language-types.enum';
import {AppConfig} from '../../config/app-config';
import {TranslateService} from '@ngx-translate/core';
import {Carousel} from 'primeng/carousel';

export class SlideshowItem {
  backgroundImage: string;
  text?: string;
  actionBtn?: string;
  action?: () => void;
  type?: 'image' | 'phones';
}

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {

  @Input() slideshowItems: SlideshowItem[];

  @Input() circularSlideshow = true;

  @Input() autoPlayInterval = 3000;

  @Input() size: { width: string, height: string } = {width: '100%', height: '250px'};

  @ViewChild(Carousel, {static: false}) carousel: Carousel;

  constructor(
    protected appConfig: AppConfig,
    protected translateService: TranslateService
  ) {
  }

  ngOnInit() {
    // this.slideshowItems = [
    //   {
    //     background: '/assets/grades-static/1/1.jpg',
    //     text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Mauris dolor felis, sagittis at.'
    //   },
    //   {
    //     background: '/assets/grades-static/1/2.jpg',
    //     text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Mauris dolor felis, sagittis at.'
    //   },
    //   {
    //     background: '/assets/grades-static/1/3.jpg',
    //     text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Mauris dolor felis, sagittis at.'
    //   }
    // ]
  }

  itemClicked(item: SlideshowItem) {
    if (item && item.action) {
      item.action();
    }
    return;
  }

  googleLinkClicked() {
    const currApp = this.appConfig.currentApp;

    if (currApp === this.appConfig.apps.easylingo) {
      window.open('https://play.google.com/store/apps/details?id=com.easylingo.app', '_blank');
    } else {
      window.open('https://play.google.com/store/apps/details?id=cz.onlinejazyky.app', '_blank');
    }
  }

  appleLinkClicked() {
    const currApp = this.appConfig.currentApp;
    const lang = this.translateService.currentLang;

    if (currApp === this.appConfig.apps.easylingo) {

      if (lang === LANGUAGE_TYPES_SHORT.ENGLISH) {
        window.open('https://apps.apple.com/us/app/easylingo-fluent-in-no-time/id1481075253', '_blank');
      } else if (lang === LANGUAGE_TYPES_SHORT.SPANISH) {
        window.open('https://apps.apple.com/es/app/easylingo-fluent-in-no-time/id1481075253', '_blank');
      } else if (lang === LANGUAGE_TYPES_SHORT.CZECH) {
        window.open('https://apps.apple.com/cz/app/easylingo-fluent-in-no-time/id1481075253', '_blank');
      } else if (lang === LANGUAGE_TYPES_SHORT.SLOVAK) {
        window.open('https://apps.apple.com/sk/app/easylingo-fluent-in-no-time/id1481075253', '_blank');
      } else if (lang === LANGUAGE_TYPES_SHORT.POLISH) {
        window.open('https://apps.apple.com/pl/app/easylingo-fluent-in-no-time/id1481075253', '_blank');
      } else {
        window.open('https://apps.apple.com/us/app/easylingo-fluent-in-no-time/id1481075253', '_blank');
      }

    } else {

      if (lang === LANGUAGE_TYPES_SHORT.ENGLISH) {
        window.open('https://apps.apple.com/us/app/online-jazyky-nau%C4%8D%C3%AD-v%C3%A1s-v%C3%ADc/id1414565168', '_blank');
      } else if (lang === LANGUAGE_TYPES_SHORT.SPANISH) {
        window.open('https://apps.apple.com/es/app/online-jazyky-nau%C4%8D%C3%AD-v%C3%A1s-v%C3%ADc/id1414565168', '_blank');
      } else if (lang === LANGUAGE_TYPES_SHORT.CZECH) {
        window.open('https://apps.apple.com/cz/app/online-jazyky-nau%C4%8D%C3%AD-v%C3%A1s-v%C3%ADc/id1414565168', '_blank');
      } else if (lang === LANGUAGE_TYPES_SHORT.SLOVAK) {
        window.open('https://apps.apple.com/sk/app/online-jazyky-nau%C4%8D%C3%AD-v%C3%A1s-v%C3%ADc/id1414565168', '_blank');
      } else if (lang === LANGUAGE_TYPES_SHORT.POLISH) {
        window.open('https://apps.apple.com/pl/app/online-jazyky-nau%C4%8D%C3%AD-v%C3%A1s-v%C3%ADc/id1414565168', '_blank');
      } else {
        window.open('https://apps.apple.com/us/app/online-jazyky-nau%C4%8D%C3%AD-v%C3%A1s-v%C3%ADc/id1414565168', '_blank');
      }

    }

    return;
  }

  refresh(items: SlideshowItem[]) {
    // this.carousel._items = items;
  }
}
