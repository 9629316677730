import { LANGUAGE_TYPES } from '../../misc/language-types.enum';

export class CourseProgressDetails {
	id: number;
	name: string;
	type: LANGUAGE_TYPES;
	isDemo: boolean;
	started?: Date;
	expiration?: Date;
	lastActivity?: Date;
	complete: number;
	success: number;
	time: number;
	learnedWords: number;
	allWords: number;
	hasCertificate: boolean;
}
