import { Injectable } from '@angular/core';
import {RubyService} from '../../../../../../../oj-app-common/ruby/ruby.service';
import {CurrencyModel} from '../../../../../../../oj-app-common/shop/models/shop/currency.model';
import { values } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class EshopCurrenciesService {

  protected _currencies: { [key: string]: CurrencyModel } = {};

  constructor(
    protected rubyService: RubyService,
  ) {
    this.loadData();
  }

  protected async loadData() {
    const d = await this.rubyService.currencies();
    if (d) {
      this._currencies = {};
      d.forEach(
        (c) => {
          this._currencies[c.code] = c;
        },
      );
    }
  }

  get currencies(): CurrencyModel[] {
    return values(this._currencies);
  }

  get currenciesByCode(): { [key: string]: CurrencyModel } {
    return this._currencies;
  }

  public getCurrencyByCode(code: string): CurrencyModel | null {
    return this._currencies[code] || null;
  }
}
