import { ProductModel } from '../../shop/models/shop/product.model';
import { parseTime } from '../../tools/parse-time';

export interface Subscription {
	eshop: string;
	free_until: null|Date;
	id: number;
	id_woo: number;
	months: number;
	paid_until: null|Date;
	start: null|Date;
	stop: null|Date;
	woo_eshop_id: number;
	product: ProductModel;
	status: SubscriptionStatus;
	is_active: boolean;
}

export type SubscriptionStatus = 'processing' | 'created' | 'pending' | 'failed' | 'completed' | 'cancelled' | 'refunded' | 'expired' | 'active' | 'on_hold' | 'pending_cancel' | 'deleted';

export function subscriptionSortFunction(s1: Subscription, s2: Subscription): number {
	let diff1 = (s2.is_active ? 1 : 0) - (s1.is_active ? 1 : 0);
	if (diff1 !== 0) {
		return diff1;
	}

	return (s2.start ? s2.start.getTime() : 0) - (s1.start ? s1.start.getTime() : 0);
}

export function createSubscriptionFromObject(input: any): Subscription {
	input.free_until = input.free_until ? parseTime(input.free_until, true) : null;
	input.paid_until = input.paid_until ? parseTime(input.paid_until, true) : null;
	input.start = input.start ? parseTime(input.start, true) : null;
	input.stop = input.stop ? parseTime(input.stop, true) : null;


	input.is_active = false;
	if (input.status === 'active' || input.status === 'completed' || input.status === 'pending_cancel') {
		let now = Date.now();
		if (input.start && input.start.getTime() < now) {
			if ((input.stop && input.stop.getTime() > now) || input.paid_until && input.paid_until.getTime() > now) {
				input.is_active = true;
			}
		}
	}

	return input;
}
