import { AbstractFillUpExercise } from './abstract-fill-up-exercise';
import arrayShuffle from 'array-shuffle';
import { SentenceToken } from './models/sentence-token';
import { StaticToken } from './models/static-token';
import { DragDropSentenceToken } from './models/drag-drop-sentence-token';
import { Sentence } from './models/sentence';
import { ANSWER_STATUS_BOOLEAN } from '../misc/answer-status-boolean';
import { InvalidExerciseData } from '../errors/invalid-exercise-data';
import { isString, trim, without } from 'lodash-es';
import { compareStringsWithTolerance } from '../../tools/text-comparator';
import { autoCalculateTokenSizes } from './token-auto-size-calculator';

export class DragDropFillUpExercise extends AbstractFillUpExercise {

	static fillUpType = 'dragdrop';

	public usedWordsInAlternativeTokens: {[key: string]: boolean} = {};

	public createToken(tokenData: any, sentence: Sentence = null): SentenceToken|null {

		if (tokenData.type === StaticToken.typeName) {

			let t = new StaticToken();
			t.id = +tokenData['id'];
			t.html = tokenData['content'];
			t.uniqueId = sentence.number + '-' + t.id;


			let trimmed = trim(tokenData['content']);
			// Odstraňování tečky na jonci řádku zrušeno dle https://podio.com/onlinejazykycz/zakladna-oj/apps/hlaseni/items/18981
			//if (trimmed === '.' || trimmed === ',' || !trimmed) {
			if (!trimmed) {
				return null;
			}

			if (t.html) {
				t.html = t.html.replace(/<div>/ig, '<br />');
				t.html = t.html.replace(/<\/div>/ig, '');
				// Často tam dávají <br /> místo toho, aby dělili věty - musíme to lépe odřádkovat
				t.html = t.html.replace(/<br\s?\/?>/ig, '<div style="height: 0.5em"></div>');

				// Odstraňování tečky na jonci řádku zrušeno dle https://podio.com/onlinejazykycz/zakladna-oj/apps/hlaseni/items/18981
				//t.html = t.html.replace(/^\s*\.\s*<div/, '<div');

				t.html = trim(t.html);

				if (!t.html) {
					return null;
				}

			}

			return t;

		} else {

			let t = new DragDropSentenceToken();
			t.id = +tokenData['id'];
			t.filled = null;
			t.uniqueId = sentence.number + '-' + t.id;

			if (isString(tokenData['content']) || tokenData['content'].length === 1) {
				t.text = tokenData['content'][0];
				t.text = t.text.replace(/\s{2,}/g, ' '); // Občas lektoři dají dvě mezery za sebe
			} else {
				// Musíme řešit situaci, kdy je v tokenu více slov

				let bestWord = tokenData['content'][0];
				for (let testedWord of tokenData['content']) {
					if (this.usedWordsInAlternativeTokens[testedWord]) {
						continue;
					}
					this.usedWordsInAlternativeTokens[testedWord] = true;
					bestWord = testedWord;
					break;
				}

				t.text = bestWord;
				t.alternativeWords = tokenData['content'];

				t.text = t.text.replace(/\s{2,}/g, ' ');
				t.alternativeWords = t.alternativeWords.map(
					(s) => s.replace(/\s{2,}/g, ' ')
				);
			}

			sentence.allTokens.push(t);
			sentence.availableTokens.push(t);
			t.number = sentence.allTokens.length;
			t.autoSetSize();

			return t;

		}

	}


	isTouched(): boolean {
		return this.sentences.reduce(
			(prevValue, currSentence) => {
				let hasFilled = currSentence.tokens.some((token: DragDropSentenceToken) => !!token.filled);
				if (prevValue) {
					return true;
				}
				return !!hasFilled;
			},
			false
		);
	}

	public init(lessonData: object): void {
		super.init(lessonData);
		for (let fillupIndex of Object.keys(lessonData['fillups'])) {
			let fillupSpec = lessonData['fillups'][fillupIndex];
			if (fillupSpec['wrong'] && fillupSpec['wrong'].length) {
				let sentence = this.sentences.find((s) => s.number === +fillupIndex);
				if (!sentence) {
					continue;
				}
				for (let wrongWord of fillupSpec['wrong']) {
					if (!trim(wrongWord)) {
						continue;
					}
					let wrongToken = new DragDropSentenceToken();
					sentence.allTokens.push(wrongToken);
					sentence.availableTokens.push(wrongToken);
					wrongToken.text = wrongWord;
					wrongToken.number = sentence.allTokens.length;
					wrongToken.uniqueId = sentence.number + '-' + wrongToken.number;
					wrongToken.autoSetSize();
				}
				autoCalculateTokenSizes(sentence.allTokens);
			}
		}


		this.sentences.map(
			(s) => {
				s.availableTokens = arrayShuffle(s.availableTokens);
			}
		);

	}

	public reset(): void {

		this.sentences.map(
			(sentence: Sentence) => {

				sentence.availableTokens = sentence.allTokens.slice(0);
				sentence.availableTokens = arrayShuffle(sentence.availableTokens);

				sentence.tokens.map(
					(token: SentenceToken) => {
						if (token instanceof DragDropSentenceToken) {
							token.filled = null;
							token.result = ANSWER_STATUS_BOOLEAN.UNKNOWN_YET;
						}
					}
				);

			}
		);

	}

	public evaluate(): void {

		let correct = 0;
		let total = 0;

		this.sentences.map(
			(sentence: Sentence) => {

				let sentenceCorrect = true;

				sentence.tokens.map(
					(token: SentenceToken) => {
						if (token instanceof DragDropSentenceToken) {
							total++;

							//if (token.selected === token) { // Dva různé tokeny se stejným textem jou také správně
							if (token.filled && token.text && token.filled.text && compareStringsWithTolerance(token.filled.text, token.text, this.tolerance)) {
								token.result = ANSWER_STATUS_BOOLEAN.CORRECT;
								correct++;
							} else {

								// Alternativní slova - některé tokeny mají více správných vyplnění

								let isAlternativeWordCorrect = false;

								if (token.filled && token.filled.text && token.alternativeWords ) {
									for (let altWord of token.alternativeWords) {
										if (compareStringsWithTolerance(token.filled.text, altWord, this.tolerance)) {
											isAlternativeWordCorrect = true;
											break;
										}
									}
								}

								if (!isAlternativeWordCorrect) {
									token.result = ANSWER_STATUS_BOOLEAN.INCORRECT;
									sentenceCorrect = false;
								} else {
									token.result = ANSWER_STATUS_BOOLEAN.CORRECT;
									correct++;
								}

							}
						}
					}
				);

				sentence.result = sentenceCorrect ? ANSWER_STATUS_BOOLEAN.CORRECT : ANSWER_STATUS_BOOLEAN.INCORRECT;

			}
		);

		this.setResultUsingNumbers(correct, total);

	}

	public getAnswersForApi(): object {

		let answers = {};
		this.sentences.map(
			(sentence: Sentence) => {
				let words = {};
				for (let token of sentence.tokens) {
					if (token instanceof DragDropSentenceToken) {
						words[token.id] = token.filled ? token.filled.text : '';
					}
				}
				answers[sentence.id] = words;
			}
		);

		return {
			'answers': answers
		};

	}

	public canBeEvaluated(): boolean {
		return this.sentences.reduce(
			(everythingFilled: boolean, thisSentence: Sentence) => {

				if (!everythingFilled) {
					return false;
				}

				return thisSentence.tokens.reduce(
					(allTokensHasValue: boolean, thisToken: SentenceToken) => {
						if (!allTokensHasValue) {
							return false;
						}
						if (thisToken.type === StaticToken.typeName) {
							return true;
						}
						if (!(<DragDropSentenceToken>thisToken).filled) {
							return false;
						}
						return true;
					},
					true
				);

			},
			true
		);
	}

	public setFromPreviousAnswers(data: any): void {
		if (!data) return;

		if (!data.answers) {
			throw new InvalidExerciseData('Data does not contain data.answers.fillups.');
		}


		for (let sentenceIdStr of Object.keys(data.answers)) {
			let sentenceId = +sentenceIdStr;
			let usedTokens = {};
			let sentence = this.sentences.find(s => s.id === sentenceId);
			if (!sentence) {
				continue;
			}
			let values = {};
			for (let tokenIdString of Object.keys(data.answers[sentenceIdStr])) {
				let tokenId = +tokenIdString;
				values[tokenId] = data.answers[sentenceIdStr][tokenIdString];
			}

			for (let token of sentence.tokens) {
				if (token instanceof DragDropSentenceToken) {
					if (values[token.id]) {
						let foundToken = <DragDropSentenceToken>sentence.tokens.find(
							(thisToken: SentenceToken) => {
								if (thisToken instanceof DragDropSentenceToken) {
									if (compareStringsWithTolerance(values[token.id], thisToken.text, this.tolerance)  && !usedTokens[thisToken.id]) {
										return true;
									}
								}
								return false;
							}
						);
						if (foundToken) {
							this.putTokenIntoSlot(token, foundToken, sentence);
							usedTokens[foundToken.id] = true;
						}
					}
				}
			}
		}

	}


	public removeTokenFromAllSlots(token: DragDropSentenceToken, addToAvailableTokens = true) {
		this.sentences.map(
			(sentence) => {
				sentence.tokens.map(
					(sentenceToken) => {
						if (sentenceToken instanceof DragDropSentenceToken) {
							if (sentenceToken.filled === token) {
								sentenceToken.filled = null;
								if (addToAvailableTokens) {
									if (sentence.availableTokens.indexOf(token) === -1) {
										sentence.availableTokens = [token].concat(sentence.availableTokens);
									}
								}
							}
						}
					}
				);
			}
		);
	}

	public emptySlot(slot: DragDropSentenceToken, sentence: Sentence, addToAvailableTokens = true) {
		if (!slot.filled) {
			return;
		}

		if (addToAvailableTokens) {
			sentence.availableTokens = [slot.filled].concat(sentence.availableTokens);
		}

		slot.filled = null;
	}

	public putTokenIntoSlot(slotToken: DragDropSentenceToken, filledToken: DragDropSentenceToken, sentence: Sentence) {
		this.removeTokenFromAllSlots(filledToken, false);
		this.emptySlot(slotToken, sentence, true);
		slotToken.filled = filledToken;
		sentence.availableTokens = without(sentence.availableTokens, filledToken);
	}

	public getAllDraggableTokens(): DragDropSentenceToken[] {
		let tokens = [];
		this.sentences.map(
			(s) => {
				tokens = tokens.concat(s.allTokens);
			}
		);
		return tokens;
	}

	public getAllSlots(): DragDropSentenceToken[] {
		let tokens = [];
		this.sentences.map(
			(s) => {
				tokens = tokens.concat(s.tokens.filter((t) => t.type === DragDropSentenceToken.typeName));
			}
		);
		return tokens;
	}



}
