import { CourseDetailResponse } from '../../studovna-api/responses/course-detail';
import { Chapter } from './chapter';

export class Course extends CourseDetailResponse {

	chapters: Chapter[];
	isNonLingual = false;

	public static createFromData(details: CourseDetailResponse, chapters: Chapter[]): Course {

		let c = new Course();
		Object.assign(c, details);

		// Shallow clone array
		c.chapters = chapters.slice(0);

		c.chapters.sort(
			(ch1: Chapter, ch2: Chapter) => {
				if (ch1.order || ch2.order) {
					return ch1.order - ch2.order;
				}
				return ch1.id - ch2.id;
			}
		);

		c.isNonLingual = (c.type === 'CZECH' && (!c.target || c.target === '0'));

		return c;

	}

}
