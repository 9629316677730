import {Inject, Injectable, Renderer2} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppTranslationService} from './app-translation.service';
import {AppConfig} from '../config/app-config';
import {DOCUMENT} from '@angular/common';
import {CourseShopOptionsModel} from '../../../oj-app-common/shop/models/course-shop-options.model';
import {CourseCombinedModel} from '../../../oj-app-common/shop/models/shop/course-combined.model';
import * as CryptoJS from 'crypto-js';
import {CookieManagementService} from './cookie.service';

@Injectable({
  providedIn: 'root',
})
export class AffiliateService {

  searchingParams: AffiliateModel = {
    oj_id: '',
    oj_source: '',
    lang: ''
  };

  STORAGE_KEY = 'affiliate';

  constructor(
    protected http: HttpClient,
    protected appTranslationService: AppTranslationService,
    protected appConfig: AppConfig,
    @Inject(DOCUMENT) private _document: Document,
    private cookieService: CookieManagementService
  ) {

  }

  parseUrl(url: string) {
    // parse params from url and store into searchingParams variable
    const uri = new URL(url);
    Object.keys(this.searchingParams)
      .forEach(key => {
        if (uri.searchParams.get(key)) {
          this.searchingParams[key] = uri.searchParams.get(key);
        }
      });

    console.log('affiliate params successfully loaded');
    this.set(this.searchingParams);
  }

  // set value to local storage
  set(val: AffiliateModel) {
    const data = localStorage.getItem(this.STORAGE_KEY);
    if (data) {
      localStorage.removeItem(this.STORAGE_KEY);
    }
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(val));
  }

  // get value from local storage
  get(): AffiliateModel {
    const val = localStorage.getItem(this.STORAGE_KEY);

    if (val) {
      return JSON.parse(val);
    }
    return null;
  }

  // clear local storage
  clear() {
    localStorage.removeItem(this.STORAGE_KEY);
  }

  send(email: string, studentId: number, renderer2: Renderer2) {
    const storedValue = this.get();

    if (!storedValue || !email) {
      console.log('there is no any affiliate values');
      return;
    }

    const url = 'https://ruby-api.onlinejazyky.cz/api/students';

    const headers = new HttpHeaders();

    headers.set('Content-Type', 'application/json');

    const data: AffiliateRubyModel = {
      eshop_id: studentId,
      email,
      language: storedValue.lang,
    };

    if (this.isNotNullAndHasValue(storedValue.oj_source)) {
      data.affiliate_partner = storedValue.oj_source;
    }

    if (this.isNotNullAndHasValue(storedValue.oj_id)) {
      data.affiliate_id = storedValue.oj_id;
    }

    if (this.isNotNullAndHasValue(storedValue.lang)) {
      data.language = storedValue.lang;
    }
    // else {
    //   data.language = this.appTranslationService.currentLanguage;
    // }

    data.brand = this.appConfig.brandCode;

    this.setCookie(data);

    this.appendAffiliateScript(renderer2, email);

    this.http
      .put(
        url,
        data,
        {
          headers,
        },
      )
      .subscribe(() => {
        // this.clear();
      });
  }

  setCookie(data: AffiliateRubyModel) {

    Object.keys(data)
      .forEach(k => {

        this.cookieService.setCookieIfConsented(k, data[k]);

      })
  }

  // check if variable is not null and has any value
  isNotNullAndHasValue(value: string) {
    return value && value.length > 0;
  }

  /**
   * Set Affiliate data on the Document Body.
   *
   * @param renderer2             The Angular Renderer
   * @param userEmail             Email of user
   * @returns                     Void
   */
  public appendAffiliateScript(renderer2: Renderer2, userEmail: string): void {

    if (!userEmail) {
      return;
    }


    const script2 = renderer2.createElement('script');
    script2.innerHTML = '';
    script2.src = 'https://affiliate.easylingo.com/c3.js';
    script2.async = true;
    script2.defer = true;

    renderer2.appendChild(this._document.body, script2);

    const script = renderer2.createElement('script');
    script.type = 'text/javascript';

    script.text = `
    var ab_instance = "affiliate.easylingo.com";
    var ab_kampan = 7;
    var ab_cena = 0;
    var ab_id_transakce = "${userEmail}";
    `;
    renderer2.appendChild(this._document.body, script);

  }

  googleAnalyticsAfterLogin(method: 'email' | 'facebook' = 'email', email: string) {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      event: 'login',
      method,
      user_id: this.emailToMd5(email),
    });
  }

  googleAnalyticsAfterRegister(method: 'email' | 'facebook' = 'email', email: string) {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      event: 'sign_up',
      method,
      user_id: this.emailToMd5(email),
    });
  }

  googleAnalyticsAfterDemo(course_id: number, course_name: string) {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      event: 'demo',
      course_id,
      course_name
    });
  }

  googleAnalyticsViewProduct(courseShopOptions: CourseShopOptionsModel, courseCombined: CourseCombinedModel) {
    window['dataLayer'] = window['dataLayer'] || [];

    const subscriptionItems: any[] = [];

    const item = {
      event: 'view_item',
      currency: null,
      value: null,
      items: [],
    }

    let oneTimePrice = 0;

    courseShopOptions.oneTimePurchases.forEach(otp => {
      if (otp.product.prices) {
        if (otp.product.prices['EUR']) {
          oneTimePrice = otp.product.prices['EUR']['oneTime'] || 0;
          item.currency = 'EUR';
        } else if (otp.product.prices['CZK']) {
          oneTimePrice = otp.product.prices['CZK']['oneTime'] || 0;
          item.currency = 'CZK';
        }
      }
    });

    const allProducts = courseShopOptions.oneTimePurchases.concat(courseShopOptions.subscriptions) || [];

    allProducts.forEach(otp => {
      let price = 0;
      let currency = 'CZK';

      if (otp.product.prices) {

        if (otp.product.prices['EUR']) {
          price = otp.product.prices['EUR']['oneTime'] || otp.product.prices['EUR']['monthly'] || 0;
          currency = 'EUR';
        } else if (otp.product.prices['CZK']) {
          price = otp.product.prices['CZK']['oneTime'] || otp.product.prices['CZK']['monthly'] || 0;
          currency = 'CZK';
        }

      }

      if (!item.currency) {
        item.currency = currency;
      }

      const subscriptionItem = {
        item_id: otp.product.id,
        item_name: otp.product.name,
        price,
        currency,
      }

      subscriptionItems.push(subscriptionItem);
    });

    item.value = oneTimePrice;

    if (!item.value && subscriptionItems.length > 0) {
      item.value = subscriptionItems[0].price;
    }

    if (courseShopOptions.availableAsTrial) {
      subscriptionItems.push({
        item_id: courseCombined.id_eshop,
        item_name: courseCombined.name,
        price: 0,
        currency: item.currency,
      })
    }

    item.items = [...subscriptionItems];

    window['dataLayer'].push(item);

  }

  emailToMd5(email: string): string {
    const hash = CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
    return hash;
    // return Md5.hashStr(email);
  }

}

// model for getting data from URL
export interface AffiliateModel {
  oj_source: string;
  oj_id: string;
  lang: string;
}

// model sent to ruby
export interface AffiliateRubyModel {
  eshop_id: number;
  email: string;
  affiliate_partner?: string;
  affiliate_id?: string;
  language: string
  brand?: 'oj' | 'el'
}
