import {
  checkExpireDate,
  createNotExpiredStatus,
  ExpirationStatus
} from '../../pages/base-layout-page/pages/eshop-page/functions/course-expire-compare';
import {CourseProgressDetails} from '../../../../oj-app-common/studovna-api/models/course-progress-details';
import {AvailableCourse} from '../../../../oj-app-common/eshop-api/models/available-course';
import {CourseCombinedModel} from '../../../../oj-app-common/shop/models/shop/course-combined.model';
import {ProductCombinedModel} from '../../../../oj-app-common/shop/models/shop/product-combined.model';
import {add} from 'ngx-bootstrap/chronos';


export interface CourseItem {

  id: number;
  imageSrc: string;
  isWithRibbon?: boolean;
  isWithPro?: boolean;
  isWithLock?: boolean;
  isWithClock?: boolean;
  isDemo?: boolean;
  isFavorite?: boolean;
  isStarred?: boolean;
  name: string;
  productId?: number;

  displayProgress?: boolean;
  progress?: number;
  expiredStatus?: ExpirationStatus;
  expireDate?: Date;
  grayscale?: boolean;
  displayDotsMenu?: boolean;
  displayFavoriteStar?: boolean;

  button?: 'get' | 'study' | 'activate' | 'start';

  buttonSize?: 'lg' | 'sm';
  courseSize?: 'lg' | 'sm';

}

export function createCourseItemFromData(
  studovnaData: CourseProgressDetails,
  eshopData: AvailableCourse,
  currentCourse: number = null): CourseItem {

  const course: CourseItem = {
    id: +studovnaData.id,
    productId: +eshopData.productId,
    name: studovnaData.name,
    imageSrc: eshopData.image,
    isWithRibbon: false,
    isWithPro: false,
    isDemo: studovnaData.isDemo,
    displayProgress: true,
    progress: Number.parseInt(studovnaData.complete.toString(), 0),
    expiredStatus: checkExpireDate(eshopData.expireDate),
    isFavorite: eshopData.isFavorite,
    isStarred: eshopData.isStarred,
  };

  if (course.expiredStatus.expireDate) {
    if (course.expiredStatus.isExpired) {
      course.isWithLock = true;
    } else {
      course.isWithClock = true;
    }
  }

  if (currentCourse === course.id) {
    course.isWithRibbon = true;
  }

  if (course.expiredStatus.isExpired) {
    course.grayscale = true;
    course.button = null;
    course.isWithLock = true;
    course.isWithRibbon = false;
    course.displayProgress = false;
  } else {
    // if (course.progress <= 0.001) {
      course.button = 'study';
      // course.displayProgress = false;
    // }
  }

  return course;
}

export function createCourseItemFromRubyCalc(
  model: CourseCombinedModel,
  additionalData: { currentCourseId?: number, withoutPurchaseStatus?: boolean } = null): CourseItem {

  const item: CourseItem = {
    id: model.id_study,
    name: model.name,
    imageSrc: model.imageUrl,
    isDemo: model.userHasAsTrial && !model.userHasAsFullCourse,
    isWithRibbon: false,
    isWithPro: false,
    productId: model.id_eshop,
    displayProgress: false,
    isFavorite: model.favorite,
    isStarred: model.starred,
    expiredStatus: checkExpireDate(model.expireDate),
    expireDate: model.expireDate,
  };

  if (additionalData && additionalData.withoutPurchaseStatus) {
    item.isDemo = false;
    return item;
  }

  // Výchozí stav - úplně neaktivovaný kurz
  if (!model.userHasAsFullCourse && !model.userHasAsTrial) {
    item.button = 'start';
    item.isWithLock = true;
  }

  // Aktivní kurz
  if (additionalData && additionalData.currentCourseId) {
    if (additionalData.currentCourseId === item.id) {
      item.isWithRibbon = true;
      item.button = 'study';
    }
  }


  // Zakoupen nebo má předplatné, je přidán
  if (model.userHasAsFullCourse && model.favorite) {
    item.button = 'study';
    if (model.expireDate) {
      item.isWithClock = true;
    }
  }

  // Zakoupen nebo má předplatné, není přidán
  if (model.userHasAsFullCourse && !model.favorite) {
    if (!item.button) {
      item.button = 'activate';
    }
    if (model.expireDate) {
      item.isWithClock = true;
    }
  }


  // Expirovaný kurz
  if (!model.userHasAsFullCourse && model.expireDate) {

    if (item.expiredStatus.isExpired) {
      item.button = 'get';
      item.expireDate = model.expireDate;
      item.isWithLock = true;
      item.grayscale = true;
    }

  }


  // Má alespoň aktivovaný trial
  if (!model.userHasAsFullCourse && model.userHasAsTrial && !item.expiredStatus.isExpired) {

    item.isDemo = true;

    if (!item.button) {
      if (model.favorite) {
        // Má přidán
        item.button = 'study';
      } else {
        // Nemá přidán
        item.button = 'activate';
      }
    }

  }


  return item;

}

export function createProductItemFromRubyCalc(product: ProductCombinedModel): CourseItem {
  return {
    id: product.id,
    productId: product.id,
    name: product.name,
    imageSrc: product.image_url,
    displayProgress: false,
    expiredStatus: createNotExpiredStatus(),
  };
}
