import { MethodDescription } from '../method-description';

export const ConvertIdMethodDescription = new MethodDescription();

ConvertIdMethodDescription.name = 'convertId';
ConvertIdMethodDescription.fields = [
	'id',
	'from',
	'to',
];
ConvertIdMethodDescription.usesToken = false;
