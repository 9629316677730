import {NgModule} from '@angular/core';

/* Custom components && modules import */
import {CommonModule} from '@angular/common';
import {UserInfoComponent} from './user-info.component';
import {MatButtonModule, MatIconModule} from '@angular/material';
import {TranslateModule} from "@ngx-translate/core";
import {PluralPipeModule} from "../../pipes/plural-pipe.module";
import {DeviceDetectorService} from "ngx-device-detector";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";

@NgModule({
  declarations: [
    UserInfoComponent
  ],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    PluralPipeModule
  ],
  providers: [
    DeviceDetectorService
  ],
  exports: [
    UserInfoComponent
  ]
})
export class UserInfoModule {
}
