import {MainNavigationLayoutComponent} from './main-navigation-layout.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationPanelModule} from '../../components/navigation-panel/navigation-panel.module';
import {SidebarModule} from '../../components/sidebar/sidebar.module';
import {RouterModule} from '@angular/router';
import {DirectivesModule} from '../../directives/directives.module';
import {PluralPipeModule} from '../../pipes/plural-pipe.module';

@NgModule({
  declarations: [
    MainNavigationLayoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NavigationPanelModule,
    SidebarModule,
    DirectivesModule,
    PluralPipeModule
  ],
  providers: [
  ],
  exports: [
    MainNavigationLayoutComponent
  ]
})
export class MainNavigationLayoutModule { }
