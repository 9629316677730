import { Component, OnInit } from '@angular/core';
import {LoadingService} from '../../services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  isShowed = false;
  loadingText;

  constructor(
    private _loadingService: LoadingService
  ) { }

  ngOnInit() {
    this._loadingService
      .isShowed
      .subscribe(val => this.isShowed = val);
    this._loadingService
      .loadingText
      .subscribe(val => this.loadingText = val);
  }

}
