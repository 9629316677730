import { ANSWER_STATUS_BOOLEAN } from '../../misc/answer-status-boolean';

export class BaseImage {

	id: number;

	imageUrl: string;

	result: ANSWER_STATUS_BOOLEAN = ANSWER_STATUS_BOOLEAN.UNKNOWN_YET;

	originalImage?: string;

	number: number;

}
