import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'whitespaceTruncate'
})
export class WhitespaceTruncatePipe implements PipeTransform {

  transform(text: string): string {
    if (!text || text.length === 0) {
      return;
    }
    if (/\s/.test(text)) {
      return text.replace(/ /, '%20').toString();
    }
    return text;
  }

}
