export class RenderedDataModel {
  weekDailyGoalPos: number;
  barHeight: any;
  dayStatusClass: any;
  formattedTimeToday: string;
  formattedPercentageToday: string;
  formattedTimeWeek: string;
  formattedPercentageWeek: string;
  formattedTimeOverall: string;
  formattedPercentageOverall: string;
  dailyTaskProgressVocabulary: number;
  formattedTimeDailyTaskVocabularyStudyTime: string;
  formattedTimeDailyTaskVocabularyGoalTime: string;
  dailyTaskProgressExercise: number;
  formattedTimeDailyTaskExerciseStudyTime: string;
  formattedTimeDailyTaskExerciseGoalTime: string;
  dailyTaskProgressStudy: number;
  formattedTimeDailyTaskStudyStudyTime: string;
  formattedTimeDailyTaskStudyGoalTime: string;

  static createEmpty(): RenderedDataModel {
    return {
      weekDailyGoalPos: 0,
      barHeight: {},
      dayStatusClass: {},
      formattedTimeToday: '',
      formattedPercentageToday: '',
      formattedTimeWeek: '',
      formattedPercentageWeek: '',
      formattedTimeOverall: '',
      formattedPercentageOverall: '',
      dailyTaskProgressVocabulary: 0,
      formattedTimeDailyTaskVocabularyStudyTime: '',
      formattedTimeDailyTaskVocabularyGoalTime: '',
      dailyTaskProgressExercise: 0,
      formattedTimeDailyTaskExerciseStudyTime: '',
      formattedTimeDailyTaskExerciseGoalTime: '',
      dailyTaskProgressStudy: 0,
      formattedTimeDailyTaskStudyStudyTime: '',
      formattedTimeDailyTaskStudyGoalTime: '',
    };
  }
}
