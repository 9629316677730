import { MethodDescription } from '../method-description';

export const CheckLoginMethodDescription = new MethodDescription();

CheckLoginMethodDescription.name = 'checkLogin';
CheckLoginMethodDescription.fields = [
	'email',
	'password',
	'language',
];
