import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SidebarComponent} from '../../../../components/sidebar/sidebar.component';
import {LoginService} from '../../../../services/login.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {StudovnaApi} from '../../../../../../oj-app-common/studovna-api/studovna-api';
import {CurrentUserService} from '../../../../auth/current-user.service';
import {ThemeService} from '../../../../theme/theme.service';
import {VocabularyService} from '../../../../services/vocabulary.service';
import {CurrentLessonSeriesService} from '../../../../services/current-lesson-series-service';
import {CurrentCourseService} from '../../../../services/current-course-service';
import {OverlayService} from '../../../../components/overlay-dialog/overlay-service';
import {MessageService} from 'primeng/api';
import {ActivityComponent} from '../../../../common/functions/activity.component';
import {BaseLayoutService} from '../../base-layout.service';
import {LoadingService} from '../../../../services/loading.service';
import {TutorialService} from '../../../../services/tutorial.service';
import {Subscription} from 'rxjs';
import {BsModalService} from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-activity-sidebar',
  templateUrl: './activity-sidebar.component.html',
  styleUrls: ['./activity-sidebar.component.scss', './sidebar-today.scss', './sidebar-activity.scss']
})
export class ActivitySidebarComponent extends ActivityComponent implements OnInit, OnDestroy {
  loaded = false;

  loadDataSubscriber: Subscription;

  currentCourseSubscriber: Subscription;

  @ViewChild(SidebarComponent, {static: true}) sidebar: SidebarComponent;

  constructor(
    protected _loginService: LoginService,
    protected router: Router,
    protected translateService: TranslateService,
    protected studovnaApi: StudovnaApi,
    protected currentUserService: CurrentUserService,
    protected themeService: ThemeService,
    protected vocabularyService: VocabularyService,
    protected currentLessonService: CurrentLessonSeriesService,
    protected currentCourseService: CurrentCourseService,
    protected overlayDialogService: OverlayService,
    protected baseLayoutService: BaseLayoutService,
    protected messageService: MessageService,
    protected loadingService: LoadingService,
    protected modalService: BsModalService,
    protected tutorial: TutorialService
  ) {
    super(
      _loginService,
      router,
      translateService,
      studovnaApi,
      currentUserService,
      themeService,
      vocabularyService,
      currentLessonService,
      currentCourseService,
      overlayDialogService,
      baseLayoutService,
      messageService,
      loadingService,
      modalService,
      tutorial,
    );
  }

  ngOnInit() {
    this.reloadData();

    this.currentCourse = this.currentCourseService.getCurrentAvailableCourse();

    this.loadDataSubscriber = this.currentCourseService
      .onAvailableCourseChanged
      .subscribe(v => {
        this.currentCourse = v;
      });

    this.loading
      .subscribe(v => {
        setTimeout(() => {
          this.loaded = v
        }, 50);
      });


  }

  ngOnDestroy() {
    if (this.loadDataSubscriber) {
      this.loadDataSubscriber.unsubscribe();
    }

    if (this.currentCourseSubscriber) {
      this.currentCourseSubscriber.unsubscribe();
    }
  }

}
