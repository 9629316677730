import { EvaluationResult } from '../../exercises/evaluation-result';
import { Achievements } from '../models/achievements';

export class ExerciseEvaluationResponse {

	lessonBlock: number;
	evaluation: EvaluationResult;
	data: any;
	points: number;
	achievements: Achievements;

	correctAnswers: number;
	questionNumber: number;
	result: number;
	loggedActivity: boolean;

	video: string;
	video320: string;

}
