import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SidebarComponent} from '../../../../components/sidebar/sidebar.component';
import {MatSelectChange} from '@angular/material';
import {DashboardSidebarContentComponent} from './dashboard-sidebar-content/dashboard-sidebar-content.component';
import {
  IntroTutorialModalComponent,
} from '../../../../components/tutorial/intro-tutorial-modal/intro-tutorial-modal.component';
import {AvailableCourse} from '../../../../models/available-course';
import {ActivityComponent} from '../../../../common/functions/activity.component';
import {LoginService} from '../../../../services/login.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {StudovnaApi} from '../../../../../../oj-app-common/studovna-api/studovna-api';
import {CurrentUserService} from '../../../../auth/current-user.service';
import {ThemeService} from '../../../../theme/theme.service';
import {VocabularyService} from '../../../../services/vocabulary.service';
import {CurrentLessonSeriesService} from '../../../../services/current-lesson-series-service';
import {CurrentCourseService} from '../../../../services/current-course-service';
import {OverlayService} from '../../../../components/overlay-dialog/overlay-service';
import {BaseLayoutService} from '../../base-layout.service';
import {MessageService} from 'primeng/api';
import {LoadingService} from '../../../../services/loading.service';
import {TutorialService} from '../../../../services/tutorial.service';
import {Subscription} from 'rxjs';
import {HelpcrunchService} from '../../../../services/helpcrunch.service';
import {AppConfig} from '../../../../config/app-config';
import {LANGUAGE_TYPES} from '../../../../../../oj-app-common/misc/language-types.enum';
import {SlideshowComponent, SlideshowItem} from '../../../../components/slideshow/slideshow.component';
import {AppAdsService} from '../../../../services/app-ads.service';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {_} from '../../../../../../oj-app-common/i18n/translate-placeholder';
import {ModalContentComponent} from '../../../../components/modal-content/modal-content.component';
import {RubyCalculatorService} from '../eshop-page/services/ruby-calculator-service';
import {EshopUserPreferencesService} from '../eshop-page/services/eshop-user-preferences.service';
import {PaymentDialogService} from '../../../../components/payment-dialogs/payment-dialog.service';
import {EshopApi} from '../../../../../../oj-app-common/eshop-api/eshop-api';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss', './sidebar-activity.scss', './sidebar-today.scss'],
})
export class DashboardPageComponent extends ActivityComponent implements OnInit, AfterViewInit, OnDestroy {
  loaded = false;

  @ViewChild(SidebarComponent, {static: true}) sidebar: SidebarComponent;

  @ViewChild(IntroTutorialModalComponent, {static: false}) tutorialModal: IntroTutorialModalComponent;

  @ViewChild(SlideshowComponent, {static: false}) slideshowComponent: SlideshowComponent;

  public currentCourse: AvailableCourse;

  currentCourseSubscriber: Subscription;

  availableCourseSubscriber: Subscription;

  slideshowItems: SlideshowItem[] = [];

  isFullyLoadedBanner = false;

  showYellowBanner = false;

  slideshowTimeout = 0;

  modalRef: BsModalRef;

  constructor(
    protected _loginService: LoginService,
    protected router: Router,
    protected translateService: TranslateService,
    protected studovnaApi: StudovnaApi,
    protected currentUserService: CurrentUserService,
    protected themeService: ThemeService,
    protected vocabularyService: VocabularyService,
    protected currentLessonService: CurrentLessonSeriesService,
    protected currentCourseService: CurrentCourseService,
    protected overlayDialogService: OverlayService,
    protected baseLayoutService: BaseLayoutService,
    protected messageService: MessageService,
    protected loadingService: LoadingService,
    protected modalService: BsModalService,
    protected tutorial: TutorialService,
    protected helpcrunchService: HelpcrunchService,
    protected appConfig: AppConfig,
    protected appAdsService: AppAdsService,
    protected rubyCalcService: RubyCalculatorService,
    protected eshopPrefs: EshopUserPreferencesService,
    protected paymentService: PaymentDialogService,
    protected eshopApi: EshopApi,
  ) {
    super(
      _loginService,
      router,
      translateService,
      studovnaApi,
      currentUserService,
      themeService,
      vocabularyService,
      currentLessonService,
      currentCourseService,
      overlayDialogService,
      baseLayoutService,
      messageService,
      loadingService,
      modalService,
      tutorial,
    );
  }

  ngOnInit() {
    this.loadingService.hide();

    this.appAdsService
      .showYellowBanner
      .subscribe(v => {
        this.showYellowBanner = v
        this.generateSlideshow();
      });

    this.loadDashboard();
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy() {
    if (this.currentCourseSubscriber) {
      this.currentCourseSubscriber.unsubscribe();
    }

    if (this.availableCourseSubscriber) {
      this.availableCourseSubscriber.unsubscribe();
    }
  }

  async generateSlideshow() {
    const lang = this.translateService.currentLang as ('cs' | 'sk' | 'en' | 'es' | 'pl');

    this.slideshowItems = [];

    this.isFullyLoadedBanner = false;

    const currLang = this.translateService.currentLang;

    const today = new Date();

    const bannersData = await this.eshopApi
      .dashboardBanners(
        lang,
        this.currentUserService.token,
      );

    if (bannersData) {

      bannersData.forEach(banner => {

        // const showBanner = today >= banner.dateFrom && today <= banner.dateTo && banner.active;
        const showBanner = true;

        if (showBanner) {

          const item = {
            backgroundImage: '',
            action: () => {
              window.open(banner.link, '_blank');
            },
          };

          if (banner.imageDesktop && banner.imageDesktop.length > 0) {
            item.backgroundImage = banner.imageDesktop[0].url;
          }

          this.slideshowItems.push(item);
        }

      });

      this.slideshowTimeout = 7000;

    }

    this.slideshowItems.push({
      backgroundImage: '/assets/img/shop/temp-banners/mobily.png',
      action: () => null,
      type: 'phones',
    });

    if (!this.currentCourseService.getCurrentAvailableCourse()) {
      this.slideshowTimeout = 0;
      return;
    }

    if (this.showYellowBanner && currLang === 'es') {
      this.slideshowItems.push({
        backgroundImage: '/assets/img/shop/temp-banners/es-banner.png',
        action: () => {
          this.onSubscribeButtonClick();
        },
      });
      this.slideshowTimeout = 3000;
    } else if (this.showYellowBanner && currLang === 'cs') {
      this.slideshowItems.push({
        backgroundImage: '/assets/img/shop/temp-banners/cs-banner-pro.png',
        action: () => {
          this.onSubscribeButtonClick();
        },
      });
      this.slideshowTimeout = 7000;
    }

    this.isFullyLoadedBanner = true;

  }

  async onSubscribeButtonClick() {
    const rubyCalc = await this.rubyCalcService.getCalculator();
    const courseId = this.currentCourseService.courseId;
    const productId = this.currentCourseService.productId;
    const courseCombinedModel = rubyCalc.getCourseData(courseId);
    const shopOptions = rubyCalc.getShopOptionsOfCourse(courseCombinedModel.id_study, this.eshopPrefs.currency);
    try {
      await this.paymentService.handleButtonClick(
        'get', courseCombinedModel, shopOptions, productId,
      );
    } catch (e) {
      console.warn(e);
    }
  }

  async loadDashboard() {
    this.loadingService.show();

    this.themeService.setDarkTheme();

    await this.helpcrunchService.initialize();

    this.reloadData()
      .then(() => {
        this.loadingService.hide();
        setTimeout(() => {
          this.checkAlertForContinueInStudy();
        }, 1500);
      })
      .catch(() => this.loadingService.hide());

    this.availableCourseSubscriber = this.currentCourseService
      .onAvailableCourseChanged
      .subscribe(v => {
        this.currentCourse = v;
        this.generateSlideshow();
      });

    setTimeout(() => {
      this.currentCourseSubscriber = this.currentCourseService
        .onSwitchCourse
        .subscribe(() => {
          this.reloadData()
        });
    }, 5000);

    this.loading
      .subscribe(v => {
        this.loaded = v;
      });

    setTimeout(() => {
      this.baseLayoutService.sidebarContent.next(DashboardSidebarContentComponent);
    }, 50);
  }

  checkAlertForContinueInStudy() {
    if (this.dailyTasksTotal === this.dailyTasksCompleted && !this.isTodayGoalReached) {

      const initState = {
        position: 'center',
        title: _('Denní cíl'),
        body: '<p>' + _('continue-in-study') + '</p>',
        buttons: [
          {
            text: _('Pokračovat'),
            class: ['rounded-button-primary rounded-button'],
            role: 'cancel',
          },
        ],
      };
      const modalOptions: ModalOptions = new ModalOptions();
      modalOptions.initialState = initState;
      modalOptions.class = 'modal-dialog-centered custom-modal';
      this.modalRef = this.modalService
        .show(ModalContentComponent, modalOptions);

    }

  }

  onLanguageChange(e: MatSelectChange) {
    this.translateService.use(e.value);
  }

  toggleSidebar() {
    this.sidebar.toggleSidebar();
  }

  onThemeChange(val) {
    this.sidebar.options.theme = val.value;
  }

  onPositionChange(val) {
    this.sidebar.options.position = val.value;
    this.sidebar.animate();
  }
}
