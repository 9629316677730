import moment from 'moment';
import {_} from '../../../../../../../oj-app-common/i18n/translate-placeholder';

export function checkExpireDate(expireDate: Date, now: Date = null, expireSoonMeansHowManyDays = 7): ExpirationStatus {

  let expiredWarning = '';
  let expireWarningHtml = '';
  let willExpireText = '';
  let willExpireHtml = '';
  let willExpireSoon = false;
  if (expireDate) {
    const nowMoment = moment(now || new Date());
    const expireDay = moment(expireDate);
    if (expireDay.isBefore(nowMoment, 'day')) {
      let expireDateString = expireDay.format('D. M.');
      if (!expireDay.isSame(nowMoment, 'year')) {
        expireDateString += ' ' + expireDay.format('Y');
      }
      expiredWarning = _('Kurz expiroval') + ' ' + expireDateString;
      expireWarningHtml = _('Kurz expiroval') + ' <time>' + expireDateString + '</time>';
    }
    if (expireDay.isSameOrAfter(nowMoment, 'day')) {
      let willExpireString = expireDay.format('D. M.');
      if (!expireDay.isSame(nowMoment, 'year')) {
        willExpireString += ' ' + expireDay.format('Y');
      }
      willExpireText = _('shop-course-subscribed-until') + ' ' + willExpireString;
      willExpireHtml = _('shop-course-subscribed-until') + ' <time>' + willExpireString + '</time>';
    }
    if (expireDay.isSameOrAfter(nowMoment) && expireDay.diff(nowMoment, 'd') <= expireSoonMeansHowManyDays) {
      willExpireSoon = true;
    }
  }

  return {
    isExpired: !!expiredWarning,
    expireWarning: expiredWarning,
    expireDate,
    willExpireText,
    willExpireHtml,
    expireWarningHtml,
    willExpireSoon,
  };

}


export interface ExpirationStatus {
  isExpired: boolean;
  expireWarning: string;
  expireWarningHtml: string;
  willExpireText: string;
  willExpireHtml: string;
  willExpireSoon: boolean;
  expireDate?: Date;
}

export function createNotExpiredStatus(): ExpirationStatus {
  return {
    isExpired: false,
    expireWarningHtml: '',
    expireWarning: '',
    expireDate: null,
    willExpireHtml: '',
    willExpireSoon: false,
    willExpireText: '',
  };
}
