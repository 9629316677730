import {Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Lesson} from '../../../../oj-app-common/course-structure/models/lesson';
import {ActivityTimer} from '../../common/activity-timer';
import { COMPONENT_TYPE } from './component-type.enum';
import { LESSON_TYPE } from 'oj-app-common/course-structure/models/lesson-type.enum';
import { LESSON_CONTENT_TYPE } from 'oj-app-common/course-structure/models/lesson-content-type.enum';
import {EXERCISE_TYPE} from '../../../../oj-app-common/course-structure/models/exercise-type.enum';
import {DragDropAssignTextToImageExercise} from '../../../../oj-app-common/exercises/assign-text-to-image/drag-drop-assign-text-to-image-exercise';
import {FillinAssignTextToImageExercise} from '../../../../oj-app-common/exercises/assign-text-to-image/fillin-assign-text-to-image-exercise';
import {DragDropFillUpExercise} from '../../../../oj-app-common/exercises/fill-up/drag-drop-fill-up-exercise';
import {WriteFillUpExercise} from '../../../../oj-app-common/exercises/fill-up/write-fill-up-exercise';
import {StudovnaApi} from '../../../../oj-app-common/studovna-api/studovna-api';
import {CurrentUserService} from '../../auth/current-user.service';
import {LessonPageController} from './lesson-page-controller';
import {DomSanitizer} from '@angular/platform-browser';
import {CurrentCourseService} from '../../services/current-course-service';
import {MediaFilesService} from '../../services/media-files.service';
import {SidebarService} from '../../components/sidebar/sidebar.service';
import {CurrentLessonSeriesService} from '../../services/current-lesson-series-service';
import {DragulaService} from 'ng2-dragula';
import {Router} from '@angular/router';
import {WhitespaceTruncatePipe} from '../../pipes/whitespace-truncate.pipe';
import {SoundService} from '../../common/sound/sound.service';
import {AppTranslationService} from '../../services/app-translation.service';
import {RestoreLessonService} from './lesson/restore-lesson.service';
import {ReportBugService} from '../../services/report-bug.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {TtsService} from '../../sound/tts.service';
import {MessageService} from 'primeng/api';
import {ExerciseToleranceService} from '../../services/exercise-tolerance.service';

@Component({
  selector: 'app-base',
  template: 'Base component'
})
export class BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  lesson: Lesson;
  @Input()
  content: any;
  @Input()
  controller: LessonPageController;

  public timer: ActivityTimer;

  public static getLessonComponentType(lesson: Lesson, lessonData: any): COMPONENT_TYPE {
    let parsedLessonData: any;
    let typeOfLesson: string;

    if (lesson.type === LESSON_TYPE.CONTENT) {
      switch (lesson.subType) {

        case LESSON_CONTENT_TYPE.TEXT:
          return COMPONENT_TYPE.CONTENT_TEXT;

        case LESSON_CONTENT_TYPE.VIDEO:
          return COMPONENT_TYPE.CONTENT_VIDEO;

        case LESSON_CONTENT_TYPE.PRONOUNCE:
          return COMPONENT_TYPE.CONTENT_PRONOUNCE;

        default:
          return COMPONENT_TYPE.CONTENT_TEXT;
      }
    }
    if (lesson.type === LESSON_TYPE.VOCABULARY) {
      return COMPONENT_TYPE.VOCABULARY;
    }
    if (lesson.type === LESSON_TYPE.PHRASE) {
      return COMPONENT_TYPE.VOCABULARY_PHRASE;
    }
    if (lesson.type === LESSON_TYPE.EXERCISE) {
      switch (lesson.subType) {
        case EXERCISE_TYPE.TRUE_FALSE:
          return COMPONENT_TYPE.TRUE_FALSE;

        case EXERCISE_TYPE.NOT_IN_GROUP:
          return COMPONENT_TYPE.NOT_IN_GROUP;

        case EXERCISE_TYPE.TEST:
          return COMPONENT_TYPE.TEST;

        case EXERCISE_TYPE.SORT_WORDS:
          return COMPONENT_TYPE.SORT_WORDS;

        case EXERCISE_TYPE.SORT_SENTENCES:
          return COMPONENT_TYPE.SORT_SENTENCES;

        case EXERCISE_TYPE.ASSIGN_TO_GROUPS:
          return COMPONENT_TYPE.ASSIGN_TO_GROUPS;

        case EXERCISE_TYPE.CROSSWORD:
          return COMPONENT_TYPE.CROSSWORD;

        case EXERCISE_TYPE.ASSIGN_TEXT_TO_IMAGE:
          parsedLessonData = JSON.parse(lessonData.data);
          typeOfLesson = parsedLessonData.type;
          if (typeOfLesson === DragDropAssignTextToImageExercise.typeString) {
            return COMPONENT_TYPE.ASSIGN_TEXT_TO_IMAGE_DRAG_DROP;
          } else if (typeOfLesson === FillinAssignTextToImageExercise.typeString) {
            return COMPONENT_TYPE.ASSIGN_TEXT_TO_IMAGE_FILL_IN;
          } else {
            throw new Error('Lesson data does not contain a valid [type] field.');
          }

        case EXERCISE_TYPE.FILLUP:
          parsedLessonData = JSON.parse(lessonData.data);
          typeOfLesson = parsedLessonData.type;
          if (typeOfLesson === DragDropFillUpExercise.fillUpType) {
            return COMPONENT_TYPE.FILLUP_DRAG_DROP;
          } else if (typeOfLesson === WriteFillUpExercise.fillUpType) {
            return COMPONENT_TYPE.FILLUP_WRITE;
          } else {
            throw new Error('Lesson data does not contain a valid [type] field.');
          }
      }
    }

    return COMPONENT_TYPE.DEFAULT;
  }

  public static getLessonComponentTypeWithoutLesson(lessonData: any): COMPONENT_TYPE {
    let parsedLessonData: any;
    let typeOfLesson: string;

    if (lessonData.lessonType === LESSON_TYPE.CONTENT) {
      switch (lessonData.specificLessonType) {

        case LESSON_CONTENT_TYPE.TEXT:
          return COMPONENT_TYPE.CONTENT_TEXT;

        case LESSON_CONTENT_TYPE.VIDEO:
          return COMPONENT_TYPE.CONTENT_VIDEO;

        case LESSON_CONTENT_TYPE.PRONOUNCE:
          return COMPONENT_TYPE.CONTENT_PRONOUNCE;

        default:
          return COMPONENT_TYPE.CONTENT_TEXT;
      }
    }
    if (lessonData.lessonType === LESSON_TYPE.VOCABULARY) {
      return COMPONENT_TYPE.VOCABULARY;
    }
    if (lessonData.lessonType === LESSON_TYPE.PHRASE) {
      return COMPONENT_TYPE.VOCABULARY_PHRASE;
    }
    if (lessonData.lessonType === LESSON_TYPE.EXERCISE) {
      switch (lessonData.specificLessonType) {
        case EXERCISE_TYPE.TRUE_FALSE:
          return COMPONENT_TYPE.TRUE_FALSE;

        case EXERCISE_TYPE.NOT_IN_GROUP:
          return COMPONENT_TYPE.NOT_IN_GROUP;

        case EXERCISE_TYPE.TEST:
          return COMPONENT_TYPE.TEST;

        case EXERCISE_TYPE.SORT_WORDS:
          return COMPONENT_TYPE.SORT_WORDS;

        case EXERCISE_TYPE.SORT_SENTENCES:
          return COMPONENT_TYPE.SORT_SENTENCES;

        case EXERCISE_TYPE.ASSIGN_TO_GROUPS:
          return COMPONENT_TYPE.ASSIGN_TO_GROUPS;

        case EXERCISE_TYPE.CROSSWORD:
          return COMPONENT_TYPE.CROSSWORD;

        case EXERCISE_TYPE.ASSIGN_TEXT_TO_IMAGE:
          parsedLessonData = JSON.parse(lessonData.data);
          typeOfLesson = parsedLessonData.type;
          if (typeOfLesson === DragDropAssignTextToImageExercise.typeString) {
            return COMPONENT_TYPE.ASSIGN_TEXT_TO_IMAGE_DRAG_DROP;
          } else if (typeOfLesson === FillinAssignTextToImageExercise.typeString) {
            return COMPONENT_TYPE.ASSIGN_TEXT_TO_IMAGE_FILL_IN;
          } else {
            throw new Error('Lesson data does not contain a valid [type] field.');
          }

        case EXERCISE_TYPE.FILLUP:
          parsedLessonData = JSON.parse(lessonData.data);
          typeOfLesson = parsedLessonData.type;
          if (typeOfLesson === DragDropFillUpExercise.fillUpType) {
            return COMPONENT_TYPE.FILLUP_DRAG_DROP;
          } else if (typeOfLesson === WriteFillUpExercise.fillUpType) {
            return COMPONENT_TYPE.FILLUP_WRITE;
          } else {
            throw new Error('Lesson data does not contain a valid [type] field.');
          }
      }
    }

    return COMPONENT_TYPE.DEFAULT;
  }

  constructor(
    protected studovnaApi: StudovnaApi,
    protected currentUser: CurrentUserService,
    protected currentCourseService: CurrentCourseService,
    protected sanitizer: DomSanitizer,
    protected mediaFiles: MediaFilesService,
    protected tts: TtsService,
    protected elementRef: ElementRef,
    protected zone: NgZone,
    protected sidebarService: SidebarService,
    protected lessonSeriesService: CurrentLessonSeriesService,
    protected dragulaService: DragulaService,
    protected router: Router,
    protected whitespaceTruncatePipe: WhitespaceTruncatePipe,
    protected modalService: BsModalService,
    protected soundService: SoundService,
    protected appTranslationService: AppTranslationService,
    protected restoreLessonService: RestoreLessonService,
    protected reportBugService: ReportBugService,
    protected messageService: MessageService,
    protected toleranceService: ExerciseToleranceService
  ) {
    if (!this.timer) {
      // TODO nastavit na 30
      this.timer = new ActivityTimer(60);
    }
  }


  public logTimeActivity(submit = true): (null | { start: Date, end: Date, idle: number }) {
    this.timer.stop();

    const startTime = this.timer.startTime;
    const endTime = this.timer.endTime;
    let idle = 0;

    const hasSomePlayedAudioOrVideoTime = this.controller.getPlayedAudioOrVideoTime();

    const shortenedTimeBreakpoint = 240;

    if (hasSomePlayedAudioOrVideoTime && hasSomePlayedAudioOrVideoTime > 0) {
      idle -= hasSomePlayedAudioOrVideoTime;

      if (idle < 0) {
        idle = 0;
      }

      if (hasSomePlayedAudioOrVideoTime > shortenedTimeBreakpoint) {
        startTime.setTime(endTime.getTime() - hasSomePlayedAudioOrVideoTime * 1000);
      }
    }

    this.timer.reset();

    if (!startTime) {
      // Pod 1 sekund aktivitu nelogujeme.
      return;
    }

    if (this.controller && this.controller.getIsPreview()) {
      return;
    }

    if (submit) {
      try {
        this.studovnaApi.logActivity(
          this.currentUser.studovnaId,
          this.currentUser.token,
          this.lesson.id,
          startTime,
          endTime,
          idle,
          0,
          false,
          !(hasSomePlayedAudioOrVideoTime > shortenedTimeBreakpoint)
        ).then(
          (response) => {
            if (response.achievement) {
              this.controller.receivedAchievements(response.achievement);
            }
          }
        ).catch(
          (error) => {
            console.error(error);
          }
        )
        ;
      } catch (error) {
        console.error(error);
      }

    }

    return {
      start: startTime,
      end: endTime,
      idle,
    };

  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    if (this.timer) {
      if (this.timer.running) {
        this.logTimeActivity(true);
      }

      setTimeout(() => {
        this.timer.unbindPlatform();
        this.timer.stop();
        this.timer.destroy();
      }, 1000);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.controller) {
      setTimeout(
        () => {
          this.controller.setContentComponent(this);
          if (this.timer) {
            this.timer.start();
            this.controller.setActiveTimer(this.timer);
          }
        },
        0
      );
    }
  }

  getLessonSubtitle(): string {
    return this.lesson.type + ' - ' + this.lesson.subType;
  }
}
